import { Signal } from '@preact/signals-react';
import { find, first, forEach, isNumber, map, values } from 'lodash';

import { Annotation, TodoDefinition } from 'interfaces/annotation';
import {
  LayerVisualizationSettings,
  slidesLayerVisualizationSettings,
} from '../../slidesVisualizationAndConfiguration';

export const getAnnotationLabel = (annotation: Annotation) =>
  `${annotation.annotationAssignment.annotationAssignmentId} - ${annotation.annotationAssignment.name} (${annotation.workingState})`;

export const getAnnotationSettingsKey = (annotation: Annotation, optionName: string) =>
  `${getAnnotationLabel(annotation)}-${optionName}`;

export const getAnnotationTodoNameAndDefinition = (
  annotation: Annotation
): {
  name: string;
  todo: TodoDefinition;
} => {
  const annotationAssignmentTodos = values(annotation?.annotationAssignment?.todos);
  const todoNames = map(annotation?.annotationsData, 'todo');

  if (annotationAssignmentTodos.length > 1) {
    console.warn("Found multiple todos for annotation, shouldn't happen", {
      annotation,
      todoNames,
      annotationAssignmentTodos,
    });
  }
  const todoName = todoNames.length > 0 ? first(todoNames) : 'Unnamed';
  return { name: todoName, todo: first(annotationAssignmentTodos) };
};

export const defaultAnnotationVisualSettings: LayerVisualizationSettings = {
  show: false,
  selected: false,
  opacity: 100,
  color: '#dddddd',
};

export const showNewAnnotationAssignmentIdForSlide = ({
  activeAnnotationAssignmentId,
  slideAnnotations,
  viewerIndex,
  slideId,
}: {
  activeAnnotationAssignmentId: number;
  slideAnnotations: Array<Annotation>;
  viewerIndex: number;
  slideId: string;
}) => {
  if (!isNumber(activeAnnotationAssignmentId)) {
    return;
  }
  const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[viewerIndex];
  if (!viewerSlideLayerVisualizationSettings) {
    console.error('No viewerSlideLayerVisualizationSettings found for viewerIndex', viewerIndex);
    return;
  }
  const newSettings = viewerSlideLayerVisualizationSettings.value;
  if (!newSettings?.[slideId]) {
    newSettings[slideId] = {};
  }
  const slideLayerVisualizationSettings = newSettings[slideId];

  const savedAnnotationData = find(slideAnnotations, {
    annotationAssignment: { annotationAssignmentId: activeAnnotationAssignmentId },
  });
  if (!savedAnnotationData) {
    console.error('No savedAnnotationData found for activeAnnotationAssignmentId', activeAnnotationAssignmentId);
    return;
  }
  const parentLabel = getAnnotationLabel(savedAnnotationData);
  const parentSignal = slideLayerVisualizationSettings?.[parentLabel];
  if (!parentSignal) {
    const defaultSettings = { ...defaultAnnotationVisualSettings, show: true, selected: true };
    slideLayerVisualizationSettings[parentLabel] = new Signal(defaultSettings);
  } else {
    const previousSettings = parentSignal.value;
    parentSignal.value = { ...previousSettings, selected: true, show: true };
  }

  const { todo } = getAnnotationTodoNameAndDefinition(savedAnnotationData);

  forEach(todo?.options, (option) => {
    const annotationSettingsKey = getAnnotationSettingsKey(savedAnnotationData, option?.name);
    const layerSignal = slideLayerVisualizationSettings?.[annotationSettingsKey];
    if (!layerSignal) {
      slideLayerVisualizationSettings[annotationSettingsKey] = new Signal({
        ...defaultAnnotationVisualSettings,
        show: true,
        selected: true,
        ...(option.color ? { color: option.color } : {}),
      });
    } else {
      const previousSettings = layerSignal.value;
      layerSignal.value = { ...previousSettings, selected: true, show: true };
    }
  });

  viewerSlideLayerVisualizationSettings.value = newSettings;
};
