import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  Link as MuiLink,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getModel } from 'api/platform';
import { fetchStudies } from 'api/study';
import { InferenceParams, Job, JobType } from 'interfaces/job';
import { find, first, fromPairs, isBoolean, isEmpty, keyBy, map, startCase, toString } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useUiSettings } from 'utils/queryHooks/uiConstantsHooks';
import useInternalUsersById from 'utils/queryHooks/useInternalUsersById';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { getModelId } from '../CalculateFeatures/utils';
import { classNamesOptions, modelTypeCell, modelTypeOptions } from './inferenceFieldsOptions';
import { PrepareCustomerResultsJobParams } from './PrepareCustomerResultsJobParams';
import { SubtaskDetails } from './SubtaskDetails';
import { stringToDisplay } from './utils';

const SKIP = 'skip';

interface JobDataProps {
  job: Job;
  isLoading?: boolean;
  onClosed?: () => void;
}

export const JobDetails: React.FC<JobDataProps> = ({ job, isLoading = false, onClosed }) => {
  const showDrawerCloseButton = Boolean(onClosed);
  const { labId } = useCurrentLabId();

  const { data: studies } = useQuery(['studies', labId], fetchStudies(labId));
  const modelId = getModelId(first((job?.params as InferenceParams)?.modelPaths));
  const { data: modelData } = useQuery({
    queryKey: ['model', modelId],
    queryFn: ({ signal }) => getModel(modelId, signal),
    enabled: job?.type === JobType.Inference && !isEmpty(modelId),
  });

  const { internalUsersById } = useInternalUsersById({ enabled: Boolean(job?.userId) });

  const { uiSettings } = useUiSettings();
  const currStudy = job?.studyId ? find(studies, (study) => job.studyId === study.id)?.name : undefined;

  const requestSenderToDisplayText = fromPairs(
    map(uiSettings?.enumDisplayNames?.['requestSender'], ({ value, label }) => [value, label]) || []
  );
  const jobStatusToDisplayText = fromPairs(
    map(uiSettings?.enumDisplayNames?.['jobStatus'], ({ value, label }) => [value, label]) || []
  );
  jobStatusToDisplayText[SKIP] = 'Skipped';

  const modelTypeOptionsByValue = keyBy(modelTypeOptions, 'value');
  const classNamesOptionsByValue = keyBy(classNamesOptions, 'value');

  const assignmentIds =
    job?.type === JobType.Inference ? stringToDisplay((job?.params as InferenceParams)?.assignmentIds) : '';
  const classNames =
    job?.type === JobType.Inference
      ? stringToDisplay(
          map(
            (job?.params as InferenceParams)?.classNames,
            (className) => classNamesOptionsByValue[className]?.text || className
          )
        )
      : '';
  // heatmapNames and modelPaths  saved as array but currently there is only one heatmap and model per job
  const modelPaths =
    job?.type === JobType.Inference ? stringToDisplay((job?.params as InferenceParams)?.modelPaths) : '';
  const heatmapNames =
    job?.type === JobType.Inference ? stringToDisplay((job?.params as InferenceParams)?.heatmapNames) : '';

  const runType = job?.type === JobType.Inference ? (job?.params as InferenceParams)?.runType : undefined;

  const tissueMaskFromAnnotations =
    job?.type === JobType.Inference ? (job?.params as InferenceParams)?.tissueMaskFromAnnotations : undefined;

  const tissueSegmentationModelOverride =
    job?.type === JobType.Inference ? (job?.params as InferenceParams)?.tissueSegmentationModelOverride : undefined;

  return (
    <Grid container p={2} direction="column" spacing={2} flexWrap="nowrap">
      <Grid container item alignItems="center">
        <Grid item xs={showDrawerCloseButton ? 10 : 12}>
          <Typography variant="h3">{job?.name ?? job?.orchestrationId}</Typography>
        </Grid>
        {showDrawerCloseButton && (
          <Grid item xs={2} textAlign="right">
            <IconButton onClick={onClosed}>
              <CloseIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>

      <Grid item>
        <Typography variant="body1">{job?.description}</Typography>
      </Grid>

      <Grid item container direction="column">
        <Grid item container spacing={1}>
          <Grid item>
            <Typography variant="body1">Started at: </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1"> {job?.startedAt && moment(job?.startedAt).format('lll')}</Typography>
          </Grid>
        </Grid>
        <Grid item container spacing={1}>
          <Grid item>
            <Typography variant="body1">Source: </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1"> {requestSenderToDisplayText[job?.requestSender]}</Typography>
          </Grid>
        </Grid>
        <Grid item container spacing={1}>
          <Grid item>
            <Typography variant="body1">Run by: </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              {internalUsersById[job?.userId]?.name ?? internalUsersById[job?.userId]?.primaryEmail ?? job?.userId}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container spacing={1}>
        <Grid item>
          <Typography variant="body1">Status Message: </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">{job?.statusMessage ?? ''}</Typography>
        </Grid>
      </Grid>
      <Grid item>
        {isLoading ? (
          <Skeleton variant="rectangular" height={150} />
        ) : (
          <Accordion defaultExpanded slotProps={{ transition: { unmountOnExit: true } }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography variant="h4">External Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container direction="column">
                <Grid item container spacing={1}>
                  <Grid item>
                    <Typography variant="body1">Task Id:</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="body1">{job?.externalTaskId}</Typography>
                  </Grid>
                </Grid>
                <Grid item container spacing={1}>
                  <Grid item>
                    <Typography variant="body1">Link:</Typography>
                  </Grid>
                  <Grid xs={10} item sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    <Tooltip placement="top-start" title={job?.externalTaskLink}>
                      <MuiLink color="inherit" underline="hover" href={job?.externalTaskLink} target="_blank">
                        {job?.externalTaskLink}
                      </MuiLink>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
      </Grid>
      <Grid item>
        {isLoading ? (
          <Skeleton variant="rectangular" height={150} />
        ) : (
          <Accordion defaultExpanded slotProps={{ transition: { unmountOnExit: true } }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography variant="h4">Parameters</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                {heatmapNames && (
                  <Grid item container spacing={1}>
                    <Grid item>
                      <Typography variant="body1">Heatmap name: </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1"> {heatmapNames}</Typography>
                    </Grid>
                  </Grid>
                )}
                <Grid item container spacing={1}>
                  <Grid item>
                    <Typography variant="body1">Study: </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body1"> {currStudy}</Typography>
                  </Grid>
                </Grid>
                {runType && (
                  <Grid item container spacing={1}>
                    <Grid item>
                      <Typography variant="body1">Model type: </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">{modelTypeOptionsByValue[runType]?.text || runType}</Typography>
                    </Grid>
                  </Grid>
                )}
                {job?.type === JobType.Inference && (
                  <Grid item container spacing={1}>
                    <Grid item>
                      <Typography variant="body1">Model: </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Tooltip placement="top-start" title={modelPaths}>
                        <Typography variant="body1" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {modelPaths}
                        </Typography>
                      </Tooltip>
                    </Grid>
                  </Grid>
                )}
                {modelData?.meta?.modelType === modelTypeCell.apiModelValue && (
                  <>
                    <Grid item container spacing={1}>
                      <Grid item>
                        <Typography variant="body1">dedup Value: </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">{(job?.params as InferenceParams)?.dedupValue}</Typography>
                      </Grid>
                    </Grid>
                    <Grid item container spacing={1}>
                      <Grid item>
                        <Typography variant="body1">Use Dynamic Cell Detection: </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">
                          {isBoolean((job?.params as InferenceParams)?.useDynamicCellDetection) &&
                            startCase(toString((job?.params as InferenceParams)?.useDynamicCellDetection))}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item container spacing={1}>
                      <Grid item>
                        <Typography variant="body1">Dynamic Cell Detection Config: </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">
                          {(job?.params as InferenceParams)?.dynamicCellDetectionConfig}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
                {job?.type === JobType.Inference && (
                  <Grid item container spacing={1}>
                    <Grid item>
                      <Typography variant="body1">ROI mask: </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">
                        {isBoolean(tissueMaskFromAnnotations) && startCase(toString(tissueMaskFromAnnotations))}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {!isEmpty(tissueSegmentationModelOverride) && (
                  <Grid item container spacing={1}>
                    <Grid item>
                      <Typography variant="body1">TSM Model: </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Tooltip placement="top-start" title={tissueSegmentationModelOverride}>
                        <Typography variant="body1" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {tissueSegmentationModelOverride}
                        </Typography>
                      </Tooltip>
                    </Grid>
                  </Grid>
                )}
                {tissueMaskFromAnnotations && (
                  <Grid>
                    <Grid item container spacing={1}>
                      <Grid item>
                        <Typography variant="body1">Assignments Ids: </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">{assignmentIds}</Typography>
                      </Grid>
                    </Grid>
                    <Grid item container spacing={1}>
                      <Grid item>
                        <Typography variant="body1">Class names: </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">{classNames}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {job?.type === JobType.PrepareCustomerResults && <PrepareCustomerResultsJobParams job={job} />}
              </Box>
            </AccordionDetails>
          </Accordion>
        )}
      </Grid>
      {job?.type !== JobType.PrepareCustomerResults && <SubtaskDetails job={job} isLoading={isLoading} />}
    </Grid>
  );
};
