import { CohortQueryObject } from 'interfaces/cohort';
import { PrepareCustomerResultsJob, QueryStatistics, ResultForExport } from 'interfaces/job';
import { stringify } from 'qs';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export const prepareCustomerResults = ({
  studyId,
  resultTypesToExport,
  jobId,
  queryObject,
  labId,
}: {
  studyId: string;
  resultTypesToExport: string[];
  jobId?: string;
  queryObject?: CohortQueryObject;
  labId: string;
}) => {
  if (!studyId) {
    console.error('Study ID is required');
    return Promise.reject('Study ID is required');
  }
  return apiRequestHandlerPromise({
    url: `customer_results/${studyId}/prepare?${stringify(
      { resultTypesToExport, jobId, labId },
      {
        arrayFormat: 'repeat',
      }
    )}`,
    method: 'POST',
    data: queryObject ? JSON.stringify({ queryObject }) : undefined,
  });
};

export interface PrepareCustomerResultsJobWithUnpublishedExperimentResultIds extends PrepareCustomerResultsJob {
  unpublishedExperimentResultIds?: number[];
}

export const getCustomerResultsJobs = (
  { studyId, labId }: { studyId: string; labId: string },
  signal?: AbortSignal
) => {
  return apiRequestHandlerPromise<PrepareCustomerResultsJobWithUnpublishedExperimentResultIds[]>({
    url: `customer_results/${studyId}/jobs?${stringify({ labId }, { arrayFormat: 'repeat' })}`,
    method: 'GET',
    signal,
  });
};

export const previewPrepareCustomerResultsManifest = (
  {
    studyId,
    queryObject,
    labId,
  }: {
    studyId: string;
    queryObject?: CohortQueryObject;
    labId: string;
  },
  signal: AbortSignal
) => {
  if (!studyId) {
    console.error('Study ID is required');
    return Promise.reject('Study ID is required');
  }
  return apiRequestHandlerPromise<{
    manifest: ResultForExport[];
    unpublishedExperimentResultIds: number[];
    queryStatistics: QueryStatistics;
  }>({
    url: `customer_results/${studyId}/prepare/preview_manifest?${stringify({ labId })}`,
    method: 'POST',
    data: queryObject ? JSON.stringify({ queryObject }) : undefined,
    signal,
  });
};
