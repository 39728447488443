import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSignals } from '@preact/signals-react/runtime';
import { filter, find, first, fromPairs, isEmpty, join, keys, map } from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useAppSelector } from 'redux/hooks';

import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Grid, Typography } from '@mui/material';
import { getFeaturesByExperimentResultIdQueryKey } from 'api/features';
import {
  generateManualNormalization,
  generateNormalizationOverride,
  getSlideChannelNormalizationsQueryKey,
} from 'api/slideMultiplexChannelNormalizations';
import { FeatureMetadata } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import PresetSection from 'components/atoms/PresetSection';
import { BasePreset } from 'interfaces/basePreset';
import { ExperimentResult, NormalizationResult } from 'interfaces/experimentResults';
import { Permission } from 'interfaces/permissionOption';
import queryClient from 'utils/queryClient';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { useMutationWithErrorSnackbar } from 'utils/useMutationWithErrorSnackbar';
import { usePermissions } from 'utils/usePermissions';
import { useStainTypeIdToDisplayName } from 'utils/useStainTypeIdToDisplayName';
import {
  computeDynamicRangeFromNormalizationOrChannelHistogram,
  slidesChannelNormalizationSettings,
} from './channelNormalizations';

interface Props {
  slide: SlideWithChannelAndResults;
  currentNormalizationId: number | null;
  isLoadingNormalizationData: boolean;
  isLoadingNormalizationOptions: boolean;
  normalizationData: NormalizationResult;
  isNormalizationFromDifferentStudy: boolean;
  defaultNormalization: ExperimentResult | null;
  normalizationOptions: ExperimentResult[];
  setCurrentNormalizationId: (id: number) => void;
}

const MANUAL_NORMALIZATION_WITHOUT_SAVING = 'Manual';
const MANUAL_NORMALIZATION_UNSAVED = 'Manual (unsaved)';
const MANUAL_NORMALIZATION_ID = 'manual';

export const MultiplexChannelNormalizationSection: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  slide,
  currentNormalizationId,
  isLoadingNormalizationData,
  isLoadingNormalizationOptions,
  isNormalizationFromDifferentStudy,
  normalizationData,
  defaultNormalization,
  normalizationOptions,
  setCurrentNormalizationId,
}) => {
  useSignals();
  const { hasPermission } = usePermissions();

  const canViewUnpublishedResults = hasPermission(Permission.ViewUnpublishedResults);

  const { stainTypeIdToDisplayName, isLoadingStainTypeOptions } = useStainTypeIdToDisplayName();

  const viewerSlidesChannelNormalizationSettings = slidesChannelNormalizationSettings[slide.viewerIndex];

  const slideId = slide.id;
  const studyId = slide.studyId;

  const { labId } = useCurrentLabId();
  const { name: userName } = useAppSelector((state) => state.auth.profile);

  const onMutationSuccess = (result: ExperimentResult) => {
    const normalizationsQueryKey = getSlideChannelNormalizationsQueryKey({ id: slideId, labId: slide.labId ?? labId });
    setCurrentNormalizationId(result.experimentResultId);
    queryClient.cancelQueries(normalizationsQueryKey);
    // Keep the most recent normalizations at the top
    queryClient.setQueryData(normalizationsQueryKey, (prev: ExperimentResult[]) => [result, ...prev]);
    const featureValuesQueryKey = getFeaturesByExperimentResultIdQueryKey({
      experimentResultId: result?.experimentResultId,
      labId: slide?.labId,
    });
    if (result?.featureValues) {
      queryClient.cancelQueries(featureValuesQueryKey);
      queryClient.setQueryData(featureValuesQueryKey, result.featureValues);
    } else {
      console.warn('No feature values found for manual normalization', result);
    }
  };

  const generateNormalizationOverrideMutation = useMutationWithErrorSnackbar({
    onSuccess: onMutationSuccess,
    mutationFn: generateNormalizationOverride,
    mutationDescription: 'saving multiplex channel normalization override',
  });

  const saveManualNormalizationMutation = useMutationWithErrorSnackbar({
    onSuccess: onMutationSuccess,
    mutationFn: generateManualNormalization,
    mutationDescription: 'saving manual multiplex channel normalization',
  });

  const isLoading =
    isLoadingNormalizationOptions ||
    isLoadingNormalizationData ||
    generateNormalizationOverrideMutation.isLoading ||
    saveManualNormalizationMutation.isLoading;

  const settingsForAllChannels = viewerSlidesChannelNormalizationSettings.value[slideId];

  const channelSettingsPairs = map(
    slide.channelsMetadata,
    (channel): [string, [min: number, max: number] | undefined] => [
      channel.id,
      settingsForAllChannels?.[channel.id]?.value,
    ]
  );
  const channelOverrides = fromPairs(
    filter(channelSettingsPairs, ([channelId, range]) => {
      if (!range) {
        return false;
      } else if (!normalizationData) {
        // Save all channels if no current normalization data is available
        return true;
      }
      const channel = slide?.channels?.[channelId];
      const currentRange = computeDynamicRangeFromNormalizationOrChannelHistogram(
        Number(channelId),
        channel?.histogram,
        normalizationData,
        slide.encoding === 'uint16'
      );
      // Save the channel if the range has changed
      return !currentRange || range[0] !== currentRange[0] || range[1] !== currentRange[1];
    })
  );

  const hasOverrides = !currentNormalizationId || (channelOverrides && !isEmpty(channelOverrides));
  const canSave =
    canViewUnpublishedResults && (hasOverrides || isNormalizationFromDifferentStudy || !currentNormalizationId);

  const normalizationPresets: Array<BasePreset & Omit<FeatureMetadata, 'createdAt'>> = useMemo(() => {
    return isLoading
      ? [{ id: 'loading', name: 'Loading...', createdBy: userName, labId, createdAt: new Date(), published: false }]
      : !isEmpty(normalizationOptions)
      ? map(normalizationOptions, ({ name: normalizationName, ...normalization }) => {
          return {
            id: `${normalization.experimentResultId}`,
            name: `(${moment(normalization.createdAt).format('YYYY-MM-DD HH:mm')})${
              normalizationName ? ` ${normalizationName}` : ''
            }${normalization.approved ? ' (approved)' : ''}`,
            labId,
            ...normalization,
            createdAt: moment(normalization.createdAt).toDate(),
            createdBy: normalization.createdBy,
            published: normalization.approved,
          };
        })
      : [
          {
            id: MANUAL_NORMALIZATION_ID,
            // If the user can't view unpublished results, they can't save a new normalization (which would be unpublished by default)
            name: canViewUnpublishedResults ? MANUAL_NORMALIZATION_UNSAVED : MANUAL_NORMALIZATION_WITHOUT_SAVING,
            createdBy: userName,
            labId,
            createdAt: new Date(),
            published: false,
          },
        ];
  }, [normalizationOptions, userName, labId, isLoading]);

  const currentNormalizationPreset = find(normalizationPresets, {
    id: `${currentNormalizationId ?? MANUAL_NORMALIZATION_ID}`,
  });

  const manualNormalizationPreset = find(normalizationPresets, { id: MANUAL_NORMALIZATION_ID });

  const defaultNormalizationPreset =
    find(normalizationPresets, {
      id: `${defaultNormalization?.experimentResultId ?? MANUAL_NORMALIZATION_ID}`,
    }) ?? first(normalizationPresets);

  const saveSlideNormalization = (name: string, description?: string) => {
    if (currentNormalizationId) {
      generateNormalizationOverrideMutation.mutate({
        labId,
        name,
        slideId,
        channelOverrides,
        description,
        baseExperimentResultId: currentNormalizationId,
        studyId,
      });
    } else {
      saveManualNormalizationMutation.mutate({
        labId,
        name,
        slideId,
        channelOverrides,
        description,
        studyId,
      });
    }
  };

  const selectChannelsNormalizations = (preset: Partial<BasePreset>) =>
    setCurrentNormalizationId(!isNaN(Number(preset.id)) ? Number(preset.id) : null);

  const failedToLoadNormalizationData =
    !isLoadingNormalizationData && !normalizationData && Boolean(currentNormalizationId);

  return (
    <PresetSection
      defaultPreset={defaultNormalizationPreset}
      selectedPreset={!failedToLoadNormalizationData ? currentNormalizationPreset : manualNormalizationPreset}
      presets={normalizationPresets}
      // If the user can't view unpublished results, they can't save a new normalization (which would be unpublished by default)
      onSavePreset={canSave && !isLoading ? saveSlideNormalization : undefined}
      onSelectPreset={selectChannelsNormalizations}
      label={
        <>
          Normalization Configurations{' '}
          {isLoading && <CircularProgress size={18.5} title="Loading normalization data..." />}
        </>
      }
      isLoading={isLoading}
      description={
        <Grid item container direction="column" rowGap={1}>
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              Channel dynamic ranges are used in inference.
            </Typography>
          </Grid>
          {!isLoading && !currentNormalizationId ? (
            <Grid item>
              <Typography variant="body2" color="textSecondary">
                No normalization is currently set. Saving the current channel ranges will be create a new manual
                normalization.
              </Typography>
            </Grid>
          ) : (
            <Grid item>
              <Accordion disableGutters elevation={0}>
                <AccordionSummary sx={{ p: 0 }} expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="body1" color="textSecondary">
                    {isLoading ? 'Loading...' : `${keys(channelOverrides).length} overridden channels`}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    {!isLoading && hasOverrides ? (
                      map(channelOverrides, (range, channelId) => {
                        return (
                          <Grid
                            item
                            container
                            key={channelId}
                            direction="row"
                            justifyContent="space-between"
                            columnGap={1}
                          >
                            <Typography variant="body1" color="textSecondary">
                              {channelId}.
                              {isLoadingStainTypeOptions
                                ? 'Loading...'
                                : slide?.channelMarkerTypes?.[channelId]
                                ? ` ${stainTypeIdToDisplayName(slide.channelMarkerTypes[channelId])}:`
                                : ''}
                            </Typography>
                            <Typography variant="body1" color="textSecondary">
                              {join(range, ' - ')}
                            </Typography>
                          </Grid>
                        );
                      })
                    ) : (
                      <Grid item>
                        <Typography variant="body2" color="textSecondary">
                          Change and save new configurations to use them instead of the calculated ones.
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
              {isNormalizationFromDifferentStudy && (
                <Grid item pt={1}>
                  <Typography variant="body2" color="textSecondary">
                    The current normalization is from a different study. Save it if you want it to be available for
                    customers who view the slide in this study.
                  </Typography>
                </Grid>
              )}
            </Grid>
          )}
          {failedToLoadNormalizationData && (
            <Grid item>
              <Typography variant="body2" color="error">
                Failed to load normalization data - falling back to default channel ranges.
              </Typography>
            </Grid>
          )}
        </Grid>
      }
    />
  );
};

export default MultiplexChannelNormalizationSection;
