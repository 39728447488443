import React, { useMemo, useState } from 'react';

import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import { Box, Divider, Grid, IconButton, Popover, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import { GridToolbar } from '@mui/x-data-grid';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Tooltip as ChartTooltip,
  Legend,
  LinearScale,
  Title,
} from 'chart.js';
import { AnnotationAssignment } from 'interfaces/annotation';
import { flatMap, forEach, includes, keys, reduce, sum, values } from 'lodash';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, ChartTooltip, Legend);

const AnnotationAssignmentToolbar: React.FC<{ annotationAssignment: AnnotationAssignment }> = ({
  annotationAssignment,
}) => {
  const theme = useTheme();
  const slideIdCount = annotationAssignment?.annotations?.length;

  const annotationsCount = sum(
    flatMap(annotationAssignment?.annotations, (annotation) => sum(values(annotation?.annotationSummary)))
  );
  const [barChartAnchorEl, setBarChartAnchorEl] = useState<null | HTMLElement>(null);

  const handleChartPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setBarChartAnchorEl(event.currentTarget);
  };

  const handleChartPopoverClose = () => {
    setBarChartAnchorEl(null);
  };

  const chartOpen = Boolean(barChartAnchorEl);

  const summaryAnnotationsPerClass = useMemo(() => {
    let result: {
      [key: string]: number;
    } = {};

    forEach(annotationAssignment?.annotations, (annotation) => {
      forEach(annotation.annotationSummary, (value, key) => {
        result[key] = (result[key] || 0) + value;
      });
    });

    return result;
  }, [annotationAssignment]);

  const roisCount = useMemo(
    () =>
      reduce(
        summaryAnnotationsPerClass,
        (totalSum, value, key) => {
          if (includes(key, 'roi')) {
            return totalSum + value;
          }
          return totalSum;
        },
        0
      ),
    [summaryAnnotationsPerClass]
  );

  const data = useMemo(
    () => ({
      labels: keys(summaryAnnotationsPerClass),
      datasets: [
        {
          label: 'Annotations',
          data: values(summaryAnnotationsPerClass),
          backgroundColor: theme.palette.primary.main,
        },
      ],
    }),
    [summaryAnnotationsPerClass, theme]
  );

  const options = useMemo(
    () => ({
      responsive: true,
      indexAxis: 'y' as const,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          position: 'top' as const,
          display: true,
          text: `${annotationsCount} Annotations`,
        },
      },
    }),
    [annotationsCount]
  );

  return (
    <Grid container direction="row" spacing={1} justifyContent="space-between">
      <Grid item container xs={6} direction="row" spacing={1} m={0.5}>
        <Grid item mr={1}>
          <GridToolBarStatCell title="slides" data={slideIdCount} />
        </Grid>
        <Grid item>
          <Divider orientation="vertical" />
        </Grid>
        <Grid item mr={1}>
          <GridToolBarStatCell title="Training ROIs" data={roisCount} />
        </Grid>
        <Grid item>
          <Divider orientation="vertical" />
        </Grid>
        <Grid item>
          <Grid container direction="row" spacing={1}>
            <Grid item>
              <IconButton
                sx={{
                  borderRadius: '4px',
                  backgroundColor: theme.palette.mode == 'light' ? theme.palette.grey[200] : theme.palette.grey[900],
                }}
                onClick={handleChartPopoverOpen}
              >
                <BarChartRoundedIcon />
              </IconButton>
              <Popover
                open={chartOpen}
                anchorEl={barChartAnchorEl}
                onClose={handleChartPopoverClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <Box sx={{ minWidth: 500, width: data.labels.length * 50, maxWidth: 1000, maxHeight: 400 }} m={2} p={1}>
                  <Bar data={data} options={options} />
                </Box>
              </Popover>
            </Grid>
            <Grid item>
              <GridToolBarStatCell title="Annotations" data={annotationsCount} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item justifyItems="end" alignContent="center" mr={0.5}>
        <GridToolbar />
      </Grid>
    </Grid>
  );
};

const GridToolBarStatCell: React.FC<{ title: string; data: any }> = ({ title, data }) => {
  return (
    <Grid container direction="column">
      <Grid item>
        <Typography variant="body1" fontWeight="bold">
          {data}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle2">{title}</Typography>
      </Grid>
    </Grid>
  );
};

export default AnnotationAssignmentToolbar;
