import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Link,
  TableCell,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { QueryStatistics, ResultForExport } from 'interfaces/job';
import { filter, includes, isEmpty, isNumber, lowerCase, orderBy, size, toNumber, trim, uniqBy } from 'lodash';
import React, { useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { TableVirtuoso } from 'react-virtuoso';
import { useDebounce } from 'use-debounce';

import { useCurrentLabId } from 'utils/useCurrentLab';
import { useNavigationToViewerPage } from 'utils/useNavigationToViewerPage';

export const PrepareCustomerResultsJobManifestTable: React.FC<{
  previewMode?: boolean;
  manifest: ResultForExport[];
  studyId: string;
  unpublishedExperimentResultIds: number[];
  tableHeight?: number | string;
  queryStatistics?: QueryStatistics;
}> = ({ previewMode, manifest, queryStatistics, studyId, unpublishedExperimentResultIds, tableHeight = 300 }) => {
  const theme = useTheme();
  const { getUrlToSlidePage, getUrlToCasePage } = useNavigationToViewerPage();
  const { labId } = useCurrentLabId();

  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderByColumn, setOrderByColumn] = useState<keyof ResultForExport>('caseId');
  const [textFilterState, setTextFilter] = useState<string>('');

  const [textFilter] = useDebounce(lowerCase(trim(textFilterState)), 200, {
    leading: true,
    trailing: true,
  });

  const handleSort = (column: keyof ResultForExport) => {
    const isAsc = orderByColumn === column && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderByColumn(column);
  };

  const filteredManifestResults = useMemo(
    () =>
      textFilter
        ? filter(
            manifest,
            (manifestResult) =>
              lowerCase(`${manifestResult.caseId}`).includes(textFilter) ||
              lowerCase(`${manifestResult.slideId}`).includes(textFilter) ||
              lowerCase(`${manifestResult.caseName}`).includes(textFilter) ||
              lowerCase(`${manifestResult.originalFileName}`).includes(textFilter) ||
              `${manifestResult.experimentResultId}`.includes(textFilter) ||
              lowerCase(`${manifestResult.orchestrationId}`).includes(textFilter) ||
              `${manifestResult.caseId}`.includes(textFilter)
          )
        : manifest,
    [manifest, textFilter]
  );

  const sortedFilteredManifestResults = useMemo(
    () => orderBy(filteredManifestResults, (result) => toNumber(result[orderByColumn]), order),
    [filteredManifestResults, order]
  );

  const [isExpanded, setIsExpanded] = useState(false);

  const allSlidesInExport = queryStatistics?.slides_count ?? size(uniqBy(manifest, 'slideId'));
  const allCasesInExport = queryStatistics?.procedures_count ?? size(uniqBy(manifest, 'caseId'));
  const allOrchestrationsInExport = size(uniqBy(manifest, 'orchestrationId'));
  const excludedSlidesInExport = queryStatistics?.excluded_slides_count;
  const excludedCasesInExport = queryStatistics?.excluded_procedures_count;

  const exclusionStatisticsText = (
    <>
      {isNumber(excludedSlidesInExport) && excludedSlidesInExport > 0 && (
        <Typography variant="body2" color="textSecondary">
          Slides Excluded from the Export ({excludedSlidesInExport}) (Excluded manually or by QC)
        </Typography>
      )}
      {isNumber(excludedCasesInExport) && excludedCasesInExport > 0 && (
        <Typography variant="body2" color="textSecondary">
          Cases Excluded from the Export ({excludedCasesInExport}) (Excluded manually or by QC)
        </Typography>
      )}
    </>
  );

  return (
    <Accordion
      sx={{ width: '100%' }}
      expanded={isExpanded}
      onChange={(event, newExpanded) => setIsExpanded(newExpanded)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container justifyContent="center" alignItems="center" columns={12} spacing={1}>
          <Grid item xs={12}>
            <Typography variant="body1">Results For Export: </Typography>
          </Grid>
          {!isExpanded && (
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                Slides Included in the Export ({allSlidesInExport})
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Cases Included in the Export ({allCasesInExport})
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Orchestrations Included in the Export ({allOrchestrationsInExport})
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Results Included in the Export ({size(manifest)})
              </Typography>
              {exclusionStatisticsText}
            </Grid>
          )}
          {!isEmpty(unpublishedExperimentResultIds) && !isExpanded && (
            <Grid item container alignItems="center">
              <WarningIcon />
              <Typography variant="caption">
                {previewMode
                  ? 'Some results are not published, but will be included in the export.'
                  : 'Some cases have been updated with new results, please contact Nucleai to prepare an up to date export.'}
              </Typography>
            </Grid>
          )}
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container justifyContent="center" alignItems="center" columns={12} spacing={1}>
          <TextField
            label={`Search (showing ${size(filteredManifestResults)}${
              textFilterState ? `/${size(manifest)}` : ''
            } results)`}
            variant="outlined"
            size="small"
            value={textFilterState}
            onChange={(e) => setTextFilter(e.target.value)}
            style={{ marginBottom: '16px' }}
          />
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary">
              Slides Included in the Export ({size(uniqBy(filteredManifestResults, 'slideId'))}
              {textFilterState ? `/${allSlidesInExport}` : ''})
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Cases Included in the Export ({size(uniqBy(filteredManifestResults, 'caseId'))}
              {textFilterState ? `/${allCasesInExport}` : ''})
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Orchestrations Included in the Export ({size(uniqBy(filteredManifestResults, 'orchestrationId'))}
              {textFilterState ? `/${allOrchestrationsInExport}` : ''})
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Results Included in the Export ({size(filteredManifestResults)}
              {textFilterState ? `/${size(manifest)}` : ''})
            </Typography>
            {exclusionStatisticsText}
          </Grid>
          {!isEmpty(unpublishedExperimentResultIds) && (
            <Grid item container alignItems="center">
              <WarningIcon />
              <Typography variant="caption">
                {previewMode
                  ? 'This result is not published, but will be included in the export.'
                  : 'These cases have been updated with new results, please contact Nucleai to prepare an up to date export.'}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <TableVirtuoso
              style={{ height: tableHeight }}
              fixedHeaderContent={() => (
                <TableRow
                  sx={{ background: theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900] }}
                >
                  <TableCell sx={{ width: '100px' }}>
                    <TableSortLabel
                      active={orderByColumn === 'experimentResultId'}
                      direction={orderByColumn === 'experimentResultId' ? order : 'asc'}
                      onClick={() => handleSort('experimentResultId')}
                    >
                      Experiment Result ID
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ width: '170px' }}>
                    <TableSortLabel
                      active={orderByColumn === 'orchestrationId'}
                      direction={orderByColumn === 'orchestrationId' ? order : 'asc'}
                      onClick={() => handleSort('orchestrationId')}
                    >
                      Orchestration ID
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ width: '170px' }}>
                    <TableSortLabel
                      active={orderByColumn === 'slideId'}
                      direction={orderByColumn === 'slideId' ? order : 'asc'}
                      onClick={() => handleSort('slideId')}
                    >
                      Slide ID
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ width: '80px' }}>
                    <TableSortLabel
                      active={orderByColumn === 'caseId'}
                      direction={orderByColumn === 'caseId' ? order : 'asc'}
                      onClick={() => handleSort('caseId')}
                    >
                      Case ID
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ width: '200px' }}>
                    <TableSortLabel
                      active={orderByColumn === 'caseName'}
                      direction={orderByColumn === 'caseName' ? order : 'asc'}
                      onClick={() => handleSort('caseName')}
                    >
                      Case Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ width: '200px' }}>
                    <TableSortLabel
                      active={orderByColumn === 'originalFileName'}
                      direction={orderByColumn === 'originalFileName' ? order : 'asc'}
                      onClick={() => handleSort('originalFileName')}
                    >
                      Original File Name
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              )}
              data={sortedFilteredManifestResults}
              itemContent={(index, result) => (
                <React.Fragment key={`${result.experimentResultId}-${result.slideId}-${result.caseId}`}>
                  <TableCell sx={{ width: '100px' }}>
                    <Grid item container direction="column" justifyContent="center" alignItems="center" mt={1}>
                      <Grid item>{result.experimentResultId}</Grid>
                      {includes(unpublishedExperimentResultIds, result.experimentResultId) && (
                        <Grid item>
                          <WarningIcon />
                        </Grid>
                      )}
                    </Grid>
                  </TableCell>
                  <TableCell sx={{ width: '170px' }}>{result.orchestrationId}</TableCell>
                  <TableCell sx={{ width: '170px' }}>
                    <Link
                      to={getUrlToSlidePage({ slideId: result.slideId, labId, caseStudyId: studyId })}
                      target="_blank"
                      rel="noopener noreferrer"
                      component={RouterLink}
                    >
                      <Typography variant="caption">{result.slideId}</Typography>
                    </Link>
                  </TableCell>
                  <TableCell sx={{ width: '80px' }}>
                    <Link
                      to={getUrlToCasePage({ caseId: result.caseId, labId, caseStudyId: studyId })}
                      target="_blank"
                      rel="noopener noreferrer"
                      component={RouterLink}
                    >
                      <Typography variant="caption">{result.caseId}</Typography>
                    </Link>
                  </TableCell>
                  <TableCell sx={{ width: '200px' }}>{result.caseName}</TableCell>
                  <TableCell sx={{ width: '200px' }}>{result.originalFileName}</TableCell>
                </React.Fragment>
              )}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
