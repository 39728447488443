import { Box, darken, lighten, styled } from '@mui/system';
import {
  DataGrid,
  GridColDef,
  GridRenderEditCellParams,
  GridRowClassNameParams,
  GridValidRowModel,
} from '@mui/x-data-grid';
import { useCommonDataGridStyles } from 'components/atoms/BaseDataGrid/commonStyles';
import { CellRulePanelWithRules, CellRuleValue } from 'interfaces/cellRule';
import { CellType } from 'interfaces/cellType';
import { StainType } from 'interfaces/stainType';
import { every, forEach, map } from 'lodash';
import React, { useMemo } from 'react';
import CellRuleDisplay from './CellRuleGridCell/CellRuleDisplay';
import CellRuleDropdown from './CellRuleGridCell/CellRuleDropdown';
import CellRuleLegend from './CellRuleLegend';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .row-warning': {
    backgroundColor: theme.palette.mode == 'light' ? theme.palette.warning.light : theme.palette.warning.dark,
    '&:hover': {
      backgroundColor:
        theme.palette.mode == 'light'
          ? darken(theme.palette.warning.light, 0.1)
          : lighten(theme.palette.warning.dark, 0.1),
    },
  },
}));

interface CellRulesDataGridProps {
  panelWithRules: CellRulePanelWithRules;
  stainTypes: StainType[];
  cellTypes: CellType[];
  panelSelected: boolean;
  isLoading: boolean;
  editable?: boolean;
}

export const cellRuleWidth = 30;

const rulesToTable = (rules: CellRulePanelWithRules['rules'], stainTypes: StainType[], cellTypes: CellType[]) => {
  if (rules === undefined) return {};
  let table: Record<string, Record<string, number>> = {};

  forEach(cellTypes, (cellType) => {
    table[cellType.id] = {};
    forEach(stainTypes, (stainType) => {
      table[cellType.id][stainType.id] = CellRuleValue.Indifferent;
    });
  });

  forEach(rules, (rule) => {
    const { cellTypeId, stainTypeId, ruleValue } = rule;
    if (!table[cellTypeId]) {
      table[cellTypeId] = {};
    }
    table[cellTypeId][stainTypeId] = ruleValue;
  });

  return table;
};

const CellRulesDataGrid: React.FC<CellRulesDataGridProps> = ({
  panelWithRules,
  stainTypes,
  cellTypes,
  isLoading,
  panelSelected,
  editable = false,
}) => {
  const rules = panelWithRules?.rules ?? [];

  const table = useMemo(() => rulesToTable(rules, stainTypes, cellTypes), [rules, stainTypes, cellTypes]);

  const commonDataGridStyles = useCommonDataGridStyles();

  const rows = useMemo(
    () =>
      map(cellTypes, (cellType) => {
        const row = { id: cellType.id, ...table[cellType.id] };
        return row;
      }),
    [cellTypes, table]
  );

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Cell Type',
      width: 150,
      editable: false,
      valueFormatter: (value, _row, _column) => {
        const cellType = cellTypes.find((type) => type.id === value);
        return cellType?.displayName || value;
      },
    },
    ...map(
      stainTypes,
      (stainType) =>
        ({
          field: stainType.id,
          sortable: false,
          editable,
          headerName: stainType.displayName,
          headerAlign: 'center',
          align: 'center',
          width: cellRuleWidth,
          renderEditCell: (params: GridRenderEditCellParams) => (
            <CellRuleDropdown
              studyId={panelWithRules?.studyId as string}
              cellRule={{
                cellRulePanelId: panelWithRules?.id,
                cellTypeId: params.row.id as string,
                stainTypeId: stainType.id,
                ruleValue: params.value as CellRuleValue,
                id: `${panelWithRules.id}-${params.row.id}-${stainType.id}`,
              }}
            />
          ),
          renderCell: (params) => <CellRuleDisplay ruleValue={params.value as CellRuleValue} />,
          // the custom header is so the text is rotated 90 degrees, this allows for a more compact data grid display
          renderHeader: () => (
            <Box
              sx={{ transform: 'translateY(25%) rotate(-90deg)', width: '100%', height: '100%', overflow: 'visible' }}
            >
              {stainType.displayName}
            </Box>
          ),
        } as GridColDef)
    ),
  ];

  const getRowClassName = (params: GridRowClassNameParams<GridValidRowModel>) => {
    // if there are no rules for this cell type, we want to highlight it
    const rowRules = map(stainTypes, (stainType) => params.row[stainType.id]);
    if (every(rowRules, (value) => value === CellRuleValue.Indifferent)) {
      return 'row-warning';
    }
  };

  return (
    <StyledDataGrid
      rows={rows}
      columns={columns}
      slots={{ footer: CellRuleLegend }}
      getRowClassName={getRowClassName}
      columnHeaderHeight={150}
      loading={isLoading && panelSelected}
      disableColumnMenu
      disableColumnFilter
      disableColumnSelector
      disableRowSelectionOnClick
      density="compact"
      sx={{ ...commonDataGridStyles }}
    />
  );
};

export default CellRulesDataGrid;
