import { Layer } from '@deck.gl/core/typed';
import { IconLayer } from '@deck.gl/layers/typed';
import { signal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';
import { useQuery } from '@tanstack/react-query';
import { FeatureCollection } from '@turf/helpers';
import { compact, Dictionary, map, max, times, uniqBy } from 'lodash';
import { DrawLineStringMode, DrawPointMode, EditableGeoJsonLayer } from 'nebula.gl';
import { useMemo } from 'react';

import { getProcedureSlidePinComments, procedureSlidePinCommentsQueryKey } from 'api/procedureSlidePinComments';
import { MAX_VIEWERS } from 'components/Procedure/SlidesViewer/constants';
import Pin from 'interfaces/pin';
import { uuidv4 } from 'utils/helpers';
import { DrawAnnotationPolygonMode } from './NebulaGLExtensions/editModes';

export const viewerDraftComments = times(MAX_VIEWERS, () => signal<Dictionary<Pin | null>>({}));

export const ADD_COMMENT_LAYER_ID = 'add-comments-layer';
export const EDIT_COMMENT_LAYER_ID = 'edit-comments-layer';

const editComment = ({
  slideId,
  viewerIndex,
  updatedData,
  latestPinIndex,
}: {
  slideId: string;
  viewerIndex: number;
  latestPinIndex: number;
  updatedData: FeatureCollection;
}) => {
  const dataGeometry = updatedData?.features?.[0]?.geometry;
  if (!dataGeometry || dataGeometry.type !== 'Point') {
    return;
  }
  const coordinates = dataGeometry.coordinates as [number, number];
  const currentViewerDraftComments = viewerDraftComments[viewerIndex];
  if (!currentViewerDraftComments) {
    return;
  }
  const currentDraftComments = currentViewerDraftComments.value;
  currentViewerDraftComments.value = {
    ...currentDraftComments,
    [slideId]: {
      x: coordinates[0],
      y: coordinates[1],
      comment: '',
      draft: true,
      id: uuidv4(),
      pinIndex: latestPinIndex + 1,
    },
  };
};

const startEditingComment = ({
  comment,
  slideId,
  viewerIndex,
}: {
  comment?: Pin;
  slideId: string;
  viewerIndex: number;
}) => {
  if (!comment) {
    return;
  }
  const currentViewerDraftComments = viewerDraftComments[viewerIndex];
  if (!currentViewerDraftComments) {
    return;
  }
  const currentDraftComments = currentViewerDraftComments.value;
  currentViewerDraftComments.value = { ...currentDraftComments, [slideId]: comment };
};

export interface PinCommentsLayerProps {
  slideId: string;
  procedureId: number;
  viewerIndex: number;
  mode?: 'addPoint' | 'addLine' | 'addPolygon' | 'edit';
}

function createSVGIcon(idx: number) {
  return `
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <circle cx="33" cy="18" r="14.5" fill="#333" fill-rule="evenodd" stroke="#FFF" stroke-width="3" transform="translate(-17 -2)"/>
        <text x="16" y="22" fill="white" text-anchor="middle">${idx}</text>
    </svg>
  `;
}

function svgToDataURL(svg: string) {
  return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svg)}`;
}

export const useExistingCommentLayer = ({
  slideId,
  procedureId,
  viewerIndex,
  editable,
}: PinCommentsLayerProps & { editable?: boolean }): Layer => {
  useSignals();

  const { data: result } = useQuery(procedureSlidePinCommentsQueryKey(procedureId, slideId), {
    queryFn: (params) => getProcedureSlidePinComments({ procedureId, slideId, signal: params.signal }),
  });

  const currentSlideDraftComments = viewerDraftComments[viewerIndex]?.value?.[slideId];

  const pinsWithDraft = useMemo(
    () => uniqBy(compact([...map(result?.pins, (pin) => ({ ...pin, draft: false })), currentSlideDraftComments]), 'id'),
    [result?.pins, currentSlideDraftComments]
  );

  return useMemo(
    () =>
      new IconLayer<Pin>({
        id: EDIT_COMMENT_LAYER_ID,
        data: pinsWithDraft,
        pickable: true,
        getIcon: (d, { index }) => ({ url: svgToDataURL(createSVGIcon(index + 1)), width: 32, height: 32 }),
        sizeScale: 10,
        getSize: 4,
        getPosition: (draft) => [draft.x, draft.y],
        onClick: editable ? ({ object }) => startEditingComment({ comment: object, slideId, viewerIndex }) : undefined,
      }),
    [pinsWithDraft, editable, slideId, viewerIndex]
  );
};

export const usePinCommentsLayer = ({ slideId, procedureId, viewerIndex, mode = 'edit' }: PinCommentsLayerProps) => {
  useSignals();

  const { data: result, isLoading } = useQuery(procedureSlidePinCommentsQueryKey(procedureId, slideId), {
    queryFn: (params) => getProcedureSlidePinComments({ procedureId, slideId, signal: params.signal }),
  });

  const latestPinIndex = max(map(result?.pins, 'pinIndex'));

  const currentViewerDraftComments = viewerDraftComments[viewerIndex];
  const currentSlideDraftComments = currentViewerDraftComments?.value?.[slideId];

  const hasCurrentSlideDraftComments = Boolean(currentSlideDraftComments);

  const addCommentLayer: Layer | undefined = useMemo(
    () =>
      !hasCurrentSlideDraftComments && mode !== 'edit' && !isLoading
        ? // @ts-ignore
          (new EditableGeoJsonLayer({
            id: ADD_COMMENT_LAYER_ID,
            data: { type: 'FeatureCollection', features: [] },
            mode:
              mode === 'addLine'
                ? DrawLineStringMode
                : mode === 'addPolygon'
                ? DrawAnnotationPolygonMode
                : DrawPointMode,
            onEdit: (onEditParams: { updatedData: FeatureCollection; editType: string; editContext: any }) =>
              editComment({ slideId, viewerIndex, latestPinIndex, ...onEditParams }),
          }) as Layer)
        : undefined,
    [hasCurrentSlideDraftComments, isLoading, mode, slideId, viewerIndex, latestPinIndex]
  );

  const editCommentLayer = useExistingCommentLayer({ slideId, procedureId, viewerIndex, editable: mode === 'edit' });

  return { addCommentLayer, editCommentLayer };
};
