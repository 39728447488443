import { first, forEach, get, includes, join, keys, map, slice, some, split, startsWith, trim } from 'lodash';
import {
  applyStainTypeIfRequired,
  bracketsRegex,
  capitalization,
  getDictBrackets,
  getFormattedArea,
  getFormattedCellWithoutPositiveOrNegative,
  getFormattedCellWithStainAndClassifiers,
  getFormattedClassificationModels,
  getFormattedRegistrationType,
  getFormattedStain,
  getFormattedText,
  getGridBasedString,
  getStatistic,
} from '.';
import { FormatBracketsOptions } from './formatBracketsOptions';

export const formatBracketsFeature = (key: string, bracketTypesOptions: FormatBracketsOptions): string => {
  try {
    const featureParts = slice(split(key, bracketsRegex), 1, -1);
    let family = '';
    forEach(featureParts, (featurePart) => {
      if (startsWith(featurePart, 'f:')) {
        family = split(featurePart, ':')[1];
      }
    });

    // if its not feature family, check if this is a registration feature
    if (family === '') {
      if (some(featureParts, (featurePart) => startsWith(featurePart, 'r:'))) {
        return formatRegistrationFeature(featureParts, bracketTypesOptions);
      }
    }

    if (f[family]) {
      return capitalization(f[family](featureParts, bracketTypesOptions));
    } else {
      return key;
    }
  } catch (error) {
    console.error(`Error formatting feature: ${key}`, error);
    return key;
  }
};

export const formatRegistrationFeature = (featureParts: string[], options: FormatBracketsOptions) => {
  const dictBrackets = getDictBrackets(featureParts);

  // the format is <r:registration_type:> so the getDictBrackets converts it to {r: {registration_type: ''}}
  const registrationType = first(keys(dictBrackets['r']));
  const units = includes(registrationType, '_um') ? ' (μm)' : '';

  return `Registration ${getFormattedRegistrationType(registrationType)} ${getFormattedStain(
    dictBrackets['source_s'],
    options.stainTypeOptions
  )} to ${getFormattedStain(dictBrackets['target_s'], options.stainTypeOptions)}${units}`;
};

export const f: Record<string, (featureParts: string[], options: FormatBracketsOptions) => string> = {
  '1': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);

    return applyStainTypeIfRequired(
      `${getFormattedArea(dictBrackets['a'], bracketTypesOptions.areaTypeOptions)} area`,
      dictBrackets['s'],
      bracketTypesOptions
    );
  },
  '2': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions } = bracketTypesOptions;

    return applyStainTypeIfRequired(
      `${getFormattedArea(dictBrackets['numerator']['a'], areaTypeOptions)} to ${getFormattedArea(
        dictBrackets['denominator']['a'],
        areaTypeOptions
      )} ratio`,
      dictBrackets['s'],
      bracketTypesOptions
    );
  },
  '3': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions } = bracketTypesOptions;

    return `${getFormattedCellWithStainAndClassifiers(
      dictBrackets['c'],
      dictBrackets['s'],
      dictBrackets['classifiers'],
      bracketTypesOptions
    )} count in ${getFormattedArea(dictBrackets['a'], areaTypeOptions)}`;
  },
  '4': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions } = bracketTypesOptions;

    const { startWith, endWith, threshold } = getGridBasedString(dictBrackets);

    return `${startWith}${getFormattedCellWithStainAndClassifiers(
      dictBrackets['c'],
      dictBrackets['s'],
      dictBrackets['classifiers'],
      bracketTypesOptions
    )} fraction out of all cells${threshold} in ${getFormattedArea(dictBrackets['a'], areaTypeOptions)}${endWith}`;
  },
  '5': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions } = bracketTypesOptions;

    const { startWith, endWith, threshold } = getGridBasedString(dictBrackets);

    return `${startWith}${getFormattedCellWithStainAndClassifiers(
      dictBrackets['numerator']['c'],
      dictBrackets['s'],
      dictBrackets['numerator']['classifiers'],
      bracketTypesOptions
    )} to ${getFormattedCellWithStainAndClassifiers(
      dictBrackets['denominator']['c'],
      dictBrackets['s'],
      dictBrackets['denominator']['classifiers'],
      bracketTypesOptions
    )} ratio${threshold} in ${getFormattedArea(dictBrackets['a'], areaTypeOptions)}${endWith}`;
  },

  '6': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions } = bracketTypesOptions;

    const { startWith, endWith, threshold } = getGridBasedString(dictBrackets);

    return `${startWith}${getFormattedCellWithStainAndClassifiers(
      dictBrackets['c'],
      dictBrackets['s'],
      dictBrackets['classifiers'],
      bracketTypesOptions
    )} density${threshold} in ${getFormattedArea(dictBrackets['a'], areaTypeOptions)}${endWith}`;
  },

  '7': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions } = bracketTypesOptions;

    const numeratorCell = `${getFormattedCellWithStainAndClassifiers(
      dictBrackets['numerator']['c'],
      dictBrackets['s'],
      dictBrackets['numerator']['classifiers'],
      bracketTypesOptions
    )}`;

    const denominatorCell = `${getFormattedCellWithStainAndClassifiers(
      dictBrackets['denominator']['c'],
      dictBrackets['s'],
      dictBrackets['denominator']['classifiers'],
      bracketTypesOptions
    )}`;

    if (numeratorCell === denominatorCell) {
      return `Fold change ${numeratorCell} fraction out of all cells in ${getFormattedArea(
        dictBrackets['numerator']['a'],
        areaTypeOptions
      )} vs. ${getFormattedArea(dictBrackets['denominator']['a'], areaTypeOptions)}`;
    }

    return `${numeratorCell} fraction out of all cells in ${getFormattedArea(
      dictBrackets['numerator']['a'],
      areaTypeOptions
    )} to ${denominatorCell} fraction out of all cells in ${getFormattedArea(
      dictBrackets['denominator']['a'],
      areaTypeOptions
    )} ratio`;
  },
  '8': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions } = bracketTypesOptions;

    return `${getFormattedCellWithStainAndClassifiers(
      dictBrackets['numerator']['c'],
      dictBrackets['s'],
      dictBrackets['numerator']['classifiers'],
      bracketTypesOptions
    )} density in ${getFormattedArea(
      dictBrackets['numerator']['a'],
      areaTypeOptions
    )} to ${getFormattedCellWithStainAndClassifiers(
      dictBrackets['denominator']['c'],
      dictBrackets['s'],
      dictBrackets['denominator']['classifiers'],
      bracketTypesOptions
    )} density in ${getFormattedArea(dictBrackets['denominator']['a'], areaTypeOptions)} ratio`;
  },
  '9': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions } = bracketTypesOptions;

    return `mean percent ${getFormattedCellWithStainAndClassifiers(
      dictBrackets['neighbor']['c'],
      dictBrackets['s'],
      dictBrackets['neighbor']['classifiers'],
      bracketTypesOptions
    )} within ${dictBrackets['max_dist']} um of ${getFormattedCellWithStainAndClassifiers(
      dictBrackets['target']['c'],
      dictBrackets['s'],
      dictBrackets['target']['classifiers'],
      bracketTypesOptions
    )} in ${getFormattedArea(dictBrackets['a'], areaTypeOptions)}`;
  },
  '24': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions } = bracketTypesOptions;

    return `${dictBrackets['reduction']} percent ${getFormattedCellWithStainAndClassifiers(
      dictBrackets['neighbor']['c'],
      dictBrackets['s'],
      dictBrackets['neighbor']['classifiers'],
      bracketTypesOptions
    )} within ${dictBrackets['max_dist']} um of ${getFormattedCellWithStainAndClassifiers(
      dictBrackets['target']['c'],
      dictBrackets['s'],
      dictBrackets['target']['classifiers'],
      bracketTypesOptions
    )} in ${getFormattedArea(dictBrackets['a'], areaTypeOptions)}`;
  },
  '10': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions } = bracketTypesOptions;

    return `${dictBrackets['reduction']} percent ${getFormattedCellWithStainAndClassifiers(
      dictBrackets['neighbor']['c'],
      dictBrackets['s'],
      dictBrackets['neighbor']['classifiers'],
      bracketTypesOptions
    )} within ${dictBrackets['max_dist']} um of ${getFormattedCellWithStainAndClassifiers(
      dictBrackets['target']['c'],
      dictBrackets['s'],
      dictBrackets['target']['classifiers'],
      bracketTypesOptions
    )} in ${getFormattedArea(dictBrackets['numerator']['a'], areaTypeOptions)} to ${
      dictBrackets['reduction']
    } percent ${getFormattedCellWithStainAndClassifiers(
      dictBrackets['neighbor']['c'],
      dictBrackets['s'],
      dictBrackets['neighbor']['classifiers'],
      bracketTypesOptions
    )} within ${dictBrackets['max_dist']} um of ${getFormattedCellWithStainAndClassifiers(
      dictBrackets['target']['c'],
      dictBrackets['s'],
      dictBrackets['target']['classifiers'],
      bracketTypesOptions
    )} in ${getFormattedArea(dictBrackets['denominator']['a'], areaTypeOptions)} ratio`;
  },
  '11': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions } = bracketTypesOptions;

    return `${dictBrackets['reduction']} percent ${getFormattedCellWithStainAndClassifiers(
      dictBrackets['numerator']['neighbor']['c'],
      dictBrackets['s'],
      dictBrackets['numerator']['neighbor']['classifiers'],
      bracketTypesOptions
    )} within ${dictBrackets['max_dist']} um of ${getFormattedCellWithStainAndClassifiers(
      dictBrackets['target']['c'],
      dictBrackets['s'],
      dictBrackets['target']['classifiers'],
      bracketTypesOptions
    )} in ${getFormattedArea(dictBrackets['a'], areaTypeOptions)} to ${
      dictBrackets['reduction']
    } percent ${getFormattedCellWithStainAndClassifiers(
      dictBrackets['denominator']['neighbor']['c'],
      dictBrackets['s'],
      dictBrackets['denominator']['neighbor']['classifiers'],
      bracketTypesOptions
    )} within ${dictBrackets['max_dist']} um of ${getFormattedCellWithStainAndClassifiers(
      dictBrackets['target']['c'],
      dictBrackets['s'],
      dictBrackets['target']['classifiers'],
      bracketTypesOptions
    )} in ${getFormattedArea(dictBrackets['a'], areaTypeOptions)} ratio`;
  },
  // deprecated feature - calculate percent cells with greater than y neighbors (instead greater than equal to y neighbors)
  '12': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions } = bracketTypesOptions;

    return `percent ${getFormattedCellWithStainAndClassifiers(
      dictBrackets['target']['c'],
      dictBrackets['s'],
      dictBrackets['target']['classifiers'],
      bracketTypesOptions
    )} with at least ${dictBrackets['min_neighbors']} ${getFormattedCellWithStainAndClassifiers(
      dictBrackets['neighbor']['c'],
      dictBrackets['s'],
      dictBrackets['neighbor']['classifiers'],
      bracketTypesOptions
    )} neighbors within ${dictBrackets['max_dist']} um in ${getFormattedArea(dictBrackets['a'], areaTypeOptions)}`;
  },
  // deprecated feature - calculate percent cells with greater than y neighbors (instead greater than equal to y neighbors)
  '13': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions } = bracketTypesOptions;

    return `percent ${getFormattedCellWithStainAndClassifiers(
      dictBrackets['target']['c'],
      dictBrackets['s'],
      dictBrackets['target']['classifiers'],
      bracketTypesOptions
    )} with at least ${dictBrackets['min_neighbors']} ${getFormattedCellWithStainAndClassifiers(
      dictBrackets['neighbor']['c'],
      dictBrackets['s'],
      dictBrackets['neighbor']['classifiers'],
      bracketTypesOptions
    )} neighbors within ${dictBrackets['numerator']['max_dist']} um to ${
      dictBrackets['denominator']['max_dist']
    } um in ${getFormattedArea(dictBrackets['a'], areaTypeOptions)} ratio`;
  },
  // deprecated feature - calculate percent cells with greater than y neighbors (instead greater than equal to y neighbors)
  '14': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions } = bracketTypesOptions;

    return `percent ${getFormattedCellWithStainAndClassifiers(
      dictBrackets['target']['c'],
      dictBrackets['s'],
      dictBrackets['target']['classifiers'],
      bracketTypesOptions
    )} with at least ${dictBrackets['min_neighbors']} ${getFormattedCellWithStainAndClassifiers(
      dictBrackets['neighbor']['c'],
      dictBrackets['s'],
      dictBrackets['neighbor']['classifiers'],
      bracketTypesOptions
    )} neighbors within ${dictBrackets['max_dist']} um in ${getFormattedArea(
      dictBrackets['numerator']['a'],
      areaTypeOptions
    )} to ${getFormattedArea(dictBrackets['denominator']['a'], areaTypeOptions)} ratio`;
  },
  '15': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions } = bracketTypesOptions;

    return `${getFormattedCellWithStainAndClassifiers(
      dictBrackets['c'],
      dictBrackets['s'],
      dictBrackets['classifiers'],
      bracketTypesOptions
    )} ${dictBrackets['reduction']} nucleus ${getFormattedText(dictBrackets['f_type'])} in ${getFormattedArea(
      dictBrackets['a'],
      areaTypeOptions
    )}`;
  },
  '16': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions } = bracketTypesOptions;

    return `${getFormattedCellWithStainAndClassifiers(
      dictBrackets['source_s']?.['subset']['c'],
      dictBrackets['source_s']?.['s'],
      dictBrackets['source_s']?.['subset']['classifiers'],
      bracketTypesOptions
    )} and ${getFormattedCellWithStainAndClassifiers(
      dictBrackets['target_s']['subset']['c'],
      dictBrackets['target_s']['s'],
      dictBrackets['target_s']['subset']['classifiers'],
      bracketTypesOptions
    )} colocalization by joint density (sigma: ${dictBrackets['sigma']}, thresh: ${
      dictBrackets['thresh']
    }) in ${getFormattedArea(dictBrackets['a'], areaTypeOptions)}`;
  },
  '17': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { stainTypeOptions } = bracketTypesOptions;

    return `${getFormattedCellWithStainAndClassifiers(
      getFormattedCellWithoutPositiveOrNegative(dictBrackets['subset']?.['c']),
      dictBrackets['subset']?.['s'],
      dictBrackets['subset']?.['classifiers'],
      bracketTypesOptions
    )} coexpression of (${getFormattedStain(dictBrackets['source_s']?.['s'], stainTypeOptions)}+, ${getFormattedStain(
      dictBrackets['target_s']['s'],
      stainTypeOptions
    )}+) by joint density (sigma: ${dictBrackets['sigma']}, thresh: ${dictBrackets['thresh']})`;
  },
  '18': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { stainTypeOptions } = bracketTypesOptions;

    const sourcesStain = map(dictBrackets['sources_s'], (source_s) => {
      return `${getFormattedStain(source_s, stainTypeOptions)}+`;
    });

    return `${getFormattedCellWithStainAndClassifiers(
      getFormattedCellWithoutPositiveOrNegative(dictBrackets['subset']?.['c']),
      dictBrackets['subset']?.['s'],
      dictBrackets['subset']?.['classifiers'],
      bracketTypesOptions
    )} coexpression of (${join(sourcesStain, ', ')}, ${getFormattedStain(
      dictBrackets['target_s']['s'],
      stainTypeOptions
    )}+) by joint density (sigma: ${dictBrackets['sigma']}, thresh: ${dictBrackets['thresh']})`;
  },
  '19': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { stainTypeOptions } = bracketTypesOptions;

    return `${getFormattedCellWithStainAndClassifiers(
      getFormattedCellWithoutPositiveOrNegative(dictBrackets['numerator']['subset']['c']),
      dictBrackets['numerator']['subset']?.['s'],
      dictBrackets['numerator']['subset']?.['classifiers'],
      bracketTypesOptions
    )} coexpression of (${getFormattedStain(
      dictBrackets['numerator']['source_s']['s'],
      stainTypeOptions
    )}+, ${getFormattedStain(
      dictBrackets['numerator']['target_s']['s'],
      stainTypeOptions
    )}+) by joint density to ${getFormattedCellWithStainAndClassifiers(
      getFormattedCellWithoutPositiveOrNegative(dictBrackets['denominator']['subset']['c']),
      dictBrackets['denominator']['subset']?.['s'],
      dictBrackets['denominator']['subset']?.['classifiers'],
      bracketTypesOptions
    )} coexpression of (${getFormattedStain(
      dictBrackets['denominator']['source_s']['s'],
      stainTypeOptions
    )}+, ${getFormattedStain(
      dictBrackets['denominator']['target_s']['s'],
      stainTypeOptions
    )}+) by joint density ratio (sigma: ${dictBrackets['sigma']}, thresh: ${dictBrackets['thresh']})`;
  },
  '20': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions } = bracketTypesOptions;

    return `${dictBrackets['reduction']} percent ${getFormattedCellWithStainAndClassifiers(
      dictBrackets['neighbor']['c'],
      dictBrackets['neighbor']['s'],
      dictBrackets['neighbor']['classifiers'],
      bracketTypesOptions
    )} within ${dictBrackets['max_dist']} um of ${getFormattedCellWithStainAndClassifiers(
      dictBrackets['target']['c'],
      dictBrackets['target']['s'],
      dictBrackets['target']['classifiers'],
      bracketTypesOptions
    )} in ${getFormattedArea(dictBrackets['a'], areaTypeOptions)}`;
  },
  // deprecated feature - calculate percent cells with greater than y neighbors (instead greater than equal to y neighbors)
  '21': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions } = bracketTypesOptions;

    return `percent ${getFormattedCellWithStainAndClassifiers(
      dictBrackets['target']['c'],
      dictBrackets['target']['s'],
      dictBrackets['target']['classifiers'],
      bracketTypesOptions
    )} with at least ${dictBrackets['min_neighbors']} ${getFormattedCellWithStainAndClassifiers(
      dictBrackets['neighbor']['c'],
      dictBrackets['neighbor']['s'],
      dictBrackets['neighbor']['classifiers'],
      bracketTypesOptions
    )} neighbors within ${dictBrackets['max_dist']} um in ${getFormattedArea(dictBrackets['a'], areaTypeOptions)}`;
  },
  '23': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions } = bracketTypesOptions;

    return `${getFormattedCellWithStainAndClassifiers(
      dictBrackets['numerator']['c'],
      dictBrackets['s'],
      dictBrackets['numerator']['classifiers'],
      bracketTypesOptions
    )} in ${getFormattedArea(
      dictBrackets['numerator']['a'],
      areaTypeOptions
    )} to ${getFormattedCellWithStainAndClassifiers(
      dictBrackets['denominator']['c'],
      dictBrackets['s'],
      dictBrackets['denominator']['classifiers'],
      bracketTypesOptions
    )} in ${getFormattedArea(dictBrackets['denominator']['a'], areaTypeOptions)} ratio`;
  },
  '25': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions } = bracketTypesOptions;

    return `shannon diversity index for ${getFormattedCellWithStainAndClassifiers(
      dictBrackets['c'],
      dictBrackets['s'],
      dictBrackets['classifiers'],
      bracketTypesOptions
    )} in ${getFormattedArea(dictBrackets['a'], areaTypeOptions)}`;
  },
  '26': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions } = bracketTypesOptions;

    return `clark evans index for ${getFormattedCellWithStainAndClassifiers(
      dictBrackets['c'],
      dictBrackets['s'],
      dictBrackets['classifiers'],
      bracketTypesOptions
    )} in ${getFormattedArea(dictBrackets['a'], areaTypeOptions)}`;
  },
  '27': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions } = bracketTypesOptions;

    return `geary's c for ${getFormattedCellWithStainAndClassifiers(
      dictBrackets['target']['c'],
      dictBrackets['s'],
      dictBrackets['target']['classifiers'],
      bracketTypesOptions
    )} to ${getFormattedCellWithStainAndClassifiers(
      dictBrackets['neighbor']['c'],
      dictBrackets['s'],
      dictBrackets['neighbor']['classifiers'],
      bracketTypesOptions
    )} in ${getFormattedArea(dictBrackets['a'], areaTypeOptions)} (distance method: ${getFormattedText(
      dictBrackets['distance_method']
    )}, max distance: ${dictBrackets['max_dist']} um)`;
  },
  '28': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions } = bracketTypesOptions;

    return `moran's i ${getStatistic(dictBrackets['statistic'])}for ${getFormattedCellWithStainAndClassifiers(
      dictBrackets['target']['c'],
      dictBrackets['s'],
      dictBrackets['target']['classifiers'],
      bracketTypesOptions
    )} to ${getFormattedCellWithStainAndClassifiers(
      dictBrackets['neighbor']['c'],
      dictBrackets['s'],
      dictBrackets['neighbor']['classifiers'],
      bracketTypesOptions
    )} in ${getFormattedArea(dictBrackets['a'], areaTypeOptions)} (distance method: ${getFormattedText(
      dictBrackets['distance_method']
    )}, max distance: ${dictBrackets['max_dist']} um)`;
  },
  '29': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions } = bracketTypesOptions;

    return `ripley's ${dictBrackets['f_type']} function for ${getFormattedCellWithStainAndClassifiers(
      dictBrackets['c'],
      dictBrackets['s'],
      dictBrackets['classifiers'],
      bracketTypesOptions
    )} in ${getFormattedArea(dictBrackets['a'], areaTypeOptions)} (cell radius: ${
      dictBrackets['cell_radius']
    } um, max distance: ${dictBrackets['max_dist']} um)`;
  },
  '30': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions } = bracketTypesOptions;

    return `count of ${getFormattedArea(dictBrackets['a'], areaTypeOptions)} region instances`;
  },
  '32': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { classificationModelOptions } = bracketTypesOptions;
    const formattedClassificationModels =
      dictBrackets['cm'] && dictBrackets['cm'] !== 'intensity'
        ? ` (${getFormattedClassificationModels(dictBrackets['cm'], classificationModelOptions)})`
        : '';

    return `H score ${getFormattedCellWithStainAndClassifiers(
      dictBrackets['pos']['c'],
      dictBrackets['s'],
      dictBrackets?.['pos']?.['classifiers'],
      bracketTypesOptions
    )}${formattedClassificationModels}`;
  },
  '33': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions } = bracketTypesOptions;

    return applyStainTypeIfRequired(
      `${getFormattedCellWithStainAndClassifiers(
        getFormattedCellWithoutPositiveOrNegative(dictBrackets['c']),
        dictBrackets['s'],
        dictBrackets['classifiers'],
        bracketTypesOptions
      )} nuclear to cytoplasm ratio in ${getFormattedArea(dictBrackets['a'], areaTypeOptions)}`,
      dictBrackets['s'],
      bracketTypesOptions
    );
  },
  '34': (featureParts, bracketTypesOptions) => {
    // TODO: add test for this case (and 37, which relies on it) and fix accordingly - auto generated code
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions } = bracketTypesOptions;

    return `${getFormattedCellWithStainAndClassifiers(
      dictBrackets['c'],
      dictBrackets['s'],
      dictBrackets['classifiers'],
      bracketTypesOptions
    )} density by distance to ${getFormattedArea(
      get(dictBrackets, 'boundary.a'),
      areaTypeOptions
    )} boundary in ${getFormattedArea(dictBrackets['a'], areaTypeOptions)} (interval: ${
      dictBrackets['interval_size']
    } um)`;
  },
  '35': (featureParts, bracketTypesOptions) => {
    return f['6'](featureParts, bracketTypesOptions);
  },
  '36': (featureParts, bracketTypesOptions) => {
    return f['8'](featureParts, bracketTypesOptions);
  },
  '37': (featureParts, bracketTypesOptions) => {
    return f['34'](featureParts, bracketTypesOptions);
  },
  '38': (featureParts, bracketTypesOptions) => {
    return f['1'](featureParts, bracketTypesOptions);
  },
  '39': (featureParts, bracketTypesOptions) => {
    return f['12'](featureParts, bracketTypesOptions);
  },
  '40': (featureParts, bracketTypesOptions) => {
    return f['13'](featureParts, bracketTypesOptions);
  },
  '41': (featureParts, bracketTypesOptions) => {
    return f['14'](featureParts, bracketTypesOptions);
  },
  '42': (featureParts, bracketTypesOptions) => {
    return f['21'](featureParts, bracketTypesOptions);
  },
  '43': (featureParts, bracketTypesOptions) => {
    const dictBrackets = getDictBrackets(featureParts);
    const { areaTypeOptions } = bracketTypesOptions;

    // If all the details are tumor, show only the positive cell numerator
    if (
      dictBrackets['numerator']['neg']['c'] === 'tumor_cell-negative' &&
      (dictBrackets['denominator']['c'] === 'tumor_cell' ||
        dictBrackets['denominator']['c'] === 'tumor_cell-negative|tumor_cell-positive') &&
      dictBrackets['a'] === '1'
    ) {
      return `SPS for ${getFormattedCellWithStainAndClassifiers(
        dictBrackets['numerator']['pos']['c'],
        dictBrackets['s'],
        dictBrackets['numerator']['pos']['classifiers'],
        bracketTypesOptions
      )} (max radius: ${dictBrackets['max_dist']}, min neighbors: ${dictBrackets['min_neighbors']})`;
    }

    return `SPS for ${getFormattedCellWithStainAndClassifiers(
      dictBrackets['numerator']['pos']['c'],
      dictBrackets['s'],
      dictBrackets['numerator']['pos']['classifiers'],
      bracketTypesOptions
    )} with ${getFormattedCellWithStainAndClassifiers(
      dictBrackets['denominator']['c'],
      dictBrackets['s'],
      dictBrackets['denominator']['classifiers'],
      bracketTypesOptions
    )} in ${getFormattedArea(dictBrackets['a'], areaTypeOptions)} (negative cells: ${trim(
      getFormattedCellWithStainAndClassifiers(
        dictBrackets['numerator']['neg']['c'],
        dictBrackets['s'],
        dictBrackets['numerator']['neg']['classifiers'],
        bracketTypesOptions
      )
    )}, max radius: ${dictBrackets['max_dist']}, min neighbors: ${dictBrackets['min_neighbors']})`;
  },
};

export const getFeatureNameBrackets = (key: string) => {
  const featureParts = slice(split(key, bracketsRegex), 1, -1);
  return getDictBrackets(featureParts);
};
