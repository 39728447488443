import { List, ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { cloneDeep, flatMap, map } from 'lodash';
import React from 'react';

import { Box } from '@mui/system';
import { useSignals } from '@preact/signals-react/runtime';
import { viewerClickData } from '../../../viewerDataSignals';
import { FeatureCollection } from '../../NebulaGLExtensions/geojson-types';
import { viewerAnnotationData } from '../useActiveAnnotationDraft';
import useAnnotationsForViewer, { AnnotationItem, UNKNOWN_DIAGNOSIS } from '../useAnnotations';
import ContextMenuIcon from './ContextMenuIcon';

export const DiagnosisSelect: React.FC<{
  slideId: string;
  viewerIndex: number;
  featureIndex: number;
  annotationId: number;
}> = ({ featureIndex, slideId, viewerIndex, annotationId }) => {
  useSignals();

  const { getFilteredAnnotationItemsByFeatureIndex, activeAnnotationData, updateAnnotationSettings } =
    useAnnotationsForViewer({
      slideId,
      viewerIndex,
    });
  const annotationFeatures = activeAnnotationData?.features;
  const annotationItems = getFilteredAnnotationItemsByFeatureIndex(featureIndex);

  const onItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, item: AnnotationItem) => {
    if (viewerClickData[viewerIndex]) {
      viewerClickData[viewerIndex].value = null;
    }
    if (!annotationFeatures?.[featureIndex]) {
      console.warn("Couldn't find feature in draft", {
        editedAnnotationData: activeAnnotationData,
        featureIndex,
      });
      return;
    }
    const editedAnnotationData: FeatureCollection = cloneDeep(activeAnnotationData);
    editedAnnotationData.features = cloneDeep(annotationFeatures) as typeof editedAnnotationData.features;
    const editedFeature = editedAnnotationData.features[featureIndex];
    editedFeature.properties = {
      ...(editedFeature?.properties || {}),
      annotationId,
      markerType: editedFeature?.properties?.markerType || 'tagger_annotation',
    };
    if (item.isMarkerAnnotation) {
      editedFeature.properties.diagnosis = UNKNOWN_DIAGNOSIS;
      editedFeature.properties.markerPositivity = { [item.name]: item.positive };
    } else {
      editedFeature.properties.diagnosis = item.name;
      editedFeature.properties.markerPositivity = item.markerPositivity;
    }
    if (viewerAnnotationData[viewerIndex]) {
      viewerAnnotationData[viewerIndex].value = editedAnnotationData;
    }
    updateAnnotationSettings(item);
  };

  return <AnnotationOptionsMenu annotationItems={annotationItems} onClick={onItemClick} />;
};

export const AnnotationOptionsMenu: React.FC<{
  annotationItems: AnnotationItem[];
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, item: AnnotationItem) => void;
}> = ({ annotationItems, onClick }) => {
  useSignals();

  const flatItems: AnnotationItem[] = flatMap(annotationItems, (annotationItem) => {
    if (annotationItem.isMarkerAnnotation) {
      return [
        { ...annotationItem, positive: true },
        { ...annotationItem, positive: false },
      ];
    } else {
      return [annotationItem];
    }
  });

  return (
    <Box onWheel={(e) => e.stopPropagation()} sx={{ maxHeight: '300px', overflowY: 'auto' }}>
      <List>
        {map(flatItems, (item: AnnotationItem) => (
          <Tooltip
            title={`${item.displayName} ${item.isMarkerAnnotation ? (item.positive ? ' Positive' : ' Negative') : ''}`}
            key={`${item.name}-${item.color}-${item.positive}`}
            enterDelay={500}
            enterNextDelay={500}
            placement="right"
          >
            <ListItemButton selected={item.selected} onClick={(event) => onClick && onClick(event, item)} tabIndex={0}>
              <AnnotationOption item={item} />
            </ListItemButton>
          </Tooltip>
        ))}
      </List>
    </Box>
  );
};

export const AnnotationOption: React.FC<{ item: AnnotationItem }> = ({ item }) => {
  return (
    <>
      {item.color && (
        <ListItemIcon>
          <ContextMenuIcon
            positive={item.positive}
            displayPosNeg={item?.isMarkerAnnotation}
            // TODO: implement with brushtool
            // hole={isHole(item.optionName)}
            hole={false}
            color={item.color}
          />
        </ListItemIcon>
      )}
      {item.icon}
      <Box
        sx={{
          overflow: 'hidden',
          '& .MuiListItemText-primary': { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' },
        }}
      >
        <ListItemText
          primary={`${item.displayName} ${item.isMarkerAnnotation ? (item.positive ? ' Positive' : ' Negative') : ''}`}
        />
      </Box>
    </>
  );
};
