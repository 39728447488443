import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Divider, Grid, IconButton, ListItem, Paper, Popper } from '@mui/material';
import {
  editTypeToShapeSubTypeMap,
  ShapeSubTypes,
  shapeSubTypeToEditTypeMap,
} from 'components/Procedure/Header/SlideInteractionMenu/slideAnnotations/options';
import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { get } from 'lodash';
import React, { useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { AnnotationOption, AnnotationOptionsMenu } from './AnnotationsContextMenu/AnnotationOptionsMenu';
import useActiveClassToAnnotate from './useActiveClassToAnnotate';
import useAnnotationsForViewer, { AnnotationItem, getShapeSubTypeFromTodoOption } from './useAnnotations';

interface StatusBarProps {
  slide: SlideWithChannelAndResults;
}

const AnnotatingQuickMenu: React.FC<StatusBarProps> = ({ slide }) => {
  const [expanded, setExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleExpandClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    setExpanded((prev) => !prev);
  };

  const activeAnnotationClass = useActiveClassToAnnotate({
    slideId: slide?.id,
    viewerIndex: slide?.viewerIndex,
  });

  const [editAnnotationsMode, setEditAnnotationMode] = useQueryParam('editAnnotationsMode', StringParam);
  const shapeSubTypeOfCurrentAnnotationMode: ShapeSubTypes | null = get(
    editTypeToShapeSubTypeMap,
    editAnnotationsMode,
    null
  );

  const { annotationItems, updateAnnotationSettings } = useAnnotationsForViewer({
    slideId: slide?.id,
    viewerIndex: slide?.viewerIndex,
  });

  const onItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, item: AnnotationItem) => {
    const selectedItemShapeSubType = getShapeSubTypeFromTodoOption(item);
    if (
      shapeSubTypeOfCurrentAnnotationMode === null ||
      selectedItemShapeSubType !== shapeSubTypeOfCurrentAnnotationMode
    ) {
      // change the annotation mode
      setEditAnnotationMode(shapeSubTypeToEditTypeMap[selectedItemShapeSubType]);
    }

    updateAnnotationSettings(item);
  };

  if (!activeAnnotationClass?.name) {
    return null;
  }

  return (
    <>
      <div style={{ position: 'absolute', bottom: 50, left: 15 }} ref={(el) => setAnchorEl(el)} />
      <Popper id={`quickMenu-${slide?.viewerIndex}`} open={Boolean(anchorEl)} anchorEl={anchorEl} placement="top-start">
        <Paper
          sx={{
            border: '1px solid #ddd',
            borderRadius: '4px',
            width: 250,
          }}
        >
          {expanded && (
            <>
              <AnnotationOptionsMenu annotationItems={annotationItems} onClick={onItemClick} />
              <Divider variant="middle" />
            </>
          )}
          <Grid container direction="row" spacing={1} alignItems="center" width="100%">
            <Grid item xs={10}>
              <ListItem>
                <AnnotationOption item={activeAnnotationClass} />
              </ListItem>
            </Grid>
            <Grid item xs={2}>
              <IconButton size="small" onClick={handleExpandClick}>
                <ExpandMoreIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Paper>
      </Popper>
    </>
  );
};

export default AnnotatingQuickMenu;
