import SearchIcon from '@mui/icons-material/Search';
import { Grid, IconButton, Typography } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';

import { AdvancedBaseSlideControl } from 'components/Procedure/SlideControls/AdvancedBaseSlideControls';
import { SlideDeconvolutionControls } from 'components/Procedure/SlideControls/SlideDeconvolutionControls';
import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import SearchBar from 'components/atoms/SearchBar';
import { Permission } from 'interfaces/permissionOption';
import { MULTIPLEX_STAIN_ID } from 'interfaces/stainType';
import { getStainStrWithNegPosControl } from 'utils/helpers';
import useScannerSlideVisualizationPreset from 'utils/queryHooks/useScannerSlideVisualizationPresets';
import { usePermissions } from 'utils/usePermissions';
import { useStainTypeIdToDisplayName } from 'utils/useStainTypeIdToDisplayName';
import useStudy from 'utils/useStudy';
import { BaseSlideControl } from '../../SlideControls/BaseSlideControl';
import Multiplex from '../../SlideControls/Multiplex';
import Results from './Results';
import SlideAnnotation from './SlideAnnotation';

interface Props {
  slide: SlideWithChannelAndResults;
  isPlaceholderData: boolean;
  isLoadingCaseData: boolean;
  doesCaseHaveMultipleStainResults: boolean;
}

const SlideInfobar: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  slide,
  isPlaceholderData,
  isLoadingCaseData,
  doesCaseHaveMultipleStainResults,
}) => {
  useSignals();
  const [textSearch, setTextSearch] = useState('');
  const { hasPermission } = usePermissions();
  const [useOSDViewer] = useQueryParam('useOSDViewer', BooleanParam);
  const canViewAnnotations = hasPermission(Permission.ViewAnnotations);
  const { stainTypeIdToDisplayName, isLoadingStainTypeOptions } = useStainTypeIdToDisplayName();
  const [onSearch, setOnSearch] = useState(false);
  const toggleSearchMode = (isOnnSearch: boolean) => {
    setOnSearch(isOnnSearch);
  };

  const [inlineSlideControls] = useQueryParam('inlineSlideControls', BooleanParam);

  const [useAdvancedBaseSlideControls] = useQueryParam('useAdvancedBaseSlideControls', BooleanParam);

  const { study } = useStudy(slide?.studyId, { enabled: Boolean(slide?.studyId) });

  const studyDeconvolutionSettings = study?.settings?.deconvolutionSettings;

  const { data: baseSlideVisualSettingsScannerPresets, isLoading: isLoadingBaseSlideVisualSettingsScannerPresets } =
    useScannerSlideVisualizationPreset(slide?.scannerManufacturer, { enabled: Boolean(slide?.scannerManufacturer) });
  const scannerVisualizationSettings = baseSlideVisualSettingsScannerPresets?.visualizationPreset;

  const showSlideControls = !inlineSlideControls || useOSDViewer;
  return (
    <Grid container direction="column" mb="20px" spacing={2} flexWrap={'nowrap'}>
      <Grid item mx={1}>
        <Grid item container wrap="nowrap" alignItems="center" justifyContent="space-between" sx={{ height: '60px' }}>
          {onSearch ? (
            <Grid item md={12}>
              <SearchBar
                helperText="Filter overlays and channels"
                onChange={setTextSearch}
                closeSearchMode={() => {
                  toggleSearchMode(false);
                }}
              />
            </Grid>
          ) : (
            <Grid container wrap="nowrap" direction="column">
              <Grid container item wrap="nowrap" alignItems="center" justifyContent="space-between">
                <Grid item md={6}>
                  <Typography variant="h3" variantMapping={{ h3: 'h2' }}>
                    {isLoadingStainTypeOptions || !slide?.stainingType
                      ? `Loading...`
                      : getStainStrWithNegPosControl(
                          stainTypeIdToDisplayName(slide?.stainingType),
                          slide?.negativeControl,
                          slide?.positiveControl
                        )}{' '}
                    ({(slide?.viewerIndex ?? 0) + 1})
                  </Typography>
                </Grid>
                <Grid item container wrap="nowrap" alignItems="center" justifyContent="space-around" md={6}>
                  <Grid item md={8}>
                    {showSlideControls && isEmpty(slide?.channelsMetadata) && (
                      <BaseSlideControl
                        slideId={slide.id}
                        viewerIndex={slide.viewerIndex}
                        stainTypeId={slide.stainingType}
                        slideManufacturerSettings={scannerVisualizationSettings}
                      />
                    )}
                  </Grid>
                  <Grid item md={2}>
                    <IconButton
                      onClick={() => {
                        toggleSearchMode(true);
                      }}
                      color="inherit"
                    >
                      <SearchIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant="caption">{slide.originalFileName}</Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      {!onSearch &&
        !useOSDViewer &&
        showSlideControls &&
        isEmpty(slide?.channelsMetadata) &&
        useAdvancedBaseSlideControls && (
          <Grid item>
            <AdvancedBaseSlideControl
              slideId={slide.id}
              viewerIndex={slide.viewerIndex}
              stainTypeId={slide.stainingType}
              slideManufacturerSettings={scannerVisualizationSettings}
              disabled={isLoadingBaseSlideVisualSettingsScannerPresets}
            />
          </Grid>
        )}

      {!useOSDViewer && studyDeconvolutionSettings && (
        <Grid item>
          <SlideDeconvolutionControls slide={slide} deconvolutionSettings={studyDeconvolutionSettings} />
        </Grid>
      )}

      <Grid item>
        <Results
          slide={slide}
          isLoadingCaseData={isLoadingCaseData}
          doesCaseHaveMultipleStainResults={doesCaseHaveMultipleStainResults}
          isPlaceholderData={isPlaceholderData}
          textSearch={textSearch}
        />
      </Grid>

      {showSlideControls &&
        (!isEmpty(slide?.channelsMetadata) ||
          ((isLoadingCaseData || isPlaceholderData) && slide?.stainingType === MULTIPLEX_STAIN_ID)) && (
          <Grid item>
            <Multiplex slide={slide} filterText={textSearch} />
          </Grid>
        )}

      {canViewAnnotations && !useOSDViewer && (
        <Grid item>
          <SlideAnnotation slide={slide} filterText={textSearch} enabled={!isLoadingCaseData && !isPlaceholderData} />
        </Grid>
      )}
    </Grid>
  );
};

export default SlideInfobar;
