import { ActionModal } from 'components/StudyDashboard/ProceduresPage/Actions/ActionModal';
import { RunMultiplexBinaryClassifier } from 'components/StudyDashboard/ProceduresPage/Actions/Multiplex/RunMultiplexBinaryClassifier';
import { RunMultiplexCellSegmentation } from 'components/StudyDashboard/ProceduresPage/Actions/Multiplex/RunMultiplexCellSegmentation';
import { RunMultiplexCellTyping } from 'components/StudyDashboard/ProceduresPage/Actions/Multiplex/RunMultiplexCellTyping';
import { RunMultiplexHistogram } from 'components/StudyDashboard/ProceduresPage/Actions/Multiplex/RunMultiplexHistogram';
import { RunMultiplexNormalization } from 'components/StudyDashboard/ProceduresPage/Actions/Multiplex/RunMultiplexNormalization';
import { RunMultiplexThreshold } from 'components/StudyDashboard/ProceduresPage/Actions/Multiplex/RunMultiplexThreshold';
import { RunInferenceSteps } from 'components/StudyDashboard/ProceduresPage/Actions/RunInference/RunInferenceSteps';
import { RunSlideRegistrations } from 'components/StudyDashboard/ProceduresPage/Actions/RunSlideRegistrations';
import { JobType } from 'interfaces/job';
import { Permission } from 'interfaces/permissionOption';
import React from 'react';
import { CasesParams } from 'utils/useCasesParams';
import { usePermissions } from 'utils/usePermissions';

interface Props {
  jobType: JobType;
  onClose: () => void;
  casesParams: CasesParams;
  jobId?: string;
}

const JobModal: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  jobType,
  onClose,
  casesParams,
  jobId,
}) => {
  const { hasPermission } = usePermissions();
  const canRunInference = hasPermission(Permission.RunInference);
  const canRunSlideRegistrations = hasPermission(Permission.ExecuteSlideRegistrations);
  const canRunMultiplexCellSegmentation = hasPermission(Permission.RunMultiplexCellSegmentation);
  const canRunMultiplexBinaryClassifier = hasPermission(Permission.ExecuteMultiplexBinaryClassifier);
  const canRunMultiplexNormalization = hasPermission(Permission.ExecuteMultiplexNormalization);
  const canRunMultiplexHistogram = hasPermission(Permission.ExecuteMultiplexHistogram);
  const canRunMultiplexThreshold = hasPermission(Permission.ExecuteMultiplexThreshold);
  const canRunMultiplexCellTyping = hasPermission(Permission.ExecuteMultiplexCellTyping);

  switch (jobType) {
    case JobType.Inference:
      if (canRunInference) {
        return (
          <ActionModal header="Run Inference" onClose={onClose}>
            <RunInferenceSteps jobId={jobId} casesParams={casesParams} onClose={onClose} />
          </ActionModal>
        );
      }
      break;
    case JobType.MultiplexCellSegmentation:
      if (canRunMultiplexCellSegmentation) {
        return (
          <ActionModal header="Run Multiplex Cell Segmentation" onClose={onClose}>
            <RunMultiplexCellSegmentation onClose={onClose} />
          </ActionModal>
        );
      }
      break;
    case JobType.BinaryClassifier:
      if (canRunMultiplexBinaryClassifier) {
        return (
          <ActionModal header="Run Multiplex Binary Classifier" onClose={onClose}>
            <RunMultiplexBinaryClassifier onClose={onClose} />
          </ActionModal>
        );
      }
      break;
    case JobType.MultiplexHistogram:
      if (canRunMultiplexHistogram) {
        return (
          <ActionModal header="Run Multiplex Histogram" onClose={onClose}>
            <RunMultiplexHistogram jobId={jobId} casesParams={casesParams} onClose={onClose} />
          </ActionModal>
        );
      }
      break;
    case JobType.MultiplexNormalization:
      if (canRunMultiplexNormalization) {
        return (
          <ActionModal header="Run Multiplex Normalization" onClose={onClose}>
            <RunMultiplexNormalization jobId={jobId} casesParams={casesParams} onClose={onClose} />
          </ActionModal>
        );
      }
      break;
    case JobType.MultiplexThreshold:
      if (canRunMultiplexThreshold) {
        return (
          <ActionModal header="Run Multiplex Threshold" onClose={onClose}>
            <RunMultiplexThreshold onClose={onClose} />
          </ActionModal>
        );
      }
      break;
    case JobType.MultiplexCellTyping:
      if (canRunMultiplexCellTyping) {
        return (
          <ActionModal header="Run Multiplex Cell Typing" onClose={onClose}>
            <RunMultiplexCellTyping onClose={onClose} />
          </ActionModal>
        );
      }
      break;
    case JobType.SlideRegistrations:
      if (canRunSlideRegistrations) {
        return (
          <ActionModal header="Run Slide Registrations" onClose={onClose}>
            <RunSlideRegistrations onClose={onClose} casesParams={casesParams} />
          </ActionModal>
        );
      }
      break;
    default:
      return null;
  }
};

export default JobModal;
