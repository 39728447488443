import { useSignals } from '@preact/signals-react/runtime';
import { compact, concat, every, find, forEach, includes, isEmpty, isNumber, map, without } from 'lodash';
import React from 'react';

import {
  LayerVisualizationChange,
  LayerVisualizationSettings,
  defaultHeatmapOpacity,
  slidesLayerVisualizationSettings,
  useApplyChangesToSlideLayerVisualizationSettings,
} from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import { FeatureMetadata } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import { getAllFlatMapDeepHeatmaps } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { getHeatmapUrlKeyFromFeatureMetadata } from '../helpers';

const generateChangesForHeatmapAndNestedHeatmaps = ({
  checked,
  debug,
  heatmapId,
  viewerIndex,
  slideId,
  heatmaps,
  addOrchestrationIdToUrl,
}: {
  checked: boolean;
  debug: boolean;
  heatmapId: string;
  viewerIndex: number;
  slideId: string;
  heatmaps: FeatureMetadata[];
  addOrchestrationIdToUrl: boolean;
}) => {
  const allHeatmaps = getAllFlatMapDeepHeatmaps(heatmaps);
  const heatmap = find(allHeatmaps, { id: heatmapId });

  const baseLayerSettingsForChange: LayerVisualizationSettings = {
    selected: checked,
    show: checked,
    opacity: checked ? defaultHeatmapOpacity : 0,
  };

  const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[viewerIndex];
  const slideLayerVisualizationSettings = viewerSlideLayerVisualizationSettings?.value?.[slideId] || {};
  const layerSettings = slideLayerVisualizationSettings?.[heatmapId];
  const previousLayerSettings = layerSettings?.value;
  const newLayerSettings = {
    ...(previousLayerSettings || {}),
    ...baseLayerSettingsForChange,
    // Don't override the opacity if it's already set
    ...(isNumber(previousLayerSettings?.opacity) ? { opacity: previousLayerSettings.opacity } : {}),
  };

  const changes: LayerVisualizationChange[] = [];
  const parentHeatmap = find(allHeatmaps, ({ nestedItems }) => includes(map(nestedItems, 'id'), heatmapId));
  changes.push({
    layerId: heatmapId,
    newSettings: newLayerSettings,
    layerUrlKey: getHeatmapUrlKeyFromFeatureMetadata({
      heatmap,
      parentHeatmap,
      addOrchestrationId: addOrchestrationIdToUrl,
    }),
  });
  if (debug) {
    console.debug('Adding change to heatmap', { layerId: heatmapId, newSettings: newLayerSettings });
  }

  // If the heatmap has children, update the children
  const nestedHeatmapIds = map(heatmap?.nestedItems, 'id');
  if (!isEmpty(nestedHeatmapIds)) {
    forEach(nestedHeatmapIds, (key) => {
      const nestedLayerSettings = slideLayerVisualizationSettings?.[key];
      const previousNestedLayerSettings = nestedLayerSettings?.value;
      const newNestedLayerSettings = {
        ...(previousNestedLayerSettings || {}),
        ...baseLayerSettingsForChange,
        opacity: previousNestedLayerSettings?.opacity ?? defaultHeatmapOpacity,
      };
      const nestedHeatmap = find(allHeatmaps, { id: key });
      changes.push({
        layerId: key,
        newSettings: newNestedLayerSettings,
        layerUrlKey: getHeatmapUrlKeyFromFeatureMetadata({
          heatmap: nestedHeatmap,
          parentHeatmap: heatmap,
          addOrchestrationId: addOrchestrationIdToUrl,
        }),
      });
      if (debug) {
        console.debug('Adding change to nested heatmap', { layerId: key, newSettings: newNestedLayerSettings });
      }
    });
  }

  return changes;
};

const generateChangesForParentHeatmapFromNestedSelection = ({
  checked,
  debug,
  heatmapId,
  viewerIndex,
  slideId,
  heatmaps,
  addOrchestrationIdToUrl,
}: {
  heatmaps: FeatureMetadata[];
  viewerIndex: number;
  slideId: string;
  heatmapId: string;
  checked: boolean;
  debug: boolean;
  addOrchestrationIdToUrl: boolean;
}) => {
  const allHeatmaps = getAllFlatMapDeepHeatmaps(heatmaps);

  const changes: LayerVisualizationChange[] = [];
  const parentHeatmap = find(allHeatmaps, ({ nestedItems }) => includes(map(nestedItems, 'id'), heatmapId));

  // If the heatmap doesn't have a parent, finish updating
  const parentHeatmapId = parentHeatmap?.id;
  if (!parentHeatmapId) {
    return changes;
  }
  const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[viewerIndex];
  if (!viewerSlideLayerVisualizationSettings) {
    console.warn(`Invalid viewerIndex: ${viewerIndex}`);
    return changes;
  }

  // If the heatmap has a parent, update the parent and siblings as needed (show parent and hide siblings if all siblings are selected, hide parent if not all siblings are selected)
  const newLayerVisualizationSettings = viewerSlideLayerVisualizationSettings?.value;
  const newSlideLayerVisualizationSettings = newLayerVisualizationSettings?.[slideId] || {};

  const parentHeatmapSettings = newSlideLayerVisualizationSettings?.[parentHeatmapId];
  const previousParentHeatmapSettings = parentHeatmapSettings?.value;

  const parentNestedHeatmapIds = map(parentHeatmap?.nestedItems, 'id');

  const allNestedHeatmapsVisible = every(
    map(parentNestedHeatmapIds, (nestedHeatmapId) =>
      nestedHeatmapId === heatmapId ? checked : newSlideLayerVisualizationSettings[nestedHeatmapId]?.value?.selected
    )
  );

  const newParentHeatmapSettings = allNestedHeatmapsVisible
    ? {
        ...(previousParentHeatmapSettings || {}),
        selected: true,
        show: true,
        opacity: previousParentHeatmapSettings?.opacity ?? defaultHeatmapOpacity,
      }
    : {
        ...(previousParentHeatmapSettings || {}),
        show: false,
        selected: false,
        opacity: previousParentHeatmapSettings?.opacity ?? defaultHeatmapOpacity,
      };

  changes.push({
    layerId: parentHeatmapId,
    newSettings: newParentHeatmapSettings,
    layerUrlKey: getHeatmapUrlKeyFromFeatureMetadata({
      heatmap: parentHeatmap,
      addOrchestrationId: addOrchestrationIdToUrl,
    }),
  });

  if (debug) {
    console.debug(
      `Adding change to heatmap parent because ${
        allNestedHeatmapsVisible ? 'not ' : ''
      }all nested heatmaps are visible`,
      { layerId: parentHeatmapId, newSettings: newParentHeatmapSettings }
    );
  }

  forEach(parentNestedHeatmapIds, (key) => {
    if (key === heatmapId) {
      // Skip the heatmap that was just clicked
      return;
    }
    const nestedHeatmap = find(allHeatmaps, { id: key });
    const nestedLayerSettings = newSlideLayerVisualizationSettings?.[key];
    const previousNestedLayerSettings = nestedLayerSettings?.value;
    const newNestedLayerSettings = allNestedHeatmapsVisible
      ? {
          ...(previousNestedLayerSettings || {}),
          selected: true,
          show: false,
          opacity: previousNestedLayerSettings?.opacity ?? defaultHeatmapOpacity,
        }
      : {
          selected: false,
          ...(previousNestedLayerSettings || {}),
          show: Boolean(previousNestedLayerSettings?.selected),
        };
    changes.push({
      layerId: key,
      newSettings: newNestedLayerSettings,
      layerUrlKey: getHeatmapUrlKeyFromFeatureMetadata({
        heatmap: nestedHeatmap,
        parentHeatmap,
        addOrchestrationId: addOrchestrationIdToUrl,
      }),
    });
    if (debug) {
      console.debug(
        `Adding change to nested heatmap because ${
          allNestedHeatmapsVisible ? 'not ' : ''
        }all nested heatmaps are visible`,
        { layerId: key, newSettings: newNestedLayerSettings }
      );
    }
  });

  return changes;
};

export const useSelectHeatmap = ({
  viewerIndex,
  slideId,
  heatmaps,
  stainTypeId,
  addOrchestrationIdToUrl,
  debug = false,
  setHeatmapsExpanded,
}: {
  viewerIndex: number;
  slideId: string;
  heatmaps: FeatureMetadata[];
  stainTypeId: string;
  addOrchestrationIdToUrl: boolean;
  debug?: boolean;
  setHeatmapsExpanded: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  useSignals();

  const applyChangesToSlideLayerVisualizationSettings = useApplyChangesToSlideLayerVisualizationSettings();

  const changeHeatmapSelectionState = React.useCallback(
    (heatmapId: string, checked: boolean) => {
      if (debug) {
        console.debug('changeHeatmapSelectionState', {
          viewerIndex,
          slideId,
          heatmapId,
          heatmaps,
          stainTypeId,
          addOrchestrationIdToUrl,
          checked,
          debug,
        });
      }

      // Sanity check - make sure the viewerIndex is valid
      const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[viewerIndex];
      if (!viewerSlideLayerVisualizationSettings) {
        console.warn(`Invalid viewerIndex: ${viewerIndex}`);
        return;
      }

      // Sanity check - make sure the heatmap exists
      const allHeatmaps = getAllFlatMapDeepHeatmaps(heatmaps);
      const heatmap = find(allHeatmaps, { id: heatmapId });

      if (!heatmap) {
        console.error('changeHeatmapSelectionState: heatmap not found', { heatmapId, allHeatmaps });
        return;
      }

      // Update the expanded state of the heatmap accordion as needed
      if (checked) {
        setHeatmapsExpanded((prevHeatmapsExpanded) => [...prevHeatmapsExpanded, heatmapId]);
      } else {
        setHeatmapsExpanded((prevHeatmapsExpanded) => without(prevHeatmapsExpanded, heatmapId));
      }

      const commonChangeProps = {
        checked,
        debug,
        heatmapId,
        viewerIndex,
        slideId,
        heatmaps,
        addOrchestrationIdToUrl,
      };

      const changes = compact(
        concat(
          generateChangesForHeatmapAndNestedHeatmaps(commonChangeProps),
          generateChangesForParentHeatmapFromNestedSelection(commonChangeProps)
        )
      );

      if (debug) {
        console.debug('Final changes', {
          slideId,
          viewerIndex,
          changes,
          stainTypeId,
          heatmapId,
          heatmaps,
          checked,
          addOrchestrationIdToUrl,
        });
      }

      applyChangesToSlideLayerVisualizationSettings({
        slideId,
        viewerIndex,
        changes,
        stainTypeId,
        debug,
        changeFlow: `changeHeatmapSelectionState-${heatmapId}`,
      });
      return;
    },
    [applyChangesToSlideLayerVisualizationSettings]
  );

  return { changeHeatmapSelectionState };
};
