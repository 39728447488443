import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Divider, Fade, Grid, Popper, ToggleButton } from '@mui/material';
import { flatMap, forEach, fromPairs, map } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { BooleanParam, StringParam, useQueryParam } from 'use-query-params';

import { useConfirmation } from 'components/modals/ConfirmationContext';
import { viewerAnnotationData } from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/EditAnnotationLayers/useActiveAnnotationDraft';
import { Permission } from 'interfaces/permissionOption';
import { useAnnotationQueryParams } from 'services/annotations/useAnnotationQueryParams';
import { usePermissions } from 'utils/usePermissions';
import usePrevious from 'utils/usePrevious';
import { InteractionMenusProps } from '..';
import { useSlideAnnotationKeyboardEvents } from './hooks';
import { slideAnnotationOptions } from './options';
import { SlideInteractionOption } from './SlideInteractionOption';

export const SlideAnnotationInteractions: React.FunctionComponent<InteractionMenusProps> = ({
  hasActiveNonAnnotationInteractions,
  clearNonAnnotationInteractions,
}) => {
  const [useOSDViewer] = useQueryParam('useOSDViewer', BooleanParam);
  const { annotationsActive, annotationQueryKeys, setAnnotationQueryParams } = useAnnotationQueryParams();
  const previousAnnotationsActive = usePrevious(annotationsActive);
  const [editAnnotationsMode, setEditAnnotationMode] = useQueryParam('editAnnotationsMode', StringParam);

  const [anchorEl, setAnchorEl] = React.useState<null | Element>(null);

  const clearSlideInteraction = useCallback(() => {
    clearNonAnnotationInteractions();
    setAnnotationQueryParams(fromPairs(map(annotationQueryKeys, (key) => [key, null])));
    forEach(viewerAnnotationData, (annotationDraftSignal) => {
      annotationDraftSignal.value = null;
    });
    setEditAnnotationMode(null);
  }, [clearNonAnnotationInteractions]);

  const [isDrawingToolSelectionOpenState, setIsDrawingToolSelectionOpen] = React.useState(annotationsActive);
  const isDrawingToolSelectionOpen = annotationsActive && Boolean(anchorEl) && isDrawingToolSelectionOpenState;

  useEffect(() => {
    if (!previousAnnotationsActive && annotationsActive) {
      // Open/Close the drawing tool selection if the drawing modes are active/inactive
      setIsDrawingToolSelectionOpen(annotationsActive);
    }
  }, [annotationsActive]);

  const { hasPermission } = usePermissions();
  const canAnnotateSlides = hasPermission(Permission.AnnotateSlides);
  const hasPermissionsToDrawingTools = canAnnotateSlides;

  useSlideAnnotationKeyboardEvents();

  const confirmWithModal = useConfirmation();

  return (
    !useOSDViewer &&
    hasPermissionsToDrawingTools &&
    annotationsActive && (
      <Grid item>
        <ToggleButton
          ref={setAnchorEl}
          value="slideActions"
          onChange={() => setIsDrawingToolSelectionOpen(!isDrawingToolSelectionOpen)}
          title={isDrawingToolSelectionOpen ? 'Open slide annotation tools' : 'Hide'}
          disabled={Boolean(hasActiveNonAnnotationInteractions)}
        >
          {isDrawingToolSelectionOpen ? <ArrowForwardIosIcon /> : <EditIcon />}
        </ToggleButton>
        <Popper anchorEl={anchorEl} open={isDrawingToolSelectionOpen} placement="left" transition disablePortal>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps}>
              <Box sx={{ bgcolor: 'background.paper' }}>
                <Grid item container direction="row" p={0} m={0}>
                  <SlideInteractionOption
                    title={'Clear current slide annotations'}
                    onClick={async () => {
                      if (
                        await confirmWithModal({
                          title: 'Clear current slide annotations changes',
                          text: "Unsaved changes will be lost. Are you sure you want to clear the current slide's annotations?",
                        })
                      ) {
                        clearSlideInteraction();
                      }
                    }}
                    value="clearSlideInteraction"
                    icon={<DeleteSweepIcon />}
                  />
                  <Divider orientation="vertical" />
                  {flatMap(slideAnnotationOptions, ({ editType, title, icon: OptionIcon, color, sx }) => (
                    <SlideInteractionOption
                      key={editType}
                      value={editType}
                      title={title}
                      selected={editAnnotationsMode === editType}
                      onClick={() => {
                        clearNonAnnotationInteractions();
                        if (editType === editAnnotationsMode) {
                          setEditAnnotationMode(null);
                        } else {
                          setEditAnnotationMode(editType);
                        }
                      }}
                      icon={
                        <OptionIcon
                          color={color ? color : editAnnotationsMode === editType ? 'primary' : 'inherit'}
                          sx={sx}
                        />
                      }
                    />
                  ))}
                </Grid>
              </Box>
            </Fade>
          )}
        </Popper>
      </Grid>
    )
  );
};
