import { Viewport } from '@deck.gl/core/typed';
import { DeckGLProps } from '@deck.gl/react/typed';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Divider, ListItemIcon, ListItemText, MenuItem, Paper, Popover } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import centroid from '@turf/centroid';
import { Feature } from '@turf/helpers';
import { cloneDeep, find, findIndex, slice } from 'lodash';
import React from 'react';
import { NumberParam, useQueryParam } from 'use-query-params';

import { FeatureSizeSection } from 'components/FeatureSizeSection';
import HtmlDeckGLOverlay from 'components/HtmlDeckGLOverlay';
import HtmlDeckGLOverlayItem from 'components/HtmlDeckGLOverlay/HtmlDeckGLOverlayItem';
import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { ImagePyramid } from 'components/Procedure/useSlideImages';
import { Permission } from 'interfaces/permissionOption';
import { getActiveAnnotationAssignmentIdViewerKey } from 'services/annotations/useAnnotationQueryParams';
import { usePermissions } from 'utils/usePermissions';
import { viewerClickData } from '../../../viewerDataSignals';
import { useActiveAnnotationDraft, viewerAnnotationData } from '../useActiveAnnotationDraft';
import { DiagnosisSelect } from './AnnotationOptionsMenu';
import { StickyMenuItems } from './StickyMenuItems';

const DEFAULT_COORDINATES = [0, 0] as [number, number];

export interface TaggedItem extends DiagnosisItem {
  isPoint?: boolean;
}

export interface DiagnosisItem {
  text: string;
  diagnosis: string;
  color: string;
  positive: boolean;
  selected: boolean;
  icon?: React.ReactNode;
}

export interface StickyItem {
  text: string;
  icon: React.ReactNode;
  menuAction: () => void;
  color?: any; // TODO: PropTypes.Color;
}

export const AnnotationsContextMenu: React.FC<{
  slide: SlideWithChannelAndResults;
  baseImagePyramids: ImagePyramid;
  viewport: Viewport;
  rightClickInfo?: Parameters<DeckGLProps['onClick']>[0];
  locked?: boolean;
  isSelectFeaturesOn?: boolean;
  // TODO: is this necessary?
  isOnlyNonCanvasEditingEnabled?: boolean;
}> = ({
  slide,
  viewport,
  baseImagePyramids,
  rightClickInfo,
  locked,
  isSelectFeaturesOn,
  isOnlyNonCanvasEditingEnabled,
}) => {
  useSignals();
  const { activeAnnotationData, slideAnnotations } = useActiveAnnotationDraft({
    slideId: slide?.id,
    viewerIndex: slide?.viewerIndex,
  });
  const annotationFeatures = activeAnnotationData?.features;

  const unassignedFeatureIndex = findIndex(annotationFeatures, (feature) => !feature?.properties?.diagnosis);

  const unassignedFeature = unassignedFeatureIndex >= 0 ? annotationFeatures[unassignedFeatureIndex] : undefined;

  const rightClickFeatureIndex = rightClickInfo?.index;

  const rightClickFeature = !isNaN(rightClickFeatureIndex) ? annotationFeatures[rightClickFeatureIndex] : undefined;

  const contextMenuFeatureIndex = unassignedFeature ? unassignedFeatureIndex : rightClickFeatureIndex;

  const featureForContextMenu = (unassignedFeature || rightClickFeature) as Feature;

  const featureCoordinates = featureForContextMenu
    ? (centroid(featureForContextMenu)?.geometry?.coordinates as [number, number])
    : undefined;

  const { hasPermission } = usePermissions();
  const canAnnotateSlides = hasPermission(Permission.AnnotateSlides);

  const featureAnnotationId = featureForContextMenu?.properties?.annotationId;
  const [activeAnnotationAssignmentId] = useQueryParam(
    getActiveAnnotationAssignmentIdViewerKey(slide.viewerIndex),
    NumberParam
  );

  const savedAnnotationData = canAnnotateSlides
    ? !isNaN(featureAnnotationId)
      ? find(slideAnnotations, { annotationId: featureAnnotationId })
      : find(slideAnnotations, {
          annotationAssignment: {
            annotationAssignmentId: activeAnnotationAssignmentId,
          },
        })
    : undefined;

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement>(null);

  const handleRemove = () => {
    if (!activeAnnotationData?.features?.[contextMenuFeatureIndex]) {
      return;
    }
    const currentDraft = cloneDeep(activeAnnotationData);
    currentDraft.features = [
      ...slice(currentDraft.features, 0, contextMenuFeatureIndex),
      ...slice(currentDraft.features, contextMenuFeatureIndex + 1),
    ];
    if (viewerAnnotationData[slide?.viewerIndex]) {
      viewerAnnotationData[slide?.viewerIndex].value = currentDraft;
    }
    if (viewerClickData[slide?.viewerIndex]) {
      viewerClickData[slide?.viewerIndex].value = null;
    }
  };

  // allow escape functionality
  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === 'Escape') {
      if (!activeAnnotationData?.features?.[contextMenuFeatureIndex]?.properties?.diagnosis) {
        event.stopPropagation();
        event.preventDefault();
        handleRemove();
      } else {
        if (viewerClickData[slide?.viewerIndex]) {
          viewerClickData[slide?.viewerIndex].value = null;
        }
      }
    }
  };

  return (
    canAnnotateSlides && (
      <HtmlDeckGLOverlay viewport={viewport}>
        <HtmlDeckGLOverlayItem
          key="contextMenu"
          draggable={false}
          coordinates={featureCoordinates || DEFAULT_COORDINATES}
        >
          <div ref={(el) => setAnchorEl(el)} />

          <Popover
            id="annotationContextMenu"
            open={Boolean(featureCoordinates) && Boolean(anchorEl)}
            anchorEl={anchorEl}
            disableEscapeKeyDown={false}
            onKeyDown={handleKeyDown}
          >
            <Paper>
              <DiagnosisSelect
                annotationId={savedAnnotationData?.annotationId}
                slideId={slide?.id}
                viewerIndex={slide?.viewerIndex}
                featureIndex={contextMenuFeatureIndex}
              />

              <Divider variant="middle" />

              <StickyMenuItems
                viewerIndex={slide?.viewerIndex}
                annotationDraft={activeAnnotationData}
                featureIndex={contextMenuFeatureIndex}
                isSelectFeaturesOn={isSelectFeaturesOn}
                isOnlyNonCanvasEditingEnabled={isOnlyNonCanvasEditingEnabled}
                handleRemove={handleRemove}
              />

              <Divider variant="middle" />

              {/* TODO: implement lock */}
              <MenuItem onClick={console.debug}>
                <ListItemIcon>
                  {locked ? <LockOpenIcon fontSize="small" /> : <LockIcon fontSize="small" />}
                </ListItemIcon>
                <ListItemText>{locked ? 'Unlock' : 'Lock'}</ListItemText>
              </MenuItem>

              <FeatureSizeSection
                feature={featureForContextMenu}
                maxResolution={slide?.maxResolution}
                maxLevel={baseImagePyramids?.layerSource?.maxLevel ?? 0}
              />
            </Paper>
          </Popover>
        </HtmlDeckGLOverlayItem>
      </HtmlDeckGLOverlay>
    )
  );
};
