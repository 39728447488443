import { Grid, Typography, useTheme } from '@mui/material';
import CellRulePanelDropdown from 'components/Pages/OperationsSettings/CellRulesDataGrid/CellRulePanel/CellRulePanelDropdown';
import { useStainTypesUsedByPanel } from 'components/Pages/OperationsSettings/CellRulesDataGrid/panel.util';
import { TodoOption } from 'interfaces/annotation';
import { CellRuleValue } from 'interfaces/cellRule';
import { compact, find, forEach, includes, map } from 'lodash';
import React, { useMemo } from 'react';
import useCellRulePanels from 'utils/queryHooks/cellRule/useCellRulePanels';
import useCellRules from 'utils/queryHooks/cellRule/useCellRules';
import ClassesToAnnotateList, { ClassesToAnnotateListSkeleton } from '../ClassesToAnnotateList';

interface PresetsTodoTypesProps {
  selectedClassesToAnnotate: TodoOption[];
  onClassesSelected: (classes: TodoOption[]) => void;
  studyId: string;
  getClassToAnnotateFromTaxonomyId: (taxonomyId: string) => TodoOption;
  isLoading?: boolean;
  isError?: boolean;
  selectedPanelId: string | null;
  setSelectedPanelId: (panelId: string | null) => void;
}

// for now we only save explicit rules
const RULES_FOR_MARKER_POSITIVITY_PROPERTY = [CellRuleValue.MustBeNegative, CellRuleValue.MustBePositive];

const CellRules: React.FC<PresetsTodoTypesProps> = ({
  selectedClassesToAnnotate,
  onClassesSelected,
  studyId,
  getClassToAnnotateFromTaxonomyId,
  isLoading,
  isError,
  selectedPanelId,
  setSelectedPanelId,
}) => {
  const theme = useTheme();
  const selectedClassesToAnnotateNames = useMemo(
    () => compact(map(selectedClassesToAnnotate, 'name')) ?? [],
    [selectedClassesToAnnotate]
  );

  const { data: panels, isLoading: isLoadingPanels, isError: isErrorLoadingPanels } = useCellRulePanels(studyId);

  const {
    data: panel,
    isLoading: isLoadingPanel,
    isError: isErrorLoadingPanel,
  } = useCellRules(selectedPanelId, studyId);

  const panelStainTypes = useStainTypesUsedByPanel(panel);

  // create todos from cell rules, where diagnosis is the cell type
  const cellRuleTodos: TodoOption[] = useMemo(() => {
    if (!panel) {
      return [];
    }
    const todosFromCellRules: TodoOption[] = [];
    forEach(panel.rules, (cellRule) => {
      if (includes(RULES_FOR_MARKER_POSITIVITY_PROPERTY, cellRule.ruleValue)) {
        let taxonomyToAnnotate: TodoOption = find(todosFromCellRules, { name: cellRule.cellTypeId });
        if (!taxonomyToAnnotate) {
          taxonomyToAnnotate = getClassToAnnotateFromTaxonomyId(cellRule.cellTypeId);
          taxonomyToAnnotate.markerPositivity = {};
          todosFromCellRules.push(taxonomyToAnnotate);
        }
        taxonomyToAnnotate.markerPositivity[cellRule.stainTypeId] = cellRule.ruleValue;
      }
    });
    forEach(panelStainTypes, (stainType) => {
      if (stainType.phenotypic) {
        const phenotypicTodoOptionToAdd: TodoOption = {
          name: stainType.id,
          displayName: stainType.displayName,
          color: theme.palette.primary.main,
          className: 'point',
          isMarkerAnnotation: true,
        };
        todosFromCellRules.push(phenotypicTodoOptionToAdd);
      }
    });

    return todosFromCellRules;
  }, [panel]);

  return (
    <Grid item container direction="column" mt={2}>
      <Grid item px={2}>
        <CellRulePanelDropdown
          panels={panels ?? []}
          selectedPanelId={selectedPanelId}
          onSelectPanel={setSelectedPanelId}
          isLoading={isLoadingPanels || isLoading}
          studyId={studyId}
          textFieldProps={{
            error: isErrorLoadingPanels || isError,
            helperText: isErrorLoadingPanels || isError ? 'Error loading panels' : undefined,
          }}
        />
      </Grid>
      <Grid item xs={true} sx={{ overflow: 'auto' }}>
        {selectedPanelId && isLoadingPanel && <ClassesToAnnotateListSkeleton />}
        {isErrorLoadingPanel && <Typography color="error">Error loading panel rules</Typography>}
        <ClassesToAnnotateList
          classesToAnnotate={cellRuleTodos}
          isSelectable
          selectProps={{
            isTodoSelected: (id) => includes(selectedClassesToAnnotateNames, id),
            selectedClassesToAnnotate,
            onClassesSelected,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default CellRules;
