import { ResultType } from './experimentResults';

export interface ArtifactResultsConfig {
  resultTypes?: string[];
  filterArtifactsWithTsmOnly?: boolean;
  groupByModel?: boolean;
}

export const BinaryClassifierConfig: ArtifactResultsConfig = {
  resultTypes: [ResultType.MarkersProbabilities],
  filterArtifactsWithTsmOnly: false,
};

export const CellTypingConfig: ArtifactResultsConfig = {
  resultTypes: [ResultType.MarkerPositivities],
  filterArtifactsWithTsmOnly: false,
  groupByModel: false,
};

export const AreaConfig: ArtifactResultsConfig = {
  resultTypes: [ResultType.SlideInferenceAreas],
  filterArtifactsWithTsmOnly: true,
};

export const CellConfig: ArtifactResultsConfig = {
  resultTypes: [ResultType.SlideInferencePoints],
  filterArtifactsWithTsmOnly: true,
};

export const DefectConfig: ArtifactResultsConfig = {
  resultTypes: [ResultType.SlideInferenceDefects],
  filterArtifactsWithTsmOnly: true,
};

export const TlsConfig: ArtifactResultsConfig = {
  resultTypes: [ResultType.SlideInferenceTls],
  filterArtifactsWithTsmOnly: true,
};

export const ClassificationIntensityConfig: ArtifactResultsConfig = {
  resultTypes: [ResultType.SlideInferenceClassificationIntensity],
  filterArtifactsWithTsmOnly: false,
};

export const ClassificationConfig: ArtifactResultsConfig = {
  resultTypes: [ResultType.SlideInferenceClassification],
  filterArtifactsWithTsmOnly: false,
};

export const NucleiConfig: ArtifactResultsConfig = {
  resultTypes: [ResultType.SlideInferenceNuclei],
  filterArtifactsWithTsmOnly: false,
};

export const CellAttributesTableConfig: ArtifactResultsConfig = {
  resultTypes: [ResultType.SlideCellAttributesTable],
  filterArtifactsWithTsmOnly: false,
  groupByModel: false,
};

export const CellSegmentationConfig: ArtifactResultsConfig = {
  resultTypes: [ResultType.SlideInferenceCellSegmentation],
  filterArtifactsWithTsmOnly: false,
};
