import CloseIcon from '@mui/icons-material/Close';

import { Drawer, Grid, IconButton, Typography, useTheme } from '@mui/material';
import { settingsDetailsDrawerWidth } from 'components/atoms/FlexContainers/FlexContainers';
import { SITE_HEADER_HEIGHT } from 'components/SiteHeader/SiteHeader';
import React, { PropsWithChildren } from 'react';
import { BooleanParam, useQueryParam, withDefault } from 'use-query-params';

export enum CurrentSelectedSettingsDetailsType {
  SlideTags = 'SlideTags',
  ExternalLabels = 'ExternalLabels',
}

interface Props {
  title: string;
  type: CurrentSelectedSettingsDetailsType;
}

const SettingsDetailsDrawer: React.FC<PropsWithChildren<Props>> = ({ children, title, type }) => {
  const theme = useTheme();
  const [settingsDetailsDrawerOpen, setSettingsDetailsDrawerOpen] = useQueryParam(
    'settingsDetailsDrawerOpen',
    withDefault(BooleanParam, false)
  );
  const [selectedSettingsDetailsType] =
    useQueryParam<CurrentSelectedSettingsDetailsType>('selectedSettingsDetailsType');

  const onDrawerClose = () => setSettingsDetailsDrawerOpen(false);

  return (
    settingsDetailsDrawerOpen &&
    selectedSettingsDetailsType === type && (
      <Drawer
        anchor={'right'}
        open={settingsDetailsDrawerOpen}
        onClose={onDrawerClose}
        variant={'persistent'}
        sx={{
          width: settingsDetailsDrawerWidth,
          [`& .MuiDrawer-paper`]: {
            width: settingsDetailsDrawerWidth,
            zIndex: theme.zIndex.appBar - 1,
            height: `calc(100% - ${SITE_HEADER_HEIGHT})`,
            marginTop: SITE_HEADER_HEIGHT,
          },
        }}
      >
        <Grid container p={2} direction="column" spacing={2} flexWrap="nowrap">
          <Grid container item alignItems="center">
            <Grid item xs={10}>
              <Typography variant="h3">{title}</Typography>
            </Grid>
            <Grid item xs={2} textAlign="right">
              <IconButton onClick={onDrawerClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item>{children}</Grid>
        </Grid>
      </Drawer>
    )
  );
};

export default SettingsDetailsDrawer;
