import { useSignals } from '@preact/signals-react/runtime';
import { forEach } from 'lodash';
import { useCallback, useState } from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';

import { calculateZoomFromMagnification } from 'utils/slideTransformation';
import { deckGLViewerStates } from './SlidesViewer/DeckGLViewer/slidesViewerState';
import { SlideWithChannelAndResults } from './useSlideChannelsAndResults/utils';

export const useSlideMagnification = (slides: SlideWithChannelAndResults[]) => {
  useSignals();
  const [magnificationValue, setMagnificationValue] = useState(null);

  const [useOSDViewer] = useQueryParam('useOSDViewer', BooleanParam);

  const handleMagnificationChangedInHeader = useCallback(
    (newMagnificationValue: number) => {
      setMagnificationValue(newMagnificationValue);

      if (!useOSDViewer) {
        forEach(deckGLViewerStates, (deckGLViewer, viewerIndex) => {
          const slide = slides[viewerIndex];
          if (!slide) {
            return;
          }
          const viewerDeckGLPreviousState = deckGLViewer.value;
          const maxResolution = slide?.maxResolution ?? 1;
          const deckGLZoomLevel = calculateZoomFromMagnification(newMagnificationValue, maxResolution);

          deckGLViewer.value = {
            ...(viewerDeckGLPreviousState || {}),
            ...(slide?.id
              ? {
                  [slide.id]: {
                    ...(viewerDeckGLPreviousState?.[slide.id] || {}),
                    zoom: !isNaN(deckGLZoomLevel) ? deckGLZoomLevel : 0,
                  },
                }
              : {}),
          };
        });
      }
    },
    [slides, useOSDViewer]
  );

  const updateMagnificationFromZoom = useCallback((newMagnificationValue: number) => {
    // in this case we don't want to re-render the slides viewer, we just want to update
    // the magnification in the header
    setMagnificationValue(newMagnificationValue);
  }, []);

  return {
    magnificationValue,
    handleMagnificationChangedInHeader,
    updateMagnificationFromZoom,
  };
};
