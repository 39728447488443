import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSignals } from '@preact/signals-react/runtime';
import { find, values } from 'lodash';
import React, { FC, useMemo, useTransition } from 'react';
import { JsonParam, useQueryParam } from 'use-query-params';

import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, TextField, Typography } from '@mui/material';
import Slider from 'components/atoms/Slider';
import {
  baseSlidesVisualSettings,
  BaseSlideVisualSettings,
  defaultBaseSlideVisualSettings,
} from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';

export const AdvancedBaseSlideControl: FC<{
  slideId: string;
  viewerIndex: number;
  stainTypeId: string;
  slideManufacturerSettings?: Partial<BaseSlideVisualSettings>;
  disabled?: boolean;
}> = (props) => {
  useSignals();

  const { slideId, viewerIndex, slideManufacturerSettings, disabled } = props;

  // In the URL, we store the settings for each stain type and viewer index to allow for sharing the settings when navigating to a different slide,
  // as well as to allow for sharing the settings when users share the URL.
  const [urlStainToBaseSlideSettings, setUrlStainToBaseSlideSettings] = useQueryParam<{
    [stainTypeId: string]: { [viewerIndex: number]: BaseSlideVisualSettings };
  }>('stainToBaseSlideSettings', JsonParam);

  const viewerBaseSlideSettings = baseSlidesVisualSettings[viewerIndex];

  const settingsFromUrl = useMemo(
    () =>
      props.stainTypeId
        ? {
            [slideId]:
              urlStainToBaseSlideSettings?.[props.stainTypeId]?.[viewerIndex] ||
              // If the settings for the current stain type and viewer index are not found, use any viewer's settings for the stain type, if available
              find(values(urlStainToBaseSlideSettings?.[props.stainTypeId])),
          }
        : undefined,
    [slideId, viewerIndex, urlStainToBaseSlideSettings, props.stainTypeId]
  );

  const slideManufacturerSettingsWithDefaults = {
    ...defaultBaseSlideVisualSettings,
    ...slideManufacturerSettings,
  };

  const baseSlideVisualSettings = {
    ...slideManufacturerSettingsWithDefaults,
    ...(settingsFromUrl || {}),
    ...(viewerBaseSlideSettings.value?.[slideId] || {}),
  };

  const [, startTransition] = useTransition();

  const applyNewViewSettings = (change: Partial<BaseSlideVisualSettings>) => {
    const viewerBaseSlideSettingsInner = baseSlidesVisualSettings[viewerIndex];

    const baseSlideVisualSettingsInner = {
      ...defaultBaseSlideVisualSettings,
      ...(viewerBaseSlideSettingsInner.value?.[slideId] || {}),
    };
    const newViewSettings = { ...baseSlideVisualSettingsInner, ...change };

    baseSlidesVisualSettings[viewerIndex].value = {
      ...baseSlidesVisualSettings[viewerIndex].value,
      [slideId]: newViewSettings,
    };

    if (props.stainTypeId) {
      startTransition(() => {
        setUrlStainToBaseSlideSettings({
          ...urlStainToBaseSlideSettings,
          [props.stainTypeId]: {
            ...urlStainToBaseSlideSettings?.[props.stainTypeId],
            [viewerIndex]: newViewSettings,
          },
        });
      });
    }
  };

  const handleChangeSlideGamma = (gamma: number) => applyNewViewSettings({ gamma });
  const handleChangeSlideBrightness = (brightness: number) => applyNewViewSettings({ brightness });
  const handleChangeSlideContrast = (contrast: number) => applyNewViewSettings({ contrast });

  const defaultGamma = slideManufacturerSettingsWithDefaults.gamma;
  const defaultContrast = slideManufacturerSettingsWithDefaults.contrast;
  const defaultBrightness = slideManufacturerSettingsWithDefaults.brightness;

  const resetToDefaults = () => {
    handleChangeSlideGamma(defaultGamma);
    handleChangeSlideContrast(defaultContrast);
    handleChangeSlideBrightness(defaultBrightness);
  };

  // These values will be used as follows:
  // r,g,b = (pow(color, 1.0 / gamma) - 0.5) * contrast + brightness + 0.5;
  // Therefore, default values are set to result in no change to the color.
  const currentGamma = baseSlideVisualSettings?.gamma ?? defaultGamma;
  const currentContrast = baseSlideVisualSettings?.contrast ?? defaultContrast;
  const currentBrightness = baseSlideVisualSettings?.brightness ?? defaultBrightness;

  const isResetEnabled =
    currentGamma !== defaultGamma || currentContrast !== defaultContrast || currentBrightness !== defaultBrightness;

  const [expandAccordion, setExpandAccordion] = React.useState(false);

  return (
    <Accordion
      square
      expanded={expandAccordion}
      onChange={() => setExpandAccordion(!expandAccordion)}
      disabled={Boolean(disabled)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ paddingInline: 1 }}>
        <Grid item container alignItems="center" justifyContent="space-between" pt={1}>
          <Grid item>
            <Typography variant="h3">Advanced Image Controls</Typography>
          </Grid>
        </Grid>
      </AccordionSummary>

      <AccordionDetails sx={{ padding: 1 }}>
        <Grid container wrap="nowrap" alignItems="center" direction="column">
          <Grid item container wrap="nowrap" alignItems="center" spacing={2}>
            <Grid item container md={2} mr={1}>
              <Grid item>
                <Typography variant="caption">Gamma</Typography>
              </Grid>
              <Grid item>
                <TextField
                  size="small"
                  type="number"
                  variant="standard"
                  inputProps={{ min: 0.0001, max: 10, step: 0.05 }}
                  InputProps={{ sx: { fontSize: 10.5 } }}
                  value={currentGamma}
                  onChange={(event) => {
                    const newGamma = Number(event.target.value);
                    handleChangeSlideGamma(newGamma);
                  }}
                />
              </Grid>
            </Grid>
            <Grid item md={9}>
              <Slider
                value={currentGamma}
                onValueChange={handleChangeSlideGamma}
                max={10}
                step={0.1}
                marks={[{ value: defaultGamma, label: defaultGamma.toFixed(1) }]}
                track={false}
              />
            </Grid>
          </Grid>
          <Grid item container wrap="nowrap" alignItems="center" spacing={2}>
            <Grid item container md={2} mr={1}>
              <Grid item>
                <Typography variant="caption">Contrast</Typography>
              </Grid>
              <Grid item>
                <TextField
                  size="small"
                  type="number"
                  variant="standard"
                  inputProps={{ min: 0, max: 2, step: 0.05 }}
                  InputProps={{ sx: { fontSize: 10.5 } }}
                  value={currentContrast}
                  onChange={(event) => {
                    const newContrast = Number(event.target.value);
                    handleChangeSlideContrast(newContrast);
                  }}
                />
              </Grid>
            </Grid>
            <Grid item md={9}>
              <Slider
                value={currentContrast}
                onValueChange={handleChangeSlideContrast}
                max={2}
                step={0.1}
                marks={[{ value: defaultContrast, label: defaultContrast.toFixed(1) }]}
                track={false}
              />
            </Grid>
          </Grid>
          <Grid item container wrap="nowrap" alignItems="center" spacing={2}>
            <Grid item container md={2} mr={1}>
              <Grid item>
                <Typography variant="caption">Brightness</Typography>
              </Grid>
              <Grid item>
                <Grid item>
                  <TextField
                    size="small"
                    type="number"
                    variant="standard"
                    inputProps={{ min: 0, max: 1, step: 0.05 }}
                    InputProps={{ sx: { fontSize: 10.5 } }}
                    value={currentBrightness}
                    onChange={(event) => {
                      const newBrightness = Number(event.target.value);
                      handleChangeSlideBrightness(newBrightness);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={9}>
              <Slider
                value={currentBrightness}
                onValueChange={handleChangeSlideBrightness}
                max={1}
                step={0.01}
                marks={[{ value: defaultBrightness, label: defaultBrightness.toFixed(1) }]}
                track={false}
              />
            </Grid>
          </Grid>
          <Grid container wrap="nowrap" justifyContent="space-between" spacing={2}>
            <Grid item>
              <Button
                sx={{ marginTop: 3 }}
                variant="contained"
                color="primary"
                onClick={resetToDefaults}
                disabled={!isResetEnabled}
              >
                Reset to Defaults
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
