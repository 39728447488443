import { compact, filter, flatMap, isEmpty, map, omit, partition, some } from 'lodash';
import moment from 'moment';

import {
  FeatureMetadata,
  FeatureMetadataBeforeSecondaryAnalysisGrouping,
  FeatureMetadataSecondaryAnalysisEntry,
  FeatureMetadataSecondaryAnalysisEntryWithoutPublishingStatus,
  FeatureMetadataWithSecondaryAnalysisFlow,
  FeatureMetadataWithSecondaryAnalysisFlowWithoutPublishingStatus,
  FeatureMetadataWithSingleRun,
  SecondaryAnalysisStatus,
} from './featureMetadata';

export const isRunWithoutSecondaryAnalysis = (result?: FeatureMetadata): result is FeatureMetadataWithSingleRun =>
  (result as { secondaryAnalysisStatus?: SecondaryAnalysisStatus })?.secondaryAnalysisStatus ===
  SecondaryAnalysisStatus.None;

export const isParsedSecondaryAnalysisRun = (
  result: FeatureMetadata & {
    secondaryAnalysisStatus?: SecondaryAnalysisStatus;
  }
): result is FeatureMetadataWithSecondaryAnalysisFlow =>
  result?.secondaryAnalysisStatus === SecondaryAnalysisStatus.PrimaryResult;

export const flattenSecondaryResult = (
  result: FeatureMetadataWithSingleRun | FeatureMetadataWithSecondaryAnalysisFlowWithoutPublishingStatus
) => compact([result, ...(isParsedSecondaryAnalysisRun(result) ? result.secondaryResults : [])]);

export const flattenParsedSecondaryResults = (results: FeatureMetadata[]) => flatMap(results, flattenSecondaryResult);

/**
 * Check if a result is published based on the primary run result and secondary results
 * The result is considered published if it's parent run is approved and it is the latest result that is not deleted
 * @param result The result to check if it's published (can be a primary run or a secondary result)
 * @param primaryRunResult The primary run result
 * @param primaryRunWithSecondaryResults The primary run result with all secondary results in a single array (flattened for convenience)
 * @returns true if the result is published, false otherwise
 */
const isResultPublished = (
  result:
    | FeatureMetadataWithSecondaryAnalysisFlowWithoutPublishingStatus
    | FeatureMetadataSecondaryAnalysisEntryWithoutPublishingStatus,
  primaryRunResult: FeatureMetadataWithSecondaryAnalysisFlowWithoutPublishingStatus,
  primaryRunWithSecondaryResults: ReturnType<typeof flattenSecondaryResult>
) =>
  primaryRunResult?.approved &&
  !result?.deletedAt &&
  // Check if there is a more recent result that is not deleted
  !some(
    primaryRunWithSecondaryResults,
    (otherResult) =>
      !otherResult?.deletedAt &&
      otherResult?.experimentResultId !== result?.experimentResultId &&
      otherResult?.experimentResultId !== primaryRunResult?.experimentResultId &&
      moment(otherResult?.createdAt) > moment(result?.createdAt)
  );

/**
 * Check if a result is internally approved based on the primary run result and secondary results
 * The result is considered internally approved if it's parent run is internally approved and it is the latest result that is not deleted
 * @param result
 * @param primaryRunResult
 * @param primaryRunWithSecondaryResults
 * @returns
 */
const isResultInternallyApproved = (
  result:
    | FeatureMetadataWithSecondaryAnalysisFlowWithoutPublishingStatus
    | FeatureMetadataSecondaryAnalysisEntryWithoutPublishingStatus,
  primaryRunResult: FeatureMetadataWithSecondaryAnalysisFlowWithoutPublishingStatus,
  primaryRunWithSecondaryResults: ReturnType<typeof flattenSecondaryResult>
) =>
  primaryRunResult?.internallyApproved &&
  !result?.deletedAt &&
  // Check if there is a more recent result that is not deleted
  !some(
    primaryRunWithSecondaryResults,
    (otherResult) =>
      !otherResult?.deletedAt &&
      otherResult?.experimentResultId !== result?.experimentResultId &&
      otherResult?.experimentResultId !== primaryRunResult?.experimentResultId &&
      moment(otherResult?.createdAt) > moment(result?.createdAt)
  );

const getSecondaryAnalysisPublishingStatus = (
  result:
    | FeatureMetadataWithSecondaryAnalysisFlowWithoutPublishingStatus
    | FeatureMetadataSecondaryAnalysisEntryWithoutPublishingStatus,
  primaryRunResult: FeatureMetadataWithSecondaryAnalysisFlowWithoutPublishingStatus,
  primaryRunWithSecondaryResults: ReturnType<typeof flattenSecondaryResult>
): FeatureMetadataWithSecondaryAnalysisFlow['secondaryAnalysisPublishingStatus'] => ({
  published: isResultPublished(result, primaryRunResult, primaryRunWithSecondaryResults),
  internallyApproved: isResultInternallyApproved(result, primaryRunResult, primaryRunWithSecondaryResults),
});

/**
 * Add publishing status to feature metadata based on the primary run result and secondary results
 * This will be performed recursively for nested items and secondary results
 *
 * @param primaryRunResult - A run without secondary results or a primary run with secondary results
 * @returns a feature metadata object with publishing status
 */
const addSecondaryAnalysisPublishingStatusToFeatureMetadata = (
  primaryRunResult: FeatureMetadataWithSingleRun | FeatureMetadataWithSecondaryAnalysisFlowWithoutPublishingStatus
): FeatureMetadata => {
  if (isRunWithoutSecondaryAnalysis(primaryRunResult)) {
    return primaryRunResult;
  }
  const primaryRunWithSecondaryResults = flattenSecondaryResult(primaryRunResult);

  return {
    ...omit(primaryRunResult, ['secondaryResults']),
    secondaryAnalysisPublishingStatus: getSecondaryAnalysisPublishingStatus(
      primaryRunResult,
      primaryRunResult,
      primaryRunWithSecondaryResults
    ),
    secondaryResults: map(primaryRunResult.secondaryResults, (secondaryResult) => {
      return {
        ...omit(secondaryResult, ['secondaryResults']),
        secondaryAnalysisPublishingStatus: getSecondaryAnalysisPublishingStatus(
          secondaryResult,
          primaryRunResult,
          primaryRunWithSecondaryResults
        ),
      } as FeatureMetadataSecondaryAnalysisEntry;
    }),
  };
};

export const groupPrimaryResultsWithSecondaryResults = (
  results: FeatureMetadataBeforeSecondaryAnalysisGrouping[]
): FeatureMetadata[] => {
  const [secondaryResults, primaryResults] = partition(results, (result) => Boolean(result.primaryRunOrchestrationId));

  const secondaryResultsWithoutPrimary = map(
    filter(secondaryResults, (result) => !some(primaryResults, { orchestrationId: result.primaryRunOrchestrationId })),
    (result) => ({ ...result, secondaryAnalysisStatus: SecondaryAnalysisStatus.SecondaryResultWithMissingParent })
  );

  const resultsWithSecondaryAsNested = map(primaryResults, (primaryResult) => {
    const secondaryResultsForPrimary = map(
      filter(secondaryResults, {
        primaryRunOrchestrationId: primaryResult.orchestrationId,
      }),
      (secondaryResult) => ({
        ...secondaryResult,
        secondaryAnalysisStatus: SecondaryAnalysisStatus.SecondaryResult,
      })
    ) as FeatureMetadataSecondaryAnalysisEntryWithoutPublishingStatus[];

    if (!isEmpty(secondaryResultsForPrimary)) {
      return {
        ...primaryResult,
        secondaryResults: secondaryResultsForPrimary,
        secondaryAnalysisStatus: SecondaryAnalysisStatus.PrimaryResult,
      } as FeatureMetadataWithSecondaryAnalysisFlowWithoutPublishingStatus;
    } else {
      return {
        ...primaryResult,
        secondaryResults: undefined,
        secondaryAnalysisStatus: SecondaryAnalysisStatus.None,
      } as FeatureMetadataWithSingleRun;
    }
  });

  const allFinalResultsWithoutPublishingStatus = [...resultsWithSecondaryAsNested, ...secondaryResultsWithoutPrimary];
  return map(allFinalResultsWithoutPublishingStatus, addSecondaryAnalysisPublishingStatusToFeatureMetadata);
};
