import { Permission } from 'interfaces/permissionOption';
import { useEffect } from 'react';
import { useAnnotationQueryParams } from 'services/annotations/useAnnotationQueryParams';
import { BooleanParam, StringParam, useQueryParam } from 'use-query-params';
import { usePermissions } from 'utils/usePermissions';
import { SlideAnnotationEditType } from './options';

export const useSlideAnnotationKeyboardEvents = () => {
  const [useOSDViewer] = useQueryParam('useOSDViewer', BooleanParam);
  const [, setEditAnnotationMode] = useQueryParam('editAnnotationsMode', StringParam);
  const [, setHideFillColor] = useQueryParam('hideFillColor', BooleanParam);

  const { annotationsActive } = useAnnotationQueryParams();

  const { hasPermission } = usePermissions();
  const canAnnotateSlides = hasPermission(Permission.AnnotateSlides);
  const canRunSecondaryAnalysis = hasPermission(Permission.RunSecondaryAnalysis);
  const hasPermissionsToDrawingTools = canAnnotateSlides || canRunSecondaryAnalysis;

  useEffect(() => {
    if (!useOSDViewer && hasPermissionsToDrawingTools && annotationsActive) {
      const handleKeyDownForShortcuts = (event: KeyboardEvent) => {
        // don't trigger shortcuts if the user is pressing a modifier key
        if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
          return;
        }
        if (event.key === 'p') {
          setEditAnnotationMode(SlideAnnotationEditType.Polygon);
        } else if (event.key === 'r') {
          setEditAnnotationMode(SlideAnnotationEditType.Roi);
        } else if (event.key === 'd') {
          setEditAnnotationMode(SlideAnnotationEditType.Delete);
        } else if (event.key === 'e') {
          // e for edit
          setEditAnnotationMode(SlideAnnotationEditType.Modify);
        } else if (event.key === 'h') {
          setHideFillColor((hideFillColor) => !hideFillColor);
        }
      };

      window.addEventListener('keydown', handleKeyDownForShortcuts);

      return () => {
        window.removeEventListener('keydown', handleKeyDownForShortcuts);
      };
    }
  }, [useOSDViewer, hasPermissionsToDrawingTools, annotationsActive]);
};
