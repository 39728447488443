import { useSignals } from '@preact/signals-react/runtime';
import { compact, forEach } from 'lodash';
import { useEffect, useState, useTransition } from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';

import {
  defaultLayerVisualizationSettings,
  LayerVisualizationChange,
  slidesLayerVisualizationSettings,
  useApplyChangesToSlideLayerVisualizationSettings,
  useGetLayerSettingsFromUrl,
} from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import { FeatureMetadata } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import usePrevious from 'utils/usePrevious';
import { getHeatmapUrlKeyFromFeatureMetadata } from '../helpers';

const osdOverrideSettings = { selected: false };

export const useUpdateRasterHeatmapsSettingsOnChange = ({
  slideId,
  viewerIndex,
  stainTypeId,
  heatmaps,
  addOrchestrationIdToUrl,
}: {
  slideId: string;
  viewerIndex: number;
  stainTypeId: string;
  heatmaps: FeatureMetadata[];
  addOrchestrationIdToUrl?: boolean;
}) => {
  useSignals();
  const [useOSDViewer] = useQueryParam('useOSDViewer', BooleanParam);
  const getLayerSettingsFromUrl = useGetLayerSettingsFromUrl();
  const applyChangesToSlideLayerVisualizationSettings = useApplyChangesToSlideLayerVisualizationSettings();

  const previousSlideParams = usePrevious({ slideId, viewerIndex });

  const [didApplyInitialSettings, setDidApplyInitialSettings] = useState(false);
  const [, startTransition] = useTransition();
  useEffect(() => {
    if (previousSlideParams?.slideId === slideId && previousSlideParams?.viewerIndex === viewerIndex) {
      return;
    }
    setDidApplyInitialSettings(false);
    const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings?.[viewerIndex];
    const changes: LayerVisualizationChange[] = [];
    forEach(compact(heatmaps), (parentHeatmap) => {
      const parentHeatmapLayerSettingsKey = parentHeatmap?.id;
      const parentLayerUrlKey = getHeatmapUrlKeyFromFeatureMetadata({
        heatmap: parentHeatmap,
        addOrchestrationId: addOrchestrationIdToUrl,
      });
      const previousParentSetting =
        viewerSlideLayerVisualizationSettings?.value?.[slideId]?.[parentHeatmapLayerSettingsKey]?.value || {};
      const parentSettingFromUrl = getLayerSettingsFromUrl({
        layerUrlKey: parentLayerUrlKey,
        stainTypeId,
        viewerIndex,
      });
      const parentSettings = {
        ...defaultLayerVisualizationSettings,
        ...previousParentSetting,
        ...parentSettingFromUrl,
        // OSD is buggy and has trouble with auto-selecting layers
        ...(useOSDViewer ? osdOverrideSettings : {}),
      };
      changes.push({ layerId: parentHeatmapLayerSettingsKey, newSettings: parentSettings });
      forEach(parentHeatmap?.nestedItems || [], (nestedHeatmap) => {
        const layerSettingsKey = nestedHeatmap.id;
        const previousSettings =
          viewerSlideLayerVisualizationSettings?.value?.[slideId]?.[layerSettingsKey]?.value || {};
        const layerUrlKey = getHeatmapUrlKeyFromFeatureMetadata({
          heatmap: nestedHeatmap,
          parentHeatmap,
          addOrchestrationId: addOrchestrationIdToUrl,
        });
        const urlSettings = getLayerSettingsFromUrl({ layerUrlKey, stainTypeId, viewerIndex });
        changes.push({
          layerId: layerSettingsKey,
          newSettings: {
            ...defaultLayerVisualizationSettings,
            ...(Boolean(previousSettings) && previousSettings),
            ...urlSettings,
            ...(parentSettings?.selected ? { selected: true } : {}),
            // OSD is buggy and has trouble with auto-selecting layers
            ...(useOSDViewer ? osdOverrideSettings : {}),
          },
        });
      });
    });

    applyChangesToSlideLayerVisualizationSettings({
      slideId,
      viewerIndex,
      changes,
      skipUrlUpdate: true,
      changeFlow: `RasterHeatmaps-initial`,
    });
    startTransition(() => {
      setDidApplyInitialSettings(true);
    });
  }, [useOSDViewer, viewerIndex, slideId, stainTypeId, addOrchestrationIdToUrl, heatmaps, getLayerSettingsFromUrl]);

  return didApplyInitialSettings;
};
