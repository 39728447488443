import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import { Box, Button, Drawer, Grid, Skeleton, useTheme } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import { find, first } from 'lodash';
import React from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { FixedSizeGrid } from 'react-window';
import { BooleanParam, useQueryParam } from 'use-query-params';

import { Accession } from 'interfaces/accession';
import { CaseSlideThumbnailData, Procedure } from 'interfaces/procedure';
import { getSlideThumbnailUrl } from 'utils/helpers';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { useSelectedSlideIds } from 'utils/useCurrentSlideIds';
import { updateZoomFactorFromSlide } from '../zoomFactor';
import { ThumbnailsSlider } from './ThumbnailsSlider';
import { useThumbnailSlides } from './useThumbnailSlides';

export const SlideThumbnailsGrid: React.FC<{
  drawerWidth: number;
  thumbnailSize: number;
  isLoading: boolean;
  currentImageIndex: number;
  changeImageIndex: (index: number) => void;
  caseSlideThumbnailsData: CaseSlideThumbnailData[];
  labId: string;
}> = React.memo(
  ({ drawerWidth, thumbnailSize, isLoading, currentImageIndex, changeImageIndex, caseSlideThumbnailsData, labId }) => {
    const gridRef = React.useRef<any>(null);

    const actualIndex = Math.max(0, currentImageIndex);

    React.useEffect(() => {
      // Scroll to the current slide thumbnail when the user navigates to a new slide
      if (gridRef.current) {
        gridRef.current.scrollToItem({ columnIndex: actualIndex, rowIndex: 0 });
      }
    }, [actualIndex]);

    const numSlides = isLoading ? 100 : caseSlideThumbnailsData?.length ?? 0;
    return (
      numSlides > 0 && (
        <FixedSizeGrid
          ref={gridRef}
          columnCount={numSlides}
          rowCount={1}
          rowHeight={thumbnailSize}
          columnWidth={thumbnailSize}
          height={thumbnailSize}
          width={Math.min(drawerWidth, thumbnailSize * numSlides)}
        >
          {({ columnIndex, style }) => {
            const slideThumbnailData = !isLoading ? caseSlideThumbnailsData?.[columnIndex] : undefined;
            const url = slideThumbnailData
              ? getSlideThumbnailUrl({
                  id: slideThumbnailData.slideId,
                  labId: slideThumbnailData?.labId || labId,
                })
              : undefined;

            const isError = !isLoading && !slideThumbnailData;
            if (isError) {
              console.error('No slide thumbnail data found for index', columnIndex);
            }

            const selected = columnIndex === actualIndex;
            return (
              <Button
                disabled={isError}
                sx={{ ...style, padding: 1 }}
                onClick={!isError ? () => changeImageIndex(columnIndex) : undefined}
              >
                {isError ? (
                  <Grid container alignItems={'center'} justifyContent={'center'} sx={{ ...slideThumbnailButtonStyle }}>
                    <ImageNotSupportedIcon fontSize="medium" color="disabled" />
                  </Grid>
                ) : isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height="100%" />
                ) : (
                  <Box
                    sx={{
                      ...slideThumbnailButtonStyle,
                      backgroundImage: url ? `url(${url})` : undefined,
                      '&:hover': !selected ? { boxShadow: 0, color: 'inherit' } : undefined,
                      ...(selected ? { color: 'primary.main', boxShadow: '0px 0px 0px 2px' } : {}),
                    }}
                  />
                )}
              </Button>
            );
          }}
        </FixedSizeGrid>
      )
    );
  }
);

export interface SlideThumbnailsProps {
  caseData: Procedure | Accession;
  height: number;
  marginLeft?: number;
  marginRight?: number;
  marginTop?: number;
  isLoadingCaseData?: boolean;
}

// In order to avoid vertical scrollbar appearing in the thumbnails drawer, we reduce the thumbnail size by 1 pixel
const AVOID_SCROLLBAR_SIZE = 1;

export const SlideThumbnails: React.FC<React.PropsWithChildren<SlideThumbnailsProps>> = ({
  caseData,
  isLoadingCaseData,
  marginLeft,
  marginRight,
  marginTop = 2,
  height,
}) => {
  useSignals();
  const theme = useTheme();
  const [slidesThumbnailsDrawerOpen, setSlidesThumbnailsDrawerOpen] = useQueryParam(
    'slidesThumbnailsDrawerOpen',
    BooleanParam
  );
  const { labId } = useCurrentLabId();

  const [selectedSlideIds] = useSelectedSlideIds(caseData);

  const selectedSlideId = first(selectedSlideIds) ?? first(caseData?.slides)?.id;

  const { isLoading, currentImageIndex, changeImageIndex, caseSlideThumbnailsData } = useThumbnailSlides(
    caseData,
    selectedSlideId,
    { enabled: !isLoadingCaseData }
  );
  const primarySlide = find(caseData?.slides, { id: selectedSlideId });

  const [useOSDViewer] = useQueryParam('useOSDViewer', BooleanParam);

  const drawerSize = useResizeDetector();

  return (
    <>
      <Drawer
        ref={drawerSize.ref}
        anchor="bottom"
        open={slidesThumbnailsDrawerOpen}
        variant="persistent"
        sx={{
          overflow: 'hidden',
          '&>.MuiPaper-root': {
            backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[500],
            height: `${height - marginTop}px`,
            width: `calc(100% - ${marginLeft + marginRight}px)`,
            marginLeft: `${marginLeft}px`,
            marginRight: `${marginRight}px`,
            marginTop: `${marginTop}px`,
            justifyContent: 'center',
            alignItems: 'center',
          },
        }}
        onClose={useOSDViewer ? () => updateZoomFactorFromSlide(primarySlide) : undefined}
      >
        {drawerSize.width ? (
          <SlideThumbnailsGrid
            isLoading={isLoading}
            currentImageIndex={currentImageIndex}
            changeImageIndex={changeImageIndex}
            caseSlideThumbnailsData={caseSlideThumbnailsData}
            labId={labId}
            thumbnailSize={height - marginTop - AVOID_SCROLLBAR_SIZE}
            drawerWidth={drawerSize.width - marginLeft - marginRight}
          />
        ) : (
          <Skeleton variant="rectangular" width="100%" height="100%" />
        )}
      </Drawer>
      <ThumbnailsSlider
        drawerHeight={`${height}px`}
        onClick={() => setSlidesThumbnailsDrawerOpen(!slidesThumbnailsDrawerOpen)}
        collapsed={!slidesThumbnailsDrawerOpen}
        marginLeft={`${marginLeft}px`}
        marginRight={`${marginRight}px`}
        text={!slidesThumbnailsDrawerOpen ? 'Show Slides' : ''}
      />
    </>
  );
};

const slideThumbnailButtonStyle = {
  border: 1,
  boxShadow: 1,
  borderRadius: 1,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  backgroundColor: '#f5f5f5',
  width: '100%',
  height: '100%',
};
