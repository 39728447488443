import { PMTilesMetadata } from '@loaders.gl/pmtiles';
import { FeatureCollection } from '@turf/helpers';
import { difference, isEmpty, map } from 'lodash';
// @ts-ignore
import { reproject } from 'reproject';

/**
 * Validate the selected layers against the PMTiles metadata.
 * This function will log a warning if there are any discrepancies between the selected layers and the PMTiles metadata.
 * @param {Object} options The options for the function.
 * @param {PMTilesMetadata} options.pmtMetadata The metadata of the PMTiles file.
 * @param {string[]} options.expectedLayers The expected layers in the PMTiles metadata.
 * @param {string[]} options.selectedLayers The selected layers to display.
 */
export const validatePmtLayers = ({
  pmtMetadata,
  expectedLayers,
  selectedLayers,
}: {
  pmtMetadata: PMTilesMetadata | null;
  expectedLayers?: string[];
  selectedLayers?: string[];
}) => {
  if (!pmtMetadata) {
    console.warn('Invalid PMTiles metadata', { pmtMetadata });
    return;
  }
  const pmtClasses = map(pmtMetadata?.tilejson?.layers, 'id');

  if (!isEmpty(selectedLayers)) {
    const unmatchedHeatmapClasses = difference(selectedLayers, pmtClasses);

    if (!isEmpty(unmatchedHeatmapClasses)) {
      console.warn('Mismatch between PMTiles classes and heatmap options', {
        unmatchedHeatmapClasses,
        pmtMetadata,
        pmtClasses,
        selectedLayers,
      });
    }
  }

  if (!isEmpty(expectedLayers)) {
    const unmatchedPmtClasses = difference(pmtClasses, expectedLayers);
    const unmatchedHeatmapClasses = difference(expectedLayers, pmtClasses);

    if (!isEmpty(unmatchedPmtClasses) || !isEmpty(unmatchedHeatmapClasses)) {
      console.warn('Mismatch between PMTiles classes and heatmap options', {
        unmatchedPmtClasses,
        unmatchedHeatmapClasses,
        pmtMetadata,
        pmtClasses,
        expectedLayers,
      });
    }
  }
};

/**
 * Reproject a feature collection from EPSG:4326 to EPSG:3857.
 * EPSG:4326, also known as the WGS84 projection (because it's based on WGS84's ellipsoid), is a coordinate system used in Google Earth and GSP systems. It represents Earth as a three-dimensional ellipsoid
 * EPSG:3857, also known as the Web Mercator projection, is a coordinate system used in web mapping applications. It represents Earth as a two-dimensional square.
 * When creating the PMT files, pixel coordinates are encoded as meters in the EPSG:3857 projection, but saved in the EPSG:4326 projection (due to how the PMT format works).
 * To return the data to the original pixel coordinates, we need to reproject it back to EPSG:3857.
 */
export const reprojectFeatureCollection = (featureCollection: FeatureCollection) => {
  // EPSG code is a unique identifier for different coordinate systems
  const originProjection = 'EPSG:4326';
  const targetProjection = 'EPSG:3857';

  return reproject(featureCollection, originProjection, targetProjection) as FeatureCollection;
};

/**
 * Convert a deck.gl tile {x ,y ,z} coordinates to a PMTiles tile {x ,y ,z} coordinates.
 * We perform the following transformations:
 * - The origin of the PMTiles tile is at the center of the map, while the origin of the deck.gl tile is at the top left.
 * - The y axis is flipped in PMTiles (negative values in the bottom, positive on top).
 * - In deck GL, we zoom out of the base layer, so we need to add the maximum zoom level of the base layer to the zoom level.
 * @param x The x coordinate of the tile.
 * @param y The y coordinate of the tile.
 * @param z The zoom level of the tile.
 * @param maxLevel The maximum zoom level of the pmt source.
 * @returns The transformed tile {x ,y ,z} coordinates.
 */
export const deckGLToPMTTileIndex = ({ x, y, z, maxLevel }: { x: number; y: number; z: number; maxLevel: number }) => {
  const actualZoom = z + maxLevel;
  // PMTiles uses a geographic coordinate system, where the origin is at the center of the map,
  // and the y axis is flipped (negative values in the bottom, positive on top).
  const actualX = x + 2 ** (actualZoom - 1);
  const actualY = 2 ** (actualZoom - 1) - y - 1;

  return { x: actualX, y: actualY, z: actualZoom };
};

/**
 * Generate a tile size for the PMTiles source.
 *
 * This tile size will help match a tile in each zoom 'coordinate' to the matching data in the web mercator projection.
 * This is required because the PMTiles source uses a geographic coordinate system, while deck.gl uses a pixel projection.
 *
 * The relevant difference stems from the fact that in Deck GL we define zooms from the original image, meaning that at zoom 0 the pixels are in 'true size'.
 * Meanwhile, in PMTiles, the zooms are defined from the geographic coordinate system, meaning that we start at zoom 0 which is defined as a single tile of
 * size 256 pixels containing the entire length of the equator (see https://docs.maptiler.com/google-maps-coordinates-tile-bounds-projection/).
 *
 * To calculate the tile size, we need to create a tile that would have a pixel resolution of 1 meter per pixel (since we encode pixels as meters in the PMTiles source).
 *
 * @param maxLevel The maximum zoom level of the pmt source.
 * @returns The tile size in pixels.
 *
 * @example: getTileSizeByMaxZoom(16) == 611.49622628141;
 */
export const getTileSizeByMaxZoom = (maxLevel: number) => {
  const MERCATOR_ZOOM_0_TILE_PIXELS_WIDTH = 256;
  const EQUATOR_LENGTH_METERS = 40075016.68557849;
  const MERCATOR_ZOOM_0_RESOLUTION_METERS_PER_PIXEL = EQUATOR_LENGTH_METERS / MERCATOR_ZOOM_0_TILE_PIXELS_WIDTH;
  const MERCATOR_MAX_ZOOM_RESOLUTION_METERS_PER_PIXEL = MERCATOR_ZOOM_0_RESOLUTION_METERS_PER_PIXEL / 2 ** maxLevel;
  return MERCATOR_MAX_ZOOM_RESOLUTION_METERS_PER_PIXEL * MERCATOR_ZOOM_0_TILE_PIXELS_WIDTH;
};
