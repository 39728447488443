import { Slide } from 'interfaces/slide';
import React from 'react';
import { ReactImageGalleryProps } from 'react-image-gallery';
import ImageWithFallback from '../ImageWithFallback';

export const getGalleryThumbnailRenderer = (slide: Slide) => {
  const GalleryThumbnailRenderer: ReactImageGalleryProps['renderThumbInner'] = ({ thumbnail, thumbnailLabel }) => {
    return (
      Boolean(thumbnail) && (
        <span className="image-gallery-thumbnail-inner">
          <div className="image-gallery-thumbnail-image">
            <ImageWithFallback imageUrls={[thumbnail]} slide={slide} />
          </div>
          {thumbnailLabel && <div className="image-gallery-thumbnail-label">{thumbnailLabel}</div>}
        </span>
      )
    );
  };
  return GalleryThumbnailRenderer;
};
