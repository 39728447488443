import { AreaType } from 'interfaces/areaType';
import { ClassificationModel } from 'interfaces/classificationModel';
import { ClusterType } from 'interfaces/clusterType';
import { StainType } from 'interfaces/stainType';
import {
  compact,
  filter,
  find,
  first,
  flatMap,
  forEach,
  fromPairs,
  includes,
  isArray,
  isEmpty,
  join,
  keys,
  last,
  map,
  merge,
  mergeWith,
  omit,
  orderBy,
  replace,
  size,
  slice,
  split,
  startsWith,
  tail,
  toString,
  trim,
  trimEnd,
  values,
} from 'lodash';
import { FormatBracketsOptions } from './formatBracketsOptions';

const actionDict: Record<string, string> = {
  '@in': ':',
  '@nin': ' is not',
  '@gt': ' >',
  '@lt': ' <',
  '@gte': ' >=',
  '@lte': ' <=',
  '@eq': ' =',
  '@neq': ' !=',
  '@and': 'and',
  '@or': 'or',
};

export const bracketsRegex = /[<>]+/;
const separatorRegex = /[_-]/g;
const newFormatCellRegex = /@(and|or)\[[^[\]]+\]/g;

export const isBrackets = (key: string) => {
  return startsWith(key, '<');
};

const getTumorClassificationDisplayName = (tumorClassification: string) => {
  return replace(tumorClassification, 'tumor_cell-', '');
};

const intensityNumbersOnTheEndRegex = /\d+$/;
const getIntensityClassificationDisplayName = (intensityClassification: string) => {
  const classificationIntensities: string[] = [];
  const intensityClassificationParts = split(intensityClassification, '|');

  // Support backwards compatibility for intensity classifier (intensity=tumor_cell-intensity1) - take only the last digits

  forEach(intensityClassificationParts, (intensityClassificationPart) => {
    const intensityNumber = intensityNumbersOnTheEndRegex.exec(intensityClassificationPart);
    if (first(intensityNumber)) classificationIntensities.push(first(intensityNumber));
  });

  return join(classificationIntensities, ' and ');
};

const createClassifiers = (classifiers: unknown) => {
  const classifiersDict: Record<string, any> = {};
  classifiersDict['stains'] = {};
  if (isArray(classifiers)) {
    forEach(classifiers, (classifier: string) => {
      const [key, value] = split(classifier, '=');
      if (value === 'False' || value === 'True') classifiersDict['stains'][key] = value;
      else if (key === 'intensity') classifiersDict[key] = getIntensityClassificationDisplayName(value);
      else if (key === 'membrane') classifiersDict[key] = includes(value, 'membranous-partial') ? 'partial' : 'full';
      else if (key === 'tumor_classification') {
        const tumorClassificationDisplayName = getTumorClassificationDisplayName(value);
        if (includes(tumorStates, tumorClassificationDisplayName)) {
          classifiersDict['state'] = tumorClassificationDisplayName;
        } else if (includes(tumorTypes, tumorClassificationDisplayName)) {
          classifiersDict['type'] = tumorClassificationDisplayName;
        } else classifiersDict['tumor classification'] = tumorClassificationDisplayName;
      } else classifiersDict[key] = value;
    });
  }

  return classifiersDict;
};

export const dealWithClassifiers = (obj: Record<string, any>) => {
  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      dealWithClassifiers(obj[key]);
      if (key === 'c') {
        const value = obj[key];
        if (typeof value === 'object' && first(keys(value)) !== 'subset' && first(keys(value)) !== 'superset') {
          obj[key] = first(keys(value));
          obj['classifiers'] = createClassifiers(first(values(value)));
        }
      }
    }
  }
};

const createRecursiveObject = (array: string[]) => {
  const result: Record<string, any> = {};
  const arrayLength = array?.length ?? 0;
  const firstArrayElement = first(array);
  if (arrayLength === 1) {
    result[firstArrayElement] = true;
    return result;
  }
  if (arrayLength === 2) {
    result[firstArrayElement] = array[1];
    return result;
  }

  //c has classifiers
  if (firstArrayElement === 'c' && arrayLength > 2) {
    result[firstArrayElement] = { [array[1]]: slice(array, 2) };
    return result;
  }

  const [key, ...remaining] = isArray(array) ? array : [];
  result[key] = createRecursiveObject(remaining);
  return result;
};

const customizer = (objValue: string, srcValue: string) => {
  if (objValue) {
    return flatMap([objValue, srcValue]);
  } else return srcValue;
};

export const getDictBrackets = (featureParts: string[]) => {
  let dictBrackets: Record<string, any> = {};
  forEach(featureParts, (featurePart: string) => {
    const featurePartsList: string[] = split(featurePart, ':');

    if (first(featurePartsList) === 'sources_s') {
      dictBrackets = mergeWith(dictBrackets, createRecursiveObject(featurePartsList), customizer);
    } else {
      dictBrackets = merge(dictBrackets, createRecursiveObject(featurePartsList));
    }
  });

  dealWithClassifiers(dictBrackets);
  return dictBrackets;
};

export const buildClassifiersString = (
  classifiers: any,
  bracketTypesOptions: FormatBracketsOptions,
  additionalOptions?: { withParenthesis: boolean; isMarkerPositivity: boolean }
) => {
  const { withParenthesis, isMarkerPositivity } = additionalOptions || {
    withParenthesis: true,
    isMarkerPositivity: false,
  };

  let classifiersString = '';
  let classifiersStainString = '';
  let classifierClusterIdString = '';

  if (!isEmpty(classifiers)) {
    const stains = classifiers['stains'];
    let otherClassifiers = omit(classifiers, 'stains');
    if (!isEmpty(stains)) {
      classifiersStainString = withParenthesis ? '(' : '';
      for (const stainKey in stains) {
        const stainValue = stains[stainKey];
        const stainKeyFormatted = getFormattedStain(stainKey, bracketTypesOptions.stainTypeOptions);
        const classifiersStainSign = isMarkerPositivity ? '' : stainValue === 'True' ? '+' : '-';
        classifiersStainString += `${stainKeyFormatted}${classifiersStainSign}, `;
      }
      classifiersStainString = classifiersStainString.slice(0, -2);
      classifiersStainString += withParenthesis ? ') ' : ' ';
    }

    if (classifiers['cluster_id']) {
      classifierClusterIdString = withParenthesis ? '(' : '';
      classifierClusterIdString += getFormattedCluster(
        classifiers['cluster_id'],
        bracketTypesOptions.clusterTypeOptions
      );
      classifierClusterIdString += withParenthesis ? ') ' : ' ';

      otherClassifiers = omit(otherClassifiers, 'cluster_id');
    }

    if (!isEmpty(otherClassifiers)) {
      classifiersString = withParenthesis ? '(' : '';
      for (const classifierKey in otherClassifiers) {
        const classifierValue = otherClassifiers[classifierKey];
        const classifierKeyFormatted = getFormattedClassificationModels(
          classifierKey,
          bracketTypesOptions.classificationModelOptions
        );
        classifiersString += `${classifierKeyFormatted}: ${classifierValue}, `;
      }
      classifiersString = classifiersString.slice(0, -2);
      classifiersString += withParenthesis ? ') ' : ' ';
    }
  }

  return trimEnd(`${classifiersStainString}${classifierClusterIdString}${classifiersString}`);
};

export const applyStainTypeIfRequired = (
  formattedFeatureDisplayName: string,
  stainIndex: string,
  options: FormatBracketsOptions
) => {
  if (!options.addStain) {
    return formattedFeatureDisplayName;
  }
  return `${getFormattedStain(stainIndex, options.stainTypeOptions)} ${formattedFeatureDisplayName}`;
};

export const getFeatureNameBrackets = (key: string) => {
  const featureParts = slice(split(key, bracketsRegex), 1, -1);
  return getDictBrackets(featureParts);
};

export const getGridBasedString = (dictBrackets: any) => {
  const gridBased = { startWith: '', endWith: '', threshold: '' };
  if (dictBrackets?.grid_based) {
    if (dictBrackets?.reduction && dictBrackets?.reduction !== 'None') {
      let reduction = dictBrackets?.reduction;
      if (dictBrackets?.reduction === 'fraction_above_threshold') {
        reduction = 'percent';
      }
      gridBased.startWith = `${reduction} of `;
    }
    if (dictBrackets?.grid_size) gridBased.endWith = ` (grid size: ${dictBrackets?.grid_size} um)`;

    if (dictBrackets?.reduction_threshold) {
      gridBased.threshold = ` above ${dictBrackets?.reduction_threshold}`;
    }
  }
  return gridBased;
};

export const getStatistic = (statistic: string) => {
  return statistic ? `(${getFormattedText(statistic)}) ` : '';
};

const getPositiveOrNegativeSign = (cellType: string) => {
  return includes(cellType, 'positive') ? '+' : '-';
};

const positiveOrNegativeRegex = / (positive|negative)/g;
export const getFormattedCellWithoutPositiveOrNegative = (cellType: string) => {
  return replace(getFormattedCell(cellType), positiveOrNegativeRegex, '');
};

export const getFormattedCell = (cellType: string) => {
  return trim(replace(cellType, separatorRegex, ' '));
};

const isPositiveOrNegative = (cellType: string) => {
  return includes(cellType, 'positive') || includes(cellType, 'negative') || includes(cellType, 'other');
};

export const getFormattedCellWithStain = (cellTypes: string[], stainType: string, stainTypeOptions: StainType[]) => {
  const formattedCellWithStain: string[] = [];
  const stainToDisplay = isEmpty(stainType) || stainType === '1' || stainType === '2' ? false : true;

  if (stainToDisplay) {
    forEach(cellTypes, (currentCellType) => {
      const stain = isPositiveOrNegative(currentCellType)
        ? ` (${getFormattedStain(stainType, stainTypeOptions)}${getPositiveOrNegativeSign(currentCellType)})`
        : '';
      formattedCellWithStain.push(`${getFormattedCellWithoutPositiveOrNegative(currentCellType)}${stain}`);
    });
  } else if (isEmpty(stainType)) {
    cellTypes.forEach((currentCellType) => {
      formattedCellWithStain.push(`${getFormattedCell(currentCellType)}`);
    });
  } else {
    cellTypes.forEach((currentCellType) => {
      formattedCellWithStain.push(`${getFormattedCellWithoutPositiveOrNegative(currentCellType)}`);
    });
  }

  return join(formattedCellWithStain, ' and ');
};

const cellTypesRegex = /,(?![^(]*\))/;
const labelActionValuesRegex = /([\w.]+)(@[a-zA-Z]+)\((.+)\)/;
const markerPosRegex = /marker.pos\((\d+)\)/;

const parseStringToCellTypesJson = (input: string, debug: boolean = false) => {
  // Split the string by commas that are outside parentheses
  const cellTypes = split(input, cellTypesRegex);

  return map(cellTypes, (cellType) => {
    const markerPosMatch = cellType.match(markerPosRegex);
    if (markerPosMatch) {
      const [, stainTypes] = markerPosMatch;
      return {
        label: 'marker.pos',
        action: '@in',
        values: orderBy(map(split(stainTypes, ','), (v) => trim(v))),
      };
    }
    // Match the label, action, and values using a regex
    const match = cellType.match(labelActionValuesRegex);
    if (!match) {
      if (debug) {
        console.warn('Cell type does not match regex', { cellType, match });
      }
      return null;
    }

    const [, label, action, cellValues] = match;

    return {
      label,
      action,
      values: orderBy(map(split(cellValues, ','), (v) => trim(v))),
    };
  });
};

// get cell label from cell.label, if it has a dot, remove the first part before the dot
const getCellLabel = (cellLabel: string) => {
  let cellLabelParts = split(cellLabel, '.');

  if (last(cellLabelParts) === 'label') {
    cellLabelParts = slice(cellLabelParts, 0, -1);
  } else if (size(cellLabelParts) > 1) {
    cellLabelParts = slice(cellLabelParts, 1);
  }

  const res = join(
    map(cellLabelParts, (cellLabelPart) => replace(cellLabelPart, separatorRegex, ' ')),
    ' '
  );

  return res;
};

const getNewFormattedCell = (cellType: string, stainType: string, bracketTypesOptions: FormatBracketsOptions) => {
  const cellTypesJson: {
    label: string;
    action: string;
    values: string[];
  }[] = parseStringToCellTypesJson(cellType);

  const cell = find(cellTypesJson, (cellTypeJson) => cellTypeJson?.label === 'cell.label');
  let formattedCell = getFormattedCellWithStain(cell.values, stainType, bracketTypesOptions.stainTypeOptions);

  if (cell?.action === '@nin') {
    formattedCell = `cell is not ${formattedCell}`;
  }

  const classifiers = compact(
    map(
      filter(cellTypesJson, (currentCellType) => currentCellType?.label !== 'cell.label'),
      (cellTypeJson) => {
        return `${getCellLabel(cellTypeJson?.label)}${
          actionDict?.[cellTypeJson?.action] || cellTypeJson?.action || ''
        } ${join(compact(cellTypeJson?.values), ', ')}`.trim();
      }
    )
  );

  const classifiersString = isEmpty(classifiers) ? '' : ` (${join(classifiers, ', ')})`;

  return `${formattedCell}${classifiersString}`;
};

const getFormattedCellNewFormat = (
  cellType: string,
  stainType: string,
  bracketTypesOptions: FormatBracketsOptions
): string => {
  // simple condition
  if (startsWith(cellType, 'cell.label')) {
    return getNewFormattedCell(cellType, stainType, bracketTypesOptions);
  } else if (
    // $and display only once- cell with classifiers
    startsWith(cellType, '@and') &&
    size(split(cellType, '@and')) === 2 &&
    size(split(cellType, '@or')) === 1
  ) {
    const cellTypeWithoutAnd = replace(replace(cellType, '@and[', ''), ']', '');
    return getNewFormattedCell(cellTypeWithoutAnd, stainType, bracketTypesOptions);
  }

  const matches = cellType.match(newFormatCellRegex);
  const cellTypesArray: any[] = matches ? matches : [];
  const operator = startsWith(cellType, '@and') ? '@and' : '@or';

  return join(
    map(cellTypesArray, (currentCellType) => {
      return getFormattedCellNewFormat(currentCellType, stainType, bracketTypesOptions);
    }),
    ` ${actionDict[operator] ?? operator} `
  );
};

export const getFormattedCellWithStainAndClassifiers = (
  cellType: string,
  stainType: string,
  classifiers: any,
  bracketTypesOptions: FormatBracketsOptions,
  additionalOptions?: { withParenthesis: boolean; isMarkerPositivity: boolean }
) => {
  // new format
  if (includes(cellType, 'cell.label')) {
    return getFormattedCellNewFormat(cellType, stainType, bracketTypesOptions);
  } else {
    const cellTypes = orderBy(split(cellType, '|'));
    const formattedCell = getFormattedCellWithStain(cellTypes, stainType, bracketTypesOptions.stainTypeOptions);
    const classifiersString = buildClassifiersString(classifiers, bracketTypesOptions, additionalOptions);
    return `${formattedCell}${isEmpty(classifiersString) ? '' : ` ${classifiersString}`}`;
  }
};

export const getFormattedStain = (stainKey: string, stainTypeOptions: StainType[]) => {
  const stainIndex = fromPairs(
    map(stainTypeOptions, (stainTypeOption) => [stainTypeOption.index, stainTypeOption.displayName])
  );
  const stainId = fromPairs(
    map(stainTypeOptions, (stainTypeOption) => [stainTypeOption.id, stainTypeOption.displayName])
  );

  return stainIndex[stainKey] || stainId[stainKey] || replace(stainKey, separatorRegex, ' ');
};

export const getFormattedCluster = (id: string, clusterTypeOptions: ClusterType[]) => {
  const cluster = find(clusterTypeOptions, (clusterTypeOption) => toString(clusterTypeOption.id) === id);
  return cluster?.displayName || `cluster id: ${id}`;
};

export const getFormattedArea = (index: string, areaTypeOptions: AreaType[]) => {
  const a = find(areaTypeOptions, (areaTypeOption) => toString(areaTypeOption.index) === index);
  return a?.displayName || replace(index, separatorRegex, ' ');
};

export const getFormattedClassificationModels = (id: string, classificationModelOptions: ClassificationModel[]) => {
  const classificationModel = find(
    classificationModelOptions,
    (classificationModelOption) => classificationModelOption.id === id
  );
  return classificationModel?.displayName || replace(id, separatorRegex, ' ');
};

export const getFormattedRegistrationType = (registrationType: string) => {
  switch (registrationType) {
    case 'mean_distance_um':
      return 'mean distance';
    case 'num_distance_measurements':
      return 'number of evaluation points';
    case 'std_distance_um':
      return 'std distance';
    default:
      return registrationType;
  }
};

const getFormattedTextRegex = /[_]/g;
export const getFormattedText = (text: string) => {
  return replace(text, getFormattedTextRegex, ' ');
};

const gridSizeUnits = ['um'];

const uppercaseRegex = /[A-Z]/;
const wordWithoutSymbolsRegex = /[-_)()]/g;
const noNeedToUppercase = (word: string) => {
  const wordWithoutSymbols = replace(word, wordWithoutSymbolsRegex, '');
  return uppercaseRegex.test(word) || includes(gridSizeUnits, wordWithoutSymbols);
};

// upper the first letter you find, ignore the rest
const firstLetterRegex = /[a-zA-Z]/;
const upperFirstLetter = (word: string) => {
  let found = false;
  return join(
    map(word, (char) => {
      if (!found && firstLetterRegex.test(char)) {
        found = true;
        return char.toUpperCase();
      } else {
        return char;
      }
    }),
    ''
  );
};

export const formatWord = (word: string) => {
  if (noNeedToUppercase(word)) {
    return word;
  } else {
    return upperFirstLetter(word);
  }
};

export const capitalization = (sentence: string) => {
  const arraySentence = split(sentence, ' ');
  return formatWord(first(arraySentence)) + ' ' + join(tail(arraySentence), ' ');
};

export const titleCase = (title: string) => {
  return join(
    map(split(title, ' '), (word) => {
      return formatWord(word);
    }),
    ' '
  );
};

const tumorStates = ['apoptotic', 'mitotic'];
const tumorTypes = ['immunoblast', 'centroblast'];
