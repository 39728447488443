import { InferenceModelData, OrchestrationInference, SlideInferenceResults } from 'interfaces/calculateFeatures';
import {
  Dictionary,
  filter,
  find,
  flatMap,
  forEach,
  get,
  groupBy,
  includes,
  keys,
  map,
  some,
  sortBy,
  values,
} from 'lodash';
import {
  AssignmentByStain,
  OrchestrationBySlideByFlowClassName,
  OrchestrationBySlideByType,
  SlideRegistrationDetailsByOrchestration,
} from '..';

export interface ModelSelection {
  inference: boolean;
  assignment: boolean;
}

export interface SlidesModels {
  slideId: string;
  stainingType: string;
  caseLabel: string;
  caseId: string;
  countModelsInCase?: Record<string, number>;
  countModelsInStain?: Record<string, number>;
  countModelsInSlide?: number;
  registrationsInCasePerStain?: Record<string, boolean>; // {[stain]: registrationExists}
  [key: string]: boolean | string | Record<string, number> | number | ModelSelection | Record<string, boolean>;
}

export const getSlidesModelsData = (
  slides: Dictionary<SlideInferenceResults>,
  selectedOrchestrations: OrchestrationBySlideByType,
  selectedPostprocessedOrchestrations: OrchestrationBySlideByFlowClassName,
  selectedAssignments: AssignmentByStain,
  modelsType: string[],
  selectedSlideRegistrationsByOrchestration: SlideRegistrationDetailsByOrchestration
) => {
  const slidesModels: SlidesModels[] = map(slides, (slideInfo, slideId) => {
    const models: Record<string, Record<string, boolean>> = {};
    forEach(get(selectedOrchestrations, slideId), (orchestrationsByModel, modelType) => {
      if (orchestrationsByModel?.orchestration.orchestrationId)
        models[modelType] = {
          ...models[modelType],
          inference: true,
        };
    });

    forEach(get(selectedPostprocessedOrchestrations, slideId), (orchestrationsByFlowClassName, flowClassName) => {
      if (orchestrationsByFlowClassName?.orchestration.orchestrationId)
        models[flowClassName] = {
          ...models[flowClassName],
          inference: true,
        };
    });

    forEach(flatMap(values(selectedAssignments)), (selectedAssignment) => {
      if (selectedAssignment.modelType && includes(selectedAssignment.assignment?.slideIds, slideId))
        models[selectedAssignment.modelType] = {
          ...models[selectedAssignment.modelType],
          assignment: true,
        };
    });

    return {
      slideId,
      stainingType: slideInfo.stainingType,
      caseLabel: slideInfo.caseLabel,
      caseId: slideInfo.caseId,
      ...models,
    };
  });

  const flatRegistrations = flatMap(values(selectedSlideRegistrationsByOrchestration));

  const slidesByCaseLabel = groupBy(sortBy(slidesModels, ['caseLabel', 'stainingType']), 'caseLabel');

  forEach(slidesByCaseLabel, (slidesOfCase, caseLabel) => {
    forEach(slidesOfCase, (slideModel) => {
      // add registrations in case per stain
      const slideId = slideModel.slideId;
      const slideRegistrations = filter(
        flatRegistrations,
        (registration) => registration.sourceSlideId === slideId || registration.targetSlideId === slideId
      );
      const caseOtherSlides = filter(slidesOfCase, (slidesData) => slidesData.slideId !== slideId);
      forEach(caseOtherSlides, (otherSlide) => {
        otherSlide.registrationsInCasePerStain = {
          ...(otherSlide.registrationsInCasePerStain || {}),
          [slideModel.stainingType]: Boolean(
            find(slideRegistrations, (registration) => {
              return (
                registration.sourceSlideId === otherSlide.slideId || registration.targetSlideId === otherSlide.slideId
              );
            })
          ),
        };
      });
    });
    // Add count of models in case to each slide
    let countModelsInCase: Record<string, number> = {};
    forEach(modelsType, (modelType) => {
      forEach(slidesOfCase, (slide) => {
        const modelSelected = slide[modelType] as ModelSelection;
        if (modelSelected?.inference || modelSelected?.assignment) {
          countModelsInCase[modelType] = countModelsInCase[modelType] ? countModelsInCase[modelType] + 1 : 1;
        }
      });

      forEach(slidesOfCase, (slide) => {
        slide.countModelsInCase = countModelsInCase;
      });
    });
  });

  const slidesByStain = groupBy(slidesModels, 'stainingType');

  const countModelsByStain: Record<string, Record<string, number>> = {};
  forEach(slidesByStain, (slidesOfStain, stain) => {
    countModelsByStain[stain] = { slidesCount: slidesOfStain.length };
    forEach(modelsType, (modelType) => {
      countModelsByStain[stain][modelType] = 0;
      forEach(slidesOfStain, (slide) => {
        const modelSelected = slide[modelType] as ModelSelection;
        if (modelSelected?.inference || modelSelected?.assignment) {
          countModelsByStain[stain][modelType]++;
        }
      });
    });

    forEach(slidesOfStain, (slide) => {
      slide.countModelsInStain = countModelsByStain[stain];
      slide.countModelsInSlide = 0;

      forEach(modelsType, (modelType) => {
        const modelSelected = slide[modelType] as ModelSelection;
        if (modelSelected?.inference || modelSelected?.assignment) {
          slide.countModelsInSlide++;
        }
      });
    });
  });

  return slidesModels;
};

export const getSidesWithoutSomeModels = (modelsType: string[], slidesModels: SlidesModels[]) => {
  return filter(slidesModels, (slide) =>
    some(
      modelsType,
      (modelType) =>
        slide.countModelsInSlide > 0 &&
        !(slide[modelType] as ModelSelection)?.inference &&
        !(slide[modelType] as ModelSelection)?.assignment &&
        slide.countModelsInStain[modelType] > 0
    )
  );
};

export const getModelsTypeByModelInferences = (inferenceArtifacts: InferenceModelData[]): string[] => {
  return keys(groupBy(inferenceArtifacts, 'modelType'));
};

export const getFlowClassNameTypesByOrchestrations = (orchestrationsArtifacts: OrchestrationInference[]): string[] => {
  return keys(groupBy(orchestrationsArtifacts, 'params.flowClassName'));
};
