import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import { useSignals } from '@preact/signals-react/runtime';
import React, { useState } from 'react';
import { SketchPicker } from 'react-color';

import { slidesLayerVisualizationSettings } from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import { getColorHex } from 'utils/helpers';
import { useGroupedLayerChanges } from './handleChanges';

export const LayerColor: React.FC<{
  viewerIndex: number;
  slideId: string;
  parentKey: string;
  layerName: string;
  layerUrlKey: string;
  stainTypeId: string;
  selected: boolean;
}> = ({ viewerIndex, slideId, layerUrlKey, stainTypeId, parentKey, layerName, selected }) => {
  useSignals();
  const { handleColorChange } = useGroupedLayerChanges(
    viewerIndex,
    slideId,
    parentKey,
    layerName,
    layerUrlKey,
    stainTypeId
  );
  const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[viewerIndex];

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const openColorPopOver = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const closeColorPopOver = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const currentColor =
    viewerSlideLayerVisualizationSettings?.value[slideId]?.[`${parentKey}-${layerName}`]?.value?.color;

  return (
    <Grid item>
      <Button
        role="color-picker"
        size="small"
        sx={{
          minWidth: 17,
          height: 17,
          mr: 1,
          display: 'flex',
          ...(selected ? { background: getColorHex(currentColor) } : {}),
        }}
        variant="contained"
        onClick={openColorPopOver}
        disabled={!selected}
      />
      <Popover
        id={id}
        open={open && selected}
        anchorEl={anchorEl}
        onClose={closeColorPopOver}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        {selected ? (
          <SketchPicker
            color={selected ? currentColor : undefined}
            onChangeComplete={(color) => handleColorChange(color?.hex)}
          />
        ) : null}
      </Popover>
    </Grid>
  );
};
