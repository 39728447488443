import { MAX_VIEWERS } from 'components/Procedure/SlidesViewer/constants';
import { filter, find, fromPairs, keys, some, times } from 'lodash';
import { useMemo } from 'react';
import { StringParam, useQueryParam, useQueryParams } from 'use-query-params';

export const getSecondaryAnalysisOrchestrationIdViewerKey = (viewerIndex: number) =>
  `secondaryAnalysisOrchestrationId${viewerIndex}`;

export const useSecondaryAnalysisOrchestrationIdForViewer = (viewerIndex: number) =>
  useQueryParam(getSecondaryAnalysisOrchestrationIdViewerKey(viewerIndex), StringParam);

export const useSecondaryAnalysisOrchestrationIdQueryParams = () => {
  const secondaryAnalysisQuerySchema = fromPairs(
    times(MAX_VIEWERS, (viewerIndex) => [getSecondaryAnalysisOrchestrationIdViewerKey(viewerIndex), StringParam])
  );
  const [secondaryAnalysisQueryParams, setSecondaryAnalysisQueryParams] = useQueryParams(secondaryAnalysisQuerySchema);
  const secondaryAnalysisQueryKeys = useMemo(() => keys(secondaryAnalysisQueryParams), [secondaryAnalysisQueryParams]);
  const secondaryAnalysisActive = some(secondaryAnalysisQueryKeys, (key) => Boolean(secondaryAnalysisQueryParams[key]));
  const secondaryAnalysisActiveViewers = useMemo(
    () =>
      filter(times(MAX_VIEWERS), (viewerIndex) =>
        Boolean(secondaryAnalysisQueryParams[getSecondaryAnalysisOrchestrationIdViewerKey(viewerIndex)])
      ),
    [secondaryAnalysisQueryKeys, secondaryAnalysisQueryParams]
  );
  const activeSecondaryAnalysisViewer = find(times(MAX_VIEWERS), (viewerIndex) =>
    Boolean(secondaryAnalysisQueryParams[getSecondaryAnalysisOrchestrationIdViewerKey(viewerIndex)])
  );
  const activeSecondaryAnalysisOrchestrationId =
    secondaryAnalysisQueryParams[getSecondaryAnalysisOrchestrationIdViewerKey(activeSecondaryAnalysisViewer)];
  return {
    activeSecondaryAnalysisViewer,
    activeSecondaryAnalysisOrchestrationId,
    secondaryAnalysisActive,
    secondaryAnalysisActiveViewers,
    secondaryAnalysisQueryParams,
    secondaryAnalysisQueryKeys,
    setSecondaryAnalysisQueryParams,
  };
};
