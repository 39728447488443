import { Slide } from 'interfaces/slide';
import React, { FunctionComponent } from 'react';
import { getSlideThumbnailUrl } from 'utils/helpers';
import ImageWithFallback from '../../ImageWithFallback';

interface Props {
  slide: Slide;
}

const SlideThumbnailImage: FunctionComponent<Props> = ({ slide }) => {
  const thumbnailUrl = getSlideThumbnailUrl(slide);
  return <ImageWithFallback imageUrls={[thumbnailUrl]} slide={slide} />;
};

export default SlideThumbnailImage;
