import Loader from 'components/Loader';
import { Procedure } from 'interfaces/procedure';
import { Slide } from 'interfaces/slide';
import React, { FunctionComponent, ReactNode } from 'react';
import ReactImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import { useFormatExtensions } from 'utils/queryHooks/uiConstantsHooks';
import { GalleryDialog } from './GalleryDialog';
import { getGalleryImageRenderer } from './GalleryImage';
import { getGalleryThumbnailRenderer } from './GalleryThumbnail';
import { GalleryViewerControls } from './GalleryViewerControls';

interface ControlledGalleryViewerProps {
  handleClose: () => void;
  currentImageIndex: number;
  changeImageIndex: (index: number) => void;
  setFullScreenActive: (active: boolean) => void;
  fullScreenActive: boolean;
  isLoading: boolean;
  slideImages: readonly ReactImageGalleryItem[];
  currentSlideIndex: number;
  disableLeftNav?: boolean;
  disableRightNav?: boolean;
  currentCase?: Procedure;
  currentSlide?: Slide;
  totalSlides: number;
  galleryLabel?: ReactNode;
  children?: ReactNode;
}

const ControlledGalleryViewer: FunctionComponent<ControlledGalleryViewerProps> = ({
  handleClose,
  currentImageIndex,
  changeImageIndex,
  setFullScreenActive,
  fullScreenActive,
  isLoading: isLoadingExternal,
  slideImages,
  currentSlideIndex,
  disableLeftNav,
  disableRightNav,
  currentCase,
  currentSlide,
  totalSlides,
  galleryLabel,
}) => {
  const galleryRef = React.useRef<ReactImageGallery>(null);
  React.useEffect(() => {
    galleryRef.current?.slideToIndex(currentImageIndex);
  }, [currentImageIndex]);

  const { isLoadingFormatExtensions } = useFormatExtensions();

  const isLoading = isLoadingExternal || isLoadingFormatExtensions;

  return (
    <GalleryDialog
      onKeyDown={(e) => {
        if (e.key === 'ArrowLeft') {
          changeImageIndex(currentImageIndex - 1);
        } else if (e.key === 'ArrowRight') {
          changeImageIndex(currentImageIndex + 1);
        } else if (e.key === 'Escape') {
          handleClose();
        }
      }}
      galleryLabel={galleryLabel}
      onClose={handleClose}
      isLoading={isLoading}
      currentSlideIndex={currentSlideIndex}
      totalSlides={totalSlides}
      currentCase={currentCase}
      currentSlide={currentSlide}
      fullScreenActive={fullScreenActive}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <ReactImageGallery
          ref={galleryRef}
          renderItem={getGalleryImageRenderer(currentSlide)}
          renderThumbInner={getGalleryThumbnailRenderer(currentSlide)}
          lazyLoad
          disableKeyDown
          onScreenChange={setFullScreenActive}
          showPlayButton={(slideImages?.length ?? 0) > 1}
          items={slideImages}
          startIndex={currentImageIndex}
          onBeforeSlide={changeImageIndex}
          slideDuration={0}
          {...GalleryViewerControls({ disableLeftNav, disableRightNav })}
        />
      )}
    </GalleryDialog>
  );
};

export default ControlledGalleryViewer;
