import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CloseIcon from '@mui/icons-material/Close';
import DeselectIcon from '@mui/icons-material/Deselect';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  ClickAwayListener,
  Divider,
  Fade,
  Grid,
  List,
  Popper,
  ToggleButton,
  Tooltip,
  useTheme,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useConfirmation } from 'components/modals/ConfirmationContext';
import { Permission } from 'interfaces/permissionOption';
import { find, flatMap } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect } from 'react';
import { BooleanParam, StringParam, useQueryParam } from 'use-query-params';
import { usePermissions } from 'utils/usePermissions';
import usePrevious from 'utils/usePrevious';
import { InteractionMenusProps } from '..';
import { useClearSecondaryAnalysisSelections, useCurrentSecondaryAnalysisOptions } from './hooks';
import { RunSecondaryAnalysisButton, SNACK_BAR_KEY_SECONDARY_ANALYSIS } from './RunSecondaryAnalysisButton';
import { SlideInteractionOption } from './SlideInteractionOption';

import { runCalculateFeatures } from 'api/platform';
import { useSecondaryAnalysisOrchestrationIdQueryParams } from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/SecondaryAnalysisLayer/useSecondaryAnalysisQueryParams';
import queryClient from 'utils/queryClient';

export const SecondaryAnalysisInteractions: React.FunctionComponent<InteractionMenusProps> = ({
  caseId,
  selectedSlides,
  hasActiveNonAnnotationInteractions,
  clearNonAnnotationInteractions,
}) => {
  const [useOSDViewer] = useQueryParam('useOSDViewer', BooleanParam);

  // Check if any of the viewers is currently performing a secondary analysis
  const { secondaryAnalysisActive } = useSecondaryAnalysisOrchestrationIdQueryParams();
  const [secondaryAnalysisAreaSelectionMode, setSecondaryAnalysisAreaSelectionMode] = useQueryParam(
    'secondaryAnalysisAreaSelectionMode',
    StringParam
  );

  const [anchorEl, setAnchorEl] = React.useState<null | Element>(null);

  const clearSecondaryAnalysisSelections = useClearSecondaryAnalysisSelections();

  const clearSecondaryAnalysis = useCallback(() => {
    clearNonAnnotationInteractions();
    clearSecondaryAnalysisSelections(true);
  }, [clearNonAnnotationInteractions]);

  const previousSecondaryAnalysisActive = usePrevious(secondaryAnalysisActive);
  const [isDrawingToolSelectionOpenState, setIsDrawingToolSelectionOpen] = React.useState(secondaryAnalysisActive);
  const isDrawingToolSelectionOpen = secondaryAnalysisActive && Boolean(anchorEl) && isDrawingToolSelectionOpenState;

  useEffect(() => {
    if (!previousSecondaryAnalysisActive && secondaryAnalysisActive) {
      // Open/Close the drawing tool selection if the drawing modes are active/inactive
      setIsDrawingToolSelectionOpen(secondaryAnalysisActive);
    }
  }, [secondaryAnalysisActive]);

  const { hasPermission } = usePermissions();
  const canRunSecondaryAnalysis = hasPermission(Permission.RunSecondaryAnalysis);
  const secondaryAnalysisOptions = useCurrentSecondaryAnalysisOptions();

  const confirmWithModal = useConfirmation();

  const theme = useTheme();

  const activeInteractionOption = find(secondaryAnalysisOptions, { editType: secondaryAnalysisAreaSelectionMode });
  const ActiveOptionIcon = activeInteractionOption?.icon || EditIcon;

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const runCalculateFeaturesMutation = useMutation(runCalculateFeatures, {
    onError: () => {
      enqueueSnackbar('Error occurred, Calculate Features failed', { variant: 'error' });
    },
    onSuccess: () => {
      enqueueSnackbar('Calculate Features Started', { variant: 'success' });
      // Invalidate jobs query to get the new job
      queryClient.invalidateQueries(['jobs']);
      clearSecondaryAnalysis();
    },
    onSettled() {
      // Invalidate jobs query to get the new job
      queryClient.invalidateQueries(['jobs']);
      closeSnackbar(SNACK_BAR_KEY_SECONDARY_ANALYSIS);
    },
  });

  return (
    !useOSDViewer &&
    canRunSecondaryAnalysis &&
    secondaryAnalysisActive && (
      <Grid item>
        <Box
          sx={{
            borderRadius: 10,
            paddingInline: 1,
            paddingBlock: 0.5,
            marginInline: 1,
            backgroundColor: theme.palette.grey[200],
          }}
        >
          <RunSecondaryAnalysisButton
            caseId={caseId}
            selectedSlides={selectedSlides}
            startSecondaryAnalysis={runCalculateFeaturesMutation.mutate}
            isStartingSecondaryAnalysis={runCalculateFeaturesMutation.isLoading}
          />
          <Tooltip
            title={
              activeInteractionOption?.title
                ? activeInteractionOption?.title
                : !isDrawingToolSelectionOpen
                ? 'Open selections for secondary analysis'
                : 'Hide'
            }
          >
            <span>
              <ToggleButton
                selected={isDrawingToolSelectionOpen}
                sx={{ borderWidth: 0 }}
                size="small"
                ref={setAnchorEl}
                value="slideActions"
                onChange={() => setIsDrawingToolSelectionOpen(!isDrawingToolSelectionOpen)}
                disabled={Boolean(hasActiveNonAnnotationInteractions) || runCalculateFeaturesMutation.isLoading}
              >
                {isDrawingToolSelectionOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                <ActiveOptionIcon color={secondaryAnalysisAreaSelectionMode ? 'primary' : 'inherit'} />
              </ToggleButton>
            </span>
          </Tooltip>
          <Divider variant="middle" orientation="vertical" sx={{ display: 'inline' }} />
          <ToggleButton
            size="small"
            title={'Clear secondary analysis selections'}
            sx={{ borderWidth: 0 }}
            onClick={async () => {
              if (
                await confirmWithModal({
                  title: 'Clear secondary analysis selections',
                  text: 'Exit secondary analysis mode?',
                })
              ) {
                setIsDrawingToolSelectionOpen(true);
                clearSecondaryAnalysis();
              }
            }}
            value="clearSecondaryAnalysis"
            disabled={runCalculateFeaturesMutation.isLoading}
          >
            <CloseIcon />
          </ToggleButton>

          <Popper
            anchorEl={anchorEl}
            open={isDrawingToolSelectionOpen && !runCalculateFeaturesMutation.isLoading}
            placement="bottom"
            transition
            disablePortal
          >
            {({ TransitionProps }) => (
              <ClickAwayListener onClickAway={() => setIsDrawingToolSelectionOpen(false)}>
                <Fade {...TransitionProps}>
                  <Box sx={{ bgcolor: 'background.paper', marginTop: '1px' }}>
                    <List>
                      <SlideInteractionOption
                        title={'Deselect all'}
                        onClick={async () => {
                          if (
                            await confirmWithModal({
                              title: 'Deselect all secondary analysis selections?',
                            })
                          ) {
                            clearSecondaryAnalysisSelections(false);
                          }
                        }}
                        value="clearSecondaryAnalysis"
                        icon={<DeselectIcon />}
                        disabled={runCalculateFeaturesMutation.isLoading}
                      />
                      {flatMap(secondaryAnalysisOptions, ({ editType, title, icon: OptionIcon, color, sx }) => (
                        <SlideInteractionOption
                          key={editType}
                          value={editType}
                          title={title}
                          selected={secondaryAnalysisAreaSelectionMode === editType}
                          onClick={() => {
                            clearNonAnnotationInteractions();
                            if (editType === secondaryAnalysisAreaSelectionMode) {
                              setSecondaryAnalysisAreaSelectionMode(null);
                            } else {
                              setSecondaryAnalysisAreaSelectionMode(editType);
                            }
                            setIsDrawingToolSelectionOpen(false);
                          }}
                          icon={
                            <OptionIcon
                              color={
                                color ? color : secondaryAnalysisAreaSelectionMode === editType ? 'primary' : 'inherit'
                              }
                              sx={sx}
                            />
                          }
                          disabled={runCalculateFeaturesMutation.isLoading}
                        />
                      ))}
                    </List>
                  </Box>
                </Fade>
              </ClickAwayListener>
            )}
          </Popper>
        </Box>
      </Grid>
    )
  );
};
