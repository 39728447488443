import {
  DataGridProps,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridRowModesModel,
  GridTreeNodeWithRender,
} from '@mui/x-data-grid';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import { every, get, isEmpty, join, keys, map, reduce, reject, some } from 'lodash';
import React from 'react';

import { BaseDisplayedFieldOfObjectInArray, DisplayedField, FieldType } from 'interfaces/genericFields';
import { formatFieldValue } from 'interfaces/genericFields/formatFieldValues';
import { getFieldValue, unwoundRowMetadataFromId } from 'interfaces/genericFields/unwindRowsWithInnerArrays';
import { humanize } from 'utils/helpers';
import { EnumDisplayNames } from 'utils/queryHooks/uiConstantsHooks';
import ValueList from '../DataGridRenderers/ValueList';
import { fieldColEditTypeToCustomEditComponent } from './cellEditorMaps';
import { BasicTableRow } from './TableEditingContext/types';

const fieldColEditTypeToMuiGridCellType: Record<FieldType, GridColDef['type'] | undefined> = {
  text: 'string',
  checkbox: 'boolean',
  date: 'date',
  number: 'string', // 'number',
  select: 'singleSelect',
  multiSelect: undefined,
  multiText: undefined,
  range: undefined,
  'checkbox-group': undefined,
  'date-range': undefined,
  custom: undefined,
};

export const displayedFieldToColumn = <
  R extends BasicTableRow,
  V extends string | number | boolean | number[] | string[] | boolean[] | (string | number)[],
  Context extends { enumDisplayNames?: EnumDisplayNames } = { enumDisplayNames?: EnumDisplayNames }
>(
  field: DisplayedField<R, V, Context>,
  context?: Context,
  arrayFieldToUnwind?: keyof R,
  unwoundRowIdField?: string | number | symbol,
  baseRowIdField?: string | number | symbol
): GridColDef<R, V, string> => {
  const isUnwoundField =
    field.isFieldOfObjectInArray && arrayFieldToUnwind && arrayFieldToUnwind === field.objectArrayPath;
  const cellEditType = isUnwoundField ? field?.unwoundRowCellEditType ?? field.cellEditType : field.cellEditType;
  const customEditComponent = field?.customCellEditor || fieldColEditTypeToCustomEditComponent(cellEditType);
  const label = field?.label ?? humanize(field.dataKey);
  return {
    field: field.dataKey,
    editable: Boolean(cellEditType),
    headerName: label,
    description: label,
    ...(!isNaN(field.columnWidth.flex)
      ? {
          maxWidth: field.columnWidth.max,
          minWidth: field.columnWidth.min,
          flex: field.columnWidth.flex,
        }
      : {
          width: field.columnWidth.width,
        }),
    sortable: field?.sortable,
    display: field?.display,
    valueGetter: (value, row, columnDef) => {
      if (isUnwoundField) {
        if (field.dataKey === unwoundRowIdField) {
          return unwoundRowMetadataFromId(row.id.toString()).innerRowId;
        }
        return get(row, field.objectArrayInnerPath);
      }
      if (arrayFieldToUnwind && field.dataKey === baseRowIdField) {
        return unwoundRowMetadataFromId(row.id.toString()).baseRowId;
      }
      return getFieldValue(row, field, context, { value, columnDef });
    },
    valueFormatter: (value, _row, _columnDef): string => formatFieldValue(value, field, context, arrayFieldToUnwind),
    ...(field.renderCell && !isUnwoundField ? { renderCell: field.renderCell(context) } : {}),
    ...(customEditComponent ? { renderEditCell: customEditComponent(field, context) } : {}),
    ...(fieldColEditTypeToMuiGridCellType[cellEditType]
      ? { type: fieldColEditTypeToMuiGridCellType[cellEditType] }
      : {}),
  };
};

export const isUpdatingRowUnique = ({
  apiRef,
  rowId,
  uniqueFields,
  getId,
}: {
  apiRef: React.MutableRefObject<GridApiCommunity>;
  rowId: GridRowId;
  uniqueFields: string[];
  getId?: (row: any) => string;
}) => {
  const fieldToRowUpdatedValue = reduce<string, { [field: string]: any }>(
    uniqueFields,
    (acc, field) => {
      acc[field] = apiRef.current.getRowWithUpdatedValues(rowId, field)[field];
      return acc;
    },
    {}
  );
  const rowModels = apiRef.current.getRowModels();
  const rows = Array.from(rowModels.values());
  const otherRows = reject(rows, getId ? (row) => rowId === getId(row) : { id: rowId });
  return !some(otherRows, (row) => {
    return every(uniqueFields, (field) => row[field] === fieldToRowUpdatedValue[field]);
  });
};

export const doesUpdatingRowHaveRequiredFields = ({
  apiRef,
  rowId,
  requiredFields,
}: {
  apiRef: React.MutableRefObject<GridApiCommunity>;
  rowId: GridRowId;
  requiredFields: string[];
}) =>
  every(requiredFields, (field) => {
    const fieldValue = apiRef.current.getRowWithUpdatedValues(rowId, field)[field];
    return fieldValue !== undefined && fieldValue !== null;
  });

export const autoGridRowHeight: DataGridProps['getRowHeight'] = () => 'auto';

export const renderArrayObjectListCell =
  <R, V = string | number | boolean, Context extends {} = {}>(
    field: BaseDisplayedFieldOfObjectInArray<R, V[], Context>
  ) =>
  (params: GridRenderCellParams<R, V[], V[], GridTreeNodeWithRender>) =>
    (
      // @ts-ignore
      <ValueList<R, V>
        {...params}
        idGetter={(index) => get(params.row, field.objectArrayPath)?.[index]?.[field.objectArrayKeyPath]}
        valueFormatter={field.singleObjectFormatter}
      />
    );

export const generateGetCellClassNames = <R, V = string | number | boolean, Context extends {} = {}>({
  fieldsToCheckForErrors,
  apiRef,
  uniqueFieldGroups,
  requiredFields,
  draftRows,
  idGetter,
  customRules,
  context,
}: {
  fieldsToCheckForErrors?: DisplayedField<R, V>[];
  apiRef: React.MutableRefObject<GridApiCommunity>;
  // Groups of fields that should be unique together
  uniqueFieldGroups?: string[][];
  requiredFields?: string[];
  draftRows?: R[];
  idGetter?: (row: R) => string;
  customRules?: Array<{ classCheck: (params: GridCellParams<R, V>) => boolean; className: string }>;
  context?: Context;
}): DataGridProps['getCellClassName'] => {
  const errorClassRules = [
    ...(requiredFields
      ? [
          {
            classCheck: (params: GridCellParams<R, V>) =>
              !doesUpdatingRowHaveRequiredFields({
                apiRef,
                rowId: params.id,
                requiredFields,
              }),
            className: 'missing-required-cell',
          },
        ]
      : []),
    ...map(uniqueFieldGroups, (uniqueFields) => ({
      classCheck: (params: GridCellParams<R, V>) =>
        !isUpdatingRowUnique({
          apiRef,
          rowId: params.id,
          uniqueFields,
        }),
      className: 'duplicate-cell',
    })),
    ...map(fieldsToCheckForErrors, (field) => ({
      classCheck: (params: GridCellParams<R, V>) =>
        Boolean(
          field.getError?.({
            value: apiRef.current.getRowWithUpdatedValues(params.id, 'id')[field.dataKey],
            row: params.row,
            context,
          })
        ),
      className: 'error-cell',
    })),
    ...(customRules || []),
  ];
  return (params) => {
    const classNames = reduce(
      errorClassRules,
      (acc, { classCheck, className }) => {
        if (classCheck(params)) {
          acc.push(className);
        }
        return acc;
      },
      []
    );
    if (isEmpty(classNames)) {
      const isDraft = some(draftRows, idGetter ? (draftRow: R) => idGetter(draftRow) === params.id : { id: params.id });
      if (isDraft) {
        classNames.push('draft-cell');
      }
    }
    return join(classNames, ' ');
  };
};

export const handleRowModesModelChangeWithoutDraftIds = (
  newRowModesModel: GridRowModesModel,
  setRowModesModel: (value: React.SetStateAction<GridRowModesModel>) => void,
  draftRows: any[],
  getDraftId?: (row: any) => string
) => {
  setRowModesModel(
    // Filter out draft rows
    reduce(
      keys(newRowModesModel),
      (acc, rowId) =>
        some(draftRows, getDraftId ? (row: any) => getDraftId(row) === rowId : { id: rowId })
          ? acc
          : { ...acc, [rowId]: newRowModesModel[rowId] },
      {}
    )
  );
};
