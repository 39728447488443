import { Button, CircularProgress, Grid, Tooltip } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import { Feature, Geometry } from '@turf/helpers';
import { castArray, find, flatMap, size } from 'lodash';
import React from 'react';
import { BooleanParam, NumberParam, StringParam, useQueryParam } from 'use-query-params';

import { useJobs } from 'components/Pages/Jobs/useJobs';
import { useCurrentSecondaryAnalysisOptions } from 'components/Procedure/Header/SlideInteractionMenu/secondaryAnalysis/hooks';
import { useSecondaryAnalysisOrchestrationIdForViewer } from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/SecondaryAnalysisLayer/useSecondaryAnalysisQueryParams';
import { Permission } from 'interfaces/permissionOption';
import { getActiveAnnotationAssignmentIdViewerKey } from 'services/annotations/useAnnotationQueryParams';
import { usePermissions } from 'utils/usePermissions';

export const geojsonFeaturesToMultiPolygon = (features: Feature[]) => {
  const coordinates: any[] = flatMap(features, (feature: Feature) => (feature.geometry as Geometry).coordinates);
  return {
    type: 'MultiPolygon',
    coordinates: coordinates,
  };
};
interface SecondaryAnalysisTriggerProps {
  slideId: string;
  viewerIndex: number;
  autoInternallyApprove: boolean;
  orchestrationId: string;
  studyId: string;
  hasMultiplePublishedOrchestrationIds?: boolean;
}

export const SecondaryAnalysisTrigger: React.FunctionComponent<
  React.PropsWithChildren<SecondaryAnalysisTriggerProps>
> = ({ viewerIndex, orchestrationId, hasMultiplePublishedOrchestrationIds, studyId }) => {
  useSignals();
  const { hasPermission } = usePermissions();
  const canRunSecondaryAnalysis = hasPermission(Permission.RunSecondaryAnalysis);
  const [useOSDViewer] = useQueryParam('useOSDViewer', BooleanParam);
  const [activeAnnotationAssignmentId] = useQueryParam(
    getActiveAnnotationAssignmentIdViewerKey(viewerIndex),
    NumberParam
  );
  const [secondaryAnalysisOrchestrationId, setSecondaryAnalysisOrchestrationId] =
    useSecondaryAnalysisOrchestrationIdForViewer(viewerIndex);
  const [, setSecondaryAnalysisAreaSelectionMode] = useQueryParam('secondaryAnalysisAreaSelectionMode', StringParam);

  const isSecondaryAnalysisActive = secondaryAnalysisOrchestrationId === orchestrationId;
  const isOtherSecondaryAnalysisActive =
    secondaryAnalysisOrchestrationId && secondaryAnalysisOrchestrationId !== orchestrationId;

  const canLoadOriginalJob = Boolean(orchestrationId) && canRunSecondaryAnalysis;
  const { data: jobsQueryResponse, isLoading: isLoadingOriginalJobFromQuery } = useJobs({
    enabled: canLoadOriginalJob,
    additionalFilters: { orchestrationIds: castArray(orchestrationId), studyId },
    fullData: true,
    studyId,
  });

  const isLoadingOriginalJob = isLoadingOriginalJobFromQuery && canLoadOriginalJob;

  const orchestrationIdJobs = jobsQueryResponse?.jobs;

  if (size(orchestrationIdJobs) > (jobsQueryResponse?.totalJobs ?? 0)) {
    console.error('Got more jobs for orchestration than a single page size, need to implement paginated query', {
      orchestrationIdJobs,
      totalJobs: jobsQueryResponse?.totalJobs,
    });
  }

  const parentJob = find(orchestrationIdJobs, (job) => !job?.parentId);

  const secondaryAnalysisOptions = useCurrentSecondaryAnalysisOptions();

  return (
    !useOSDViewer &&
    canRunSecondaryAnalysis && (
      <Tooltip
        title={
          hasMultiplePublishedOrchestrationIds
            ? `Multiple published orchestration ids - will run on ${orchestrationId}`
            : ''
        }
      >
        <Grid item xs={12} container direction="row">
          <Grid item>
            <Tooltip
              title={
                !orchestrationId
                  ? 'No orchestration id found'
                  : isLoadingOriginalJob
                  ? 'Loading original job'
                  : activeAnnotationAssignmentId
                  ? 'Cannot run secondary analysis while annotation is active'
                  : isOtherSecondaryAnalysisActive
                  ? 'Cannot run secondary analysis while other secondary analysis is active'
                  : !parentJob
                  ? 'No job found'
                  : ''
              }
            >
              <span>
                <Button
                  disabled={
                    isLoadingOriginalJob ||
                    !parentJob ||
                    Boolean(activeAnnotationAssignmentId) ||
                    isOtherSecondaryAnalysisActive
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    setSecondaryAnalysisOrchestrationId((currentId) => (currentId ? null : orchestrationId));
                    setSecondaryAnalysisAreaSelectionMode((previousMode) =>
                      // Don't change the previous mode, if set
                      find(secondaryAnalysisOptions, { editType: previousMode })
                        ? previousMode
                        : find(secondaryAnalysisOptions, { isDefault: true })?.editType
                    );
                  }}
                >
                  {isSecondaryAnalysisActive ? 'Cancel Recalculation' : 'Recalculate'}
                  {isLoadingOriginalJob && <CircularProgress size={20} sx={{ marginLeft: 1 }} />}
                </Button>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </Tooltip>
    )
  );
};

export default SecondaryAnalysisTrigger;
