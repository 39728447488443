import { DeckGLProps } from '@deck.gl/react/typed';
import { useSignals } from '@preact/signals-react/runtime';
import { compact } from 'lodash';
import { ArrayParam, useQueryParam } from 'use-query-params';
// @ts-ignore
// eslint-disable-next-line import/no-extraneous-dependencies
import { PathStyleExtension } from '@deck.gl/extensions';

import { COORDINATE_SYSTEM } from '@deck.gl/core/typed';
import { PolygonLayer } from '@deck.gl/layers/typed';
import { useTheme } from '@mui/system';
import { slidesLayerVisualizationSettings } from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { HeatmapsImagePyramids, ImagePyramid } from 'components/Procedure/useSlideImages';
import React from 'react';
import { hexToRgb } from 'utils/helpers';
import { OrthographicMapViewState } from '../OrthographicMapview';
import { useEditAnnotationLayers } from './EditAnnotationLayers/useEditAnnotationLayers';
import { usePmtLayers } from './pmtLayers/usePmtLayers';
import { useBaseSlideLayers } from './useBaseSlideLayers';
import { useDeckGLGeoJSONCellLayers } from './useDeckGLGeoJSONCellLayers';
import { useHeatmapLayers } from './useHeatmapLayers';
import { useExistingCommentLayer } from './usePinCommentsLayer';
import { useSecondaryAnalysisROILayers } from './useSecondaryAnalysisROILayers';
import { useViewAnnotationLayers } from './useViewAnnotationLayers';

export const useOverviewLayers = ({
  slide,
  baseImagePyramids,
  heatmapsImagePyramids,
  viewSize,
  viewState,
  procedureId,
  showComments,
  dashed,
  useLightOverviewMarker,
  overviewSizeFactor,
}: {
  slide: SlideWithChannelAndResults;
  baseImagePyramids: ImagePyramid;
  heatmapsImagePyramids: HeatmapsImagePyramids;
  viewState: OrthographicMapViewState;
  viewSize: { width: number; height: number };
  procedureId: number;
  showComments?: boolean;
  dashed?: boolean;
  useLightOverviewMarker: boolean;
  overviewSizeFactor: number;
}) => {
  useSignals();

  const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[slide.viewerIndex];

  const slideLayerVisualizationSettings = viewerSlideLayerVisualizationSettings.value?.[slide.id];

  const theme = useTheme();
  const layerSource = baseImagePyramids?.layerSource;

  const imageSize = layerSource.getImageSize();

  // 5% of the largest dimension
  const viewportOutlineWidth = Math.max(imageSize.width, imageSize.height) * 0.02;

  const viewAnnotationLayers = useViewAnnotationLayers({ slide });
  const editAnnotationLayer = useEditAnnotationLayers({
    slideId: slide?.id,
    viewerIndex: slide?.viewerIndex,
    viewState,
    viewSize,
    viewOnly: true,
  });

  const pmtLayers = usePmtLayers({
    idPrefix: 'overview-layer',
    slide,
    maxLevel: baseImagePyramids?.layerSource?.maxLevel ?? 0,
    rescaleFactor: overviewSizeFactor,
  });

  const [geoJSONTestUrls] = useQueryParam('geoJSONTestUrls', ArrayParam);
  const geoJSONLayers = useDeckGLGeoJSONCellLayers({
    geoJSONUrls: geoJSONTestUrls,
    baseImagePyramids,
    slideLayerVisualizationSettings,
  });

  const previousTarget = React.useRef(viewState?.target);
  React.useEffect(() => {
    if (viewState?.target) {
      previousTarget.current = viewState?.target;
    }
  }, [viewState?.target]);

  const targetForViewportOutline = viewState?.target ?? previousTarget.current;

  const scaledViewSizeWidth = (viewSize?.width ?? 1) * 2 ** -((viewState?.zoom ?? 0) as number);
  const scaledViewSizeHeight = (viewSize?.height ?? 1) * 2 ** -((viewState?.zoom ?? 0) as number);

  const leftSide = (targetForViewportOutline?.[0] ?? 0) - scaledViewSizeWidth / 2;
  const rightSide = (targetForViewportOutline?.[0] ?? 0) + scaledViewSizeWidth / 2;
  const topSide = (targetForViewportOutline?.[1] ?? 0) - scaledViewSizeHeight / 2;
  const bottomSide = (targetForViewportOutline?.[1] ?? 0) + scaledViewSizeHeight / 2;

  const commonViewerPOVLayersProps: ConstructorParameters<typeof PolygonLayer>[0] = {
    coordinateSystem: COORDINATE_SYSTEM.DEFAULT,
    data: [
      [
        [leftSide, topSide, 0],
        [rightSide, topSide, 0],
        [rightSide, bottomSide, 0],
        [leftSide, bottomSide, 0],
      ],
    ],
    getPolygon: (f) => f,
    filled: false,
    stroked: true,
    getLineWidth: viewportOutlineWidth,
    lineWidthMinPixels: 2,
    lineWidthMaxPixels: 2,
  };

  const viewerPOVLayers = dashed
    ? [
        new PolygonLayer({
          ...commonViewerPOVLayersProps,
          id: 'viewport-outline-white-background',
          getLineColor: [255, 255, 255, 255],
        }),
        new PolygonLayer({
          ...commonViewerPOVLayersProps,
          id: 'viewport-outline-main-color',
          getLineColor: hexToRgb(theme.palette.primary.main),
          getDashArray: [3, 2],
          extensions: [new PathStyleExtension({ dash: true }) as any],
        }),
      ]
    : [
        new PolygonLayer({
          ...commonViewerPOVLayersProps,
          id: 'viewport-outline',
          getLineColor: useLightOverviewMarker ? [128, 128, 128, 255] : [10, 10, 10, 255],
        }),
      ];

  const baseSlideLayers = useBaseSlideLayers({ slide, baseImagePyramids, overview: true });
  const heatmapLayers = useHeatmapLayers({ slide, baseImagePyramids, heatmapsImagePyramids, overview: true });
  const secondaryAnalysisPolygons = useSecondaryAnalysisROILayers({ slide, rescaleFactor: overviewSizeFactor });
  const existingCommentLayer = useExistingCommentLayer({
    slideId: slide.id,
    procedureId,
    viewerIndex: slide.viewerIndex,
    editable: false,
  });
  const layers: DeckGLProps['layers'] = compact([
    ...baseSlideLayers,
    ...heatmapLayers,
    ...pmtLayers,
    ...secondaryAnalysisPolygons,
    ...geoJSONLayers,
    ...(editAnnotationLayer ? [editAnnotationLayer] : viewAnnotationLayers ? viewAnnotationLayers : []),
    ...(showComments ? [existingCommentLayer] : []),
    ...viewerPOVLayers,
  ]);

  return { layers };
};
