import NoImageIcon from '@mui/icons-material/AutoAwesomeMotion';
import { useTheme } from '@mui/material';
import { Slide } from 'interfaces/slide';
import { MULTIPLEX_STAIN_ID } from 'interfaces/stainType';
import _, { flatMap } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import MultiplexSlideImage from './MultiplexSlideImage';
import SlideImage from './SlideImage';

interface ImageWithFallbackProps {
  imageUrls: string[];
  slide: Slide;
  lazyLoad?: boolean;
}

const ImageWithFallback: React.FC<ImageWithFallbackProps> = ({ imageUrls, slide, lazyLoad = true }) => {
  const theme = useTheme();

  const [currentImageUrlIndex, setCurrentImageUrlIndex] = useState(0);
  const [noImageFound, setNoImageFound] = useState(false);
  const [shouldLoadImage, setShouldLoadImage] = useState(false);

  // we are starting to save our thumbnails in png format so in the in between phase we need to check for jpg as well
  const imageUrlsWithJpgFallback = flatMap(imageUrls, (url) => [url, url?.replace('.png', '.jpg')]);

  const isMultiplex = slide?.stainingType === MULTIPLEX_STAIN_ID;

  const observerRef = useRef<IntersectionObserver | null>(null);
  const imageContainerRef = useRef<HTMLDivElement>(null);

  const handleImageError = () => {
    if (currentImageUrlIndex === imageUrlsWithJpgFallback.length - 1) {
      setNoImageFound(true);
      return;
    }
    setCurrentImageUrlIndex(currentImageUrlIndex + 1);
  };

  useEffect(() => {
    if (!lazyLoad) {
      setShouldLoadImage(true);
    } else {
      if (observerRef.current) observerRef.current.disconnect();
      observerRef.current = new IntersectionObserver((entries) => {
        _.forEach(entries, (entry) => {
          if (entry?.isIntersecting) {
            setShouldLoadImage(true);
            observerRef.current.unobserve(entry.target);
          }
        });
      });
      observerRef.current.observe(imageContainerRef.current);
    }
    return () => {
      if (observerRef.current) observerRef.current.disconnect();
    };
  }, [lazyLoad]);

  return (
    <div
      data-testid="carousel-item-thumbnail-img"
      data-src={imageUrlsWithJpgFallback[currentImageUrlIndex]}
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: 'transparent',
      }}
      ref={imageContainerRef}
    >
      {shouldLoadImage &&
        (noImageFound ? (
          <NoImageIcon
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              fontSize: '100',
              color: theme.palette.text.primary,
              opacity: 0.3,
            }}
          />
        ) : isMultiplex ? (
          <MultiplexSlideImage
            imageUrl={imageUrlsWithJpgFallback[currentImageUrlIndex]}
            handleImageError={handleImageError}
            slide={slide}
          />
        ) : (
          <SlideImage
            imageUrl={imageUrlsWithJpgFallback[currentImageUrlIndex]}
            handleImageError={handleImageError}
            slide={slide}
          />
        ))}
    </div>
  );
};

export default ImageWithFallback;
