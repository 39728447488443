import { useQuery } from '@tanstack/react-query';
import { isEmpty, keyBy, map } from 'lodash';
import { useMemo } from 'react';
import { JsonParam, useQueryParam } from 'use-query-params';

import { getJobs, GetJobsParams, JobFilters } from 'api/jobs';
import { getInternalUsers } from 'api/userCredentials';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import { useQueryWithErrorAndRetrySnackbar } from 'utils/useQueryWithErrorAndRetrySnackbar';

export const defaultRowsPerPage = 10;
export const jobsQueryKey = 'jobs';

export const useJobs = ({
  enabled = true,
  page = 1,
  pageSize = defaultRowsPerPage,
  additionalFilters = {},
  refetchInterval = 60000,
  studyId: studyIdFromProps,
  ...getJobsParams
}: Omit<GetJobsParams, 'filters' | 'labId'> & {
  enabled?: boolean;
  additionalFilters?: Partial<JobFilters>;
  refetchInterval?: number;
  studyId?: string;
}) => {
  const { labId } = useCurrentLabId();
  const [filters] = useQueryParam('jobsFilters', JsonParam);
  const { queryParams } = useEncodedFilters();
  const studyId = studyIdFromProps || queryParams.filters?.studyId;

  const usersFilter = filters?.users;
  const hasUsersFilter = !isEmpty(usersFilter);
  const { data: internalUsers, isLoading: internalUsersLoading } = useQuery(['internalUsers'], getInternalUsers, {
    enabled: enabled && !hasUsersFilter,
  });
  const users: JobFilters['users'] = useMemo(() => {
    const internalUsersById = keyBy(internalUsers, 'id');
    return map(usersFilter, (userId) => ({ id: userId, email: internalUsersById[userId]?.primaryEmail }));
  }, [usersFilter, internalUsers]);

  const filtersWithEmailUsers = { ...filters, users };

  const jobParams = {
    filters: { ...filtersWithEmailUsers, ...additionalFilters, studyId } as JobFilters,
    labId,
    page,
    pageSize,
    ...getJobsParams,
  };

  const canQueryJobs = enabled && !isEmpty(jobParams?.filters?.studyId) && !(hasUsersFilter && internalUsersLoading);
  const jobsQuery = useQueryWithErrorAndRetrySnackbar(
    [jobsQueryKey, jobParams],
    ({ signal }) => getJobs(jobParams, signal),
    {
      enabled: canQueryJobs,
      refetchInterval,
      queriedEntityName: 'job',
      keepPreviousData: true,
      initialData: { jobs: [], totalJobs: 0 },
      initialDataUpdatedAt: 0,
      staleTime: refetchInterval,
    }
  );

  return { ...jobsQuery, isLoading: jobsQuery.isLoading && canQueryJobs };
};
