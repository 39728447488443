import ExpandIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import OrchestrationIdAutoComplete from 'components/SearchFilters/AdvancedSearch/OrchestrationIdAutoComplete';
import useFeatureFilters from 'components/SearchFilters/hooks/useFeatureFilters';
import { AutomaticCondition, ConditionType } from 'interfaces/automaticCondition';
import { ExcludeOption, QualityControlLabel } from 'interfaces/qualityControlLabels';
import { Study } from 'interfaces/study';
import { find, isEmpty, map } from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import AutomaticConditionGroup from '../../../../atoms/ConditionBuilder/AutomaticConditionGroup';
import { validateLabel } from '../label.util';
import { useSlideLabels } from '../useSlideLabels';

export interface LabelBuilderProps {
  study: Study;
  label: QualityControlLabel;
  editable: boolean;
  onClose: () => void;
  isNew: boolean;
  onUpdateLabel: (updatedLabel: QualityControlLabel) => void;
}

const LabelBuilder: FunctionComponent<React.PropsWithChildren<LabelBuilderProps>> = ({
  study,
  label,
  editable,
  onClose,
  onUpdateLabel,
  isNew,
}) => {
  if (!label) {
    return <Typography>No label selected</Typography>;
  }

  // const { data: slideTagOptions, isLoading: isLoadingSlideTagOptions } = useSlideTagOptions();

  const {
    externalLabelOptions,
    isLoading: isLoadingExternalLabelOptions,
    getExternalLabelDisplayName,
  } = useSlideLabels();

  const [orchestrationId, setOrchestrationId] = useState<string>(null);
  const { orchestrationIdOptions, orchestrationsLoading } = useFeatureFilters({
    studyId: study.id,
    orchestrationId,
    onSuccessGetExperimentDefinitions: ({ orchestrations }) =>
      orchestrations && setOrchestrationId(orchestrations[0]?.orchestrationId),
  });

  const [condition, setCondition] = useState<AutomaticCondition>(label?.condition);

  const [filterLabel, setFilterLabel] = useState<string>(label.filterLabel);
  const [rejectLabel, setRejectLabel] = useState<string>(label.rejectLabel);
  const [attachFilterLabel, setAttachFilterLabel] = useState<boolean>(Boolean(label.attachFilterLabel));
  const [attachRejectLabel, setAttachRejectLabel] = useState<boolean>(Boolean(label.attachRejectLabel));
  const [filterLabelExternal, setFilterLabelExternal] = useState<boolean>(Boolean(label.filterLabelExternal));
  const [rejectLabelExternal, setRejectLabelExternal] = useState<boolean>(Boolean(label.rejectLabelExternal));
  // const [flaggedSlideTagIds, setFlaggedSlideTagIds] = useState<string[]>(label.flaggedSlideTagIds || []);
  const flaggedSlideTagIds = label.flaggedSlideTagIds || [];
  const [excludeOption, setExcludeOption] = useState<ExcludeOption>(label.excludeOption);

  // reminder: external labels save the external label id, and not the text, in the rejectLabel and filterLabel fields
  // if for some reason the external label is not an id from the list, then check if it's text is in the list and set it's id
  const externalLabelFromFilterText = find(externalLabelOptions, { text: filterLabel });
  if (filterLabelExternal && !getExternalLabelDisplayName(filterLabel) && externalLabelFromFilterText) {
    setFilterLabel(externalLabelFromFilterText.id);
  }

  const externalLabelFromRejectText = find(externalLabelOptions, { text: rejectLabel });
  if (rejectLabelExternal && !getExternalLabelDisplayName(rejectLabel) && externalLabelFromRejectText) {
    setRejectLabel(externalLabelFromRejectText.id);
  }

  const updatedLabel: QualityControlLabel = {
    filterLabel,
    rejectLabel,
    attachFilterLabel,
    attachRejectLabel,
    filterLabelExternal,
    rejectLabelExternal,
    condition,
    flaggedSlideTagIds,
    excludeOption,
    id: label.id,
  };

  const [isLabelValid, validationErrorMessage] = validateLabel(updatedLabel, externalLabelOptions);
  const [shouldShowErrors, setShouldShowErrors] = React.useState(false);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <OrchestrationIdAutoComplete
          onChange={setOrchestrationId}
          options={orchestrationIdOptions}
          disabled={!editable}
          loading={orchestrationsLoading}
          value={orchestrationId}
          helperText="Select an orchestration to load features (the orchestration id is not saved)"
        />
      </Grid>
      <Grid item container spacing={3} xs={12} alignItems="center" justifyItems="center" justifyContent="space-between">
        <Grid item container xs={6}>
          <Grid item xs={12}>
            {!filterLabelExternal ? (
              <TextField
                disabled={!editable}
                label="Label"
                value={filterLabel}
                error={shouldShowErrors && !filterLabel}
                required
                onChange={(e) => setFilterLabel(e.target.value)}
                sx={{ minWidth: 200 }}
              />
            ) : (
              <FormControl fullWidth sx={{ minWidth: 200 }} required error={shouldShowErrors} disabled={!editable}>
                <InputLabel>
                  {isLoadingExternalLabelOptions ? (
                    <>
                      Label <CircularProgress sx={{ marginLeft: 7 }} color="inherit" size={20} />
                    </>
                  ) : (
                    'Label'
                  )}
                </InputLabel>
                <Select label="Label" value={filterLabel} onChange={(e) => setFilterLabel(e.target.value)}>
                  {map(externalLabelOptions, (externalLabel) => (
                    <MenuItem value={externalLabel.id}>{externalLabel.text}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>
          <Grid item>
            <FormControlLabel
              label="Attach Label"
              control={
                <Checkbox
                  disabled={!editable}
                  checked={attachFilterLabel}
                  onChange={(e) => setAttachFilterLabel(e.target.checked)}
                />
              }
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              label="Label External"
              control={
                <Checkbox
                  disabled={!editable}
                  checked={filterLabelExternal}
                  onChange={(e) => {
                    setFilterLabelExternal(e.target.checked);
                    const externalLabelFromText = find(externalLabelOptions, { text: filterLabel });
                    const externalLabelFromId = find(externalLabelOptions, { id: filterLabel });
                    // if the user already typed the label name and it's in the list, set the id
                    if (e.target.checked && externalLabelFromText) {
                      setFilterLabel(externalLabelFromText.id);
                    } else if (!e.target.checked && externalLabelFromId) {
                      setFilterLabel(externalLabelFromId.text);
                    }
                  }}
                />
              }
            />
          </Grid>
        </Grid>
        <Grid item container xs={6}>
          <Grid item xs={12}>
            {!rejectLabelExternal ? (
              <TextField
                disabled={!editable}
                label="Opposite Label"
                value={rejectLabel}
                error={attachRejectLabel && !rejectLabel && shouldShowErrors}
                required={attachRejectLabel}
                onChange={(e) => setRejectLabel(e.target.value)}
                sx={{ minWidth: 200 }}
              />
            ) : (
              <FormControl
                fullWidth
                sx={{ minWidth: 200 }}
                required
                error={!rejectLabel && shouldShowErrors}
                disabled={!editable}
              >
                <InputLabel>
                  {isLoadingExternalLabelOptions ? (
                    <>
                      Opposite Label <CircularProgress sx={{ marginLeft: 7 }} color="inherit" size={20} />
                    </>
                  ) : (
                    'Opposite Label'
                  )}
                </InputLabel>
                <Select label="Opposite Label" value={rejectLabel} onChange={(e) => setRejectLabel(e.target.value)}>
                  {map(externalLabelOptions, (externalLabel) => (
                    <MenuItem value={externalLabel.id}>{externalLabel.text}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>
          <Grid item>
            <FormControlLabel
              label="Attach Opposite Label"
              control={
                <Checkbox
                  disabled={!editable}
                  checked={attachRejectLabel}
                  onChange={(e) => setAttachRejectLabel(e.target.checked)}
                />
              }
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              label="Opposite Label External"
              control={
                <Checkbox
                  disabled={!editable}
                  checked={rejectLabelExternal}
                  onChange={(e) => {
                    setRejectLabelExternal(e.target.checked);

                    // if the user already typed the label name and it's in the list, set the id
                    if (e.target.checked) {
                      const externalLabelFromText = find(externalLabelOptions, { text: rejectLabel });
                      if (externalLabelFromText) setRejectLabel(externalLabelFromText.id);
                    } else {
                      // if the user selected an external label and then unchecked the external checkbox, set the text
                      const externalLabelFromId = find(externalLabelOptions, { id: rejectLabel });
                      if (externalLabelFromId) setRejectLabel(externalLabelFromId.text);
                    }
                  }}
                />
              }
            />
          </Grid>
        </Grid>
        {/* we should return this part when we do something with this data */}
        {/* <Grid item xs={6}>
          <Autocomplete
            disabled={isLoadingSlideTagOptions || !editable}
            options={slideTagOptions || []}
            renderInput={(params) => (
              <TextField
                label="Flag The Following Tags"
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {isLoadingSlideTagOptions ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
            getOptionLabel={(option: SlideTag) => option.tagValue}
            multiple={true}
            onChange={(_, newValue) => {
              setFlaggedSlideTagIds(map(newValue, 'id'));
            }}
            value={
              isLoadingSlideTagOptions
                ? []
                : map(flaggedSlideTagIds, (tagId) => find(slideTagOptions, (tagOption) => tagOption.id == tagId))
            }
          />
        </Grid> */}
        <Grid item xs={12}>
          <Typography variant="h6">Exclude Option</Typography>
          <Typography variant="body2" mb={1}>
            {`Select whether to exclude slides that meet the condition (slides with the label), exclude slides that
            don't meet the condition (slides with the opposite label) or don't exclude slides based on this label.`}
            <br />
            {`For example, if this label represent a slide that has a small tissue area and you want to exclude slides
            that have a small tissue area, you would select "Label Excludes Slides".
            If you don't want to exclude slides based on this label, make sure not to select any option.`}
          </Typography>
          <ToggleButtonGroup
            color="primary"
            exclusive
            value={excludeOption}
            onChange={(event, value) => setExcludeOption(value)}
          >
            <ToggleButton key={ExcludeOption.FILTER} value={ExcludeOption.FILTER}>
              {`Label Excludes Slides`}
            </ToggleButton>
            <ToggleButton key={ExcludeOption.REJECT} value={ExcludeOption.REJECT}>
              {`Opposite Label Excludes Slides`}
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography color={shouldShowErrors && updatedLabel.condition == null ? 'error' : 'initial'}>
          Condition*
        </Typography>
        <AutomaticConditionGroup
          editable={editable}
          onOperandsChange={(newOperands) => (isEmpty(newOperands) ? setCondition(null) : setCondition(newOperands[0]))}
          operands={condition ? [condition] : []}
          study={study}
          orchestrationId={orchestrationId}
          shouldShowErrors={shouldShowErrors}
          singleOperand
          conditionOptions={[ConditionType.LOGICAL, ConditionType.RANGE, ConditionType.TAG]}
        />
      </Grid>

      <Grid item container justifyContent={'space-between'} alignItems={'flex-end'} xs={12}>
        <Grid item xs={8}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandIcon />}>Label Summary (JSON)</AccordionSummary>
            <AccordionDetails>
              <Typography component="pre">{JSON.stringify(updatedLabel, null, 2)}</Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item container xs={4} spacing={2} justifyContent={'flex-end'}>
          <Grid item>
            <Button variant="outlined" color="primary" onClick={onClose} disabled={!editable}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                if (isLabelValid) {
                  onUpdateLabel(updatedLabel);
                  onClose();
                } else {
                  setShouldShowErrors(true);
                }
              }}
            >
              {isNew ? 'Add' : 'Update'}
            </Button>
            {shouldShowErrors && <Typography color="error">{validationErrorMessage}</Typography>}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LabelBuilder;
