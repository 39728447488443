import { PMTilesMetadata, PMTilesSource } from '@loaders.gl/pmtiles';
import { useQueries, useQuery } from '@tanstack/react-query';
import { map, some, uniqBy } from 'lodash';
import { useMemo } from 'react';

import { FeatureMetadata, HeatmapType } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';

export interface PMTilesResult {
  pmtHeatmap?: FeatureMetadata;
  pmtTileSource: PMTilesSource | null;
  pmtMetadata: PMTilesMetadata | null;
}

export const setupProtomapTiles = async (pmtUrl: string): Promise<PMTilesResult> => {
  const pmtTileSource = new PMTilesSource({ url: pmtUrl });
  const pmtMetadata = await pmtTileSource.metadata;
  return { pmtTileSource, pmtMetadata };
};

const canQueryPmtHeatmap = (pmtHeatmap: FeatureMetadata) =>
  Boolean(pmtHeatmap?.heatmapUrl) &&
  pmtHeatmap.heatmapType === HeatmapType.Pmt &&
  // Wait for pre-signed URLs to be ready
  !pmtHeatmap.heatmapUrl.startsWith('s3://');

export const useProtomapTiles = (pmtHeatmap: FeatureMetadata): PMTilesResult => {
  const { data } = useQuery(
    ['pmtiles', pmtHeatmap?.heatmapUrl],
    async () => setupProtomapTiles(pmtHeatmap?.heatmapUrl),
    {
      enabled: canQueryPmtHeatmap(pmtHeatmap),
    }
  );

  return {
    pmtTileSource: data?.pmtTileSource || null,
    pmtMetadata: data?.pmtMetadata || null,
    pmtHeatmap,
  };
};

export const useProtomapTilesList = (pmtHeatmaps: FeatureMetadata[]) => {
  const uniquePmtHeatmaps = uniqBy(pmtHeatmaps, (pmtHeatmap) => pmtHeatmap?.heatmapUrl);
  const pmtTilesQueries = useQueries({
    queries: map(uniquePmtHeatmaps, (pmtHeatmap) => ({
      queryKey: ['pmtiles', pmtHeatmap?.heatmapUrl],
      queryFn: async () => setupProtomapTiles(pmtHeatmap?.heatmapUrl),
      enabled: canQueryPmtHeatmap(pmtHeatmap),
    })),
  });

  const pmtLayersData = useMemo(
    () =>
      map(uniquePmtHeatmaps, (pmtHeatmap, queryIndex) => {
        const query = pmtTilesQueries[queryIndex];
        const { pmtTileSource, pmtMetadata } = query?.data || {};
        return {
          pmtHeatmap,
          pmtTileSource,
          pmtMetadata,
          isLoading: query?.isLoading && canQueryPmtHeatmap(pmtHeatmap),
          updatedAt: query?.dataUpdatedAt,
        };
      }),
    [JSON.stringify(map(pmtTilesQueries, 'dataUpdatedAt'))]
  );

  return {
    pmtTilesQueries,
    pmtLayersData,
    isLoading: some(pmtTilesQueries, 'isLoading'),
  };
};
