import { Controller, LinearInterpolator } from '@deck.gl/core/typed';
import { OrthographicMapState } from './OrthographicMapState';

export class OrthographicMapController extends Controller<OrthographicMapState> {
  ControllerState = OrthographicMapState;

  transition = {
    transitionDuration: 100,
    transitionInterpolator: new LinearInterpolator({
      transitionProps: {
        compare: ['zoom', 'bearing', 'pitch', 'target'],
        required: ['target', 'zoom'],
      },
    }),
  };

  dragMode: 'pan' | 'rotate' = 'pan';

  setProps(props: any) {
    // setProps(props: ControllerProps & OrthographicMapStateProps) {
    props.target = props.target || [0, 0, 0];
    const oldProps = this.props;

    super.setProps(props);

    const dimensionChanged = !oldProps || oldProps.height !== props.height || oldProps.width !== props.width;
    if (dimensionChanged) {
      // Dimensions changed, normalize the props
      this.updateViewport(
        new this.ControllerState({
          makeViewport: this.makeViewport,
          ...props,
          ...this.state,
        })
      );
    }
  }

  handleEvent(event: any) {
    if (event.type === 'panstart' || event.type === 'panmove' || event.type === 'panend') {
      // Ignore rotation when Shift is pressed
      if (event.leftButton && event.srcEvent.shiftKey) {
        // To prevent the default behavior of rotation (while drawing, the shift will allow the user to pan the map instead of drawing)
        event.srcEvent = {
          ...event.srcEvent,
          metaKey: false,
          shiftKey: false,
        };
      }
    }
    // Use the default handler
    return super.handleEvent(event);
  }
}
