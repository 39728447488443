import { NEGATIVE_CONTROL, POSITIVE_CONTROL } from 'components/Procedure/constants';
import { RGBAColor } from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/NebulaGLExtensions/helpers';
import { Accession } from 'interfaces/accession';
import { QueryFilter } from 'interfaces/cohort';
import { Procedure } from 'interfaces/procedure';
import { ProceduresFieldsContext } from 'interfaces/procedure/fields/helpers';
import { Slide } from 'interfaces/slide';
import {
  capitalize,
  curry,
  Dictionary,
  filter,
  find,
  first,
  fromPairs,
  indexOf,
  isEmpty,
  isEqual,
  isNumber,
  join,
  map,
  pickBy,
  replace,
  slice,
  some,
  split,
  startCase,
  toPairs,
  toString,
  values,
} from 'lodash';
import numeral from 'numeral';
import { UiSettings } from 'utils/queryHooks/uiConstantsHooks';

declare const IMAGE_HOST: string;
declare const STATIC_IMAGE_HOST: string;

export function getSlideThumbnailUrl(slide: Pick<Slide, 'id' | 'labId'>) {
  const labId = slide.labId;
  return `${STATIC_IMAGE_HOST}/${labId}/${slide.id}/${slide.id}_thumbnail.png`;
}

export function getSlideOriginalUrl(slide: Pick<Slide, 'id' | 'labId'>) {
  const labId = slide.labId;
  return `${STATIC_IMAGE_HOST}/${labId}/${slide.id}/${slide.id}_thumbnail_big.png`;
}

export function getAccessionIdentifier(accession?: Accession) {
  return first(split(accession?.accessionData?.accessionId, '-'));
}

export function getProcedureIdentifier(procedure?: Procedure) {
  // we no longer want to fallback on the first slide label, if the procedure needs to have a specific label
  // then its respective procedure_results.label field in the db needs to be updated.
  return procedure?.label || procedure?.id?.toString() || null;
}

const uuidv4ReplaceRegex = /[018]/g;

export function uuidv4(): string {
  // @ts-ignore
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(uuidv4ReplaceRegex, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
}

export function getSlideMacroUrl(slide: Slide, formatExtensions: Record<string, string>) {
  return `${IMAGE_HOST}/${slide.id}${
    formatExtensions[slide.format]
  }.dzi/macro.png?force_portrait=true&prefer_static=true`;
}

export function toPercentage(fraction: number, fixedDigitsAfterDecimalPoint = 2) {
  const percentage = `${(fraction * 100).toFixed(fixedDigitsAfterDecimalPoint)}%`;
  return percentage === '0.00%' ? '0%' : percentage;
}

export function divideToPercentage(num1: number, num2: number) {
  return num2 !== 0 ? toPercentage(num1 / num2) : toPercentage(0);
}

export function formatNumber(n: number, format?: string, forExport?: boolean) {
  if (format) {
    let formattedNumber = numeral(n).format(format);

    if (forExport) {
      formattedNumber = replace(formattedNumber, '%', '');
    }

    return formattedNumber;
  }
  if (n === 0) return numeral(n).format('0');
  if (n > -1 && n < 1) return numeral(n).format('0.000e+0');
  return numeral(n).format('0.000');
}

export function humanize(str: string) {
  return capitalize(startCase(str));
}

export function formatDataKeyToText(key: string) {
  const keyWithoutPrefix = removePrefixFromDataKey(key);
  return humanize(replace(keyWithoutPrefix, /_/g, ' '));
}

export function removePrefixFromDataKey(key: string) {
  return key.substring(indexOf(key, '.') + 1);
}

export const displayFeatureName = curry((uiSettings: UiSettings, inferredKey: string) => {
  return (
    uiSettings?.webappFeaturesConfig[removePrefixFromDataKey(inferredKey)]?.displayName ||
    formatDataKeyToText(inferredKey)
  );
});

export const NULL_VALUE_DISPLAY_NAME = 'Unspecified';

export const displayProcedureFieldValueName = curry(
  (procedureFieldContext: ProceduresFieldsContext, inferredKey: string, inferredValue: string | number | boolean) => {
    const { cancerTypes, biopsySiteTypes } = procedureFieldContext;

    if (
      inferredValue === null ||
      inferredValue === undefined ||
      inferredValue === 'null' ||
      inferredValue === 'undefined'
    ) {
      return NULL_VALUE_DISPLAY_NAME;
    }
    if (inferredKey === 'cancerTypeId') {
      return find(cancerTypes, { id: Number(inferredValue) })?.displayName;
    }
    if (inferredKey === 'biopsySiteId') {
      return find(biopsySiteTypes, { id: Number(inferredValue) })?.displayName;
    }
    if (inferredKey === 'qcFailed') {
      // the qcFailed value is a string of 'true'/'false' and we want to show 'Unusable' or 'Usable' instead of 'true' or 'false'
      return inferredValue === 'true' ? 'Unusable' : 'Usable';
    }

    return inferredValue;
  }
);

export function formatRangeQueryStringToText(rangeQueryString: string) {
  if (!rangeQueryString) return '';
  const [start, end] = rangeQueryString.split('~');
  if (!end) return `=< ${start}`;
  if (!start) return `>= ${end}`;
  return `${start} - ${end}`;
}

export function formatRangeObjectToText(range: { start: string; end: string }) {
  if (!range) {
    return '';
  }
  if (!range.start) return `=< ${range.end}`;
  if (!range.end) return `>= ${range.start}`;
  return `${range.start} - ${range.end}`;
}
export type Prepend = {
  <T>(item: T, arr: T[]): T[];
  <T>(item: T): (arr: T[]) => T[]; // can be curried too
};

// function that prepends an item to an array, pure version of array.unshift
export const prepend: Prepend = curry(<T>(item: T, arr: T[]) => [item, ...arr]);

export const formatBytes = (bytes: number, decimals: number = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

const hexCatchingRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;

export const isHexString = (hex: string) => hexCatchingRegex.test(hex);

export const hexToRgb = (hex: string): [number, number, number] => {
  // A string beginning with a hash character (#) followed by six hexadecimal digits (\d - digits or the letters a-f).
  const result = hexCatchingRegex.exec(hex);
  if (!result) {
    console.error(`Invalid hex color: ${hex}`, { hex });
    return [0, 0, 0];
  }
  return map(result.slice(1), (value) => parseInt(value, 16)) as [number, number, number];
};

export const rgbaToHex = (rgba: RGBAColor) => {
  const rgb = slice(rgba, 0, 3);
  return `#${join(
    map(rgb, (value) => toString(value).padStart(2, '0')),
    ''
  )}`;
};

export const getColorHex = (color: any) => {
  return typeof color === 'string' ? color : color?.hex;
};

export const invertHexColor = (hexColorString: string) => {
  const colorValue = parseInt(replace(hexColorString, /#/g, ''), 16);
  const invertedColorValue = colorValue ^ 0xffffff;
  const invertedColorStringValue = invertedColorValue.toString(16).padStart(6, '0').slice(-6);
  return `#${invertedColorStringValue}`;
};

export const splitStringAfterNChars = (longText: string, n: number) =>
  longText?.match(new RegExp(`.{1,${n}}[^ ]* ?`, 'g'));

export const allFiltersHasValue = (filters: Record<string, QueryFilter>) => {
  return some(values(filters), (value: any) => isNumber(value) || !isEmpty(value));
};

export const removeNullValues = (obj: Record<string, any>) => pickBy(obj, (value) => value !== null);

export const getStainStrWithNegPosControl = (stain: string, negativeControl?: boolean, positiveControl?: boolean) => {
  if (negativeControl) {
    return `${stain} ${NEGATIVE_CONTROL}`;
  } else if (positiveControl) {
    return `${stain} ${POSITIVE_CONTROL}`;
  }

  return stain;
};

export const reduceExtraSpaces = (input: string): string => {
  // Trim the input to remove spaces at the beginning and end
  const trimmedInput = input.trim();

  // Replace multiple spaces in the middle with a single space
  const reducedSpaces = trimmedInput.replace(/\s+/g, ' ');

  return reducedSpaces;
};

/**
 * Given an object and an original version of it, return all fields that have changed, or an empty dictionary in case of equality
 * @param newObject object with changes
 * @param oldObject original object we're comparing to
 * @param compareNullsAndUndefined if true, null and undefined are considered different
 * @returns the fields of newObject that are different from oldObject
 */
export function getFieldChanges<T extends object>(
  newObject: T,
  oldObject: T,
  compareNullsAndUndefined: boolean = false
): Dictionary<T[keyof T]> {
  return fromPairs(
    filter(
      toPairs(newObject),
      ([key, value]) =>
        !isEqual(value, oldObject?.[key as keyof T]) &&
        (compareNullsAndUndefined || value !== null || oldObject?.[key as keyof T] !== undefined)
    )
  );
}
