import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import PolylineIcon from '@mui/icons-material/Polyline';
import React from 'react';

import { Badge, BadgeProps, SvgIconProps } from '@mui/material';
import { SecondaryAnalysisEditType } from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/SecondaryAnalysisLayer/useSecondaryAnalysisLayer';
import { SlideInteractionMenuOptions } from './SlideInteractionOption';

export const IconWithBadge: React.FC<
  SvgIconProps & {
    icon: React.ElementType<SvgIconProps>;
    badgeContent: BadgeProps['badgeContent'];
    badgeSize?: number;
  }
> = ({ icon: Icon, badgeContent, badgeSize = 13, ...props }) => (
  <Badge
    badgeContent={badgeContent}
    overlap="circular"
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    color="secondary"
    sx={(props?.sx as any)?.color ? { color: (props.sx as any).color } : {}}
    componentsProps={{
      badge: { style: { fontSize: badgeSize, width: badgeSize, minWidth: badgeSize, height: badgeSize } },
    }}
  >
    <Icon {...props} />
  </Badge>
);

export const secondaryAnalysisOptionsWithoutPolygons: SlideInteractionMenuOptions[] = [
  {
    editType: SecondaryAnalysisEditType.IncludeRoi,
    title: 'Include ROI',
    icon: (props: SvgIconProps) => <IconWithBadge icon={HighlightAltIcon} badgeContent="+" {...props} />,
    isDefault: true,
  },
  {
    editType: SecondaryAnalysisEditType.ExcludeRoi,
    title: 'Exclude ROI',
    icon: (props: SvgIconProps) => <IconWithBadge icon={HighlightAltIcon} badgeContent="-" {...props} />,
  },
  {
    editType: SecondaryAnalysisEditType.IncludePolygon,
    title: 'Include Polygon',
    icon: (props: SvgIconProps) => <IconWithBadge icon={PolylineIcon} badgeContent="+" {...props} />,
  },
  {
    editType: SecondaryAnalysisEditType.ExcludePolygon,
    title: 'Exclude Polygon',
    icon: (props: SvgIconProps) => <IconWithBadge icon={PolylineIcon} badgeContent="-" {...props} />,
  },
];

export const secondaryAnalysisOptionsWhenIncluding: SlideInteractionMenuOptions[] = [
  {
    editType: SecondaryAnalysisEditType.DeletePolygon,
    title: 'Delete Individual Polygon / ROI',
    icon: DeleteOutlineIcon,
  },
  {
    editType: SecondaryAnalysisEditType.ModifyPolygon,
    title: 'Modify Polygon / ROI',
    icon: DriveFileRenameOutlineIcon,
  },
  {
    editType: SecondaryAnalysisEditType.IncludeRoi,
    title: 'Include ROI',
    icon: (props: SvgIconProps) => <IconWithBadge icon={HighlightAltIcon} badgeContent="+" {...props} />,
    isDefault: true,
  },
  {
    editType: SecondaryAnalysisEditType.CutRoi,
    title: 'Remove ROI from Current Selection',
    icon: (props: SvgIconProps) => <IconWithBadge icon={HighlightAltIcon} badgeContent="-" {...props} />,
  },
  {
    editType: SecondaryAnalysisEditType.IncludePolygon,
    title: 'Include Polygon',
    icon: (props: SvgIconProps) => <IconWithBadge icon={PolylineIcon} badgeContent="+" {...props} />,
  },
  {
    editType: SecondaryAnalysisEditType.CutPolygon,
    title: 'Remove Polygon from Current Selection',
    icon: (props: SvgIconProps) => <IconWithBadge icon={PolylineIcon} badgeContent="-" {...props} />,
  },
];

export const secondaryAnalysisOptionsWhenExcluding: SlideInteractionMenuOptions[] = [
  {
    editType: SecondaryAnalysisEditType.DeletePolygon,
    title: 'Delete Individual Polygon / ROI',
    icon: DeleteOutlineIcon,
  },
  {
    editType: SecondaryAnalysisEditType.ModifyPolygon,
    title: 'Modify Polygon / ROI',
    icon: DriveFileRenameOutlineIcon,
  },
  {
    editType: SecondaryAnalysisEditType.CutRoi,
    title: 'Remove ROI from Current Selection',
    icon: (props: SvgIconProps) => <IconWithBadge icon={HighlightAltIcon} badgeContent="+" {...props} />,
  },
  {
    editType: SecondaryAnalysisEditType.ExcludeRoi,
    title: 'Exclude ROI',
    icon: (props: SvgIconProps) => <IconWithBadge icon={HighlightAltIcon} badgeContent="-" {...props} />,
    isDefault: true,
  },
  {
    editType: SecondaryAnalysisEditType.CutPolygon,
    title: 'Remove Polygon from Current Selection',
    icon: (props: SvgIconProps) => <IconWithBadge icon={PolylineIcon} badgeContent="+" {...props} />,
  },
  {
    editType: SecondaryAnalysisEditType.ExcludePolygon,
    title: 'Exclude Polygon',
    icon: (props: SvgIconProps) => <IconWithBadge icon={PolylineIcon} badgeContent="-" {...props} />,
  },
];
