import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import { Avatar, Grid, LinearProgress, Paper, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getAgentSessions, sendAMessageToAgent } from 'api/agent';
import { fetchStudies } from 'api/study';
import { AgentRawResponse } from 'interfaces/agentSession';
import { find, map } from 'lodash';
import Markdown from 'markdown-to-jsx';
import React from 'react';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import { useMutationWithErrorSnackbar } from 'utils/useMutationWithErrorSnackbar';
import { useQueryWithErrorAndRetrySnackbar } from 'utils/useQueryWithErrorAndRetrySnackbar';
import AnalysisReport from './AnalysisReport';
import ChatInput from './ChatInput';

const Copilot: React.FC = () => {
  const [message, setMessage] = React.useState('');
  const [interactions, setInteractions] = React.useState([]);

  const { labId } = useCurrentLabId();

  const { data: studies, status: studiesStatus } = useQueryWithErrorAndRetrySnackbar(
    ['studies', labId, 'withStatistics'],
    fetchStudies(labId, true),
    {
      queriedEntityName: 'studies',
    }
  );

  const {
    queryParams: {
      filters,
      features,
      clinicalData,
      slidesMode,
      resultsMode,
      caseIdsToInclude: caseIdsToIncludeFilter,
      slideIdsToInclude: slideIdsToIncludeFilter,
    },
  } = useEncodedFilters();

  const { studyId } = filters;

  const currentStudy = find(studies, { id: filters?.studyId });

  const onSubmit = (agentUserRequest: string) => {
    console.info(`sending message: ${message}`);
    sendUserMessage(agentUserRequest);
  };

  const updateInteractions = (userMessage: string, response: AgentRawResponse) => {
    setInteractions((prev) => [...prev, { userMessage, response }]);
  };

  const { mutate: sendUserMessage, isLoading } = useMutationWithErrorSnackbar({
    mutationDescription: 'Send a message to the research companion',
    // sendMessageToAgent -> session_id is hardcoded for agent POC demo (no session history)
    mutationFn: (userMessage: string) => sendAMessageToAgent({ session_id: '1', user_request: userMessage }),
    onSuccess: (data: AgentRawResponse) => {
      updateInteractions(message, data);
      setMessage('');

      // Scroll to the bottom with smooth behavior
      setTimeout(() => {
        const chatElement = document.getElementById('interactions-container');

        const reportElement = document.getElementById('analysis-report');

        if (chatElement) {
          chatElement.scrollTo({
            top: chatElement.scrollHeight,
            behavior: 'smooth',
          });
        }
        if (reportElement) {
          reportElement.scrollTo({
            top: reportElement.scrollHeight,
            behavior: 'smooth',
          });
        }
      }, 100);
    },
  });

  const { data: any, isLoading: isLoadingSessions } = useQuery({
    queryKey: ['agentSessions'],
    queryFn: () => getAgentSessions(''),
    enabled: true,
  });

  const reportActions = map(interactions, (interaction) => interaction.response.invocations);

  return (
    <Grid item container m={2} p={3} spacing={2}>
      <Grid
        container
        item
        xs={3}
        className="chat-bot-section"
        component={Paper}
        direction="column"
        spacing={2}
        px={0.5}
        mt={1}
        justifyContent={'space-between'}
        wrap="nowrap"
        height="calc(100vh - 360px)"
      >
        <Grid item>
          <Typography variant="h3">Research Companion</Typography>
          <Typography variant="caption">
            You can ask research question regarding the selected cohort and generate findings
          </Typography>
        </Grid>
        <Grid
          item
          container
          direction="column"
          spacing={1}
          height="calc(100vh - 580px)"
          overflow="scroll"
          wrap="nowrap"
          id="interactions-container"
        >
          {map(interactions, (interaction, index) => (
            <>
              <Grid item key={index + 'user'} alignSelf={'flex-end'}>
                <Paper sx={{ p: 0.5 }} variant="outlined">
                  <Typography variant="body1">{interaction.userMessage}</Typography>
                </Paper>
              </Grid>
              <Grid item container key={index + 'bot'} alignSelf={'flex-start'} direction={'row'} spacing={1}>
                <Grid item>
                  <Avatar sx={{ width: 24, height: 24 }}>
                    <FaceRetouchingNaturalIcon color="inherit" />
                  </Avatar>
                </Grid>
                <Grid item {...(index === interactions.length - 1 ? { id: 'target-element' } : {})}>
                  <Paper sx={{ p: 0.5 }} variant="outlined">
                    <Markdown>{interaction?.response?.message || ''}</Markdown>
                  </Paper>
                </Grid>
              </Grid>
            </>
          ))}
        </Grid>
        <Grid item>{(isLoadingSessions || isLoading) && <LinearProgress />}</Grid>
        <Grid item justifySelf={'flex-end'}>
          <ChatInput onSubmit={onSubmit} message={message} onChange={setMessage} />
        </Grid>
      </Grid>
      <Grid item xs={9} overflow={'scroll'} height="calc(100vh - 320px)" id="analysis-report">
        <AnalysisReport currentStudy={currentStudy} totalProcedures={37} reportActions={reportActions} />
      </Grid>
    </Grid>
  );
};

export default Copilot;
