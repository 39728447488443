import { signal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';
import {
  editTypeToShapeSubTypeMap,
  ShapeSubTypes,
} from 'components/Procedure/Header/SlideInteractionMenu/slideAnnotations/options';
import { getAnnotationTodoNameAndDefinition } from 'components/Procedure/Infobar/SlideInfobar/SlideAnnotation/helpers';
import { MAX_VIEWERS } from 'components/Procedure/SlidesViewer/constants';
import { TodoOption } from 'interfaces/annotation';
import { find, get, times } from 'lodash';
import { useActiveAnnotationAssignmentForViewer } from 'services/annotations/useAnnotationQueryParams';
import { StringParam, useQueryParam } from 'use-query-params';
import { useSlideAnnotationLayersData } from '../useSlideAnnotationLayersData';

export interface AnnotationState {
  name: string;
  positive?: boolean; // for marker annotations (to know if now annotating cells that are positive or negative to the marker)
}

// what we save in the signal
interface AnnotationSettings {
  currentClassToAnnotatePerGeometry: {
    [K in ShapeSubTypes]: AnnotationState;
  };
}

export const viewerAnnotationSettings = times(MAX_VIEWERS, () => signal<AnnotationSettings | null>(null));

const useActiveClassToAnnotate = ({ slideId, viewerIndex }: { slideId: string; viewerIndex: number }): TodoOption => {
  useSignals();

  const { slideAnnotations } = useSlideAnnotationLayersData({ slideId });
  const [activeAnnotationAssignmentId] = useActiveAnnotationAssignmentForViewer(viewerIndex);

  const savedAnnotationData = find(slideAnnotations, {
    annotationAssignment: { annotationAssignmentId: activeAnnotationAssignmentId },
  });
  const annotationTodo = getAnnotationTodoNameAndDefinition(savedAnnotationData);
  const [editAnnotationsModeFromUrl] = useQueryParam('editAnnotationsMode', StringParam);

  // Use the color from the todo option if it exists, otherwise look in all todos
  const todoOptions = annotationTodo?.todo?.options;
  if (viewerAnnotationSettings[viewerIndex]?.value) {
    const currentShapeSubtype = get(editTypeToShapeSubTypeMap, editAnnotationsModeFromUrl, '');
    const { currentClassToAnnotatePerGeometry } = viewerAnnotationSettings[viewerIndex].value;
    const currentClassToAnnotate = get(currentClassToAnnotatePerGeometry, currentShapeSubtype, null);
    if (currentClassToAnnotate) {
      const matchingOption = find(todoOptions, { name: currentClassToAnnotate.name });

      return matchingOption ? { ...matchingOption, positive: currentClassToAnnotate.positive } : ({} as TodoOption);
    }
  }

  return {} as TodoOption;
};

export default useActiveClassToAnnotate;
