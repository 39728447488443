import { find } from 'lodash';
import React, { useEffect } from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';

import { fetchStudies } from 'api/study';
import { defaultStudySlideViewMode } from 'components/StudiesDashboard/StudySettings/GeneralSettings';
import { useCurrentLabId } from 'utils/useCurrentLab';
import useStudy from 'utils/useStudy';
import { useQueryWithErrorAndRetrySnackbar } from './useQueryWithErrorAndRetrySnackbar';

export const useStudyPlatformSettings = (
  studyId?: string // This is optional since studyId is not always available on first render
) => {
  const { labId } = useCurrentLabId();
  const { isLoading: studiesLoading, data: allStudies } = useQueryWithErrorAndRetrySnackbar(
    ['studies', labId],
    fetchStudies(labId),
    {
      keepPreviousData: true,
      enabled: Boolean(studyId) && Boolean(labId),
    }
  );
  const studyDataInAllStudies = find(allStudies, { id: studyId });

  // Load single study if it is not in all studies, otherwise use the study from all studies to avoid loading it again
  const { study } = useStudy(studyId, { enabled: Boolean(studyId) && !studyDataInAllStudies });

  const loadedStudy = studyDataInAllStudies || study;
  const currentStudySettings = loadedStudy?.id === studyId ? loadedStudy?.settings : null;

  const canLoadStudySettings = Boolean(studyId);
  const isLoadingStudySettings = canLoadStudySettings && !currentStudySettings && studiesLoading;
  return {
    isLoadingStudySettings,
    ...currentStudySettings,
    defaultSlideViewMode: currentStudySettings?.defaultSlideViewMode || defaultStudySlideViewMode,
  };
};

export const useUpdateViewerTypeFromStudySettings = ({
  studyId,
}: {
  studyId?: string; // This is optional since studyId is not always available on first render
}) => {
  const { isLoadingStudySettings, ...currentStudySettings } = useStudyPlatformSettings(studyId);
  const [useOSDViewer, setUseOSDViewer] = useQueryParam('useOSDViewer', BooleanParam);

  const lastSetStudyId = React.useRef<string | null>(null);
  useEffect(() => {
    if (!isLoadingStudySettings && currentStudySettings) {
      if (lastSetStudyId.current !== studyId) {
        setUseOSDViewer(useOSDViewer ?? currentStudySettings.useOSDViewer, 'replaceIn');
        lastSetStudyId.current = studyId;
      }
    }
  }, [studyId, currentStudySettings, isLoadingStudySettings, setUseOSDViewer]);

  return { isLoadingStudySettings };
};
