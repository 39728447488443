import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import { Chip, CircularProgress, Grid, Tooltip, Typography } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import { castArray, filter, find, first, includes, isEmpty, size } from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useParams } from 'react-router-dom';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';

import { runCalculateFeatures } from 'api/platform';
import { useConfirmation } from 'components/modals/ConfirmationContext';
import { useJobs } from 'components/Pages/Jobs/useJobs';
import { geojsonFeaturesToMultiPolygon } from 'components/Procedure/Infobar/SlideInfobar/Results/SecondaryAnalysisTrigger';
import {
  secondaryAnalysisExclusionModes,
  secondaryAnalysisInclusionModes,
  secondaryAnalysisPolygons,
} from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/SecondaryAnalysisLayer/useSecondaryAnalysisLayer';
import {
  useSecondaryAnalysisOrchestrationIdForViewer,
  useSecondaryAnalysisOrchestrationIdQueryParams,
} from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/SecondaryAnalysisLayer/useSecondaryAnalysisQueryParams';
import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { Inputs } from 'interfaces/calculateFeatures';
import { CalculateFeaturesJob, JobType, SecondaryAnalysisInput } from 'interfaces/job';
import { Permission } from 'interfaces/permissionOption';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import { usePermissions } from 'utils/usePermissions';
import { IconWithBadge } from './options';

export const SNACK_BAR_KEY_SECONDARY_ANALYSIS = 'secondary-analysis';

export interface RunSecondaryAnalysisButtonProps {
  caseId: number;
  selectedSlides: SlideWithChannelAndResults[];
  isStartingSecondaryAnalysis: boolean;
  startSecondaryAnalysis: (...props: Parameters<typeof runCalculateFeatures>) => void;
}

export const RunSecondaryAnalysisButton: React.FC<RunSecondaryAnalysisButtonProps> = ({
  caseId: caseIdFromProps,
  selectedSlides,
  isStartingSecondaryAnalysis,
  startSecondaryAnalysis,
}) => {
  useSignals();
  const { hasPermission } = usePermissions();
  const canRunSecondaryAnalysis = hasPermission(Permission.RunSecondaryAnalysis);

  const { activeSecondaryAnalysisViewer } = useSecondaryAnalysisOrchestrationIdQueryParams();
  const [secondaryAnalysisOrchestrationId] =
    useSecondaryAnalysisOrchestrationIdForViewer(activeSecondaryAnalysisViewer);

  const { queryParams } = useEncodedFilters();
  const params = useParams();
  const [caseIdFromParam] = useQueryParam('caseId', NumberParam);
  const caseId = caseIdFromProps
    ? caseIdFromProps
    : queryParams.slidesMode
    ? Number(caseIdFromParam)
    : Number(params.id);

  const [fromStudyFilter] = useQueryParam('fromStudyFilter', StringParam);
  const studyId = first(selectedSlides)?.studyId || params?.studyId || queryParams.filters?.studyId || fromStudyFilter;

  const slideForActiveViewer = find(selectedSlides, { viewerIndex: activeSecondaryAnalysisViewer });
  const slideId = slideForActiveViewer?.id;
  const secondaryExperimentResult = find(slideForActiveViewer?.experimentResults, {
    orchestrationId: secondaryAnalysisOrchestrationId,
  });
  const primaryRunOrchestrationId = secondaryExperimentResult?.primaryRunOrchestrationId;
  const primaryRunExperimentResult = find(slideForActiveViewer?.experimentResults, {
    orchestrationId: primaryRunOrchestrationId,
  });
  const autoInternallyApprove = Boolean(
    primaryRunExperimentResult?.approved || primaryRunExperimentResult?.internallyApproved
  );

  const secondaryAnalysisFeature = secondaryAnalysisPolygons[activeSecondaryAnalysisViewer].value;

  const secondaryAnalysisFeatures = filter(
    secondaryAnalysisFeature?.features,
    (feature) => feature.properties.orchestrationId === secondaryAnalysisOrchestrationId
  );
  const isSecondaryAnalysisActive = Boolean(secondaryAnalysisOrchestrationId);

  const { data: jobsQueryResponse, isLoading: isLoadingOriginalJob } = useJobs({
    enabled: Boolean(secondaryAnalysisOrchestrationId) && canRunSecondaryAnalysis,
    additionalFilters: { orchestrationIds: castArray(secondaryAnalysisOrchestrationId), studyId },
    fullData: true,
  });

  const orchestrationIdJobs = jobsQueryResponse?.jobs;

  if (!isEmpty(orchestrationIdJobs) && size(orchestrationIdJobs) > jobsQueryResponse?.totalJobs) {
    console.error('Got more jobs for orchestration than a single page size, need to implement paginated query', {
      orchestrationIdJobs,
      totalJobs: jobsQueryResponse?.totalJobs,
    });
  }

  const parentJob = find(orchestrationIdJobs, (job) => !job?.parentId);
  const inclusionFeatures = filter(secondaryAnalysisFeatures, (feature) =>
    includes(secondaryAnalysisInclusionModes, feature.properties.featureType)
  );

  const exclusionFeatures = filter(secondaryAnalysisFeatures, (feature) =>
    includes(secondaryAnalysisExclusionModes, feature.properties.featureType)
  );

  const { enqueueSnackbar } = useSnackbar();
  const runSecondaryAnalysis = () => {
    if (!parentJob) {
      console.error('Parent job not found');
      return;
    }
    if (parentJob?.type !== JobType.CalculateFeatures) {
      console.error('Parent job not CalculateFeatures', { parentJob });
      return;
    }
    if (!parentJob.params) {
      console.error('Parent job params not found', { parentJob });
      return;
    }

    const secondaryAnalysisPolygonsToSend: SecondaryAnalysisInput = {
      [caseId]: {
        [slideId]: {
          ...(!isEmpty(inclusionFeatures)
            ? { inclusionMultipolygon: geojsonFeaturesToMultiPolygon(inclusionFeatures) }
            : {}),
          ...(!isEmpty(exclusionFeatures)
            ? { exclusionMultipolygon: geojsonFeaturesToMultiPolygon(exclusionFeatures) }
            : {}),
        },
      },
    };

    const newManifest = { [caseId]: [slideId] };
    const previousCaseInputs = (parentJob as CalculateFeaturesJob).params?.inputs?.[caseId];
    const newInputs: Inputs = { [caseId]: { [slideId]: previousCaseInputs?.[slideId] || [] } };

    const runTime = moment().format('YYYY-MM-DD HH:mm:ss');
    startSecondaryAnalysis({
      jobName: `${parentJob.name} - Secondary Analysis - ${runTime}`,
      jobDescription: `${parentJob.description} - Secondary Analysis - ${runTime}`,
      configParams: {
        secondaryAnalysisPolygons: secondaryAnalysisPolygonsToSend,
        primaryRunOrchestrationId,
        autoInternallyApprove,
        manifest: newManifest,
        inputs: newInputs,
        registrations: (parentJob as CalculateFeaturesJob).params.registrations,
        postprocessing: (parentJob as CalculateFeaturesJob).params.postprocessing,
        features: (parentJob as CalculateFeaturesJob).params.features,
        gridBasedCalculationParams: (parentJob as CalculateFeaturesJob).params.gridBasedCalculationParams,
        visualizations: (parentJob as CalculateFeaturesJob).params.visualizations,
        studyId,
      },
    });

    enqueueSnackbar({
      variant: 'success',
      message: (
        <Grid container>
          <Grid item>
            <Typography>Waiting for Calculate Features to start</Typography>
          </Grid>
          <Grid item>
            <CircularProgress sx={{ marginLeft: 10 }} color="inherit" size={20} />
          </Grid>
        </Grid>
      ),
      key: SNACK_BAR_KEY_SECONDARY_ANALYSIS,
      autoHideDuration: null,
    });
  };

  const confirmWithModal = useConfirmation();

  const selectionType = !isEmpty(inclusionFeatures)
    ? ('inclusion' as const)
    : !isEmpty(exclusionFeatures)
    ? ('exclusion' as const)
    : ('' as const);

  const numberOfSelectedAreas = size(inclusionFeatures) || size(exclusionFeatures);

  const noSelection = isEmpty(secondaryAnalysisFeatures) || !selectionType;

  return (
    <Tooltip
      title={
        isStartingSecondaryAnalysis
          ? 'Running Secondary Analysis'
          : !canRunSecondaryAnalysis
          ? 'You do not have permission to run secondary analysis'
          : isLoadingOriginalJob
          ? 'Loading original job'
          : !parentJob
          ? 'No job found'
          : noSelection
          ? 'No areas selected'
          : ''
      }
    >
      <span>
        <Chip
          disabled={
            isStartingSecondaryAnalysis ||
            !canRunSecondaryAnalysis ||
            isLoadingOriginalJob ||
            !isSecondaryAnalysisActive ||
            noSelection ||
            !parentJob
          }
          onClick={async (e) => {
            e.stopPropagation();
            if (
              await confirmWithModal({
                title: (
                  <Grid container direction="row" columns={12} spacing={1}>
                    <Grid item>
                      <IconWithBadge
                        badgeContent={selectionType === 'inclusion' ? '+' : '-'}
                        sx={{ fontSize: 32, color: selectionType === 'inclusion' ? 'green' : 'red' }}
                        icon={HighlightAltIcon}
                        badgeSize={19}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="h6">
                        Start secondary analysis with {numberOfSelectedAreas} area
                        {numberOfSelectedAreas === 1 ? '' : 's'}{' '}
                        {selectionType === 'inclusion' ? 'selected' : 'removed'}?
                      </Typography>
                    </Grid>
                  </Grid>
                ),
                text: (
                  <Grid item container spacing={2}>
                    <Grid item>
                      <Typography variant="body1">
                        Once started, the recalculation will run in the background and can be monitored on the top of
                        the page.
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">
                        This process may take up to a few minutes for a large WSI. You can continue to work on other
                        tasks while the recalculation is running.
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">
                        Once the recalculation is complete, the results will be available in the results drawer. If you
                        are unhappy with the new results, you can always revert back to the original results and/or
                        recalculate again.
                      </Typography>
                    </Grid>
                  </Grid>
                ),
              })
            ) {
              runSecondaryAnalysis();
            }
          }}
          label={isStartingSecondaryAnalysis ? 'Starting Secondary Analysis...' : 'Run Secondary Analysis'}
          color="primary"
        />
      </span>
    </Tooltip>
  );
};
