import {
  CategoricalChartKey,
  ChartKey,
  caseToFeatureValue,
  cohortToFeatureData,
  cohortToProcedures,
  isCategoricalChartKey,
  isClinicalCategoricalChartKey,
  isNumericalChartKey,
  isProcedureCategoricalChartKey,
  isSlideCategoricalChartKey,
  slideToFeatures,
} from 'components/FeaturesDashboard/chart.util';
import CohortWithQuery, {
  Cohort,
  CohortDefinition,
  CohortWithSelectedFeatures,
  PartialCohort,
} from 'interfaces/cohort_old';
import { Procedure } from 'interfaces/procedure';
import { Slide } from 'interfaces/slide';
import { Study } from 'interfaces/study';
import {
  ValueIteratee,
  compact,
  find,
  flatMap,
  get,
  groupBy,
  head,
  isEmpty,
  keys,
  mapValues,
  partition,
  uniq,
} from 'lodash';
import { pipe } from 'lodash/fp';
import { fetchProcedures } from 'services/procedure';
import { median } from 'simple-statistics';
import { humanize } from './helpers';

export const studyToCohort = (study: Study): CohortDefinition => {
  const cohort = {
    query: async (signal: AbortSignal) => {
      // will use query object later
      const { procedures, dendrogram, survivalAnalysis } = await fetchProcedures(study.id, study.labId, signal);
      return { procedures, dendrogram, survivalAnalysis };
    },
    id: `cohort${study.id}`,
    labId: study.labId,
    name: study.name,
    highlightedFeatures: study.highlightedFeatures,
  };
  return cohort;
};

export const fetchCohort = async (
  cohort: CohortWithQuery,
  signal: AbortSignal
): Promise<CohortWithSelectedFeatures> => {
  const cohortResponse = await cohort.query(signal);
  const { procedures, dendrogram, survivalAnalysis } = cohortResponse || {};

  return {
    ...cohort,
    procedures,
    dendrogram,
    survivalAnalysis,
  };
};

export function isFilledCohort(cohort: Cohort): cohort is CohortWithSelectedFeatures {
  return cohort?.procedures !== undefined;
}

export function firstSlideWithResults(procedure: Procedure) {
  return (
    find(
      procedure?.slides,
      (slide) => !isEmpty(slide.experimentResults) && !isEmpty(head(slide.experimentResults)?.features)
    ) || head(procedure?.slides)
  );
}

export const getSlideGroupingIteratee = (chartKey: CategoricalChartKey): ((slide: Slide) => string) => {
  const { name } = chartKey;
  if (isSlideCategoricalChartKey(chartKey)) {
    return (slide) => get(slide, name);
  }
  return (slide) => `${get(slideToFeatures(slide), name)}`;
};

export const getGroupingIteratee = (chartKey: CategoricalChartKey): ((procedure: Procedure) => string) => {
  if (!chartKey) return null;
  const { name } = chartKey;
  if (isClinicalCategoricalChartKey(chartKey)) {
    return (procedure) => get(procedure?.clinicalData, name);
  }
  if (isProcedureCategoricalChartKey(chartKey)) {
    return (procedure) => get(procedure, name);
  }
  // use firstSlideWithResults(procedure) and with it the iteratee from getSlideGroupingIteratee
  return (procedure) => getSlideGroupingIteratee(chartKey)(firstSlideWithResults(procedure));
};

export const getSlideMap = (categoricalKey: CategoricalChartKey, cohort: CohortWithSelectedFeatures) => {
  return getSlideMapFromProcedures(categoricalKey, cohortToProcedures(cohort));
};

export const getCasesMap = (categoricalKey: CategoricalChartKey, cohort: CohortWithSelectedFeatures) => {
  return getCasesMapFromCases(categoricalKey, cohortToProcedures(cohort));
};

export const getAllGroups = (categoricalKey: CategoricalChartKey, cohorts: CohortWithSelectedFeatures[]) => {
  const allGroups = flatMap(cohorts, (cohort) => keys(getCasesMap(categoricalKey, cohort)));
  return uniq(allGroups);
};

export const getCasesMapFromCases = (categoricalKey: CategoricalChartKey, procedures: Procedure[]) => {
  const casesMap = groupBy(procedures, getGroupingIteratee(categoricalKey));

  return casesMap;
};

export const getSlideMapFromProcedures = (categoricalKey: CategoricalChartKey, procedures: Procedure[]) => {
  let slidesMap: Record<string, Slide[]> = {};

  if (isSlideCategoricalChartKey(categoricalKey)) {
    slidesMap = groupBy(flatMap(procedures, 'slides'), getSlideGroupingIteratee(categoricalKey));
  } else {
    const proceduresMap = getCasesMapFromCases(categoricalKey, procedures);
    slidesMap = mapValues(proceduresMap, (proceduresList) => flatMap(proceduresList, 'slides'));
  }
  return slidesMap;
};

export const splitCohortBy = (cohort: CohortWithSelectedFeatures, chartKey: ChartKey): PartialCohort[] => {
  if (isCategoricalChartKey(chartKey)) {
    const groupingIteratee: ValueIteratee<Procedure> = getGroupingIteratee(chartKey);

    const groupedProcedures = groupBy(cohort.procedures, groupingIteratee);
    return Object.entries(groupedProcedures).map(([key, procedures]) => ({
      id: `${chartKey?.name}-${key}`,
      name: `${cohort?.name} ${humanize(chartKey?.name)} = ${key}`,
      procedures,
    }));
  } else if (isNumericalChartKey(chartKey)) {
    // means that feature is a continuous variable, split it by median

    const data = compact(cohortToFeatureData(cohort, chartKey.name));

    if (isEmpty(data)) {
      return [];
    }

    const dataMedian = median(data);

    const procedures = cohortToProcedures(cohort);

    const [left, right] = partition(
      procedures,
      pipe(caseToFeatureValue(chartKey.name), (value) => value < dataMedian)
    );

    return [
      {
        id: `${chartKey.name}-less`,
        name: `${cohort.name} < 0.5 quantile`,
        procedures: left,
      },
      {
        id: `${chartKey.name}-greater`,
        name: `${cohort.name} > 0.5 quantile`,
        procedures: right,
      },
    ];
  }
  return [];
};
