import { GridColDef, GridRowId } from '@mui/x-data-grid';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import { cloneDeep, findIndex, isEmpty, set } from 'lodash';
import React from 'react';

import { DisplayedField } from 'interfaces/genericFields';
import {
  checkFieldValuesChanged,
  getRowChangesForObjectInArray,
  getRowChangesForUnwoundObjectInArray,
} from 'interfaces/genericFields/fieldValueChanges';
import { UnwoundRow, getFieldValue, unwindRow } from 'interfaces/genericFields/unwindRowsWithInnerArrays';
import { BasicTableRow, GetRowWithChangesFunction, RowChange } from './TableEditingContext/types';

export const generateFieldValueSetter =
  <R extends BasicTableRow, Context extends any = any, UnwoundType extends object | undefined = undefined>({
    field,
    fieldsContext,
    getRowWithChanges,
    shouldApplyBulkChangesToRow,
    applyRowUpdates,
    arrayFieldToUnwind,
    unwoundRowIdField,
    omitUnwoundFields,
    idGetter,
  }: {
    field: DisplayedField<R, any, Context>;
    fieldsContext: Context;
    getRowWithChanges: GetRowWithChangesFunction<R, any>;
    applyRowUpdates: (rowId: string | number, changes: RowChange[]) => void;
    shouldApplyBulkChangesToRow?: (rowId: GridRowId) => boolean;
    arrayFieldToUnwind?: UnwoundType extends undefined ? undefined : keyof R;
    unwoundRowIdField?: UnwoundType extends undefined ? undefined : keyof UnwoundType;
    omitUnwoundFields?: UnwoundType extends undefined ? undefined : Array<keyof UnwoundType>;
    innerIdsToApplyBulkChanges?: Array<number | string>;
    innerIdsToOmitBulkChanges?: Array<number | string>;
    idGetter?: (row: R) => string | number;
  }) =>
  (value: any, row: R, _column: GridColDef<R>, _apiRef: React.MutableRefObject<GridApiCommunity>) => {
    const isUnwoundRowChange = field?.isFieldOfObjectInArray && field.objectArrayPath == arrayFieldToUnwind;

    const unwoundRowMetadata = (row as UnwoundRow<R, UnwoundType>)?._unwoundRowMetadata;
    if (Boolean(unwoundRowMetadata) && !arrayFieldToUnwind) {
      throw new Error(
        'Cannot set a field value on a row that has been unwound without specifying the arrayFieldToUnwind parameter'
      );
    }

    let rowToReturn: UnwoundType extends undefined ? R : UnwoundRow<R, UnwoundType> = row as any;
    const rowChanges: RowChange[] = [];

    const changedRow: UnwoundType extends undefined ? R : UnwoundRow<R, UnwoundType> = row as any;
    const changedValue = value;

    const error =
      field?.getError &&
      field?.getError({
        value: changedValue,
        row: changedRow,
        context: fieldsContext,
      });
    if (error) {
      // Don't apply changes if the field has an error
      return rowToReturn;
    }

    const oldRowWithUnwoundChange = getRowWithChanges(
      row,
      !shouldApplyBulkChangesToRow || shouldApplyBulkChangesToRow(idGetter?.(changedRow) || changedRow.id)
    );

    const innerRowIdx =
      arrayFieldToUnwind && unwoundRowMetadata?.innerRowId
        ? findIndex(
            oldRowWithUnwoundChange[arrayFieldToUnwind] as UnwoundType[],
            (innerRow) => innerRow[unwoundRowIdField || ('id' as keyof UnwoundType)] === unwoundRowMetadata.innerRowId
          )
        : -1;

    const oldRow = cloneDeep(
      unwoundRowMetadata
        ? unwindRow<R, UnwoundType>({
            row: oldRowWithUnwoundChange,
            arrayFieldToUnwind,
            innerRowIdx,
            unwoundRowIdField,
            omitUnwoundFields,
          })
        : oldRowWithUnwoundChange
    );

    if (unwoundRowMetadata) {
      (oldRow as UnwoundRow<R, UnwoundType>)._unwoundRowMetadata = unwoundRowMetadata;
    }

    const baseOldValue = getFieldValue(oldRow, field, fieldsContext);
    const oldValue = isUnwoundRowChange ? (baseOldValue as Array<number | string>)[innerRowIdx] : baseOldValue;

    if (checkFieldValuesChanged(changedValue, oldValue, field.isFieldOfObjectInArray && !isUnwoundRowChange)) {
      if (isUnwoundRowChange) {
        rowChanges.push(
          ...getRowChangesForUnwoundObjectInArray(changedValue, changedRow as UnwoundRow<R, UnwoundType>, oldRow, field)
        );
      } else if (field?.isFieldOfObjectInArray) {
        rowChanges.push(...getRowChangesForObjectInArray(changedValue, changedRow, oldRow, field));
      } else {
        const path = field.updatePath || field.dataKey;
        rowChanges.push({ path, value: changedValue });
      }
    }

    if (!isEmpty(rowChanges)) {
      const baseRowId = unwoundRowMetadata ? unwoundRowMetadata.baseRowId : idGetter?.(changedRow) || changedRow.id;

      applyRowUpdates(baseRowId, rowChanges);

      // Data keys of inner objects don't reflect a field in the row
      // so we need to remove them and apply the changes to a new copy of the row
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [field.dataKey as keyof R]: rowValueForDataKey, ...changedRowWithoutFieldChange } = changedRow;

      const rowWithObjectInArrayChanges = cloneDeep(changedRowWithoutFieldChange);

      for (const { path, value: newValue } of rowChanges) {
        set(rowWithObjectInArrayChanges, path, newValue);
      }

      // Make sure the row's value for the object array is updated
      rowToReturn = rowWithObjectInArrayChanges as any;
    }
    return rowToReturn;
  };
