import { Slide } from 'interfaces/slide';
import React from 'react';
import ImageWithAdjustedLevels, { adjustmentSettingsPresets, getImageBitDepth } from './ImageWithAdjustedLevels';

interface MultiplexSlideImageProps {
  imageUrl: string;
  handleImageError: () => void;
  slide?: Slide;
}

const SlideImage: React.FC<MultiplexSlideImageProps> = ({ imageUrl, handleImageError, slide }) => {
  const bitDepth: 8 | 16 = getImageBitDepth(slide.encoding, imageUrl);
  const colorAdjustmentSettings = adjustmentSettingsPresets.default[bitDepth] || {};

  return (
    <ImageWithAdjustedLevels
      src={imageUrl}
      onError={handleImageError}
      bitDepth={bitDepth}
      {...colorAdjustmentSettings}
    />
  );
};

export default SlideImage;
