import { ExternalLabel, ExternalLabelStudyDetails } from 'interfaces/externalLabel';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export const getExternalLabelOptions = () => {
  return apiRequestHandlerPromise<ExternalLabel[]>({
    url: 'external_labels',
    method: 'GET',
  });
};

export const getExternalLabelDetails = (id: string) => {
  return apiRequestHandlerPromise<ExternalLabelStudyDetails[]>({
    url: `external_labels/${id}`,
    method: 'GET',
  });
};

export const createExternalLabelOption = ({ text }: Omit<ExternalLabel, 'id'>) => {
  return apiRequestHandlerPromise<ExternalLabel>({
    url: 'external_labels',
    method: 'POST',
    data: JSON.stringify({ text }),
  });
};

export const updateExternalLabelOption = ({ id, text }: ExternalLabel) => {
  return apiRequestHandlerPromise({
    url: `external_labels/${id}`,
    method: 'POST',
    data: JSON.stringify({ text }),
  });
};

export const deleteExternalLabelOption = (id: string) => {
  return apiRequestHandlerPromise({
    url: `external_labels/${id}`,
    method: 'DELETE',
  });
};
