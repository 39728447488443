import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Autocomplete, Grid, TextField, useTheme } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import {
  FeatureMetadata,
  FeatureMetadataSecondaryAnalysisEntry,
  FeatureMetadataWithSecondaryAnalysisFlow,
} from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import {
  flattenSecondaryResult,
  isParsedSecondaryAnalysisRun,
  isRunWithoutSecondaryAnalysis,
} from 'components/Procedure/useSlideChannelsAndResults/secondaryAnalysis';
import { Permission } from 'interfaces/permissionOption';
import {
  concat,
  Dictionary,
  filter,
  find,
  first,
  forEach,
  isNumber,
  join,
  keys,
  map,
  orderBy,
  partition,
  size,
  some,
  sortBy,
  uniqBy,
} from 'lodash';
import moment from 'moment';
import React from 'react';
import { JsonParam, useQueryParam } from 'use-query-params';
import { usePermissions } from 'utils/usePermissions';
import {
  LayerVisualizationChange,
  slidesLayerVisualizationSettings,
  useApplyChangesToSlideLayerVisualizationSettings,
} from '../../slidesVisualizationAndConfiguration';

export const useActiveSecondaryAnalysisStates = () =>
  useQueryParam<{
    [slideId: string]: { [viewerIndex: number]: { [primaryRunOrchestrationId: string]: number } };
  }>('activeSecondaryAnalysisStates', JsonParam);

export const getActiveSecondaryAnalysis = ({
  primaryRunResult,
  activeSecondaryAnalysisStates,
  slideId,
  viewerIndex,
}: {
  primaryRunResult: FeatureMetadata;
  slideId: string;
  viewerIndex: number;
  activeSecondaryAnalysisStates: Dictionary<Dictionary<Dictionary<number>>>;
}): FeatureMetadata | null => {
  const allFlattenedResults = isParsedSecondaryAnalysisRun(primaryRunResult)
    ? flattenSecondaryResult(primaryRunResult)
    : [primaryRunResult];
  const [flattenedResultsByCreatedAtDeleted, flattenedResultsByCreatedAtWithoutDeleted] = partition(
    orderBy(allFlattenedResults, ['createdAt'], ['desc']) as FeatureMetadata[],
    'deletedAt'
  );

  const latestResultToDisplay =
    first(flattenedResultsByCreatedAtWithoutDeleted) || first(flattenedResultsByCreatedAtDeleted);

  const selectedSecondaryAnalysisExperimentId =
    activeSecondaryAnalysisStates?.[slideId]?.[viewerIndex]?.[primaryRunResult?.orchestrationId] ||
    latestResultToDisplay?.experimentResultId;

  const featureToReturn: FeatureMetadata =
    (find(allFlattenedResults, { experimentResultId: selectedSecondaryAnalysisExperimentId }) as FeatureMetadata) ||
    latestResultToDisplay ||
    // Should never happen, but just in case
    primaryRunResult;

  return featureToReturn;
};

export const useActiveResultForPrimaryOrchestrationId = ({
  allResults,
  primaryRunOrchestrationId,
  slideId,
  viewerIndex,
  debug,
}: {
  allResults: FeatureMetadata[];
  primaryRunOrchestrationId: string;
  slideId: string;
  viewerIndex: number;
  debug?: boolean;
}) => {
  const resultsWithSecondaryResults = filter(allResults, isParsedSecondaryAnalysisRun);

  const uniqueResultsWithSecondaryResults = uniqBy(resultsWithSecondaryResults, (result) =>
    join(map(sortBy(result.secondaryResults, 'experimentResultId'), 'experimentResultId'), '-')
  );
  if (uniqueResultsWithSecondaryResults.length > 1) {
    console.warn(
      `Slide ${slideId}, primaryRunOrchestrationId ${primaryRunOrchestrationId} ` +
        `has multiple different secondary analysis results assigned to it's features / heatmaps.`,
      {
        uniqueResultsWithSecondaryResults,
        slideId,
        primaryRunOrchestrationId,
        viewerIndex,
        resultsWithSecondaryResults,
      }
    );
  }

  const primaryRunResult = first(uniqueResultsWithSecondaryResults);

  const isSecondaryAnalysis = Boolean(primaryRunResult);

  const [activeSecondaryAnalysisStates] = useActiveSecondaryAnalysisStates();

  const firstResult = first(allResults);
  const activeResult: FeatureMetadata | null =
    isSecondaryAnalysis && primaryRunResult
      ? getActiveSecondaryAnalysis({ primaryRunResult, slideId, viewerIndex, activeSecondaryAnalysisStates })
      : firstResult;

  const orchestrationId = activeResult?.orchestrationId;

  if (
    primaryRunOrchestrationId &&
    primaryRunOrchestrationId !== orchestrationId &&
    primaryRunOrchestrationId !== activeResult?.primaryRunOrchestrationId
  ) {
    console.warn(
      `Slide ${slideId}, primaryRunOrchestrationId ${primaryRunOrchestrationId} ` +
        `does not match the active result's orchestrationId ${orchestrationId} or primaryRunOrchestrationId ${activeResult?.primaryRunOrchestrationId}.`,
      {
        slideId,
        primaryRunOrchestrationId,
        orchestrationId,
        viewerIndex,
        activeResult,
        primaryRunResult,
        deletedAt: activeResult?.deletedAt,
        deletedBy: activeResult?.deletedBy,
      }
    );
  }

  const createdAt = activeResult?.createdAt;

  const publishingStatus = isRunWithoutSecondaryAnalysis(activeResult)
    ? { published: activeResult?.approved, internallyApproved: activeResult?.internallyApproved }
    : {
        published: false,
        internallyApproved: false,
        ...((activeResult as FeatureMetadataWithSecondaryAnalysisFlow)?.secondaryAnalysisPublishingStatus || {}),
      };

  if (debug) {
    console.debug({
      slideId,
      allResults,
      primaryRunOrchestrationId,
      orchestrationId,
      createdAt,
      publishingStatus,
      viewerIndex,
      activeResult,
      primaryRunResult,
    });
  }

  return {
    orchestrationId,
    isSecondaryAnalysis,
    createdAt,
    publishingStatus,
    primaryRunResult,
    activeResult,
    deletedAt: activeResult?.deletedAt,
    deletedBy: activeResult?.deletedBy,
  };
};

export const getExperimentResultLabel = (
  result: Pick<
    FeatureMetadata,
    'primaryRunOrchestrationId' | 'createdAt' | 'displayName' | 'orchestrationId' | 'deletedAt' | 'deletedBy'
  >,
  canSeeOrchestrationId: boolean,
  primaryJobName?: string
) => {
  const displayName = result?.displayName || primaryJobName;
  const createdAt = moment(result?.createdAt).format('YYYY-MM-DD HH:mm');
  const orchestrationId = canSeeOrchestrationId && result?.orchestrationId;
  const deletedAt = result?.deletedAt ? moment(result?.deletedAt).format('YYYY-MM-DD HH:mm') : undefined;
  return `${result?.primaryRunOrchestrationId ? 'Recalculated' : 'Primary'} - ${createdAt}${
    displayName ? ` - ${displayName}` : ''
  }${orchestrationId ? ` - ${orchestrationId}` : ''}${
    deletedAt ? ` (hidden on ${deletedAt}${result?.deletedBy ? ` by ${result?.deletedBy}` : ''})` : ''
  }`;
};

export const ActiveSecondaryAnalysisResultsSelect: React.FC<{
  primaryRunResult: FeatureMetadata;
  primaryJobName?: string;
  slideId: string;
  stainTypeId: string;
  viewerIndex: number;
  internalFeatures?: boolean;
}> = ({ primaryRunResult, slideId, viewerIndex, stainTypeId, internalFeatures, primaryJobName }) => {
  useSignals();
  const theme = useTheme();

  const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[viewerIndex]?.value;
  const { hasPermission } = usePermissions();
  const canPublishResults = hasPermission(Permission.PublishResults);
  const canSeeOrchestrationId = hasPermission(Permission.SeeOrchestrationId);
  const [activeSecondaryAnalysisStates, setActiveSecondaryAnalysisStates] = useActiveSecondaryAnalysisStates();
  const currentResult = primaryRunResult
    ? getActiveSecondaryAnalysis({ primaryRunResult, activeSecondaryAnalysisStates, slideId, viewerIndex })
    : undefined;
  const flattenedResults = isParsedSecondaryAnalysisRun(primaryRunResult)
    ? flattenSecondaryResult(primaryRunResult)
    : [primaryRunResult];
  const hasDeletedResults = Boolean(some(flattenedResults, 'deletedAt'));
  const allFlattenedResultsByCreatedAt = React.useMemo(
    () =>
      orderBy(
        canPublishResults
          ? flattenedResults
          : filter(flattenedResults, (result: FeatureMetadata) =>
              isParsedSecondaryAnalysisRun(result)
                ? result?.secondaryAnalysisPublishingStatus?.published ||
                  result?.secondaryAnalysisPublishingStatus?.internallyApproved
                : result?.approved || result?.internallyApproved
            ),
        ['createdAt'],
        ['desc']
      ) as Array<FeatureMetadata | FeatureMetadataSecondaryAnalysisEntry>,
    [primaryRunResult, canPublishResults]
  );
  const resultOptions = React.useMemo(() => {
    const [flattenedResultsByCreatedAtDeleted, flattenedResultsByCreatedAtWithoutDeleted] = partition(
      allFlattenedResultsByCreatedAt,
      'deletedAt'
    );
    return map(concat(flattenedResultsByCreatedAtWithoutDeleted, flattenedResultsByCreatedAtDeleted), (result) => ({
      value: result?.experimentResultId,
      label: getExperimentResultLabel(result, canSeeOrchestrationId, primaryJobName),
      deleted: Boolean(result?.deletedAt),
    }));
  }, [allFlattenedResultsByCreatedAt, primaryJobName, canSeeOrchestrationId]);

  const applyChangesToSlideLayerVisualizationSettings = useApplyChangesToSlideLayerVisualizationSettings();

  // Switch the heatmap settings to the new experiment result
  const switchHeatmapSettings = (experimentResultId: number) => {
    const previousHeatmapExperimentResultId = currentResult?.experimentResultId ?? primaryRunResult?.experimentResultId;

    if (!isNumber(previousHeatmapExperimentResultId) || isNaN(previousHeatmapExperimentResultId)) {
      console.warn(
        `Slide ${slideId}, viewerIndex ${viewerIndex} has an invalid experimentResultId ${previousHeatmapExperimentResultId}.`
      );
    }

    const previousExperimentResultIdPartInKey = `[${previousHeatmapExperimentResultId}]`;

    const previousHeatmapIds = filter(keys(viewerSlideLayerVisualizationSettings?.[slideId]), (key) =>
      key?.includes(previousExperimentResultIdPartInKey)
    );

    const changes: LayerVisualizationChange[] = [];

    forEach(previousHeatmapIds, (previousHeatmapId) => {
      const newHeatmapId = previousHeatmapId.replace(previousExperimentResultIdPartInKey, `[${experimentResultId}]`);
      changes.push({
        layerId: newHeatmapId,
        newSettings: viewerSlideLayerVisualizationSettings?.[slideId]?.[previousHeatmapId]?.value,
      });
      changes.push({
        layerId: previousHeatmapId,
        newSettings: { show: false },
      });
    });

    applyChangesToSlideLayerVisualizationSettings({
      slideId,
      viewerIndex,
      changes,
      stainTypeId,
      changeFlow: 'secondary analysis select',
    });
  };

  return (
    size(resultOptions) > 1 && (
      <Grid
        item
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <Autocomplete
          sx={
            !internalFeatures
              ? {
                  backgroundColor: theme.palette.primary.main,
                  '& .MuiAutocomplete-inputRoot .MuiAutocomplete-endAdornment svg': {
                    color: 'white',
                  },
                  '& .MuiInput-root.MuiAutocomplete-inputRoot input': {
                    color: 'white',
                    paddingInlineStart: '4px',
                  },
                }
              : undefined
          }
          value={find(resultOptions, { value: currentResult?.experimentResultId ?? -1 })}
          onChange={(event, newValue) => {
            setActiveSecondaryAnalysisStates({
              ...activeSecondaryAnalysisStates,
              [slideId]: {
                ...activeSecondaryAnalysisStates?.[slideId],
                [viewerIndex]: {
                  ...activeSecondaryAnalysisStates?.[slideId]?.[viewerIndex],
                  [primaryRunResult.orchestrationId]: newValue?.value,
                },
              },
            });
            switchHeatmapSettings(newValue?.value ?? primaryRunResult?.experimentResultId);
          }}
          renderInput={(inputParams) => <TextField {...inputParams} fullWidth size="small" variant="standard" />}
          getOptionLabel={(option) => option.label}
          options={resultOptions}
          groupBy={hasDeletedResults ? (option) => (option.deleted ? 'Deleted' : 'Available') : undefined}
          clearIcon={<RestartAltIcon />}
          clearText={internalFeatures ? 'Return to approved results' : 'Return to published results'}
        />
      </Grid>
    )
  );
};
