import {
  Box,
  Button,
  Collapse,
  FormControl,
  FormHelperText,
  Grid,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import SavePreset from 'components/atoms/PresetSection/SavePreset';
import { every, isEmpty, slice } from 'lodash';
import React, { ReactNode, useState } from 'react';

export interface PlatformStepperProps {
  validateStep?: () => Promise<boolean>;
  handleSubmit: () => void;
  setActiveStepForValidation: React.Dispatch<React.SetStateAction<number>>;
  handleSaveAsPreset?: (name: string) => void;
  isStepFailed?: Record<number, boolean>;
  steps: {
    content: ReactNode;
    label: string;
    subLabel?: string;
    optional?: boolean;
    skip?: boolean;
    onNextOrBackClick?: () => void;
  }[];
}

export const PlatformStepper: React.FC<React.PropsWithChildren<PlatformStepperProps>> = ({
  validateStep,
  steps,
  handleSubmit,
  setActiveStepForValidation,
  handleSaveAsPreset,
  isStepFailed,
}) => {
  const [isAddingPreset, setIsAddingPreset] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const allStepsValid = every(isStepFailed, (isFailed) => !isFailed);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleFinish = async () => {
    setIsSubmitting(true);
    try {
      const isStepValid = isEmpty(validateStep) ? true : await validateStep();
      if (isStepValid) {
        await handleSubmit();
        if (activeStep === steps.length - 1) {
          setActiveStep((prevActiveStep) => {
            setActiveStepForValidation(prevActiveStep + nextStepCount());
            return prevActiveStep + nextStepCount();
          });
        }
      }
      setIsSubmitting(false);
    } catch (error) {
      console.error(error);
      setActiveStep(0);
      setIsSubmitting(false);
      throw error;
    }
  };

  const nextStepCount = () => {
    let nextStepsLength = 1;

    for (let i = activeStep + 1; i < steps.length; i++) {
      if (steps[i]?.skip) {
        nextStepsLength++;
      } else {
        break;
      }
    }

    return nextStepsLength;
  };

  const handleNext = async () => {
    const isStepValid = isEmpty(validateStep) ? true : await validateStep();
    if (isStepValid) {
      steps[activeStep]?.onNextOrBackClick?.();
      if (activeStep === steps.length - 1) {
        handleSubmit();
      } else {
        setActiveStep((prevActiveStep) => {
          setActiveStepForValidation(prevActiveStep + nextStepCount());
          return prevActiveStep + nextStepCount();
        });
      }
    }
  };

  const backStepCount = () => {
    let nextStepsLength = 1;

    for (let i = activeStep - 1; i >= 0; i--) {
      if (steps[i]?.skip) {
        nextStepsLength++;
      } else {
        break;
      }
    }

    return nextStepsLength;
  };

  const handleBack = () => {
    steps[activeStep]?.onNextOrBackClick?.();

    setActiveStep((prevActiveStep) => {
      setActiveStepForValidation(prevActiveStep - backStepCount());
      return prevActiveStep - backStepCount();
    });
  };

  return (
    <Box>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              error={activeStep !== index && isStepFailed?.[index]}
              optional={step.optional ? <Typography variant="caption">Optional</Typography> : null}
            >
              <Typography>{step.label}</Typography>
              <Typography variant="caption"> {step.subLabel}</Typography>
            </StepLabel>
            <StepContent>
              {step.content}
              <Box sx={{ mb: 2 }}>
                <Grid container wrap="nowrap" spacing={2} justifyContent="space-between">
                  <Grid container item alignItems="baseline">
                    {index !== steps.length - 1 && (
                      <Button variant="contained" onClick={handleNext} sx={{ mt: 1, mr: 1 }}>
                        Continue
                      </Button>
                    )}
                    <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                      Back
                    </Button>
                  </Grid>
                  <Grid item container alignItems="start" justifyContent="end" mt={1} mr={1}>
                    {Boolean(handleSaveAsPreset) && (
                      <Grid item container xs={4}>
                        <Collapse in={!isAddingPreset}>
                          <Button
                            disabled={false}
                            onClick={() => {
                              setIsAddingPreset(true);
                            }}
                          >
                            Save as preset...
                          </Button>
                        </Collapse>
                        <SavePreset
                          isAddingPreset={isAddingPreset}
                          setIsAddingPreset={setIsAddingPreset}
                          isLoading={false}
                          savePreset={handleSaveAsPreset}
                        />
                      </Grid>
                    )}
                    {(index === steps.length - 1 || every(slice(steps, activeStep + 1), 'optional')) && (
                      <Grid item xs={2}>
                        <FormControl
                          fullWidth
                          variant="standard"
                          error={!allStepsValid}
                          className={!allStepsValid ? 'error' : undefined}
                        >
                          <Button disabled={!allStepsValid || isSubmitting} variant="contained" onClick={handleFinish}>
                            Finish
                          </Button>
                          {!allStepsValid && (
                            <FormHelperText error id="class-does-not-exist-error-text">
                              There are uncompleted steps
                            </FormHelperText>
                          )}
                          {isSubmitting && <FormHelperText>Submitting...</FormHelperText>}
                        </FormControl>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Grid container sx={{ mt: 1, ml: 1 }} spacing={1}>
          <Grid item width="100%" justifyItems="center">
            <Typography>All steps completed - you&apos;re finished</Typography>
          </Grid>
          <Grid item>
            <Button onClick={handleBack}>Back</Button>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
