import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Accordion, AccordionDetails, AccordionSummary, Grid, Link, Skeleton, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getExternalLabelDetails } from 'api/externalLabels';
import CopyableText from 'components/atoms/CopyableText';
import { ExternalLabel, ExternalLabelStudyDetails } from 'interfaces/externalLabel';
import { isEmpty, map, size, sum, uniq } from 'lodash';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigationToViewerPage } from 'utils/useNavigationToViewerPage';
import SlideLabel from './SlideLabel';

interface ExternalLabelsDetailsProps {
  externalLabel: ExternalLabel;
}

export const ExternalLabelDetails: React.FC<ExternalLabelsDetailsProps> = ({ externalLabel }) => {
  const {
    data: externalLabelDetails,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['externalLabelDetails', externalLabel.id],
    queryFn: () => getExternalLabelDetails(externalLabel.id),
    enabled: Boolean(externalLabel.id),
  });

  return (
    <>
      {!externalLabel.id ? (
        <Typography>Select label to see the details</Typography>
      ) : isLoading ? (
        <>
          <Skeleton variant="text" width="100%" height={40} />
          <Skeleton variant="text" width="100%" height={40} />
          <Skeleton variant="text" width="100%" height={40} />
          <Skeleton variant="text" width="100%" height={40} />
        </>
      ) : isError ? (
        <Typography>Error loading external label details</Typography>
      ) : (
        <Grid container direction="column" spacing={1}>
          <LabelSummary externalLabelDetails={externalLabelDetails} />
          <Grid item>
            {map(externalLabelDetails, (studyDetails) => (
              <StudyDetails key={studyDetails.studyId} externalLabelStudyDetails={studyDetails} />
            ))}
          </Grid>
        </Grid>
      )}
    </>
  );
};

interface LabelSummaryProps {
  externalLabelDetails: ExternalLabelStudyDetails[];
}

const LabelSummary: React.FC<LabelSummaryProps> = ({ externalLabelDetails }) => {
  const totalSlidesWithLabelCount = sum(map(externalLabelDetails, (studyDetails) => size(studyDetails.slideIds)));
  const totalStudiesWithLabelCount = size(externalLabelDetails);
  const totalLabsWithLabelCount = size(uniq(map(externalLabelDetails, 'labId')));

  return (
    <Grid item container direction="column" spacing={1}>
      <Grid item>
        <Typography variant="body1" fontWeight="bold">
          Summary
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">Total Labs: {totalLabsWithLabelCount}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">Total Studies: {totalStudiesWithLabelCount}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">Total Slides: {totalSlidesWithLabelCount}</Typography>
      </Grid>
    </Grid>
  );
};

interface StudyDetailsProps {
  externalLabelStudyDetails: ExternalLabelStudyDetails;
}

const StudyDetails: React.FC<StudyDetailsProps> = ({ externalLabelStudyDetails }) => {
  const { studyId, studyName, labId, labName, slideIds } = externalLabelStudyDetails;
  const { getUrlToSlidePage } = useNavigationToViewerPage();

  const slidesCount = size(slideIds);

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="body1">
          {labName} - {studyName} - ({slidesCount} slide{slidesCount != 1 ? 's' : ''})
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="column" flexWrap="nowrap" spacing={1}>
          <Grid item container spacing={1}>
            <Grid item>
              <Typography variant="body1" fontWeight="bold">
                Lab:
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">{labName}</Typography>
            </Grid>
          </Grid>
          <Grid item container spacing={1}>
            <Grid item>
              <Typography variant="body1" fontWeight="bold">
                Lab ID:
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <CopyableText text={labId} />
            </Grid>
          </Grid>
          <Grid item container spacing={1}>
            <Grid item>
              <Typography variant="body1" fontWeight="bold">
                Study:
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1"> {studyName}</Typography>
            </Grid>
          </Grid>
          <Grid item container spacing={1} width="100%">
            <Grid item>
              <Typography variant="body1" fontWeight="bold">
                Study ID:
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <CopyableText text={studyId} />
            </Grid>
          </Grid>
          <Grid item>
            <SlideLabel label={externalLabelStudyDetails.label} title="Label Configuration:" />
          </Grid>
          <Grid item>
            {isEmpty(slideIds) ? (
              <Typography variant="body1" fontWeight="bold">
                No slide IDs with this label in this study
              </Typography>
            ) : (
              <Accordion defaultExpanded={true}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="body1">Slide IDs ({size(slideIds)})</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {map(uniq(slideIds), (slideId) => (
                    <Grid item key={slideId}>
                      <Link
                        to={getUrlToSlidePage({ slideId, labId, caseStudyId: studyId })}
                        target="_blank"
                        rel="noopener noreferrer"
                        component={RouterLink}
                      >
                        <Typography variant="caption" key={slideId}>
                          {slideId}
                        </Typography>
                      </Link>
                    </Grid>
                  ))}
                </AccordionDetails>
              </Accordion>
            )}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
