import { DeckGLProps } from '@deck.gl/react/typed';
import { signal } from '@preact/signals-react';
import { times } from 'lodash';

import { MAX_VIEWERS } from 'components/Procedure/SlidesViewer/constants';
import dayjs from 'dayjs';

export const viewerLastRenderTime = times(MAX_VIEWERS, () => signal<number>(0));

export const updateLastRenderTimeHandlers = times(MAX_VIEWERS, (viewerIndex) => () => {
  viewerLastRenderTime[viewerIndex].value = dayjs().valueOf();
});

export const viewerHoverData = times(MAX_VIEWERS, () => signal<Parameters<DeckGLProps['onHover']> | null>(null));

export const onHoverHandlers: DeckGLProps['onHover'][] = times(MAX_VIEWERS, (viewerIndex) => (info, event) => {
  const hoverDataSignal = viewerHoverData[viewerIndex];
  if (hoverDataSignal) {
    hoverDataSignal.value = [info, event];
  }
});

export const viewerClickData = times(MAX_VIEWERS, () => signal<Parameters<DeckGLProps['onClick']> | null>(null));

export const onClickHandlers: DeckGLProps['onClick'][] = times(MAX_VIEWERS, (viewerIndex) => (info, event) => {
  const clickDataSignal = viewerClickData[viewerIndex];
  if (clickDataSignal) {
    clickDataSignal.value = [info, event];
  }
});
