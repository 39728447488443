import { useQuery } from '@tanstack/react-query';
import { getInternalUsers } from 'api/userCredentials';
import { keyBy } from 'lodash';
import { useMemo } from 'react';

export const useInternalUsersById = ({ enabled = true }: { enabled?: boolean } = {}) => {
  const { data: internalUsers, isLoading: isLoadingInternalUsers } = useQuery(['internalUsers'], getInternalUsers, {
    enabled,
  });
  const internalUsersById = useMemo(() => keyBy(internalUsers, 'id'), [internalUsers]);
  return { internalUsersById, isLoadingInternalUsers };
};

export default useInternalUsersById;
