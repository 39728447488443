import { ScannerSlideVisualizationPreset } from 'interfaces/scannerSlideVisualizationPreset';
import { stringify } from 'qs';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export const getScannerSlideVisualizationPreset = async (
  scannerManufacturer: string
): Promise<ScannerSlideVisualizationPreset> => {
  try {
    return await apiRequestHandlerPromise<ScannerSlideVisualizationPreset>({
      url: `scanner-slide-visualization-presets?${stringify({ scannerManufacturer })}`,
      method: 'GET',
    });
  } catch (error) {
    if (error.status === 404) {
      return {
        scannerManufacturer,
        visualizationPreset: {},
      };
    }
    throw error;
  }
};
