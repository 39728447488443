import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { MutationKey, useMutation, useQuery } from '@tanstack/react-query';
import { find, first, map, orderBy, size, some } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';

import { getCustomerResultsJobs } from 'api/customerResults';
import { JobDetails } from 'components/Pages/Jobs/JobDetails';
import { Permission } from 'interfaces/permissionOption';
import { exportCustomerResults } from 'utils/exports';
import useInternalUsersById from 'utils/queryHooks/useInternalUsersById';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { usePermissions } from 'utils/usePermissions';

interface SelectPrepareJobToExportModalProps {
  open: boolean;
  onClose: () => void;
  studyId: string;
}

const SelectPrepareJobToExportModal: React.FC<SelectPrepareJobToExportModalProps> = ({ open, onClose, studyId }) => {
  const { hasPermission } = usePermissions();
  const canViewUnpublishedResults = hasPermission(Permission.ViewUnpublishedResults);
  const { labId } = useCurrentLabId();

  const [jobIdSelection, setJobIdSelection] = useState<string | null>(null);
  const [onlyReturnFromWholeStudyJobs, setIncludeFilteredJobs] = useState(true);

  const isLatestJobWholeStudy = !jobIdSelection && onlyReturnFromWholeStudyJobs;
  const isLatestJobWithFiltersSelected = !jobIdSelection && !onlyReturnFromWholeStudyJobs;

  const selectedCustomerResultOption = isLatestJobWholeStudy
    ? 'LATEST_WHOLE_STUDY'
    : isLatestJobWithFiltersSelected
    ? 'LATEST_INCLUDING_FILTERED'
    : jobIdSelection;

  const { data: customerResultsJobs, isLoading: isLoadingCustomerResultsJobs } = useQuery({
    queryKey: ['customerResultsJobs', { studyId, labId }],
    queryFn: ({ signal }) => getCustomerResultsJobs({ studyId, labId }, signal),
    enabled: open,
  });

  const { internalUsersById } = useInternalUsersById({
    enabled: open && some(customerResultsJobs, 'userId'),
  });

  const orderedJobs = orderBy(customerResultsJobs, 'startedAt', 'desc');

  const prepareCustomerResultsOptions = [
    {
      label: 'Latest prepared results (exported for the whole study)',
      value: 'LATEST_WHOLE_STUDY',
      job: find(orderedJobs, (job) => !job.params.queryObject),
    },
    {
      label: 'Latest prepared results (including not exported for the whole study)',
      value: 'LATEST_INCLUDING_FILTERED',
      job: first(orderedJobs),
    },
    ...map(orderedJobs, (job) => ({
      label: `${moment(job.startedAt).format('YYYY-MM-DD HH:mm:ss')} - ${
        job.userId && canViewUnpublishedResults
          ? `Run by ${internalUsersById?.[job.userId]?.name || job.userId} - `
          : ''
      }${job.id}`,
      value: job.id,
      job,
    })),
  ];

  const selectedJobOption = find(
    prepareCustomerResultsOptions,
    (option) => option.value === selectedCustomerResultOption
  );

  const jobId = selectedJobOption?.job?.id;

  const downloadCoordinatesMutation = useMutation({
    mutationKey: ['downloadCoordinates', { labId, studyId }] as MutationKey,
    mutationFn: () => exportCustomerResults({ labId, studyId, jobId }),
  });

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>Prepare Customer Results</DialogTitle>
      <DialogContent dividers>
        <InputLabel>
          Select the job results to export
          {isLoadingCustomerResultsJobs ? ` (Loading...)` : ` (${size(customerResultsJobs)} jobs found)`}
        </InputLabel>
        <Select
          value={selectedCustomerResultOption}
          onChange={(event) => {
            if (event.target.value === 'LATEST_INCLUDING_FILTERED') {
              setJobIdSelection(null);
              setIncludeFilteredJobs(true);
              return;
            } else if (event.target.value === 'LATEST_WHOLE_STUDY') {
              setJobIdSelection(null);
              setIncludeFilteredJobs(false);
              return;
            } else {
              setJobIdSelection(event.target.value as string);
              setIncludeFilteredJobs(true);
            }
          }}
        >
          {map(prepareCustomerResultsOptions, (option) => (
            <MenuItem key={option.value} value={option.value}>
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h3">Job Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {isLoadingCustomerResultsJobs ? (
              <Typography variant="body1">Loading...</Typography>
            ) : selectedJobOption ? (
              selectedJobOption?.job ? (
                <JobDetails job={selectedJobOption?.job} />
              ) : (
                <Typography variant="body1">No job details available</Typography>
              )
            ) : (
              <Typography variant="body1">No job selected</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Cancel</Button>
        <Button
          variant="contained"
          onClick={() => {
            onClose();
            downloadCoordinatesMutation.mutate();
          }}
          disabled={!jobId || downloadCoordinatesMutation.isLoading}
        >
          Download
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectPrepareJobToExportModal;
