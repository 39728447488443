import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WarningIcon from '@mui/icons-material/Warning';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Link, Typography } from '@mui/material';
import CohortQueryList from 'components/SearchFilters/CohortQueryList';
import { compact, isEmpty, join, map, some, toString, uniq } from 'lodash';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { PrepareCustomerResultsJobWithUnpublishedExperimentResultIds } from 'api/customerResults';
import CohortQueryListItem from 'components/SearchFilters/CohortQueryListItem';
import { isLegacyPrepareCustomerResultsManifest, PrepareCustomerResultsParams } from 'interfaces/job';
import { Permission } from 'interfaces/permissionOption';
import { humanize } from 'utils/helpers';
import { manualSelectionFilters } from 'utils/useCasesParams';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { useNavigationToViewerPage } from 'utils/useNavigationToViewerPage';
import { usePermissions } from 'utils/usePermissions';
import { PrepareCustomerResultsJobManifestTable } from './PrepareCustomerResultsJobManifestTable';
import { PrepareCustomerResultsJobSnapshotTable } from './PrepareCustomerResultsJobSnapshotTable';
import { stringToDisplay } from './utils';

const rawValueFields: Array<keyof PrepareCustomerResultsParams> = [
  'numOfCasesInZip',
  'resultTypesToExport',
  'canViewInternalLabels',
];

export const PrepareCustomerResultsJobParams: React.FC<{
  job: PrepareCustomerResultsJobWithUnpublishedExperimentResultIds;
}> = ({ job }) => {
  const { hasPermission } = usePermissions();
  const isNucleaiUser = hasPermission(Permission.UseNucleaiExperimentalFeatures);
  const manifest = job?.manifest;
  const queryObject = job?.params?.queryObject;

  const hasSpecificSelections = some(manualSelectionFilters, (filterName) => !isEmpty(queryObject?.[filterName]));

  const snapshot = job?.params?.snapshot;

  const unpublishedExperimentResultIds = compact(job?.unpublishedExperimentResultIds);

  const { getUrlToSlidePage, getUrlToCasePage } = useNavigationToViewerPage();

  const { labId } = useCurrentLabId();

  return (
    <>
      {map(rawValueFields, (fieldName) => {
        const value = job?.params?.[fieldName];
        return (
          value && (
            <Grid item key={fieldName}>
              <Grid item>
                <Typography variant="body1">{humanize(fieldName)}: </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">{toString(stringToDisplay(value))}</Typography>
              </Grid>
            </Grid>
          )
        );
      })}
      {queryObject && (
        <Grid item marginBlock={2}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="body1">Filters:</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ 'overflow-x': 'auto' }}>
              {hasSpecificSelections ? (
                map(
                  manualSelectionFilters,
                  (filterName) =>
                    !isEmpty(queryObject?.[filterName]) && (
                      <CohortQueryListItem
                        showIcon
                        key={filterName}
                        itemKey={filterName}
                        value={`${humanize(filterName)}: ${join(queryObject?.[filterName], ', ')}`}
                      />
                    )
                )
              ) : (
                <CohortQueryList queryObject={queryObject} showIcon />
              )}
            </AccordionDetails>
          </Accordion>
        </Grid>
      )}
      {snapshot &&
        (isEmpty(snapshot) ? (
          <Grid item>
            <Grid item>
              <Typography variant="body1">Query Snapshot: </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">None</Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid item marginBlock={2}>
            <PrepareCustomerResultsJobSnapshotTable snapshot={snapshot} studyId={job?.studyId} />
          </Grid>
        ))}
      {manifest &&
        (isEmpty(manifest) ? (
          <Grid item>
            <Grid item>
              <Typography variant="body1">Results for export:</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">None</Typography>
            </Grid>
          </Grid>
        ) : isLegacyPrepareCustomerResultsManifest(manifest) ? (
          <Grid item marginBlock={2}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid item container>
                  <Grid item>
                    <Typography variant="body1">Slide IDs</Typography>
                  </Grid>
                  {isNucleaiUser && (
                    <Grid item container direction="row" alignItems="center" mt={1} spacing={1}>
                      <Grid item xs={1} mr={1}>
                        <WarningIcon />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="caption">
                          This is a legacy export - cannot verify if results are still published
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                {map(uniq(manifest), (slideId) => (
                  <Grid item key={slideId}>
                    <Link
                      to={getUrlToSlidePage({ slideId, labId, caseStudyId: job?.studyId })}
                      target="_blank"
                      rel="noopener noreferrer"
                      component={RouterLink}
                    >
                      <Typography variant="caption" key={slideId}>
                        {slideId}
                      </Typography>
                    </Link>
                  </Grid>
                ))}
              </AccordionDetails>
            </Accordion>
          </Grid>
        ) : (
          <Grid item marginBlock={2}>
            <PrepareCustomerResultsJobManifestTable
              manifest={manifest}
              studyId={job?.studyId}
              unpublishedExperimentResultIds={unpublishedExperimentResultIds}
            />
          </Grid>
        ))}
    </>
  );
};
