import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { DataGrid, GridRowParams, GridRowSelectionModel } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import {
  getCasesOrchestrations,
  getSlidesOrchestrations,
  Orchestration,
  OrchestrationResponse,
  SlideResult,
} from 'api/experimentResults';
import Loader from 'components/Loader';
import useMainFilters from 'components/SearchFilters/hooks/useMainFilters';
import { bulkPublishableFlowClasses, ResultsMode, SlideResultsSummaryData } from 'interfaces/experimentResults';
import { filter, find, flatMap, get, includes, isEmpty, map, orderBy, some, uniqBy } from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import { useCasesParams } from '../../../../../utils/useCasesParams';
import OrchestrationView from './OrchestrationView';

interface ApproveResultsFormProps {
  onClose: () => void;
}

const resultOverride = { resultsMode: ResultsMode.Manual };

const ApproveResultsForm: FunctionComponent<React.PropsWithChildren<ApproveResultsFormProps>> = ({ onClose }) => {
  const {
    queryParams: { filters, slidesMode },
  } = useEncodedFilters();
  const { isStudyIdSpecific } = useMainFilters();
  const studyIdIsSpecific = isStudyIdSpecific(filters.studyId);

  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [selectableRowIds, setSelectableRowIds] = useState<string[]>([]);
  const [expandedOrchestrationId, setExpandedOrchestrationId] = useState<string | null>(null);

  const { casesParams } = useCasesParams(resultOverride);

  const {
    data: orchestrationData,
    isLoading,
    isError,
  } = useQuery<OrchestrationResponse>(
    ['orchestrations', casesParams],
    () => {
      if (!slidesMode) {
        return getCasesOrchestrations(casesParams);
      }
      return getSlidesOrchestrations(casesParams);
    },
    {
      enabled: studyIdIsSpecific,
    }
  );

  const studyId = get(filters, 'studyId', undefined);

  if (isLoading)
    return (
      <Box>
        <Loader />
      </Box>
    );
  if (isError) return <Box>Error...</Box>;
  if (!studyIdIsSpecific) return <Box>Must be viewing on a specific study</Box>;

  // For the first step we only show calculated features results
  const calculatedFeaturesOrchestrationData: Orchestration[] =
    map(
      filter(orchestrationData?.orchestrations, (orchestration) =>
        some(orchestration.slidesData, (slideData) => includes(bulkPublishableFlowClasses, slideData.flowClassName))
      ) || [],
      (orchestration) => ({
        ...orchestration,
        slidesData: filter(orchestration.slidesData, (slideData) =>
          includes(bulkPublishableFlowClasses, slideData.flowClassName)
        ),
      })
    ) || [];

  const orchestrationDataBySlide: SlideResult[] = flatMap(
    calculatedFeaturesOrchestrationData,
    (orchestration) => orchestration.slidesData
  );
  const approvedSlidesData: SlideResult[] = filter(
    orchestrationDataBySlide,
    (slideData) => slideData.internallyApproved == true || slideData.approved == true
  );

  const slideFlowClassResults: SlideResult[] = uniqBy(
    orchestrationDataBySlide,
    (slideData) => `${slideData.slideId} ${slideData.flowClassName}`
  );

  const slideResultsSummary: SlideResultsSummaryData[] = map(
    slideFlowClassResults,
    ({ slideId, flowClassName, caseId, caseName }) => {
      return {
        slideId,
        caseId,
        caseName,
        flowClassName,
        publishedOrchestrationId: find(approvedSlidesData, { slideId, flowClassName, approved: true })?.orchestrationId,
      };
    }
  );

  const onOrchestrationOpen = (orchestrationId: string) => {
    setExpandedOrchestrationId(orchestrationId);
    const orchestration = find(calculatedFeaturesOrchestrationData, { orchestrationId });
    if (orchestration) {
      const newSelectedRowIds = map(
        orchestration.slidesData,
        (result) => `${result?.slideId || ''} ${result?.flowClassName || ''}`
      );
      setSelectableRowIds(newSelectedRowIds);
      setRowSelectionModel(newSelectedRowIds);
    }
  };

  const onOrchestrationClose = () => {
    setExpandedOrchestrationId(null);
    setSelectableRowIds([]);
    setRowSelectionModel([]);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Box sx={{ overflowY: 'auto' }} height={'80vh'}>
          {!isEmpty(calculatedFeaturesOrchestrationData) ? (
            map(orderBy(calculatedFeaturesOrchestrationData, 'createdAtMinimum', 'desc'), (orchestration) => (
              <OrchestrationView
                key={orchestration.orchestrationId}
                orchestration={orchestration}
                studyId={studyId}
                selectedSlideIds={map(
                  rowSelectionModel,
                  (rowId) =>
                    find(
                      slideResultsSummary,
                      (result) => `${result.slideId || ''} ${result.flowClassName || ''}` === rowId
                    )?.slideId
                )}
                approvedSlideResults={approvedSlidesData}
                expanded={expandedOrchestrationId === orchestration.orchestrationId}
                onOpen={onOrchestrationOpen}
                onClose={onOrchestrationClose}
              />
            ))
          ) : (
            <Typography variant="subtitle1">No orchestrations found</Typography>
          )}
        </Box>
      </Grid>
      <Grid item xs={6}>
        <DataGrid
          sx={{ height: '80vh' }}
          rows={slideResultsSummary}
          getRowId={(row) => `${row.slideId || ''} ${row.flowClassName || ''}`}
          columns={[
            { field: 'caseName', headerName: 'Case Name', width: 100 },
            { field: 'slideId', headerName: 'Slide ID', width: 150 },
            { field: 'flowClassName', headerName: 'Flow Class Name', width: 200 },
            {
              field: 'publishedOrchestrationId',
              headerName: 'Published Orchestration',
              width: 250,
            },
          ]}
          disableRowSelectionOnClick
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100,
              },
            },
          }}
          pageSizeOptions={[100]}
          checkboxSelection
          isRowSelectable={(params: GridRowParams) => selectableRowIds.indexOf(params.id as string) !== -1}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
        />
      </Grid>
    </Grid>
  );
};

export default ApproveResultsForm;
