import {
  IconProps,
  ListItem,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
  SvgIconTypeMap,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import React from 'react';

export interface SlideInteractionMenuOptions {
  editType: string;
  title: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  color?: IconProps['color'];
  sx?: IconProps['sx'];
  isDefault?: boolean;
}

export const SlideInteractionOption: React.FunctionComponent<{
  value: string;
  selected?: boolean;
  disabled?: boolean;
  title: string;
  icon: React.ReactNode;
  onClick?: ListItemButtonProps['onClick'];
}> = ({ icon, value, selected, disabled, title, onClick }) => {
  return (
    <ListItem value={value} title={title} sx={{ p: 0 }}>
      <ListItemButton onClick={onClick} disabled={disabled} selected={selected}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={title} />
      </ListItemButton>
    </ListItem>
  );
};
