import { useSignals } from '@preact/signals-react/runtime';
import { QueryFunctionContext, useQueries } from '@tanstack/react-query';
import {
  cloneDeep,
  compact,
  filter,
  find,
  flatten,
  forEach,
  includes,
  isEmpty,
  isEqual,
  map,
  some,
  uniq,
  values,
} from 'lodash';
import { useEffect, useMemo, useState, useTransition } from 'react';

import { getPreSignedPresentationInfo } from 'api/experimentResults';
import { Accession } from 'interfaces/accession';
import { Procedure } from 'interfaces/procedure';
import { useFormatBracketsOptions } from 'utils/formatBrackets/formatBracketsOptions';
import { useSelectedSlideIds } from 'utils/useCurrentSlideIds';
import { isHeatmapActive } from '../Infobar/slidesVisualizationAndConfiguration';
import { SlideWithChannelAndResults, getSlidesChannelsAndResults, isSlideLoadingChannelsOrResults } from './utils';

export const useSlideChannelsAndResults = (procedure: Procedure | Accession) => {
  useSignals();
  const [heatmapsRequiringPreSigning, setHeatmapsRequiringPreSigning] = useState<number[]>([]);
  const formatBracketsOptions = useFormatBracketsOptions(false);

  const studyId = procedure?.studyId;

  const preSignedExperimentResultsQueries = useQueries({
    queries: map(heatmapsRequiringPreSigning, (experimentResultId) => ({
      queryKey: ['getPreSignedExperimentResultPresentationInfo', { studyId, experimentResultId }],
      queryFn: ({ signal }: QueryFunctionContext) => getPreSignedPresentationInfo(studyId, experimentResultId, signal),
      enabled: Boolean(studyId),
    })),
  });

  const preSignedExperimentResults = map(preSignedExperimentResultsQueries, 'data');

  const slidesChannelsAndResults = useMemo(() => {
    const procedureSlides = cloneDeep(procedure?.slides || []);
    forEach(procedureSlides, (slide) => {
      forEach(compact(slide?.experimentResults), (experimentResult) => {
        const presignedPresentationInfo = find(
          preSignedExperimentResults,
          (data) => Boolean(experimentResult) && !isEmpty(data?.[experimentResult.experimentResultId])
        );
        if (presignedPresentationInfo) {
          experimentResult.presentationInfo = presignedPresentationInfo[experimentResult.experimentResultId];
        }
      });
    });
    return getSlidesChannelsAndResults(procedureSlides, studyId, formatBracketsOptions);
  }, [procedure?.slides, studyId, JSON.stringify(preSignedExperimentResults), JSON.stringify(formatBracketsOptions)]);

  const [selectedSlideIds] = useSelectedSlideIds(procedure);

  const [selectedSlidesWithChannelsAndResults, setSelectedSlidesWithChannelsAndResults] = useState<
    SlideWithChannelAndResults[] | null
  >(null);
  useEffect(() => {
    const newSlidesWithChannelsAndResults = compact(
      map(selectedSlideIds, (slideId, viewerIndex) => {
        const slide = find(procedure.slides, { id: slideId });
        if (!slide) {
          return undefined;
        }

        // Warn if there are results with presentation info but no options
        const heatmapsWithPresentationInfoAndWithoutOptions = filter(
          slide.experimentResults,
          (result) => !isEmpty(result?.presentationInfo) && isEmpty(result.options)
        );

        if (!isEmpty(heatmapsWithPresentationInfoAndWithoutOptions)) {
          console.warn(
            'Found results with presentation info but no options',
            heatmapsWithPresentationInfoAndWithoutOptions
          );
        }

        return {
          viewerIndex,
          ...(slidesChannelsAndResults[slide.id] || {}),
          ...slide,
        };
      })
    );
    setSelectedSlidesWithChannelsAndResults((oldData) =>
      !isEqual(oldData, newSlidesWithChannelsAndResults) ? newSlidesWithChannelsAndResults : oldData
    );
  }, [procedure.slides, slidesChannelsAndResults, selectedSlideIds]);

  const [, startTransition] = useTransition();

  useEffect(() => {
    const newHeatmapsRequiringPreSigning: number[] = [];
    forEach(selectedSlidesWithChannelsAndResults, (slide) => {
      const allHeatmaps = [
        ...(slide?.heatmapResults?.publishedResults || []),
        ...flatten(values(slide?.heatmapResults?.internalResults || {})),
        ...flatten(values(slide?.internalHeatmaps || {})),
      ];

      const heatmapsRequiringPreSigningForSlide = filter(
        allHeatmaps,
        (heatmap) =>
          heatmap?.id &&
          Boolean(heatmap.heatmapUrl) &&
          heatmap.heatmapUrl.startsWith('s3://') &&
          // Only pre-sign heatmaps that are selected
          isHeatmapActive({ heatmapId: heatmap.id, slideId: slide.id, viewerIndex: slide.viewerIndex })
      );

      newHeatmapsRequiringPreSigning.push(...compact(map(heatmapsRequiringPreSigningForSlide, 'experimentResultId')));
    });
    if (some(newHeatmapsRequiringPreSigning, (id) => !includes(heatmapsRequiringPreSigning, id))) {
      startTransition(() => {
        setHeatmapsRequiringPreSigning((prev) => uniq([...prev, ...newHeatmapsRequiringPreSigning]));
      });
    }
  });

  const isLoadingSlideChannelsAndResults =
    isEmpty(selectedSlidesWithChannelsAndResults) ||
    some(selectedSlidesWithChannelsAndResults, isSlideLoadingChannelsOrResults);

  return { selectedSlidesWithChannelsAndResults, isLoadingSlideChannelsAndResults };
};
