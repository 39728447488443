export const biopsyTypes = [
  {
    label: 'Breast Resection',
    value: 'breastResection',
  },
  {
    label: 'Core Needle',
    value: 'core needle',
  },
  {
    label: 'Endoscopy',
    value: 'endoscopy',
  },
  {
    label: 'Lung Resection',
    value: 'lungResection',
  },
  {
    label: 'Lung Small',
    value: 'lungSmall',
  },
  {
    label: 'Other',
    value: 'Other',
  },
  {
    label: 'Skin',
    value: 'Skin',
  },
  {
    label: 'Surgical',
    value: 'Surgical',
  },
  {
    label: 'FFPE',
    value: 'FFPE',
  },
];
