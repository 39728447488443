import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { useMutation, useQuery } from '@tanstack/react-query';
import { every, includes, isArray, isEmpty, join, lowerCase, map, omit, size, some } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';

import { prepareCustomerResults, previewPrepareCustomerResultsManifest } from 'api/customerResults';
import { PrepareCustomerResultsJobManifestTable } from 'components/Pages/Jobs/PrepareCustomerResultsJobManifestTable';
import CohortQueryList from 'components/SearchFilters/CohortQueryList';
import CohortQueryListItem from 'components/SearchFilters/CohortQueryListItem';
import { ResultsMode } from 'interfaces/experimentResults';
import { humanize } from 'utils/helpers';
import queryClient from 'utils/queryClient';
import { manualSelectionFilters, useCasesParams } from 'utils/useCasesParams';
import { useCurrentLabId } from 'utils/useCurrentLab';
import useStudy from 'utils/useStudy';

interface Props {
  disabled?: boolean;
  studyId: string;
}

const resultTypeOptions = ['nuclear_segmentations_table', 'cells_table', 'areas_table'];

const PrepareCustomerResultsForDownload: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  disabled = false,
  studyId,
}) => {
  const { data: study } = useStudy(studyId);
  const { labId } = useCurrentLabId();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const prepareCustomerResultsMutation = useMutation(prepareCustomerResults, {
    onError: () => {
      enqueueSnackbar("Error occurred, Prepare Customer Results can't start", { variant: 'error' });
    },
    onSuccess: () => {
      enqueueSnackbar('Start preparing customer results', { variant: 'success' });
      queryClient.invalidateQueries(['jobs']);
    },
  });

  const [filterByCaseParamsState, setFilterByCaseParams] = useState(false);
  const { casesParams } = useCasesParams();

  const hasSpecificSelections = some(manualSelectionFilters, (filterName) => !isEmpty(casesParams?.[filterName]));

  const caseParamsWithoutStudyId = {
    ...casesParams,
    filters: omit(casesParams?.filters, 'studyId'),
  };
  // Do we have filters beyond the default ones?
  const hasCaseParams =
    hasSpecificSelections ||
    !isEmpty(
      omit(
        caseParamsWithoutStudyId,
        'filters',
        'labId',
        'slidesMode',
        'resultsMode',
        'clinicalData',
        'features',
        ...manualSelectionFilters
      )
    ) ||
    caseParamsWithoutStudyId.resultsMode !== ResultsMode.Published ||
    !isEmpty(caseParamsWithoutStudyId?.filters) ||
    !isEmpty(caseParamsWithoutStudyId?.clinicalData) ||
    !isEmpty(caseParamsWithoutStudyId?.features);

  const filterByCaseParams = filterByCaseParamsState && hasCaseParams;

  const [resultTypesToExport, setResultTypesToExport] = useState<string[]>([]);
  const handleFilesToExportChange = (event: SelectChangeEvent<string[]>) => {
    if (!isArray(event.target.value)) {
      setResultTypesToExport([event.target.value]);
      return;
    }
    setResultTypesToExport(event.target.value);
  };

  const disablePrepareButton = prepareCustomerResultsMutation.isLoading || isEmpty(resultTypesToExport) || !studyId;

  const mutationParams = {
    studyId,
    resultTypesToExport,
    labId,
    queryObject: filterByCaseParams
      ? {
          ...caseParamsWithoutStudyId,
          studyId,
          filters: { ...caseParamsWithoutStudyId?.filters, studyId },
        }
      : undefined,
  };

  const previewCustomerResultsManifestParams = omit(mutationParams, 'resultTypesToExport');

  const { isFetching: isFetchingManifestPreview, data: previewResponse } = useQuery(
    ['previewCustomerResultsManifest', [previewCustomerResultsManifestParams]],
    {
      queryFn: ({ signal }) => previewPrepareCustomerResultsManifest(previewCustomerResultsManifestParams, signal),
      keepPreviousData: true,
      enabled: !disabled && isModalOpen,
    }
  );

  const manifestPreviewBody = isFetchingManifestPreview ? (
    <Typography variant="body1" color="textSecondary">
      <CircularProgress size={16} sx={{ mr: 1 }} />
      Loading list of exports...
    </Typography>
  ) : (
    <PrepareCustomerResultsJobManifestTable
      previewMode
      manifest={previewResponse?.manifest}
      studyId={studyId}
      unpublishedExperimentResultIds={previewResponse?.unpublishedExperimentResultIds}
      queryStatistics={previewResponse?.queryStatistics}
    />
  );

  return (
    <>
      <Tooltip title={disabled ? 'Select study' : 'Prepare customer approved results for download'} placement="top">
        <span>
          <Button
            size="small"
            color="primary"
            disabled={disabled}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              setIsModalOpen(true);
            }}
          >
            Prepare customer results for download
          </Button>
        </span>
      </Tooltip>
      <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)} maxWidth="md">
        <DialogTitle>Prepare Customer Results</DialogTitle>
        <DialogContent dividers>
          <FilesToExportSelect selectedFilesToExport={resultTypesToExport} onChange={handleFilesToExportChange} />
          {hasCaseParams && (
            <Grid container direction="column" spacing={1} p={2}>
              <RadioGroup
                row
                value={filterByCaseParams ? 'filterByCaseParams' : 'exportAllPublishedResultsForStudy'}
                onChange={(event) => {
                  setFilterByCaseParams(event.target.value === 'filterByCaseParams');
                }}
                sx={{ flexDirection: 'column' }}
              >
                <FormControlLabel
                  value="exportAllPublishedResultsForStudy"
                  control={<Radio />}
                  label={`Export all published results for study ${study?.name || studyId}`}
                />
                {!filterByCaseParams && manifestPreviewBody}
                <FormControlLabel
                  value="filterByCaseParams"
                  control={<Radio />}
                  label={
                    hasSpecificSelections ? (
                      <>
                        Only export results matching the following selection
                        {map(
                          manualSelectionFilters,
                          (filterName) =>
                            !isEmpty(caseParamsWithoutStudyId?.[filterName]) && (
                              <CohortQueryListItem
                                showIcon
                                key={filterName}
                                itemKey={filterName}
                                value={`${humanize(filterName)}: ${join(caseParamsWithoutStudyId?.[filterName], ', ')}`}
                              />
                            )
                        )}
                      </>
                    ) : (
                      <>
                        Export {lowerCase(caseParamsWithoutStudyId.resultsMode)} results for study{' '}
                        {study?.name || studyId}
                        {!isEmpty(caseParamsWithoutStudyId?.filters) && (
                          <>
                            {' '}
                            matching the following criteria
                            <CohortQueryList queryObject={caseParamsWithoutStudyId} showIcon />
                          </>
                        )}
                      </>
                    )
                  }
                />
                {filterByCaseParams && manifestPreviewBody}
              </RadioGroup>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
          <Button
            variant="contained"
            onClick={() => {
              setIsModalOpen(false);
              prepareCustomerResultsMutation.mutate(mutationParams);
            }}
            disabled={disablePrepareButton}
          >
            Prepare
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const FilesToExportSelect = ({
  selectedFilesToExport,
  onChange,
}: {
  selectedFilesToExport: string[];
  onChange: (event: SelectChangeEvent<string[]>) => void;
}) => {
  const areAllSelected =
    selectedFilesToExport &&
    resultTypeOptions &&
    // Check all selected file values equal to all available file values
    size(selectedFilesToExport) === size(resultTypeOptions) &&
    every(resultTypeOptions, (option) => includes(selectedFilesToExport, option));
  const areSomeSelected = !isEmpty(selectedFilesToExport) && size(selectedFilesToExport) > 0 && !areAllSelected;

  return (
    <>
      <InputLabel>Files to export</InputLabel>
      <Select
        multiple
        value={selectedFilesToExport}
        onChange={(event) => {
          if (includes(event.target.value, 'ALL')) {
            onChange({ target: { value: areAllSelected ? [] : resultTypeOptions } } as SelectChangeEvent<string[]>);
            return;
          }
          onChange(event);
        }}
        renderValue={(selected) => join(selected, ', ')}
      >
        <MenuItem value="ALL">
          <Checkbox checked={areAllSelected} indeterminate={areSomeSelected} />
          <ListItemText primary={'All'} />
        </MenuItem>
        {map(resultTypeOptions, (option) => (
          <MenuItem key={option} value={option}>
            <Checkbox checked={includes(selectedFilesToExport, option)} />
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default PrepareCustomerResultsForDownload;
