import { PolygonLayer, TextLayer } from '@deck.gl/layers/typed';
import { deckGLToPMTTileIndex } from './helpers';
// @ts-ignore

/**
 * Generate debug layers for the PMTiles layer.
 * This function will generate a square to show the tile's boundaries, with a text layer with the tile's coordinates and the
 * number of features found in the tile,
 * @param options The options for the function.
 * @param options.id The id of the tile layer.
 * @param options.maxLevel The maximum zoom level of the base layer.
 * @param options.tileCoordinates The tile coordinates of the tile.
 * @param options.boundingBox The bounding box of the tile.
 * @param options.numFeatures The number of features found in the tile.
 * @returns The debug layers.
 */
export const generatePMTDebugLayers = ({
  id,
  maxLevel,
  tileCoordinates,
  boundingBox,
  numFeatures,
}: {
  id: string;
  maxLevel: number;
  tileCoordinates: { x: number; y: number; z: number };
  boundingBox: [number[], number[]];
  numFeatures: number;
}) => {
  const topLeft = boundingBox[0];
  const bottomRight = boundingBox[1];
  const { x, y, z } = tileCoordinates;
  const {
    x: actualX,
    y: actualY,
    z: actualZoom,
  } = deckGLToPMTTileIndex({
    x,
    y,
    z,
    maxLevel,
  });

  return [
    // Text layer with the tiles' coordinates.
    new TextLayer({
      id: `${id}-text`,
      data: [
        {
          text: `PMT Tile ${x}, ${y}, ${z}\n(actual ${actualX}, ${actualY}, ${actualZoom})\nloaded - ${numFeatures} features found`,
          // Get the center of the tile.
          position: [(topLeft[0] + bottomRight[0]) / 2, (topLeft[1] + bottomRight[1]) / 2],
          size: 16,
          color: numFeatures > 0 ? [255, 255, 0] : [255, 0, 0],
        },
      ],
      getPosition: (dataEntry) => dataEntry.position,
      getText: (dataEntry) => dataEntry.text,
      getSize: (dataEntry) => dataEntry.size,
      getColor: (dataEntry) => dataEntry.color,
      sizeScale: 1,
    }),
    new PolygonLayer({
      id: `${id}-bounding-box`,
      data: [
        {
          polygon: [
            [topLeft[0], topLeft[1]],
            [topLeft[0], bottomRight[1]],
            [bottomRight[0], bottomRight[1]],
            [bottomRight[0], topLeft[1]],
          ],
        },
      ],
      getPolygon: (dataEntry) => dataEntry.polygon,
      getFillColor: [0, 0, 0, 0],
      getLineColor: numFeatures > 0 ? [255, 255, 0] : [255, 0, 0],
      getLineWidth: 2 ** Math.max(1, 1 - tileCoordinates.z), // Scale the line width based on the zoom level.
      lineWidthScale: 1,
    }),
  ];
};
