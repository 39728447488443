import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Grid, IconButton, Skeleton, Tooltip, Typography, useTheme } from '@mui/material';
import React from 'react';

interface Props {
  pageTitle: string;
  onBackClick?: () => void;
  isCohort?: boolean;
  renderCohortDetailsToggle?: () => React.ReactNode;
}

const PageHeader = ({ pageTitle, onBackClick, isCohort, renderCohortDetailsToggle }: Props) => {
  const theme = useTheme();
  return (
    <Grid item container alignItems="center">
      <Grid item>
        <IconButton
          onClick={onBackClick}
          size="small"
          sx={{
            backgroundColor: theme.palette.secondary.main,
            mr: 2,
            borderRadius: '50%',

            color: theme.palette.primary.contrastText,
            '&:hover': {
              backgroundColor: theme.palette.secondary.dark,
            },
          }}
        >
          <KeyboardBackspaceIcon />
        </IconButton>
      </Grid>
      <Grid item xs={true} container spacing={2}>
        <Grid item xs={true}>
          <Tooltip title={pageTitle} arrow placement="top">
            {pageTitle ? (
              <Typography
                variant="h1"
                sx={{
                  fontWeight: theme.typography.fontWeightBold,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
                color="text.primary"
              >
                {pageTitle}
              </Typography>
            ) : (
              <Skeleton variant="rectangular" width={400} height={40} />
            )}
          </Tooltip>
        </Grid>
        {isCohort && (
          <Grid
            item
            xs={1}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <CollectionsBookmarkIcon fontSize="large" />
          </Grid>
        )}

        {renderCohortDetailsToggle && renderCohortDetailsToggle()}
      </Grid>
    </Grid>
  );
};

export default PageHeader;
