import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete, Checkbox, TextField } from '@mui/material';
import { Taxonomy } from 'interfaces/taxonomy';
import { compact, find, map, some } from 'lodash';
import React, { useMemo, useState, useTransition } from 'react';
import useTaxonomy from 'utils/queryHooks/taxonomy/useTaxonomy';

interface TaxonomyMultiSelectDropdownProps {
  selectedTaxonomiesIds: string[];
  onSelectTaxonomiesIds: (taxonomiesIds: string[]) => void;
  applyOnChildren?: boolean; // if true, when selecting a parent taxonomy, all its children will be selected as well and disabled
}

const icon = <CheckBoxOutlineBlankIcon />;
const checkedIcon = <CheckBoxIcon />;

const isParentTaxonomySelected = (taxonomy: Taxonomy, selectedTaxonomies: Taxonomy[]): boolean => {
  return some(
    selectedTaxonomies,
    (parent) => taxonomy.path.startsWith(`${parent.path}.`) && parent.path !== taxonomy.path
  );
};

const TaxonomyMultiSelectDropdown: React.FC<TaxonomyMultiSelectDropdownProps> = ({
  selectedTaxonomiesIds: externalTaxonomiesIds,
  onSelectTaxonomiesIds,
  applyOnChildren = false,
}) => {
  const { data: taxonomies } = useTaxonomy();

  const [selectedInternalTaxonomiesIds, setSelectedInternalTaxonomiesIds] = useState<string[]>(null);

  const [, startTransition] = useTransition(); // we use useTransition to make it so the UI doesn't freeze when selecting a taxonomy

  const handleSelectTaxonomies = (newValue: Taxonomy[]) => {
    startTransition(() => {
      onSelectTaxonomiesIds(map(newValue, 'path'));
    });
    setSelectedInternalTaxonomiesIds(map(newValue, 'path'));
  };

  const selectedTaxonomiesIds = selectedInternalTaxonomiesIds ?? externalTaxonomiesIds;

  const selectedTaxonomies = useMemo(
    () => compact(map(selectedTaxonomiesIds, (id) => find(taxonomies, (t) => t.path === id))),
    [selectedTaxonomiesIds, taxonomies]
  );

  return (
    <Autocomplete
      multiple
      fullWidth
      options={taxonomies ?? []}
      getOptionLabel={(option) => option.name}
      value={selectedTaxonomies}
      onChange={(event, newValue) => handleSelectTaxonomies(newValue as Taxonomy[])}
      disableCloseOnSelect
      isOptionEqualToValue={(option, value) => option.path === value.path}
      renderOption={(props, option, { selected }) => {
        const isParentSelected = isParentTaxonomySelected(option, selectedTaxonomies);
        const disableOptionSelection = applyOnChildren && isParentSelected;

        return (
          <li
            {...props}
            onClick={(e) => {
              if (disableOptionSelection) {
                e.preventDefault();
              } else {
                props.onClick(e);
              }
            }}
          >
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={selected || (applyOnChildren && isParentSelected)}
              disabled={disableOptionSelection}
            />
            {option.name}
          </li>
        );
      }}
      renderInput={(params) => <TextField size="small" {...params} placeholder="Select Taxonomies" />}
    />
  );
};

export default TaxonomyMultiSelectDropdown;
