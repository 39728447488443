import ExpandIcon from '@mui/icons-material/ExpandMore';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { runMultiplexNormalization } from 'api/platform';
import { JobType, NormalizationJob } from 'interfaces/job';
import { MultiplexHistogramConfig } from 'interfaces/jobs/multiplex/histogramParams';
import {
  MultiplexNormalizationJobParams,
  NormalizationConfig,
  NormalizationParamOverrideChannelGroup,
} from 'interfaces/jobs/multiplex/normalizationParams';
import { flatMap, isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { ReactElement, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { MAX_UINT16, MAX_UINT8 } from 'utils/constants';
import { CasesParams } from 'utils/useCasesParams';
import * as yup from 'yup';
import { JobWithRebuild } from '../JobWithRebuild';
import { OldJobsStep } from '../OldJobsStep';
import { PlatformStepper } from '../PlatformStepper';
import { JobNameAndDescriptionStep } from '../utils';
import HistogramConfig, { defaultHistogramConfig, validationHistogramConfig } from './RunHistogram/HistogramParams';
import { ChannelGroupsOverridesForm } from './RunNormalization/ChannelGroupsOverridesForm';
import NormalizationParamsForm from './RunNormalization/NormalizationParamsForm';

const SNACK_BAR_KEY_RUN_MULTIPLEX_NORMALIZATION = 'RUN_MULTIPLEX_NORMALIZATION';

export const defaultChannelNormalizationConfig: NormalizationConfig = {
  isLowDynamicRange: false,
  isHighAbundance: false,
  params8bit: {
    startingHistValue: 0,
    maxMinC: 85, // Math.round(255 / 3)
    minMaxC: 30,
    maxMaxC: MAX_UINT8,
  },
  params16bit: {
    startingHistValue: 0,
    maxMinC: 21845, // Math.round(65535 / 3)
    minMaxC: 2000,
    maxMaxC: MAX_UINT16,
  },
  maxCPercentile: 0.95,
};

export const defaultNormalizationParams: MultiplexNormalizationJobParams = {
  baseParams: defaultChannelNormalizationConfig,
  paramOverrideChannelGroups: [],
  resultDescription: null,
  internallyApproved: true,
};

export interface NormalizationFormValues {
  jobName: string;
  jobDescription: string;
  configParams: {
    histogramNormalization: MultiplexNormalizationJobParams;
    histogramCreation: MultiplexHistogramConfig;
  };
}

export const normalizationSchema = yup.object({
  isLowDynamicRange: yup.boolean().when('isHighAbundance', {
    is: true,
    then: yup.boolean().isFalse('Cannot be true when High Abundance is selected'),
  }),
  params8bit: yup.object({
    startingHistValue: yup.number().required(),
    maxMinC: yup.number().required(),
    minMaxC: yup
      .number()
      .required()
      .min(yup.ref('startingHistValue'), 'minMaxC must be greater than startingHistValue'),
    maxMaxC: yup.number().required(),
  }),
  params16bit: yup.object({
    startingHistValue: yup.number().required(),
    maxMinC: yup.number().required(),
    minMaxC: yup
      .number()
      .required()
      .min(yup.ref('startingHistValue'), 'minMaxC must be greater than startingHistValue'),
    maxMaxC: yup.number().required(),
  }),
});

const validationSchema = [
  yup.object({}),
  yup.object({
    configParams: yup.object({
      histogramNormalization: yup.object({
        baseParams: normalizationSchema,
        paramOverrideChannelGroups: yup
          .array()
          .of(
            yup.object({
              name: yup.string().required(),
              channels: yup.array().of(yup.mixed()).required(),
              overrides: normalizationSchema,
            })
          )
          .test(
            'unique-channel-values',
            'channel values must be unique across all channel groups',
            function (channelGroupOverrides) {
              const allNestedValues = flatMap(channelGroupOverrides, (override) => override.channels);
              const uniqueValues = new Set(allNestedValues);
              return allNestedValues.length === uniqueValues.size;
            }
          ),
      }),
    }),
  }),
  yup.object({}),
  yup.object({}),
  yup.object({
    configParams: yup.object({
      histogramCreation: validationHistogramConfig,
    }),
  }),
  yup.object({}),
];

export interface RunMultiplexNormalizationProps {
  onClose: () => void;
  jobId?: string;
  casesParams: CasesParams;
}

export const RunMultiplexNormalization = (props: RunMultiplexNormalizationProps): ReactElement => {
  const { onClose, casesParams, jobId } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(0);
  const [selectedJobId, setSelectedJobId] = useState<string>(jobId ?? undefined);

  const currentValidationSchema = validationSchema[activeStep];

  const setBasicParams = (basicParams: MultiplexNormalizationJobParams['baseParams']) => {
    reset({
      ...getValues(),
      configParams: {
        ...getValues().configParams,
        histogramNormalization: {
          ...getValues().configParams.histogramNormalization,
          baseParams: basicParams,
        },
      },
    });

    validateStepParams(0);
  };

  const setParamOverrideChannelGroups = (newParamOverrideChannelGroups: NormalizationParamOverrideChannelGroup[]) => {
    reset({
      ...getValues(),
      configParams: {
        ...getValues().configParams,
        histogramNormalization: {
          ...getValues().configParams.histogramNormalization,
          paramOverrideChannelGroups: newParamOverrideChannelGroups,
        },
      },
    });

    validateStepParams(0);
  };

  const setResultDescription = (newResultDescription: string) => {
    reset({
      ...getValues(),
      configParams: {
        ...getValues().configParams,
        histogramNormalization: {
          ...getValues().configParams.histogramNormalization,
          resultDescription: newResultDescription,
        },
      },
    });
  };

  const setInternallyApproved = (newValue: boolean) => {
    reset({
      ...getValues(),
      configParams: {
        ...getValues().configParams,
        histogramNormalization: {
          ...getValues().configParams.histogramNormalization,
          internallyApproved: newValue,
        },
      },
    });
  };

  const [isStepFailed, setIsStepFailed] = useState<Record<number, boolean>>({});

  const {
    reset,
    getValues,
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<NormalizationFormValues>({
    mode: 'onChange',
    defaultValues: {
      jobName: null,
      jobDescription: null,
      configParams: {
        histogramNormalization: defaultNormalizationParams,
        histogramCreation: defaultHistogramConfig,
      },
    },
    resolver: yupResolver(currentValidationSchema),
  });

  const checkValidationAndSetIsStepFailed = (stepIndex: number, objectToValidate: Record<string, any>) => {
    validationSchema[stepIndex]
      .validate(objectToValidate)
      .then(() => {
        setIsStepFailed((prev) => ({
          ...prev,
          [stepIndex]: false,
        }));
      })
      .catch(() => {
        setIsStepFailed((prev) => ({
          ...prev,
          [stepIndex]: true,
        }));
      });
  };

  const runMultiplexNormalizationMutation = useMutation(runMultiplexNormalization, {
    onError: () => {
      enqueueSnackbar('Error occurred, Multiplex Normalization failed', {
        variant: 'error',
      });
    },
    onSuccess: () => {
      enqueueSnackbar('Multiplex Normalization Started', { variant: 'success' });
    },
    onSettled() {
      closeSnackbar(SNACK_BAR_KEY_RUN_MULTIPLEX_NORMALIZATION);
    },
  });

  const onSubmit: SubmitHandler<NormalizationFormValues> = async (data) => {
    runMultiplexNormalizationMutation.mutate({
      ...casesParams,
      jobName: data.jobName,
      jobDescription: data.jobDescription,
      configParams: {
        histogramNormalization: data.configParams?.histogramNormalization,
        histogramCreation: data.configParams?.histogramCreation,
      },
    });

    enqueueSnackbar({
      variant: 'success',
      message: (
        <Grid container>
          <Grid item>
            <Typography>Waiting for Multiplex Normalization to start</Typography>
          </Grid>
          <Grid item>
            <CircularProgress sx={{ marginLeft: 10 }} color="inherit" size={20} />
          </Grid>
        </Grid>
      ),
      key: SNACK_BAR_KEY_RUN_MULTIPLEX_NORMALIZATION,
      autoHideDuration: null,
    });

    onClose();
  };

  const validateStepParams = async (stepIndex: number) => {
    checkValidationAndSetIsStepFailed(stepIndex, {
      configParams: {
        histogramNormalization: watch('configParams.histogramNormalization'),
        histogramCreation: watch('configParams.histogramCreation'),
      },
    });
  };

  const onSelectedJobParamChange = (newValue: NormalizationJob) => {
    if (isEmpty(newValue)) {
      setSelectedJobId(undefined);
      reset({
        jobName: null,
        jobDescription: null,
        configParams: {
          histogramNormalization: defaultNormalizationParams,
          histogramCreation: defaultHistogramConfig,
        },
      });
    } else {
      setSelectedJobId(newValue.id);
      reset({
        jobName: newValue?.name,
        jobDescription: newValue?.description,
        configParams: newValue?.params,
      });

      validateStepParams(0);
      validateStepParams(4);

      if (!('histogramNormalization' in newValue.params)) {
        console.warn('histogramNormalization not found in job params');
      }
      if (!('histogramCreation' in newValue.params)) {
        console.warn('histogramCreation not found in job params');
      }
      if (!('name' in newValue)) {
        console.warn('name not found in job params');
      }
      if (!('description' in newValue)) {
        console.warn('description not found in job params');
      }
    }
  };

  const steps = [
    {
      label: 'Upload Params From Old Job',
      optional: true,
      content: (
        <OldJobsStep
          jobType={JobType.MultiplexNormalization}
          onSelectedJob={onSelectedJobParamChange}
          selectedJobId={selectedJobId}
        />
      ),
    },
    {
      label: 'Normalization Configuration',
      content: (
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h6">Default Configuration</Typography>
            <NormalizationParamsForm
              normalizationConfig={watch('configParams.histogramNormalization.baseParams')}
              setNormalizationConfig={setBasicParams}
            />
          </Grid>
          <Grid item>
            <Grid item>
              <Typography variant="h6">Channel Groups Overrides</Typography>
              <ChannelGroupsOverridesForm
                channelGroupsOverrides={
                  watch('configParams.histogramNormalization.paramOverrideChannelGroups') ??
                  ([] as NormalizationParamOverrideChannelGroup[])
                }
                setChannelGroupsOverrides={setParamOverrideChannelGroups}
              />
            </Grid>
          </Grid>
        </Grid>
      ),
      onNextOrBackClick: () => validateStepParams(1),
    },
    {
      label: 'Additional Configuration',
      optional: true,
      content: (
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <TextField
              label="Result Description"
              value={watch('configParams.histogramNormalization.resultDescription') ?? ''}
              onChange={(event) => setResultDescription(event.target.value)}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              label="Internally Approved"
              control={
                <Checkbox
                  checked={watch('configParams.histogramNormalization.internallyApproved') ?? true}
                  onChange={(event) => setInternallyApproved(event.target.checked)}
                />
              }
            />
          </Grid>
        </Grid>
      ),
    },
    {
      label: 'Job Name and Description',
      subLabel: activeStep > 0 && watch('jobName'),
      optional: true,
      content: <JobNameAndDescriptionStep control={control} errors={errors} register={register} />,
    },
    {
      label: 'Histogram Configuration',
      optional: true,
      content: (
        <HistogramConfig
          histogramConfig={watch('configParams.histogramCreation') ?? ({} as MultiplexHistogramConfig)}
          setHistogramConfig={(histogramConfig) => {
            reset({
              ...getValues(),
              configParams: {
                ...getValues().configParams,
                histogramCreation: {
                  ...getValues().configParams.histogramCreation,
                  ...histogramConfig,
                },
              },
            });

            validateStepParams(4);
          }}
          casesParams={casesParams}
        />
      ),
      onNextOrBackClick: () => validateStepParams(4),
    },
    {
      label: 'Advanced',
      optional: true,
      content: (
        <Grid container>
          <Grid item xs={2}>
            <TextField
              label="Max Workers"
              type="number"
              InputProps={{ inputProps: { min: 0, max: 16 } }}
              value={watch('configParams.histogramCreation.maxWorkers') ?? 16}
              onChange={(event) =>
                reset({
                  ...getValues(),
                  configParams: {
                    ...getValues().configParams,
                    histogramCreation: {
                      ...getValues().configParams.histogramCreation,
                      maxWorkers: Number(event.target.value),
                    },
                  },
                })
              }
            />
          </Grid>
        </Grid>
      ),
    },
  ];

  return (
    <JobWithRebuild jobId={jobId} onSelectedJobParamChange={onSelectedJobParamChange}>
      <PlatformStepper
        handleSubmit={handleSubmit(onSubmit)}
        steps={steps}
        setActiveStepForValidation={setActiveStep}
        isStepFailed={isStepFailed}
      />
      <Accordion>
        <AccordionSummary expandIcon={<ExpandIcon />}>Normalization Params Summary (JSON)</AccordionSummary>
        <AccordionDetails>
          <Typography component="pre">
            {JSON.stringify(
              {
                jobName: watch('jobName'),
                jobDescription: watch('jobDescription'),
                configParams: {
                  histogramNormalization: watch('configParams.histogramNormalization'),
                  histogramCreation: watch('configParams.histogramCreation'),
                },
              },
              null,
              2
            )}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </JobWithRebuild>
  );
};
