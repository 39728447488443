import { AgentRawResponse, AgentRequest, AgentSession } from 'interfaces/agentSession';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export const getAgentSessions = (queryParams: string) => {
  return apiRequestHandlerPromise<AgentSession[]>({
    url: `agent/sessions?${queryParams}`,
    method: 'GET',
  });
};

// post agent send message

export const sendAMessageToAgent = (agentRequest: AgentRequest) => {
  const data = JSON.parse(JSON.stringify(agentRequest));

  return apiRequestHandlerPromise<AgentRawResponse>({
    url: `agent/send`,
    method: 'POST',
    data,
  });
};
