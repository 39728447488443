import { ButtonGroup, Chip, Grid, ToggleButton, ToggleButtonGroup, Toolbar, Typography, useTheme } from '@mui/material';
import { GridToolbarProps, ToolbarPropsOverrides } from '@mui/x-data-grid';
import { useRowSelectionContext } from 'components/atoms/RowSelectionContext';
import ActionsMenu from 'components/EditSlidesAndCasesDisplay/ActionsMenu';
import { typeToName } from 'components/ReviewPanel/form.util';
import { ProceduresFieldsCategory } from 'interfaces/procedure/fields';
import { ReviewForm } from 'interfaces/reviewForm';
import { map } from 'lodash';
import React from 'react';
import { ExperimentResultsSelection, useEncodedFilters } from 'utils/useEncodedFilters';
import { ProcedurePagination } from '../ProcedurePagination';
import { BulkEditControl } from './BulkEditControl';

export interface DataGridHeaderProps extends GridToolbarProps, ToolbarPropsOverrides {
  bulkEditMode: boolean;
  totalSlides: number;
  totalCases: number;
  isLoading: boolean;
  disableEditing: boolean;
  hidePagination: boolean;
  selectedTableColumnGroup: ProceduresFieldsCategory[];
  setSelectedTableColumnGroup?: React.Dispatch<React.SetStateAction<ProceduresFieldsCategory[]>>;
  onBulkEditSave?: (changes: { [field: string]: any }) => void;
  forms?: ReviewForm[];
  hideActionsMenu?: boolean;
  onPageChange?: (withOffset: boolean) => (event: React.ChangeEvent<unknown>, nextPage?: number) => void;
  onRowsPerPageChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  forcePage?: number;
  forcePageSize?: number;
}

export const DataGridHeader: React.FC<React.PropsWithChildren<DataGridHeaderProps>> = ({
  bulkEditMode,
  hidePagination,
  totalCases,
  totalSlides,
  isLoading,
  disableEditing,
  selectedTableColumnGroup,
  setSelectedTableColumnGroup,
  onBulkEditSave,
  forms,
  hideActionsMenu = false,
  onPageChange,
  onRowsPerPageChange,
  forcePage,
  forcePageSize,
}) => {
  const theme = useTheme();

  const { noneSelected, selectedRowsCount } = useRowSelectionContext();
  const {
    queryParams: { slidesMode },
  } = useEncodedFilters({
    experimentResultsSelection: ExperimentResultsSelection.OnlyQAFailed,
  });

  const totalRows = slidesMode ? totalSlides : totalCases;
  const noRows = totalRows === 0;

  const disableRowOperations = isLoading || noRows || noneSelected(totalRows);

  return (
    <Toolbar
      variant="dense"
      sx={{
        backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
        justifyContent: 'space-between',
        position: 'relative',
      }}
    >
      <ToggleButtonGroup
        size="small"
        color="primary"
        value={selectedTableColumnGroup}
        onChange={(_, newSelectedTableColumnGroup) =>
          setSelectedTableColumnGroup(newSelectedTableColumnGroup as ProceduresFieldsCategory[])
        }
        aria-label="table-column-groups"
      >
        <ToggleButton value="metadata">Metadata</ToggleButton>
        <ToggleButton value="patient">Patient</ToggleButton>
        <ToggleButton value="clinical">Clinical</ToggleButton>
        <ToggleButton value="outcome">Outcome</ToggleButton>
        <ToggleButton value="labels">Labels</ToggleButton>
        {map(forms, (form) => (
          <ToggleButton key={form.type} value={form.type}>
            {typeToName(form.type)}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      {!hidePagination && (
        <Grid container alignItems="center" justifyContent="flex-end">
          <ProcedurePagination
            totalSlides={totalSlides}
            dataGridPagination
            totalCases={totalCases}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            forcePage={forcePage}
            forcePageSize={forcePageSize}
          />
        </Grid>
      )}

      {bulkEditMode && (
        <Chip
          color="primary"
          sx={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
          label={
            <>
              Editing {selectedRowsCount(totalRows)} / {totalRows} {slidesMode ? 'slide' : 'case'}
              {totalRows === 1 ? '' : 's'}
            </>
          }
        />
      )}
      <Grid
        container
        alignItems="center"
        justifyContent="flex-end"
        sx={{
          marginInlineStart: 1,
        }}
      >
        <Grid
          item
          sx={{
            marginInlineEnd: 1,
          }}
        >
          {selectedRowsCount(totalRows) > 0 ? (
            <Typography variant="caption" minWidth={330}>
              ({selectedRowsCount(totalRows)} / {totalRows} {slidesMode ? 'slide' : 'case'}
              {totalRows === 1 ? '' : 's'} selected)
            </Typography>
          ) : null}
        </Grid>
        <Grid item>
          <ButtonGroup size="small" variant="text" color="secondary">
            {!disableEditing && (
              <>
                <BulkEditControl disabled={disableRowOperations} onSave={onBulkEditSave} />
              </>
            )}

            {!bulkEditMode && !hideActionsMenu && (
              <>
                <ActionsMenu totalRows={totalRows} disablePortal={false} />
              </>
            )}
          </ButtonGroup>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

export const DataGridFooter: React.FC<
  React.PropsWithChildren<{
    totalSlides: number;
    totalCases: number;
    onPageChange?: (withOffset: boolean) => (event: React.ChangeEvent<unknown>, nextPage?: number) => void;
    onRowsPerPageChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    forcePage?: number;
    forcePageSize?: number;
  }>
> = ({ totalSlides, totalCases, onPageChange, onRowsPerPageChange, forcePage, forcePageSize }) => {
  const theme = useTheme();
  return (
    <Toolbar
      variant="dense"
      sx={{
        '&.MuiToolbar-root': {
          paddingLeft: 0,
          paddingRight: 0,
          backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
          justifyContent: 'flex-end',
          '& .MuiTablePagination-root': {
            width: 'max-content',
          },
        },
      }}
    >
      <ProcedurePagination
        totalSlides={totalSlides}
        dataGridPagination
        totalCases={totalCases}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        forcePage={forcePage}
        forcePageSize={forcePageSize}
      />
    </Toolbar>
  );
};
