import { Grid, Skeleton, Typography } from '@mui/material';
import { Registration } from 'interfaces/slide';
import { cloneDeep, entries, filter, find, forEach, groupBy, map, some } from 'lodash';
import React from 'react';
import { RegistrationDetails, SlideRegistrationDetailsByOrchestration } from '../..';
import OrchIdRegistrations from './OrchIdRegistrations';

interface SlideRegistrationSectionProps {
  studyId: string;
  allRegistrations: Registration[];
  isLoading: boolean;
  isError: boolean;
  selectedRegistrations: SlideRegistrationDetailsByOrchestration;
  setSelectedRegistrations: (value: React.SetStateAction<SlideRegistrationDetailsByOrchestration>) => void;
}

const SlideRegistrationsSection: React.FC<React.PropsWithChildren<SlideRegistrationSectionProps>> = ({
  studyId,
  allRegistrations,
  isLoading,
  isError,
  selectedRegistrations,
  setSelectedRegistrations,
}) => {
  const registrationsByOrchId: SlideRegistrationByOrchestration = groupBy(allRegistrations, 'orchestrationId');

  const handleSelectionChange = (orchestration: string, newRegistrations: RegistrationDetails[]) => {
    const newSelectedRegistrations: SlideRegistrationDetailsByOrchestration = cloneDeep(selectedRegistrations);

    forEach(newRegistrations, (newRegistration) => {
      // check the other registrations if they have a selected pair from the new registration
      // we are using find because for each pair of slides, there should be only one selected row
      const orchestrationIdWithSelectedPair = find(
        entries(selectedRegistrations),
        ([orchestrationId, currentOrchRegistrations]) => {
          if (orchestrationId === orchestration) {
            return false;
          }

          return some(currentOrchRegistrations, (currentOrchRegistration) =>
            checkIfRegistrationsAreOfSamePair(newRegistration, currentOrchRegistration)
          );
        }
      );

      if (orchestrationIdWithSelectedPair) {
        const [orchestrationId, orchSelectedRegistrations] = orchestrationIdWithSelectedPair;
        // remove the other selected row for this pair of slides
        newSelectedRegistrations[orchestrationId] = filter(
          orchSelectedRegistrations,
          (currentOrchRegistration) => !checkIfRegistrationsAreOfSamePair(newRegistration, currentOrchRegistration)
        );
      }
    });

    newSelectedRegistrations[orchestration] = newRegistrations;

    setSelectedRegistrations(newSelectedRegistrations);
  };

  return (
    <Grid item container spacing={1}>
      <Grid item>
        <Typography>Slide Registration Results:</Typography>
      </Grid>
      <Grid item xs={12}>
        {isLoading ? (
          <RegistrationsByOrchIdSkeleton />
        ) : isError ? (
          <Typography>Error loading slide registrations</Typography>
        ) : (
          <Grid container spacing={1} direction="column">
            {map(registrationsByOrchId, (registrations, orchestration) => {
              return (
                <Grid item key={orchestration}>
                  <OrchIdRegistrations
                    studyId={studyId}
                    orchestration={orchestration}
                    registrations={registrations}
                    selectedRegistrations={selectedRegistrations[orchestration] || ([] as RegistrationDetails[])}
                    onSelectSlidesRegistration={(newRegistrations) =>
                      handleSelectionChange(orchestration, newRegistrations)
                    }
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const RegistrationsByOrchIdSkeleton: React.FC = () => {
  return (
    <Grid item xs={12}>
      <Grid container spacing={1} direction="column">
        <Grid item>
          <Skeleton variant="rounded" width="100%" height={40} />
        </Grid>
        <Grid item>
          <Skeleton variant="rounded" width="100%" height={40} />
        </Grid>
      </Grid>
    </Grid>
  );
};

type SlideRegistrationByOrchestration = {
  [orchestrationId: string]: Registration[];
};

const checkIfRegistrationsAreOfSamePair = (registration1: RegistrationDetails, registration2: RegistrationDetails) => {
  return (
    (registration1.sourceSlideId === registration2.sourceSlideId &&
      registration1.targetSlideId === registration2.targetSlideId) ||
    (registration1.sourceSlideId === registration2.targetSlideId &&
      registration1.targetSlideId === registration2.sourceSlideId)
  );
};

export default SlideRegistrationsSection;
