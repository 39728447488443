import {
  CompositeMode,
  DrawPointMode,
  DrawPolygonByDraggingMode,
  DrawPolygonMode as NebulaDrawPolygonMode,
  DrawRectangleMode,
} from 'nebula.gl';

import { lineString as turfLineString } from '@turf/helpers';
import lineIntersect from '@turf/line-intersect';
import { Polygon } from './geojson-types';

import { first } from 'lodash';
import { ModifyOrthographicMode } from './ModifyOrthographicMode';
import { ClickEvent, DraggingEvent, StartDraggingEvent, StopDraggingEvent } from './types';
import { getPickedEditHandle } from './utils';

class AnnotationCompositeMode extends CompositeMode {
  handleClick(clickEvent: any, props: any): void {
    if (clickEvent?.sourceEvent?.button !== 0) {
      return;
    }
    super.handleClick(clickEvent, props);
  }
}

// This class is without modify mode because according to this line writing-
// there is a bug modifying polygon while also in dragging mode.
// Sometimes it modifies and creates a new polygon at the same time
export class DrawAnnotationPolygonMode extends DrawPolygonByDraggingMode {
  clickTimeout: ReturnType<typeof setTimeout> | null = null;

  addPolygon(props: any): void {
    const clickSequence = this.getClickSequence();

    const polygonToAdd: Polygon = {
      type: 'Polygon',
      coordinates: [[...clickSequence, clickSequence[0]]],
    };

    this.resetClickSequence();

    const editAction = this.getAddFeatureOrBooleanPolygonAction(polygonToAdd, props);
    if (editAction) {
      props.onEdit(editAction);
    }
  }

  handleClick(clickEvent: ClickEvent, props: any): void {
    if (clickEvent?.sourceEvent?.button !== 0) {
      return;
    }

    // shift key is for panning the map, and alt key is for deleting a feature
    if (clickEvent.sourceEvent?.shiftKey || clickEvent.sourceEvent?.altKey) {
      return;
    }

    // This is taken from DrawPolygonMode class in nebula.gl (to combine drawing single points and dragging)
    const { picks } = clickEvent;
    const clickedEditHandle = getPickedEditHandle(picks);
    const clickSequence = this.getClickSequence();

    let overlappingLines = false;
    if (clickSequence.length > 2 && props.modeConfig && props.modeConfig.preventOverlappingLines) {
      const currentLine = turfLineString([clickSequence[clickSequence.length - 1], clickEvent.mapCoords]);
      const otherLines = turfLineString([...clickSequence.slice(0, clickSequence.length - 1)]);
      const intersectingPoints = lineIntersect(currentLine, otherLines);
      if (intersectingPoints.features.length > 0) {
        overlappingLines = true;
      }
    }

    let positionAdded = false;
    if (!clickedEditHandle && !overlappingLines) {
      // Don't add another point right next to an existing one
      this.addClickSequence(clickEvent);
      positionAdded = true;
    }

    if (positionAdded) {
      // new tentative point
      props.onEdit({
        // data is the same
        updatedData: props.data,
        editType: 'addTentativePosition',
        editContext: {
          position: clickEvent.mapCoords,
        },
      });
    }

    if (this.clickTimeout) {
      // Double-click detected
      clearTimeout(this.clickTimeout);
      this.clickTimeout = null;

      this.addPolygon(props);
    } else {
      // Set timeout to detect double-click
      this.clickTimeout = setTimeout(() => {
        this.clickTimeout = null;
      }, 250); // 250ms threshold for double-click
    }
  }

  handleKeyUp(
    event: Parameters<NebulaDrawPolygonMode['handleKeyUp']>[0],
    props: Parameters<NebulaDrawPolygonMode['handleKeyUp']>[1]
  ) {
    // this is taken from GeoJsonEditMode class in nebula.gl
    // it is needed to cancel the current drawing when escape is pressed
    // we need to copy it from there to here because NebulaDrawPolygonMode is overriding the handleKeyUp method
    if (event.key === 'Escape') {
      this.resetClickSequence();
      props.onEdit({
        // Because the new drawing feature is dropped, so the data will keep as the same.
        updatedData: props.data,
        editType: 'cancelFeature',
        editContext: {},
      });
    }

    // this is taken from DrawPolygonMode class in nebula.gl
    if (event.key === 'Enter') {
      const clickSequence = this.getClickSequence();
      if (clickSequence.length > 2) {
        const polygonToAdd: Polygon = {
          type: 'Polygon',
          coordinates: [[...clickSequence, first(clickSequence)]],
        };
        this.resetClickSequence();

        const editAction = this.getAddFeatureOrBooleanPolygonAction(polygonToAdd, props);
        if (editAction) {
          props.onEdit(editAction);
        }
      }
    }
  }

  handleStartDragging(event: StartDraggingEvent, props: any): void {
    if (event?.sourceEvent?.shiftKey) {
      return;
    }

    super.handleStartDragging(event, props);
  }

  handleStopDragging(event: StopDraggingEvent, props: any) {
    if (event?.sourceEvent?.shiftKey) {
      return;
    }

    this.addClickSequence(event);

    // @ts-ignore
    if (this.handleDraggingThrottled && this.handleDraggingThrottled.cancel) {
      // @ts-ignore
      this.handleDraggingThrottled.cancel();
    }
  }

  handleDragging(event: DraggingEvent, props: any) {
    if (event?.sourceEvent?.shiftKey) {
      return;
    }

    super.handleDragging(event, props);
  }
}

export class DrawAnnotationPointMode extends DrawPointMode {
  handleClick(clickEvent: any, props: any): void {
    // This is so right click won't add a point
    if (clickEvent?.sourceEvent?.button !== 0) {
      return;
    }
    // alt key is for deleting a feature
    if (clickEvent.sourceEvent?.altKey) {
      return;
    }

    super.handleClick(clickEvent, props);
  }
}

export class DrawAnnotationRectangleMode extends DrawRectangleMode {
  handleClick(clickEvent: any, props: any): void {
    // This is so right click won't start drawing a rectangle
    if (clickEvent?.sourceEvent?.button !== 0) {
      return;
    }
    // alt key is for deleting a feature
    if (clickEvent.sourceEvent?.altKey) {
      return;
    }

    super.handleClick(clickEvent, props);
  }
}

export class DrawAnnotationRectangleWithModifyMode extends AnnotationCompositeMode {
  constructor() {
    super([new DrawAnnotationRectangleMode(), new ModifyOrthographicMode()]);
  }
}
