import {
  BoundingBox,
  MultiScaleImageLayerProps,
} from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/StainsLayers/types';
import { DecodedPng } from 'fast-png';
import { isArray } from 'lodash';

export interface MultiScaleImageIndex {
  x: number;
  y: number;
  z: number;
}

export interface MultiScaleImageData {
  data: Array<ImageData | DecodedPng | null>;
  format: number;
  dataFormat: number;
  isDecodedPng: boolean;
}

/**
 * Function to adjust Deck.GL tile bounds to work with deep zoom tiles.
 * This function will adjust the bounds of the tile to account for the overlap pixels and edge tiles.
 */
export function getBoundsForDeepZoomTile<S extends string[]>({
  tile,
  layerSource,
  width,
  height,
}: {
  layerSource: MultiScaleImageLayerProps<S>['layerSource'];
  width: number;
  height: number;
  tile: { index: MultiScaleImageIndex; bbox: BoundingBox };
}): [left: number, top: number, right: number, bottom: number] | null {
  const { bbox } = tile;
  const tileSize = layerSource.getTileSize();

  let left: number;
  let top: number;
  let right: number;
  let bottom: number;

  if (isArray(bbox)) {
    // Pixel coordinates array
    [left, top, right, bottom] = bbox;
  } else if ('west' in bbox) {
    // Geographic coordinates
    ({ west: left, north: top, east: right, south: bottom } = bbox);
  } else {
    // Pixel coordinates object
    ({ left, top, right, bottom } = bbox);
  }

  // Calculate the ratio of the tile size passed to Deck.GL to the actual image width - which can be bigger than the tile size in case of overlap pixels or smaller in case of edge tiles.
  const tileWidthRatio = width / tileSize;
  const tileHeightRatio = height / tileSize;

  if (isNaN(tileWidthRatio) || isNaN(tileHeightRatio)) {
    console.warn('Tile width or height ratio is NaN.', {
      tileWidthRatio,
      tileHeightRatio,
      width,
      height,
      tileSize,
    });
    return null;
  }

  const originalBoundingBoxWidth = right - left;
  const originalBoundingBoxHeight = bottom - top;

  // In case of edge tiles, we scale the bounds to match the actual image size.
  right = left + originalBoundingBoxWidth * tileWidthRatio;
  bottom = top + originalBoundingBoxHeight * tileHeightRatio;

  const bounds: [left: number, top: number, right: number, bottom: number] = [left, bottom, right, top];

  return bounds;
}

/**
 * Function to adjust Deck.GL tile bounds to work with deep zoom tiles.
 * This function will adjust the bounds of the tile to account for the overlap pixels and edge tiles.
 */
export function getBoundsForDeepZoomTileWithOverlapPixels<S extends string[]>({
  tile,
  layerSource,
  width,
  height,
  tileSize,
}: {
  layerSource: MultiScaleImageLayerProps<S>['layerSource'];
  width: number;
  height: number;
  tileSize?: number;
  tile: { index: MultiScaleImageIndex; bbox: BoundingBox };
}): [left: number, top: number, right: number, bottom: number] | null {
  /*
  Example of a grid of tiles with overlapping pixels:

  +-----+-+-+-----+-+-+-----+-+-+-----+
  |     :o|o:     :o|o:     :o|o:     |
  |  T  :o|o:  T  :o|o:  T  :o|o:  T  |
  |     :o|o:     :o|o:     :o|o:     |
  +:::::+:+:+:::::+:+:+:::::+:+:+:::::+
  |ooooo:o|o:ooooo:o|o:ooooo:o|o:ooooo|
  +-----+=+=+-----+=+=+-----+=+=+-----+
  |ooooo:o|o:ooooo:o|o:ooooo:o|o:ooooo|
  +-----+-+-+-----+-+-+-----+-+-+-----+
  |     :o|o:     :o|o:     :o|o:     |
  |  T  :o|o:  T  :o|o:  T  :o|o:  T  |
  |     :o|o:     :o|o:     :o|o:     |
  +:::::+:+:+:::::+:+:+:::::+:+:+:::::+
  |ooooo:o|o:ooooo:o|o:ooooo:o|o:ooooo|
  +-----+=+=+-----+=+=+-----+=+=+-----+
  |ooooo:o|o:ooooo:o|o:ooooo:o|o:ooooo|
  +-----+-+-+-----+-+-+-----+-+-+-----+
  |     :o|o:     :o|o:     :o|o:     |
  |  T  :o|o:  T  :o|o:  T  :o|o:  T  |
  |     :o|o:     :o|o:     :o|o:     |
  +:::::+:+:+:::::+:+:+:::::+:+:+:::::+
  |ooooo:o|o:ooooo:o|o:ooooo:o|o:ooooo|
  +-----+=+=+-----+=+=+-----+=+=+-----+
  |ooooo:o|o:ooooo:o|o:ooooo:o|o:ooooo|
  +-----+-+-+-----+-+-+-----+-+-+-----+
  |     :o|o:     :o|o:     :o|o:     |
  |  T  :o|o:  T  :o|o:  T  :o|o:  T  |
  |     :o|o:     :o|o:     :o|o:     |
  +-----+-+-+-----+-+-+-----+-+-+-----+

  T = Tile
  o = Overlap pixels

  - / | = Tile boundary
  : = Tile overlap boundary

  You can see that the overlap pixels are only present in the interior of the grid, not on the edges.

  More info: https://www.gasi.ch/blog/inside-deep-zoom-2
  */
  const { bbox, index } = tile;
  const isTopRow = index.y === 0;
  const isLeftColumn = index.x === 0;

  const overlapPixels = layerSource.getOverlapPixels();
  tileSize = tileSize ?? layerSource.getTileSize();

  // Tile size increases by overlap pixels in the x and y directions, except for the first row / column tiles which only increase in one direction.
  // We use width and height as a maximum bound for the tile in case of a non conforming tile, just to be safe.
  const tileXSize = Math.max(width, tileSize + overlapPixels + (isLeftColumn ? 0 : overlapPixels));
  const tileYSize = Math.max(height, tileSize + overlapPixels + (isTopRow ? 0 : overlapPixels));

  // Edge tiles will be smaller than the tile size, so we need to fix the bounds to match the actual image size.
  const tileWidthRatio = Math.min(width / tileXSize, 1);
  const tileHeightRatio = Math.min(height / tileYSize, 1);

  if (isNaN(tileWidthRatio) || isNaN(tileHeightRatio)) {
    return null;
  }

  let left: number;
  let top: number;
  let right: number;
  let bottom: number;

  if (isArray(bbox)) {
    // Pixel coordinates array
    [left, top, right, bottom] = bbox;
  } else if ('west' in bbox) {
    // Geographic coordinates
    ({ west: left, north: top, east: right, south: bottom } = bbox);
  } else {
    // Pixel coordinates object
    ({ left, top, right, bottom } = bbox);
  }

  const originalBoundingBoxWidth = right - left;
  const originalBoundingBoxHeigh = bottom - top;

  const bboxToTileRatioX = originalBoundingBoxWidth / tileXSize;
  const bboxToTileRatioY = originalBoundingBoxHeigh / tileYSize;

  // Increase the bounds of the tile to account for the overlap pixels.
  const offsetXStart = isLeftColumn ? 0 : overlapPixels * bboxToTileRatioX;
  const offsetYStart = isTopRow ? 0 : overlapPixels * bboxToTileRatioY;

  top -= offsetXStart;
  left -= offsetYStart;

  const offsetXEnd = (isLeftColumn ? 1 : 2) * overlapPixels * bboxToTileRatioX;
  const offsetYEnd = (isTopRow ? 1 : 2) * overlapPixels * bboxToTileRatioY;

  const bboxWidth = originalBoundingBoxWidth + offsetXEnd;
  const bboxHeight = originalBoundingBoxHeigh + offsetYEnd;

  // In case of edge tiles, we scale the bounds to match the actual image size.
  bottom = top + bboxHeight * tileHeightRatio;
  right = left + bboxWidth * tileWidthRatio;

  const bounds: [left: number, top: number, right: number, bottom: number] = [left, bottom, right, top];
  return bounds;
}
