export interface StainType {
  id: string;
  displayName: string;
  index: number;
  canBeMifMarker: boolean;
  aliases?: string[];
  lineage?: boolean;
  phenotypic?: boolean;
  membrane?: boolean;
  nucleus?: boolean;
  cytoplasm?: boolean;
  function?: string;
  deletedAt?: string | null;
}

export const MULTIPLEX_STAIN_ID = 'mplex';
