import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Accordion, AccordionDetails, AccordionSummary, Grid, Link, Skeleton, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getSlideTagDetails } from 'api/slideTags';
import CopyableText from 'components/atoms/CopyableText';
import { SlideTag, SlideTagStudyDetails } from 'interfaces/slideTag';
import { filter, isEmpty, map, size, sum, uniq } from 'lodash';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigationToViewerPage } from 'utils/useNavigationToViewerPage';
import SlideLabel from '../ExternalLabelsDataGrid/SlideLabel';

interface SlideTagDetailsProps {
  tag: SlideTag;
}

export const SlideTagDetails: React.FC<SlideTagDetailsProps> = ({ tag }: SlideTagDetailsProps) => {
  const {
    data: slideTagDetails,
    isLoading,
    isError,
  } = useQuery<SlideTagStudyDetails[]>({
    queryKey: ['slideTagDetails', tag?.id],
    queryFn: () => getSlideTagDetails(tag?.id),
    enabled: Boolean(tag?.id),
  });

  return (
    <>
      {!tag?.id ? (
        <Typography>Select tag to see the details</Typography>
      ) : isLoading ? (
        <>
          <Skeleton variant="text" width="100%" height={40} />
          <Skeleton variant="text" width="100%" height={40} />
          <Skeleton variant="text" width="100%" height={40} />
          <Skeleton variant="text" width="100%" height={40} />
        </>
      ) : isError ? (
        <Typography>Error loading slide tag details</Typography>
      ) : (
        <Grid container direction="column" spacing={1}>
          <TagSummary tagDetails={slideTagDetails} />
          <Grid item>
            {map(slideTagDetails, (studyDetails) => (
              <StudyDetails key={studyDetails.studyId} tagStudyDetails={studyDetails} />
            ))}
          </Grid>
        </Grid>
      )}
    </>
  );
};

interface TagSummaryProps {
  tagDetails: SlideTagStudyDetails[];
}

const TagSummary: React.FC<TagSummaryProps> = ({ tagDetails }) => {
  const studiesWithTagInQcLabelsSettings = filter(tagDetails, (studyDetails) => !isEmpty(studyDetails.labels));
  const totalStudiesWithTagInQcLabelsSettingsCount = size(studiesWithTagInQcLabelsSettings);

  const totalSlidesWithTagCount = sum(map(tagDetails, (studyDetails) => size(studyDetails.slideIds)));
  const totalStudiesWithSlidesWithTagCount = size(
    filter(tagDetails, (studyDetails) => !isEmpty(studyDetails.slideIds))
  );
  const totalLabsWithTagCount = size(uniq(map(tagDetails, 'labId')));

  return (
    <Grid item container direction="column" spacing={1}>
      <Grid item>
        <Typography variant="body1" fontWeight="bold">
          Summary
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">Total Labs: {totalLabsWithTagCount}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">
          Total Studies with tag in qc labels settings: {totalStudiesWithTagInQcLabelsSettingsCount}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">
          Total Studies that have slides with tag: {totalStudiesWithSlidesWithTagCount}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">Total Slides with tag: {totalSlidesWithTagCount}</Typography>
      </Grid>
    </Grid>
  );
};

interface StudyDetailsProps {
  tagStudyDetails: SlideTagStudyDetails;
}

const StudyDetails: React.FC<StudyDetailsProps> = ({ tagStudyDetails }) => {
  const { studyId, studyName, labId, labName, slideIds, labels } = tagStudyDetails;
  const { getUrlToSlidePage } = useNavigationToViewerPage();

  const slidesCount = size(slideIds);

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="body1">
          {labName} - {studyName} - ({slidesCount} slide{slidesCount != 1 ? 's' : ''})
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="column" flexWrap="nowrap" spacing={1}>
          <Grid item container spacing={1}>
            <Grid item>
              <Typography variant="body1" fontWeight="bold">
                Lab:
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">{labName}</Typography>
            </Grid>
          </Grid>
          <Grid item container spacing={1}>
            <Grid item>
              <Typography variant="body1" fontWeight="bold">
                Lab ID:
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <CopyableText text={labId} />
            </Grid>
          </Grid>
          <Grid item container spacing={1}>
            <Grid item>
              <Typography variant="body1" fontWeight="bold">
                Study:
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1"> {studyName}</Typography>
            </Grid>
          </Grid>
          <Grid item container spacing={1} width="100%">
            <Grid item>
              <Typography variant="body1" fontWeight="bold">
                Study ID:
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <CopyableText text={studyId} />
            </Grid>
          </Grid>
          <Grid item>
            <Accordion defaultExpanded={true}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="body1">Label Configurations ({size(labels)})</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {map(labels, (label) => (
                  <SlideLabel label={label} />
                ))}
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item>
            {isEmpty(slideIds) ? (
              <Typography variant="body1" fontWeight="bold">
                No slide IDs with this label in this study
              </Typography>
            ) : (
              <Accordion defaultExpanded={true}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="body1">Slide IDs ({size(uniq(slideIds))})</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {map(uniq(slideIds), (slideId) => (
                    <Grid item key={slideId}>
                      <Link
                        to={getUrlToSlidePage({ slideId, labId, caseStudyId: studyId })}
                        target="_blank"
                        rel="noopener noreferrer"
                        component={RouterLink}
                      >
                        <Typography variant="caption" key={slideId}>
                          {slideId}
                        </Typography>
                      </Link>
                    </Grid>
                  ))}
                </AccordionDetails>
              </Accordion>
            )}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
