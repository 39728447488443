import {
  computeDynamicRangeFromNormalizationOrChannelHistogram,
  useMultiplexSlideChannelNormalizationData,
  useMultiplexSlideChannelNormalizationsOptions,
} from 'components/Procedure/SlideControls/Multiplex/channelNormalizations';
import { Slide } from 'interfaces/slide';
import { find, toPairs } from 'lodash';
import React from 'react';
import ImageWithAdjustedLevels, { adjustmentSettingsPresets, getImageBitDepth } from './ImageWithAdjustedLevels';

const getDapiChannelIndexFromSlide = (slide: Slide): number | undefined => {
  try {
    const slideChannels = toPairs(slide.channels);
    const dapiChannel = find(slideChannels, ([, channel]) => channel.name.toLowerCase() === 'dapi');
    if (!dapiChannel) {
      console.error('No DAPI channel found in slide');
      return undefined;
    } else {
      return Number(dapiChannel[0]);
    }
  } catch (e) {
    console.error('Error getting channel data from slide', e);
    return undefined;
  }
};

interface MultiplexSlideImageProps {
  imageUrl: string;
  handleImageError: () => void;
  slide?: Slide;
}

const MultiplexSlideImage: React.FC<MultiplexSlideImageProps> = ({ imageUrl, handleImageError, slide }) => {
  const bitDepth: 8 | 16 = getImageBitDepth(slide.encoding, imageUrl);
  const colorAdjustmentSettings = adjustmentSettingsPresets.multiplex[bitDepth];

  const { defaultNormalization } = useMultiplexSlideChannelNormalizationsOptions({ slide });
  const { normalizationData } = useMultiplexSlideChannelNormalizationData(
    slide,
    defaultNormalization?.experimentResultId,
    Boolean(defaultNormalization)
  );
  if (normalizationData) {
    const dapiChannelIndexFromChannelData = getDapiChannelIndexFromSlide(slide);
    const dynamicRange = computeDynamicRangeFromNormalizationOrChannelHistogram(
      dapiChannelIndexFromChannelData || 0,
      undefined,
      normalizationData,
      bitDepth === 16
    );
    colorAdjustmentSettings.blackPoint = dynamicRange[0];
    colorAdjustmentSettings.whitePoint = dynamicRange[1];
  }

  return (
    <ImageWithAdjustedLevels
      src={imageUrl}
      onError={handleImageError}
      bitDepth={bitDepth}
      {...(colorAdjustmentSettings || {})}
    />
  );
};

export default MultiplexSlideImage;
