// @ts-nocheck
import Grid from '@mui/material/Grid';
import { CohortQueryObject, cohortQuerySelectionKeys, MainPageFilterKey } from 'interfaces/cohort';
import { filter, includes, isArray, isEmpty, join, keys, map, values } from 'lodash';
import React from 'react';
import { isRange } from 'utils/advancedFilterAdapter';
import { useGetNameOverrideOrDisplayNameWithContext } from 'utils/features/contextHooks';
import { formatDataKeyToText, formatRangeObjectToText, formatRangeQueryStringToText, humanize } from 'utils/helpers';
import { useStainTypeIdToDisplayName } from 'utils/useStainTypeIdToDisplayName';
import { flattenItems, getTreeDataAsArray } from './CancerSubtypesField/cancerSubtypeTreeHelpers';
import CohortQueryListItem from './CohortQueryListItem';
import useMainFilters from './hooks/useMainFilters';
import filterFieldsConfig from './ProcedureFilterFields';

interface Props {
  queryObject?: CohortQueryObject;
  showIcon?: boolean;
  showSelection?: boolean;
}

const CohortQueryList = ({ queryObject, showIcon, showSelection }: Props) => {
  const { getNameOverrideOrDisplayNameWithContext } = useGetNameOverrideOrDisplayNameWithContext(true);

  const { cancerTypesList, cancerSubtypes } = useMainFilters();
  const { stainTypeIdToDisplayName } = useStainTypeIdToDisplayName();

  const fullTypesArr = getTreeDataAsArray(values(cancerSubtypes));

  const allChildrenSubTypes = flattenItems(fullTypesArr);

  const getSubtypeNames = (ids: string[]) => {
    const subtypes = filter(allChildrenSubTypes, (item) => includes(ids, item.code));
    const subtypeNames = join(map(subtypes, 'name'), ', ');

    return subtypeNames;
  };

  const getCancerTypeNames = (ids: number[]) => {
    const cancerTypes = filter(cancerTypesList, (item) => includes(ids, item.id));
    const cancerTypeNames = join(map(cancerTypes, 'displayName'), ', ');

    return cancerTypeNames;
  };

  const getNoneValue = (value: number) => {
    return value === -1 ? 'None' : value;
  };

  const getTextValue = (key: string, value: number[] | string[]) => {
    if (key === 'cancerTypes') {
      return getCancerTypeNames(map(value, (item) => Number(item)));
    } else if (key === 'stains') {
      return isArray(value)
        ? join(map(value as string[], stainTypeIdToDisplayName), ', ')
        : stainTypeIdToDisplayName(value);
    } else if (key === 'cancerSubtypes') {
      return getSubtypeNames(map(value, (item) => String(item)));
    } else if (typeof value === 'number') {
      return getNoneValue(value);
    }
    return value;
  };

  return (
    <Grid container direction="column">
      <Grid container item direction="column">
        {map(
          queryObject.filters,
          (filterItem, filterKey: keyof CohortQueryObject) =>
            queryObject[filterKey] !== 'All' &&
            queryObject[filterKey] !== '' &&
            queryObject.filters[filterKey] !== 0 && (
              <CohortQueryListItem
                key={filterKey}
                itemKey={filterKey}
                value={`${humanize(filterKey)}: ${
                  isRange(queryObject?.filters[filterKey]?.filterType)
                    ? formatRangeObjectToText(queryObject?.filters[filterKey]?.value)
                    : getTextValue(filterKey, queryObject?.filters[filterKey])
                }`}
                showIcon={showIcon}
              />
            )
        )}
      </Grid>

      <Grid container item direction="column">
        {map(filterFieldsConfig, (section, sectionKey) => (
          <Grid item key={sectionKey}>
            {map(section.fieldGroups, (fieldGroup) =>
              map(
                fieldGroup.fields,
                (field, fieldKey) =>
                  includes(keys(queryObject.clinicalData), field.dataKey) &&
                  !isEmpty(queryObject.clinicalData[field.dataKey]?.value) && (
                    <CohortQueryListItem
                      key={fieldKey}
                      itemKey={fieldKey}
                      showIcon={showIcon}
                      value={`${field.label || formatDataKeyToText(field.dataKey)}: ${
                        field.filterType !== 'range' && field.filterType !== 'date-range'
                          ? queryObject?.clinicalData[field.dataKey]?.value
                          : formatRangeObjectToText(queryObject?.clinicalData[field.dataKey]?.value)
                      }`}
                    />
                  )
              )
            )}
          </Grid>
        ))}
      </Grid>

      <Grid container item direction="column">
        {map(queryObject.features, (featureValue, featureKey) => {
          return (
            <CohortQueryListItem
              showIcon={showIcon}
              key={featureKey}
              itemKey={featureKey}
              value={`${getNameOverrideOrDisplayNameWithContext(featureKey)}: ${formatRangeQueryStringToText(
                featureValue
              )}`}
            />
          );
        })}
      </Grid>
      {queryObject?.searchTerm && (
        <CohortQueryListItem
          showIcon={showIcon}
          key={MainPageFilterKey.searchTerm}
          itemKey={MainPageFilterKey.searchTerm}
          value={`${humanize(MainPageFilterKey.searchTerm)}: ${queryObject?.searchTerm}`}
        />
      )}
      {showSelection &&
        map(
          cohortQuerySelectionKeys,
          (selectionKey) =>
            queryObject?.[selectionKey] && (
              <CohortQueryListItem
                showIcon={showIcon}
                key={selectionKey}
                itemKey={selectionKey}
                value={`${humanize(selectionKey)}: ${queryObject?.[selectionKey]}`}
              />
            )
        )}
    </Grid>
  );
};

export default CohortQueryList;
