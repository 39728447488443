import Grid from '@mui/material/Grid';
import { useQuery } from '@tanstack/react-query';
import { fetchStudies } from 'api/study';
import { getInternalUsers } from 'api/userCredentials';
import LabelledDropdown from 'components/atoms/Dropdown/LabelledDropdown';
import LabelledInput from 'components/atoms/LabelledInput/LabelledInput';
import { ALL_STUDIES_VALUE } from 'components/SearchFilters/hooks/useMainFilters';
import AutocompleteFilter from 'components/SearchFilters/MainFilters/AutocompleteFilter';
import { concat, isEmpty, isNumber, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { JsonParam, useQueryParams } from 'use-query-params';
import { useUiSettings } from 'utils/queryHooks/uiConstantsHooks';
import { useCurrentLabId } from 'utils/useCurrentLab';

const Filters: React.FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const { labId } = useCurrentLabId();
  const { uiSettings } = useUiSettings();
  const enumDisplayNames = uiSettings.enumDisplayNames;

  const { data: studies, isLoading: studiesLoading } = useQuery(['studies', labId], fetchStudies(labId));
  let studyOptions: any = null;

  if (!isEmpty(studies)) {
    studyOptions = map(studies, (study) => ({
      value: study.id,
      text: study.name,
    }));

    studyOptions = concat({ value: ALL_STUDIES_VALUE, text: 'All' }, studyOptions);
  }

  const senderOptions = map(enumDisplayNames.requestSender, (requestSender) => {
    return {
      value: requestSender.value,
      text: requestSender.label,
    };
  });

  const statusOptions = map(enumDisplayNames.jobStatus, (jobStatus) => {
    return {
      value: jobStatus.value,
      text: jobStatus.label,
    };
  });

  const handleFilterChange = (filterKey: string, value: any) => {
    setQueryParams(
      { jobsFilters: { ...filters, [filterKey]: !isNumber(value) && isEmpty(value) ? undefined : value } },
      'replaceIn'
    );
  };

  const { data: internalUsers, isLoading: internalUsersLoading } = useQuery(['internalUsers'], getInternalUsers);
  const internalUsersOptions = map(internalUsers, (internalUser) => ({
    value: internalUser.id,
    text: internalUser.name,
  }));

  const [{ jobsFilters: filters }, setQueryParams] = useQueryParams({
    jobsFilters: JsonParam,
  });

  // Have state for jobName because of debounce
  const [jobName, setJobName] = useState(filters?.jobName || null);

  useEffect(() => {
    setJobName(filters?.jobName || null);
  }, [filters?.jobName]);

  const handleJobNameChange = (newJobName: string) => {
    setJobName(newJobName);
    debouncedUpdateJobNameQueryParam(newJobName);
  };

  const updateJobNameQueryParam = (updatedJobName: string) =>
    setQueryParams({ jobsFilters: { ...filters, jobName: updatedJobName } });

  const debouncedUpdateJobNameQueryParam = useDebouncedCallback(updateJobNameQueryParam, 1000);

  const numOfFilters = 5;
  const xsGrid = 12 / numOfFilters;
  const spacing = 1;

  return (
    <Grid my={2} container spacing={spacing} wrap="wrap">
      <Grid item xs={xsGrid}>
        <LabelledDropdown
          size="small"
          label="Study"
          options={studyOptions}
          value={studyOptions ? filters?.studyId || 0 : ''}
          onOptionSelected={(optionValue) => {
            handleFilterChange('studyId', optionValue);
          }}
          loading={studiesLoading}
        />
      </Grid>
      <Grid item xs={xsGrid}>
        <LabelledInput
          size="small"
          label="Job Name"
          value={jobName || ''}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleJobNameChange(event.target.value)}
          onReset={() => {
            setJobName('');
            handleJobNameChange(null);
          }}
        />
      </Grid>

      <Grid item xs={xsGrid}>
        <AutocompleteFilter
          label="User Name"
          options={internalUsersOptions}
          limitTags={1}
          handleChange={(optionValue) => {
            handleFilterChange('users', optionValue);
          }}
          value={filters?.users || []}
          loading={internalUsersLoading}
        />
      </Grid>

      <Grid item xs={xsGrid}>
        <AutocompleteFilter
          label={'Source'}
          options={senderOptions}
          limitTags={1}
          handleChange={(optionValue) => {
            handleFilterChange('senders', optionValue);
          }}
          value={filters?.senders || []}
        />
      </Grid>
      <Grid item xs={xsGrid}>
        <AutocompleteFilter
          label={'Status'}
          options={statusOptions}
          limitTags={1}
          handleChange={(optionValue) => {
            handleFilterChange('statuses', optionValue);
          }}
          value={filters?.statuses || []}
        />
      </Grid>
    </Grid>
  );
};

export default Filters;
