import { Permission } from 'interfaces/permissionOption';
import { Procedure } from 'interfaces/procedure';
import { Slide } from 'interfaces/slide';
import { compact, filter, find, first, forEach, includes, isEmpty, isEqual, map, size, slice, uniq } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { BooleanParam, DelimitedArrayParam, StringParam, useQueryParam, withDefault } from 'use-query-params';
import { useEncodedFilters } from './useEncodedFilters';
import { usePermissions } from './usePermissions';
import { useStudyPlatformSettings } from './useStudyPlatformSettings';

const isPreciseStainMatch = (slide: Slide, stainTypes: string[]) =>
  Boolean(slide) && includes(stainTypes, slide.stainingType) && !slide.positiveControl && !slide.negativeControl;

export const useGetDefaultSelectedSlideIds = (studyId: string) => {
  const params = useParams();

  const { hasPermission } = usePermissions();
  const isAccessionViewer = hasPermission(Permission.ViewAccessionDashboard);

  const hasTaggingPermissions = hasPermission(Permission.EditSlideTagsAssignments);
  const [slideTagsDrawerOpen] = useQueryParam('slideTagsDrawerOpen', BooleanParam);
  const [slideIdForReviewing] = useQueryParam('slideIdForReviewing', StringParam);

  const { defaultStainingType: defaultStainingTypeFromStudy, defaultSlideViewMode } = useStudyPlatformSettings(studyId);
  const displayOneSlideByDefault = defaultSlideViewMode === 'single';

  const { queryParams } = useEncodedFilters();
  const filteredStainTypes = queryParams?.filters?.stains;
  const [viewersStainPreferences] = useQueryParam('viewersStainPreferences', DelimitedArrayParam);

  const [selectedSlideIds] = useQueryParam('selectedSlideIds', DelimitedArrayParam);

  return useCallback(
    (
      slidesInput: Slide[]
    ): {
      slideIds: string[];
      // The source of the selected slides returned by this function (for debugging)
      source: 'urlPath' | 'filters' | 'queryParams' | 'viewersStainPreferences' | 'study' | 'procedure' | 'tagging';
    } => {
      const slides = compact(slidesInput);
      let slideIdFromUrlPath: string = params.slideId;
      if (slideIdFromUrlPath && !includes(map(slides, 'id'), slideIdFromUrlPath)) {
        if (!isEmpty(slides)) {
          // If the slide from the URL is not in the procedure, we will ignore it
          console.warn('Slide from URL not found in procedure slides', { slides, slideIdFromUrlPath });
        }
        slideIdFromUrlPath = undefined;
      }
      const slideIdForReviewInCase = find(slides, { id: slideIdForReviewing })?.id;
      const isTaggingSlide = hasTaggingPermissions && slideTagsDrawerOpen && Boolean(slideIdForReviewInCase);
      // First get good defaults based on tagging and study settings
      const slidesWithFilteredStainTypes = uniq([
        ...filter(slides, (slide) => isPreciseStainMatch(slide, filteredStainTypes)),
        ...filter(slides, (slide) => includes(filteredStainTypes, slide.stainingType)),
      ]);

      const firstSlideIdWithDefaultStainTypeFromStudy =
        !filteredStainTypes || includes(filteredStainTypes, defaultStainingTypeFromStudy)
          ? find(slides, (slide) => isPreciseStainMatch(slide, [defaultStainingTypeFromStudy]))?.id ||
            find(slides, { stainingType: defaultStainingTypeFromStudy })?.id
          : undefined;

      const slidesThatAreNotControls = filter(slides, (slide) => !slide.positiveControl && !slide.negativeControl);

      const firstSlideIdMatchingFilteredStainTypes = first(slidesWithFilteredStainTypes)?.id;

      const defaultFirstSlideId = slideIdFromUrlPath
        ? slideIdFromUrlPath
        : isTaggingSlide
        ? // Use the slide for review as the first slide if the user is tagging a slide
          slideIdForReviewInCase
        : // otherwise use the first slide with the default stain type
          firstSlideIdWithDefaultStainTypeFromStudy ||
          // otherwise use the first slide with a stain type from the filters
          firstSlideIdMatchingFilteredStainTypes ||
          // otherwise use the first slide from the procedure that isn't a control
          first(slidesThatAreNotControls)?.id ||
          // otherwise use the first slide from the procedure
          first(slides)?.id;

      const defaultSecondSlideId =
        // Use the first slide with a stain type from the filters that is not the first slide
        find(slidesWithFilteredStainTypes, (slide) => slide.id !== defaultFirstSlideId)?.id ||
        // otherwise use the first slide from the procedure that is not the first slide
        find(slides, (slide) => slide.id !== defaultFirstSlideId)?.id;

      const defaultsWithoutViewerStainPreferences = compact([defaultFirstSlideId, defaultSecondSlideId]);

      if (!isEmpty(viewersStainPreferences)) {
        // If the user has set their preferences, we will try to match them
        const defaultSlidesWithoutViewerStainPreferences = map(defaultsWithoutViewerStainPreferences, (id) =>
          find(slides, { id })
        );
        const matchingSlides: string[] = [];
        forEach(viewersStainPreferences, (stainType, viewerIndex) => {
          if (isTaggingSlide && viewerIndex === 0) {
            // If the user is tagging a slide, we want to make sure the slide for review is the first one
            matchingSlides.push(slideIdForReviewInCase);
            return;
          }
          // If a slide has already been selected and is in the procedure, we will keep it
          const currentlySelectedSlide = selectedSlideIds?.[viewerIndex];
          if (currentlySelectedSlide && includes(map(slides, 'id'), currentlySelectedSlide)) {
            matchingSlides.push(currentlySelectedSlide);
            return;
          }
          const findNewPreciseStainMatch = (slide: Slide) =>
            isPreciseStainMatch(slide, [stainType]) && !includes(matchingSlides, slide?.id);

          const findNewStainMatch = (slide: Slide) =>
            slide?.stainingType === stainType && !includes(matchingSlides, slide?.id);

          const matchingSlideFromDefaults = (
            find(defaultSlidesWithoutViewerStainPreferences, findNewPreciseStainMatch) ||
            find(defaultSlidesWithoutViewerStainPreferences, findNewStainMatch)
          )?.id;
          if (matchingSlideFromDefaults) {
            matchingSlides.push(matchingSlideFromDefaults);
          }
          const matchingSlideFromAllSlides = (find(slides, findNewPreciseStainMatch) || find(slides, findNewStainMatch))
            ?.id;

          if (matchingSlideFromAllSlides) {
            matchingSlides.push(matchingSlideFromAllSlides);
          }
        });
        if (slideIdFromUrlPath && !includes(matchingSlides, slideIdFromUrlPath)) {
          // If the user has a slide in the URL, we will make sure it is in the selected slides, regardless of the viewer preferences
          if (size(matchingSlides) > 1) {
            // If there are already two slides selected, we will replace the second one, to avoid messing with tagging
            matchingSlides[1] = slideIdFromUrlPath;
          } else {
            matchingSlides[0] = slideIdFromUrlPath;
          }
        }
        if (!isEmpty(matchingSlides)) {
          return { slideIds: matchingSlides, source: 'viewersStainPreferences' };
        }
      }
      // Otherwise, we will show the two default slides, unless the user is tagging a slide, there is only one slide, or the user has set the viewer to show one slide by default
      const slideIds =
        !isTaggingSlide && size(slides) > 1 && !isAccessionViewer && !displayOneSlideByDefault
          ? defaultsWithoutViewerStainPreferences
          : [first(defaultsWithoutViewerStainPreferences)];

      const newFirstSlideId = first(slideIds);
      const doSlidesMatchStudySettings =
        firstSlideIdWithDefaultStainTypeFromStudy === newFirstSlideId &&
        size(slideIds) === (displayOneSlideByDefault ? 1 : 2);
      const source = doSlidesMatchStudySettings
        ? 'study'
        : slideIdFromUrlPath === newFirstSlideId
        ? 'urlPath'
        : isTaggingSlide
        ? 'tagging'
        : firstSlideIdMatchingFilteredStainTypes === newFirstSlideId
        ? 'filters'
        : 'procedure';
      return { slideIds, source };
    },
    [
      hasTaggingPermissions,
      slideTagsDrawerOpen,
      slideIdForReviewing,
      defaultStainingTypeFromStudy,
      filteredStainTypes,
      viewersStainPreferences,
      isAccessionViewer,
      displayOneSlideByDefault,
    ]
  );
};

/**
 * hook for managing the selectedSlideIds query param
 * @param procedure the procedure to get the default selected slides from (if needed)
 * @returns the current selectedSlideIds from the query param
 */
export const useSelectedSlideIds = (procedure?: Procedure) => {
  const getDefaultSelectedSlideIds = useGetDefaultSelectedSlideIds(procedure?.studyId);

  const { slideIds: defaultSelectedSlideIds } = useMemo(
    () => (procedure ? getDefaultSelectedSlideIds(procedure?.slides) : { slideIds: [], source: 'procedure' }),
    [procedure?.slides]
  );

  // We try to start the viewer with good defaults, but will change the selectedSlideIds as needed to reflect study settings and user actions
  const SelectedSlideIdsParam = useMemo(
    () => withDefault(DelimitedArrayParam, defaultSelectedSlideIds),
    [defaultSelectedSlideIds]
  );
  return useQueryParam('selectedSlideIds', SelectedSlideIdsParam);
};

export const useSetSelectedSlideIds = (procedure: Procedure) => {
  const [selectedSlideIds, setSelectedSlideIdsParam] = useSelectedSlideIds(procedure);
  const [viewersStainPreferences, setViewersStainPreferences] = useQueryParam(
    'viewersStainPreferences',
    DelimitedArrayParam
  );

  const setSelectedSlideIds = useCallback(
    (newSetSelectedSlideIds: string[]) => {
      // Make sure the selected slides are in the procedure
      const slidesFromProcedure = compact(map(newSetSelectedSlideIds, (id) => find(procedure?.slides, { id })));
      const newSelectedSlides = map(slidesFromProcedure, 'id');
      setSelectedSlideIdsParam(
        (currentSelectedSlides) =>
          !isEqual(newSelectedSlides, currentSelectedSlides) ? newSelectedSlides : currentSelectedSlides,
        'replaceIn'
      );
      // Update the viewersStainPreferences based on the selected slides
      const newStainPreferences = map(slidesFromProcedure, 'stainingType');
      setViewersStainPreferences(
        (currentViewersStainPreferences) =>
          !isEqual(newStainPreferences, currentViewersStainPreferences)
            ? newStainPreferences
            : currentViewersStainPreferences,
        'replaceIn'
      );
    },
    [setSelectedSlideIdsParam, procedure?.slides]
  );

  const setFirstSlideId = useCallback(
    (id: string) => {
      setSelectedSlideIds([id, ...slice(selectedSlideIds, 1)]);
    },
    [selectedSlideIds, setSelectedSlideIds]
  );

  return {
    selectedSlideIds,
    viewersStainPreferences,
    setSelectedSlideIds,
    setFirstSlideId,
  };
};

export const useSyncSelectedSlideIds = (procedure: Procedure) => {
  const studyId = procedure?.studyId;
  const getDefaultSelectedSlideIds = useGetDefaultSelectedSlideIds(studyId);

  const { selectedSlideIds, setSelectedSlideIds, viewersStainPreferences } = useSetSelectedSlideIds(procedure);
  const { hasPermission } = usePermissions();

  const hasTaggingPermissions = hasPermission(Permission.EditSlideTagsAssignments);
  const [slideTagsDrawerOpen] = useQueryParam('slideTagsDrawerOpen', BooleanParam);
  const [slideIdForReviewing, setSlideIdForReviewing] = useQueryParam('slideIdForReviewing', StringParam);
  const slideIdForReviewInCase = find(procedure?.slides, { id: slideIdForReviewing })?.id;
  const isTaggingSlide = hasTaggingPermissions && slideTagsDrawerOpen && Boolean(slideIdForReviewInCase);

  const { isLoadingStudySettings } = useStudyPlatformSettings(studyId);
  const [studySettingsApplied, setStudySettingsApplied] = useQueryParam('studySettingsApplied', StringParam);

  // Sync the selectedSlideIds query param when starting to tag a slide
  useEffect(() => {
    if (slideIdForReviewing && !slideIdForReviewInCase) {
      console.warn('Slide for reviewing not found in procedure slides', {
        slideIdForReviewing,
        slides: map(procedure?.slides, 'id'),
      });
      setSlideIdForReviewing(undefined, 'replaceIn');
    } else if (isTaggingSlide) {
      // If the user asked to tag a slide, make sure it is selected on the first viewer
      if (first(selectedSlideIds) === slideIdForReviewInCase) {
        return;
      } else if (includes(selectedSlideIds, slideIdForReviewInCase)) {
        console.info('Moving slide for review to the first viewer');
        setSelectedSlideIds([
          slideIdForReviewInCase,
          ...filter(selectedSlideIds, (id) => id !== slideIdForReviewInCase),
        ]);
      } else {
        console.info('Adding slide for review to the first viewer');
        setSelectedSlideIds([slideIdForReviewInCase, ...slice(selectedSlideIds, 1)]);
      }
    }
  }, [isTaggingSlide, slideIdForReviewInCase, selectedSlideIds]);

  useEffect(() => {
    const { source: defaultSelectedSlideSource, slideIds: newSelectedSlideIds } = getDefaultSelectedSlideIds(
      procedure?.slides
    );
    if (studyId && !isLoadingStudySettings && studySettingsApplied !== studyId && isEmpty(viewersStainPreferences)) {
      // Update selected slides, if needed, based on the study settings
      if (
        !isEqual(newSelectedSlideIds, selectedSlideIds) &&
        // Only make the change if the slide for review is the first one if it is selected
        // Otherwise we will have a race between the useEffects
        (!slideIdForReviewInCase || first(newSelectedSlideIds) === slideIdForReviewInCase)
      ) {
        console.info('Updating selected slides based on study settings');
        if (defaultSelectedSlideSource === 'study') {
          setStudySettingsApplied(studyId, 'replaceIn');
        } else {
          console.warn('New selected slides do not match study settings', {
            selectedSlideIds,
            newSelectedSlideIds,
            defaultSelectedSlideSource,
          });
        }
        setSelectedSlideIds(newSelectedSlideIds);
      }
    } else if (isEmpty(selectedSlideIds)) {
      // If no slides are selected, set the default selected slides
      console.info('Setting default selected slides');
      setSelectedSlideIds(newSelectedSlideIds);
    }
  }, [
    isLoadingStudySettings,
    studySettingsApplied,
    viewersStainPreferences,
    selectedSlideIds,
    procedure?.slides,
    slideIdForReviewInCase,
    setSelectedSlideIds,
    getDefaultSelectedSlideIds,
  ]);
};
