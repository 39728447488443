import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getAllStudies } from 'api/study';
import { modelTypesByApiModelValue } from 'components/Pages/Jobs/inferenceFieldsOptions';
import { Model, trainingTypesByValue } from 'interfaces/model';
import { find, isBoolean, join, map, startCase, toString } from 'lodash';
import moment from 'moment';
import React from 'react';
import { ModelDetailsRow } from './ModelDetailsRow';

interface ModelDetailsProps {
  modelDetails: Model;
  onClosed?: () => void;
}

export const ModelDetails: React.FC<React.PropsWithChildren<ModelDetailsProps>> = ({ modelDetails, onClosed }) => {
  const { data: allStudies } = useQuery(['allStudies'], getAllStudies);

  return (
    <Grid container p={2} direction="column" spacing={2} flexWrap="nowrap">
      <Grid container item alignItems="center">
        <Grid item xs={10}>
          <Typography variant="h3">{modelDetails?.meta?.modelId}</Typography>
        </Grid>
        <Grid item xs={2} textAlign="right">
          <IconButton onClick={onClosed}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Grid item>
        <Typography variant="body1">{modelDetails?.meta?.description}</Typography>
      </Grid>
      <Grid item container direction="column">
        <Grid item container spacing={1} alignItems="center">
          <Grid item>
            <Typography variant="body1">Created at:</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2"> {modelDetails?.date && moment(modelDetails?.date).format('lll')}</Typography>
          </Grid>
        </Grid>
        {modelDetails?.hashData && (
          <Grid item container spacing={1} alignItems="center">
            <Grid item>
              <Typography variant="body1">Hash:</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2"> {modelDetails?.hashData}</Typography>
            </Grid>
          </Grid>
        )}
        {modelDetails?.hashDeps && (
          <Grid item container spacing={1} alignItems="center">
            <Grid item>
              <Typography variant="body1">Hash Deps:</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2"> {modelDetails?.hashDeps}</Typography>
            </Grid>
          </Grid>
        )}
        <Grid item container spacing={1} alignItems="center">
          <Grid item xs={1}>
            <Typography variant="body1">URL:</Typography>
          </Grid>
          <Grid item xs={11}>
            <Tooltip placement="top-start" title={modelDetails?.url}>
              <Typography variant="body2" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {modelDetails?.url}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Accordion defaultExpanded slotProps={{ transition: { unmountOnExit: true } }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography variant="h4">Metadata</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container direction="column">
              <ModelDetailsRow fieldName="Project Name" fieldValue={modelDetails?.meta?.projectName} />
              <ModelDetailsRow
                fieldName="Study"
                fieldValue={find(allStudies, { id: modelDetails?.meta?.studyId })?.name ?? modelDetails?.meta?.studyId}
              />
              <ModelDetailsRow
                fieldName="Model Type"
                fieldValue={
                  modelTypesByApiModelValue[modelDetails?.meta?.modelType]?.text ?? modelDetails?.meta?.modelType
                }
              />
              <ModelDetailsRow fieldName="Stain Types" fieldValue={join(modelDetails?.meta?.stainTypes, ', ')} />
              <ModelDetailsRow fieldName="Cancer Types" fieldValue={join(modelDetails?.meta?.tissueTypes, ', ')} />
              <ModelDetailsRow
                fieldName="Training Type"
                fieldValue={
                  trainingTypesByValue[modelDetails?.meta?.trainingType]?.text ?? modelDetails?.meta?.trainingType
                }
              />
              <ModelDetailsRow fieldName="End2End" fieldValue={startCase(toString(modelDetails?.meta?.end2end))} />
              <ModelDetailsRow
                fieldName="Assignment Ids"
                fieldValue={join(modelDetails?.meta?.deps?.assignmentIds, ', ')}
              />
            </Grid>
          </AccordionDetails>
        </Accordion>
        {modelDetails?.meta?.deps?.classesConfig && (
          <Accordion defaultExpanded slotProps={{ transition: { unmountOnExit: true } }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography variant="h4">Classes Config</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container direction="column">
                {map(modelDetails?.meta?.deps?.classesConfig, (classConfig, key) => {
                  return (
                    <Accordion slotProps={{ transition: { unmountOnExit: true } }} disableGutters>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="h5">{key}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container direction="column">
                          <ModelDetailsRow fieldName="Id" fieldValue={classConfig?.id ?? '---'} />
                          <ModelDetailsRow fieldName="Color" fieldValue={classConfig?.color ?? '---'} />
                          <ModelDetailsRow
                            fieldName="Data Items"
                            fieldValue={join(classConfig?.dataItems, ', ') ?? '---'}
                          />
                          <ModelDetailsRow
                            fieldName="Ignore"
                            fieldValue={
                              isBoolean(classConfig?.ignore) ? startCase(toString(classConfig?.ignore)) : '---'
                            }
                          />
                          <ModelDetailsRow
                            fieldName="Hollow Shape"
                            fieldValue={
                              isBoolean(classConfig?.hollowShape)
                                ? startCase(toString(classConfig?.hollowShape))
                                : '---'
                            }
                          />
                          <ModelDetailsRow
                            fieldName="Is Point"
                            fieldValue={
                              isBoolean(classConfig?.isPoint) ? startCase(toString(classConfig?.isPoint)) : '---'
                            }
                          />
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
      </Grid>
    </Grid>
  );
};
