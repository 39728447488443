import { SlideTag, SlideTagStudyDetails } from 'interfaces/slideTag';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';

export const getSlideTagOptions = () => {
  return apiRequestHandlerPromise<Array<SlideTag>>({
    url: 'slide_tags',
    method: 'GET',
  });
};

export const getSlideTagDetails = (id: string) => {
  return apiRequestHandlerPromise<SlideTagStudyDetails[]>({
    url: `slide_tags/${id}`,
    method: 'GET',
  });
};

export const createSlideTagOption = ({ tagValue, perStudy, perExperimentResult, uiOrder }: Omit<SlideTag, 'id'>) => {
  return apiRequestHandlerPromise<SlideTag>({
    url: 'slide_tags',
    method: 'POST',
    data: JSON.stringify({ tagValue, perStudy, perExperimentResult, uiOrder }),
  });
};

export const updateSlideTagOption = ({ id, tagValue, perStudy, perExperimentResult, uiOrder }: SlideTag) => {
  return apiRequestHandlerPromise({
    url: `slide_tags/${id}`,
    method: 'POST',
    data: JSON.stringify({ tagValue, perStudy, perExperimentResult, uiOrder }),
  });
};

export const deleteSlideTagOption = (id: string) => {
  return apiRequestHandlerPromise({
    url: `slide_tags/${id}`,
    method: 'DELETE',
  });
};
