import { useSignals } from '@preact/signals-react/runtime';
import { compact, find, flatMap, forEach, fromPairs, includes, isEmpty, map, some } from 'lodash';
import { useCallback } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';

import {
  secondaryAnalysisInclusionModes,
  secondaryAnalysisPolygons,
} from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/SecondaryAnalysisLayer/useSecondaryAnalysisLayer';
import { useSecondaryAnalysisOrchestrationIdQueryParams } from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/SecondaryAnalysisLayer/useSecondaryAnalysisQueryParams';
import {
  secondaryAnalysisOptionsWhenExcluding,
  secondaryAnalysisOptionsWhenIncluding,
  secondaryAnalysisOptionsWithoutPolygons,
} from './options';

export const useClearSecondaryAnalysisSelections = () => {
  useSignals();
  // Check if any of the viewers is currently performing a secondary analysis
  const { secondaryAnalysisQueryKeys, setSecondaryAnalysisQueryParams } =
    useSecondaryAnalysisOrchestrationIdQueryParams();
  const [, setSecondaryAnalysisAreaSelectionMode] = useQueryParam('secondaryAnalysisAreaSelectionMode', StringParam);

  const clearSecondaryAnalysisSelections = useCallback(
    (stopAnnotating: boolean) => {
      forEach(secondaryAnalysisPolygons, (secondaryAnalysisSignal) => {
        const previousValue = secondaryAnalysisSignal.value;
        secondaryAnalysisSignal.value =
          stopAnnotating || !previousValue ? null : { type: 'FeatureCollection', features: [] };
      });
      if (stopAnnotating) {
        setSecondaryAnalysisAreaSelectionMode(null);
        setSecondaryAnalysisQueryParams(fromPairs(map(secondaryAnalysisQueryKeys, (key) => [key, null])));
      } else {
        setSecondaryAnalysisAreaSelectionMode(
          find(secondaryAnalysisOptionsWithoutPolygons, { isDefault: true })?.editType ?? null
        );
      }
    },
    [setSecondaryAnalysisQueryParams, setSecondaryAnalysisAreaSelectionMode]
  );

  return clearSecondaryAnalysisSelections;
};

export const useCurrentSecondaryAnalysisOptions = () => {
  useSignals();
  const secondaryAnalysisFeatures = compact(
    flatMap(secondaryAnalysisPolygons, (secondaryAnalysisPolygon) => secondaryAnalysisPolygon.value?.features)
  );

  const noSecondaryAnalysisSelections = isEmpty(secondaryAnalysisFeatures);

  const secondaryAnalysisHasInclusion = some(secondaryAnalysisFeatures, (feature) =>
    includes(secondaryAnalysisInclusionModes, feature?.properties?.featureType)
  );
  const secondaryAnalysisOptions = noSecondaryAnalysisSelections
    ? secondaryAnalysisOptionsWithoutPolygons
    : secondaryAnalysisHasInclusion
    ? secondaryAnalysisOptionsWhenIncluding
    : secondaryAnalysisOptionsWhenExcluding;
  return secondaryAnalysisOptions;
};
