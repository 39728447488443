import { useSignals } from '@preact/signals-react/runtime';
import {
  concat,
  filter,
  first,
  forEach,
  fromPairs,
  groupBy,
  includes,
  isEmpty,
  keys,
  lowerCase,
  map,
  some,
  uniq,
  values,
} from 'lodash';
import React, { useMemo } from 'react';

import { GroupedLayersVisualControls } from 'components/Procedure/Infobar/GroupedLayersVisualControls';
import { isHeatmapActive } from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import {
  getMarkerPositivityParentHeatmapId,
  groupParquetHeatmapsByType,
  markerPositivityColumnPrefix,
  ParquetLayerType,
  useParquetHeatmapFiles,
} from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/parquetLayers/helpers';
import { viewerLastRenderTime } from 'components/Procedure/SlidesViewer/DeckGLViewer/viewerDataSignals';
import { FeatureMetadata } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import { defaultLayerColors } from 'components/theme/theme';
import { OptionsEntry } from 'interfaces/experimentResults';
import { humanize } from 'utils/helpers';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { useStainTypeIdToDisplayName } from 'utils/useStainTypeIdToDisplayName';
import { useUpdateCellTableHeatmapsSettingsOnChange } from './useUpdateCellTableHeatmapsSettingsOnChange';

export const getCellTableLayerId = (cellTableHeatmap: FeatureMetadata, layerName: string) =>
  `${cellTableHeatmap.id}-${layerName}`;

export const computeDefaultCellTableLayerSettings = (
  cellTableHeatmap: FeatureMetadata,
  layerIndex: number,
  optionFromHeatmap?: OptionsEntry
) => {
  return {
    id: getCellTableLayerId(cellTableHeatmap, optionFromHeatmap.key),
    color: optionFromHeatmap?.color || defaultLayerColors[+layerIndex % defaultLayerColors.length],
    opacity: 100,
    show: false,
    select: false,
  };
};

export const CellTableControl: React.FC<{
  heatmaps: FeatureMetadata[];
  slideId: string;
  viewerIndex: number;
  stainTypeId: string;
  filterText: string;
  hideOrchestrationId?: boolean;
  onEmptyFilter?: () => void;
}> = ({
  heatmaps: parquetHeatmaps,
  slideId,
  viewerIndex,
  filterText,
  hideOrchestrationId,
  stainTypeId,
  onEmptyFilter,
}) => {
  useSignals();

  const currentViewerLastRenderTime = viewerLastRenderTime[viewerIndex].value;

  const { stainTypeIdToDisplayName } = useStainTypeIdToDisplayName();

  const { labId } = useCurrentLabId();
  const parquetHeatmapByType = useMemo(() => {
    const allGroups = groupParquetHeatmapsByType(parquetHeatmaps);
    // Only Leica can see all parquet heatmaps
    if (labId === 'd3fa2bde-df7b-11ed-8d28-236e8a21d649') {
      return allGroups;
    }
    return {
      [ParquetLayerType.MarkerPositivity]: allGroups[ParquetLayerType.MarkerPositivity],
    };
  }, [parquetHeatmaps, labId]);
  const { parquetFiles } = useParquetHeatmapFiles({ parquetHeatmapByType });

  const layerIdsToDisplayNames = useMemo(() => {
    const result: { [key: string]: string } = {};

    forEach(parquetHeatmapByType[ParquetLayerType.CellClassification], (cellTableHeatmap) => {
      const parquetClasses = cellTableHeatmap?.options || [];
      const cellTableHeatmapId = cellTableHeatmap.id;
      result[cellTableHeatmapId] = cellTableHeatmap?.displayName;
      forEach(parquetClasses, (layer) => {
        if (layer) {
          result[getCellTableLayerId(cellTableHeatmap, layer.key)] = humanize(layer.key);
        }
      });
    });

    const groupMarkerPositivityHeatmapsById = groupBy(
      parquetHeatmapByType[ParquetLayerType.MarkerPositivity],
      getMarkerPositivityParentHeatmapId
    );
    forEach(groupMarkerPositivityHeatmapsById, (heatmaps, parentId) => {
      // result[parentId] = `${humanize(first(heatmaps)?.key)}: Marker Positivity`;
      result[parentId] = `Marker Positivity`;
      forEach(heatmaps, (heatmap) => {
        const stainType = heatmap.columnName.replace(markerPositivityColumnPrefix, '');
        result[heatmap.id] = stainTypeIdToDisplayName(stainType) || humanize(stainType);
      });
    });
    return result;
  }, [parquetHeatmapByType, stainTypeIdToDisplayName]);

  useUpdateCellTableHeatmapsSettingsOnChange({
    slideId,
    viewerIndex,
    stainTypeId,
    parquetHeatmapByType,
  });

  const groupedFilterLayers = useMemo(() => {
    const result: { [key: string]: string[] } = {};

    forEach(parquetHeatmapByType[ParquetLayerType.CellClassification], (cellTableHeatmap) => {
      const parquetClasses = cellTableHeatmap?.options || [];

      const filteredLayers =
        filterText !== ''
          ? filter(parquetClasses, (layer) => {
              if (filterText === '') return true;

              const lowerCaseFilter = lowerCase(filterText);
              return includes(lowerCase(layer.key), lowerCaseFilter);
            })
          : parquetClasses;
      const cellTableHeatmapId = cellTableHeatmap.id;
      result[cellTableHeatmapId] = map(filteredLayers, 'key');
    });

    const groupMarkerPositivityHeatmapsById = groupBy(
      parquetHeatmapByType[ParquetLayerType.MarkerPositivity],
      getMarkerPositivityParentHeatmapId
    );
    forEach(groupMarkerPositivityHeatmapsById, (heatmaps, key) => {
      const filteredLayers =
        filterText !== ''
          ? filter(heatmaps, (heatmap) => {
              if (filterText === '') return true;

              const lowerCaseFilter = lowerCase(filterText);
              return includes(lowerCase(heatmap.displayName), lowerCaseFilter);
            })
          : heatmaps;
      result[key] = map(filteredLayers, 'columnName');
    });
    if (isEmpty(result)) {
      onEmptyFilter?.();
    }
    return result;
  }, [parquetHeatmapByType, filterText]);

  const groupDisplayNames = useMemo(() => {
    const result: { [key: string]: string } = {};

    forEach(parquetHeatmapByType[ParquetLayerType.CellClassification], (cellTableHeatmap) => {
      const columnName = cellTableHeatmap?.columnName;
      const cellTableHeatmapId = cellTableHeatmap.id;
      result[cellTableHeatmapId] = `Cell Classification (${columnName})`;
    });

    const groupMarkerPositivityHeatmapsById = groupBy(
      parquetHeatmapByType[ParquetLayerType.MarkerPositivity],
      getMarkerPositivityParentHeatmapId
    );
    forEach(keys(groupMarkerPositivityHeatmapsById), (key) => {
      result[key] = `Marker Positivity`;
    });
    return result;
  }, [parquetHeatmapByType]);

  const groupOrchestrationIds = useMemo(() => {
    const result: { [key: string]: string } = {};

    forEach(parquetHeatmapByType[ParquetLayerType.CellClassification], (cellTableHeatmap) => {
      const cellTableHeatmapId = cellTableHeatmap.id;
      result[cellTableHeatmapId] = cellTableHeatmap?.orchestrationId;
    });

    const groupMarkerPositivityHeatmapsById = groupBy(
      parquetHeatmapByType[ParquetLayerType.MarkerPositivity],
      getMarkerPositivityParentHeatmapId
    );

    forEach(groupMarkerPositivityHeatmapsById, (heatmaps, key) => {
      result[key] = first(heatmaps)?.orchestrationId;
    });
    return result;
  }, [parquetHeatmapByType]);

  const heatmapsActivityState = fromPairs(
    map(
      concat(
        map(parquetHeatmapByType[ParquetLayerType.CellClassification], 'id'),
        uniq(map(parquetHeatmapByType[ParquetLayerType.MarkerPositivity], getMarkerPositivityParentHeatmapId))
      ),
      (heatmapId) => [heatmapId, isHeatmapActive({ heatmapId, slideId, viewerIndex })]
    )
  );
  const groupLoadingStates = useMemo(() => {
    const result: { [key: string]: boolean } = {};

    forEach(parquetHeatmapByType[ParquetLayerType.CellClassification], (cellTableHeatmap) => {
      const cellTableHeatmapId = cellTableHeatmap.id;
      const heatmapUrl = cellTableHeatmap?.heatmapUrl;
      result[cellTableHeatmapId] = some(
        values(parquetFiles?.[heatmapUrl]),
        (file) =>
          // Query is running
          file?.isLoading ||
          // There is no data but the heatmap is active
          (isEmpty(file?.rows) && heatmapsActivityState[cellTableHeatmap.id]) ||
          // Query is done but rendered data is outdated
          (!isEmpty(file?.rows) && file?.updatedAt > currentViewerLastRenderTime)
      );
    });

    const groupMarkerPositivityHeatmapsById = groupBy(
      parquetHeatmapByType[ParquetLayerType.MarkerPositivity],
      getMarkerPositivityParentHeatmapId
    );

    forEach(groupMarkerPositivityHeatmapsById, (heatmaps, parentHeatmapId) => {
      const heatmapUrl = first(heatmaps)?.heatmapUrl;
      result[parentHeatmapId] = some(
        values(parquetFiles?.[heatmapUrl]),
        (file) =>
          // Query is running
          file?.isLoading ||
          // There is no data but the heatmap is active
          (isEmpty(file?.rows) && heatmapsActivityState[parentHeatmapId]) ||
          // Query is done but rendered data is outdated
          (!isEmpty(file?.rows) && file?.updatedAt > currentViewerLastRenderTime)
      );
    });
    return result;
  }, [parquetHeatmapByType, parquetFiles, currentViewerLastRenderTime, JSON.stringify(heatmapsActivityState)]);

  return (
    !isEmpty(groupedFilterLayers) && (
      <GroupedLayersVisualControls
        viewerIndex={viewerIndex}
        slideId={slideId}
        groupedLayers={groupedFilterLayers}
        groupLoadingStates={groupLoadingStates}
        groupOrchestrationIds={groupOrchestrationIds}
        groupDisplayNames={groupDisplayNames}
        layerIdsToDisplayNames={layerIdsToDisplayNames}
        stainTypeId={stainTypeId}
        hideOrchestrationId={hideOrchestrationId}
        defaultLayerSettings={{ opacity: 100 }}
      />
    )
  );
};
