import { Slide } from 'interfaces/slide';
import { compact, isEmpty } from 'lodash';
import React from 'react';
import { ReactImageGalleryProps } from 'react-image-gallery';
import ImageWithFallback from '../ImageWithFallback';

export const getGalleryImageRenderer = (slide: Slide) => {
  const GalleryImageRenderer: ReactImageGalleryProps['renderItem'] = ({ original, description, thumbnail }) => {
    const imagesToRender = compact([original, thumbnail]);
    return (
      !isEmpty(imagesToRender) && (
        <div className="image-gallery-image">
          <ImageWithFallback imageUrls={imagesToRender} slide={slide} />
          {description && <span className="image-gallery-description">{description}</span>}
        </div>
      )
    );
  };
  return GalleryImageRenderer;
};
