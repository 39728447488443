import {
  compact,
  concat,
  filter,
  find,
  findIndex,
  flatMap,
  groupBy,
  includes,
  isEmpty,
  isString,
  keys,
  map,
  partition,
  replace,
  some,
} from 'lodash';

import {
  ExperimentResult,
  PresentationInfoLayer,
  publishableFlowClasses,
  publishableResultTypes,
} from 'interfaces/experimentResults';
import { isBrackets } from 'utils/formatBrackets';
import { FormatBracketsOptions } from 'utils/formatBrackets/formatBracketsOptions';
import { formatBracketsVisualization } from 'utils/formatBrackets/formatBracketsVisualization';
import { humanize } from 'utils/helpers';
import {
  experimentResultToGroup,
  FeatureMetadata,
  FeatureMetadataBeforeSecondaryAnalysisGrouping,
  HeatmapType,
  parseBaseFeatureMetadataFromExperimentResult,
} from './featureMetadata';
import { groupPrimaryResultsWithSecondaryResults, isParsedSecondaryAnalysisRun } from './secondaryAnalysis';

const extensionRegex = /\.\S+$/g;
const underscoreAndPathSepRegex = /[_/]/g;
const underscoreRegex = /_/g;

/**
 * Until we standardize the presentation info schema, we need to handle different types of heatmaps
 */
export const getPresentationInfoForOptionKey = (experimentResult: ExperimentResult, optionKey: string) => {
  return (experimentResult.presentationInfo?.[optionKey] ||
    experimentResult.presentationInfo?.layers?.[optionKey] ||
    find(experimentResult.presentationInfo?.layers, { key: optionKey }) ||
    find(experimentResult.presentationInfo?.layers, { class_name: optionKey })) as
    | PresentationInfoLayer
    | string
    | undefined;
};

export const getHeatmapDisplayName = (context: FormatBracketsOptions, key: string, mainHeatmapKey?: string) => {
  if (isBrackets(key)) {
    return formatBracketsVisualization(key, context, mainHeatmapKey);
  }

  // legacy name
  const itemConfig = context.uiSettings.webappSidebarConfig.heatmapsConfig[key];
  return itemConfig?.displayName || replace(key, underscoreRegex, ' ');
};

const parsePmtHeatmap = (
  experimentResult: ExperimentResult,
  layerInfo: PresentationInfoLayer,
  nestedLayers?: FeatureMetadataBeforeSecondaryAnalysisGrouping[]
): FeatureMetadataBeforeSecondaryAnalysisGrouping | null => {
  const compositePresentationInfo = getPresentationInfoForOptionKey(experimentResult, 'composite_layer_info');
  const pmtHeatmapComponents = layerInfo || (compositePresentationInfo as PresentationInfoLayer);
  const pmtHeatmapUrl = pmtHeatmapComponents?.storage_url;
  if (!pmtHeatmapUrl) {
    console.warn('Dynamic heatmap url not found', { layerInfo, experimentResult });
    return null;
  }
  if (layerInfo?.file_type && layerInfo.file_type !== 'pmt') {
    console.warn('Unsupported file type for pmt heatmap', { layerInfo, experimentResult });
    return null;
  }

  const optionsForHeatmap = find(experimentResult.options, { key: layerInfo.key })?.layers || experimentResult.options;
  const nestedItems: FeatureMetadata[] =
    nestedLayers ||
    map(optionsForHeatmap, (heatmapOption) => {
      return {
        id: `[${experimentResult.experimentResultId}]-${heatmapOption.key}`,
        key: heatmapOption.key,
        color: heatmapOption.color,
        show: false,
        selected: false,
        displayName: heatmapOption?.key,
        options: optionsForHeatmap,
      };
    });

  const displayName =
    experimentResult?.name ||
    // If the experiment result name is not available, use the artifact url as the name by replacing the file extension and underscores with spaces
    `[${experimentResult.experimentResultId}] - ${replace(
      replace(pmtHeatmapComponents?.artifact_url, extensionRegex, ''),
      underscoreAndPathSepRegex,
      ' '
    )}`;
  return {
    ...parseBaseFeatureMetadataFromExperimentResult(experimentResult, 'parsePmtHeatmap-'),
    displayName,
    heatmapUrl: pmtHeatmapUrl,
    heatmapType: HeatmapType.Pmt,
    nestedItems,
    options: optionsForHeatmap,
  } as FeatureMetadataBeforeSecondaryAnalysisGrouping;
};

export const parseGeoJsonHeatmap = (
  experimentResult: ExperimentResult,
  layerInfo: PresentationInfoLayer,
  formatBracketsOptions: FormatBracketsOptions
): FeatureMetadataBeforeSecondaryAnalysisGrouping | null => {
  const geoJsonUrl = layerInfo?.storage_url;
  if (!geoJsonUrl) {
    console.warn('Dynamic heatmap url not found', { layerInfo, experimentResult });
    return null;
  }
  if (layerInfo?.file_type && layerInfo.file_type !== 'geojson') {
    console.warn('Unsupported file type for geojson heatmap', { layerInfo, experimentResult });
    return null;
  }

  const optionsForHeatmap = find(experimentResult.options, { key: layerInfo.key })?.layers || experimentResult.options;
  const matchingOption = find(optionsForHeatmap, { key: layerInfo.class_name });

  // We support his direct presentation info parsing since this is a legacy feature anyway
  const layerIndex = findIndex(experimentResult?.presentationInfo?.layers, { class_name: layerInfo.class_name });

  if (!matchingOption && layerIndex === -1) {
    console.warn('No matching option found for geojson layer', { layerInfo, experimentResult });
    return null;
  }

  const displayName =
    getHeatmapDisplayName(formatBracketsOptions, matchingOption?.key || layerInfo?.class_name) ||
    // If the experiment result name is not available, use the artifact url as the name by replacing the file extension and underscores with spaces
    `${replace(replace(layerInfo?.artifact_url, extensionRegex, ''), underscoreAndPathSepRegex, ' ')}`;
  return {
    ...parseBaseFeatureMetadataFromExperimentResult(experimentResult, 'parseGeoJsonHeatmap-'),
    id: `parseGeoJsonHeatmap-[${experimentResult.experimentResultId}]=geojson-${layerInfo.class_name}`,
    key: matchingOption?.key || layerInfo?.class_name || `[${experimentResult.experimentResultId}]-${layerIndex}`,
    displayName,
    heatmapUrl: geoJsonUrl,
    heatmapType: HeatmapType.GeoJson,
  };
};

export const parseLayeredVectorHeatmaps = (
  experimentResult: ExperimentResult,
  formatBracketsOptions: FormatBracketsOptions
): FeatureMetadataBeforeSecondaryAnalysisGrouping[] => {
  const parentDisplayName = `${experimentResult.name || humanize(experimentResult.flowClassName)}`;

  // TODO: we should align the options schema to have the nested layers in an option.layers array, making the source of truth the options array
  // This will then become the 'legacy' parser for pmt (if we decide to keep it)
  const pmtLayerKeys = concat(
    map(filter(experimentResult?.presentationInfo?.layers, { file_type: 'pmt' }), 'key'),
    filter(
      keys(experimentResult?.presentationInfo),
      (key) => experimentResult?.presentationInfo[key]?.file_type === 'pmt'
    )
  );

  return map(pmtLayerKeys, (pmtLayerKey) => {
    const pmtLayer = getPresentationInfoForOptionKey(experimentResult, pmtLayerKey) as PresentationInfoLayer;
    const nestedOptions = find(experimentResult.options, { key: pmtLayerKey })?.layers || experimentResult.options;

    const nestedItems: FeatureMetadataBeforeSecondaryAnalysisGrouping[] = compact(
      map(nestedOptions, (option) => {
        // TODO: we should align the options schema to have the nested layers in an option.layers array, making the source of truth the options array
        // This will then become the 'legacy' parser for pmt (if we decide to keep it)
        const matchingLayer = getPresentationInfoForOptionKey(experimentResult, option.key) as PresentationInfoLayer;
        // For each option, find the matching layer and parse it as a heatmap if it's a geojson layer
        if (matchingLayer) {
          if (matchingLayer?.file_type === 'parquet') {
            return null; // Handled by parseParquetHeatmaps
          } else if (matchingLayer?.file_type === 'geojson') {
            // If the layer is a geojson layer, parse it as a geojson heatmap
            return parseGeoJsonHeatmap(experimentResult, matchingLayer, formatBracketsOptions);
          } else if (matchingLayer && matchingLayer.file_type !== 'pmt') {
            // If the layer is not a pmt layer, log a warning, since we don't support other types of layers yet
            console.warn('Unsupported file type for layered heatmap', { matchingLayer, experimentResult, option });
          }
        }

        // If no layer is found, parse the option as a 'pmt-layer' heatmap, which contains the option's color and key
        return {
          ...parseBaseFeatureMetadataFromExperimentResult(experimentResult, 'parseLayeredVectorHeatmaps-nested-'),
          id: `parseLayeredVectorHeatmaps-nested-[${experimentResult.experimentResultId}]-${option.key}`,
          displayName: getHeatmapDisplayName(formatBracketsOptions, option?.key),
          heatmapType: HeatmapType.PmtLayer,
          options: nestedOptions,
          key: option.key,
        };
      })
    );

    const pmtHeatmap = pmtLayer ? parsePmtHeatmap(experimentResult, pmtLayer, nestedItems) : null;
    return (pmtHeatmap || {
      ...parseBaseFeatureMetadataFromExperimentResult(experimentResult, 'parseLayeredVectorHeatmaps-'),
      displayName: parentDisplayName,
      nestedItems,
      options: nestedOptions,
    }) as FeatureMetadataBeforeSecondaryAnalysisGrouping;
  });
};

const parseLegacyRasterHeatmaps = (
  experimentResult: ExperimentResult
): FeatureMetadataBeforeSecondaryAnalysisGrouping[] => {
  if (!experimentResult) {
    return [];
  }
  const singleHeatmapUrlPresentationInfo = getPresentationInfoForOptionKey(experimentResult, 'heatmap_url');

  if (isEmpty(singleHeatmapUrlPresentationInfo)) {
    return [];
  }

  const parentDisplayName = experimentResult.name || humanize(experimentResult.flowClassName);
  const nestedItems: FeatureMetadataBeforeSecondaryAnalysisGrouping['nestedItems'] = map(
    experimentResult.options,
    (heatmapOption) => {
      return {
        id: `[${experimentResult.experimentResultId}]-${heatmapOption.key}`,
        key: heatmapOption.key,
        color: heatmapOption.color,
        show: false,
        selected: false,
        displayName: heatmapOption?.key,
      };
    }
  );

  const newHMResult = {
    ...parseBaseFeatureMetadataFromExperimentResult(experimentResult, 'parseLegacyRasterHeatmaps-'),
    displayName: parentDisplayName,
    heatmapUrl:
      (singleHeatmapUrlPresentationInfo as PresentationInfoLayer)?.storage_url ||
      (singleHeatmapUrlPresentationInfo as string),
    heatmapType: HeatmapType.Dzi,
    nestedItems,
  } as FeatureMetadataBeforeSecondaryAnalysisGrouping;

  return [newHMResult];
};

/*
 * Get all the heatmap 'options' and parse their nested layers as composite raster heatmaps
 */
const parseCompositeRasterHeatmapUrls = (
  experimentResult: ExperimentResult,
  formatBracketsOptions: FormatBracketsOptions
): FeatureMetadataBeforeSecondaryAnalysisGrouping[] => {
  return flatMap(experimentResult.options, (heatmapOption) => {
    const compositePresentationInfo = heatmapOption
      ? getPresentationInfoForOptionKey(experimentResult, heatmapOption.key)
      : null;

    const parentDisplayName = getHeatmapDisplayName(formatBracketsOptions, heatmapOption.key);

    const nestedItems: FeatureMetadataBeforeSecondaryAnalysisGrouping['nestedItems'] = compact(
      map(heatmapOption.layers, (nestedHeatmap) => {
        const presentationInfo =
          getPresentationInfoForOptionKey(experimentResult, heatmapOption.key.concat(nestedHeatmap.key)) ||
          getPresentationInfoForOptionKey(experimentResult, nestedHeatmap.key);
        const hasHeatmapUrl = presentationInfo && isString(presentationInfo);
        return {
          id: `[${experimentResult.experimentResultId}]-${heatmapOption.key}-${nestedHeatmap.key}`,
          key: nestedHeatmap.key,
          color: nestedHeatmap.color,
          heatmapUrl: hasHeatmapUrl ? presentationInfo : undefined,
          show: false,
          selected: false,
          displayName: getHeatmapDisplayName(formatBracketsOptions, nestedHeatmap.key, heatmapOption.key),
          heatmapType: HeatmapType.Dzi,
          options: heatmapOption.layers,
        };
      })
    );

    const hasCompositeHeatmapUrl = compositePresentationInfo && isString(compositePresentationInfo);

    // Some heatmaps don't have either the composite heatmap url or nested heatmap urls, but if both are missing, we should log a warning and skip the heatmap
    if (!hasCompositeHeatmapUrl && !some(nestedItems, 'heatmapUrl')) {
      console.warn('No heatmap url found for composite heatmap', { heatmapOption, experimentResult });
      return [];
    }

    return {
      ...parseBaseFeatureMetadataFromExperimentResult(experimentResult, 'parseCompositeRasterHeatmapUrls-'),
      id: `[${experimentResult.experimentResultId}]-${heatmapOption.key}`,
      key: heatmapOption.key,
      color: heatmapOption.color,
      heatmapUrl: hasCompositeHeatmapUrl ? compositePresentationInfo : undefined,
      heatmapType: HeatmapType.Dzi,
      nestedItems,
      displayName: parentDisplayName,
      options: heatmapOption.layers,
    };
  });
};

/*
 * Get all the heatmap 'options' and parse their nested layers as composite raster heatmaps
 */
const parseParquetHeatmaps = (
  experimentResult: ExperimentResult,
  formatBracketsOptions: FormatBracketsOptions
): FeatureMetadataBeforeSecondaryAnalysisGrouping[] => {
  return flatMap(experimentResult.options, (heatmapOption) => {
    if (heatmapOption?.type === 'point') {
      // Point columns are the X, Y coordinates of cells, not data for a heatmap
      return [];
    }
    const presentationInfo = getPresentationInfoForOptionKey(experimentResult, heatmapOption.key);
    if (!presentationInfo || isString(presentationInfo) || presentationInfo.file_type !== 'parquet') {
      return [];
    }
    const columnName = heatmapOption.column_name;
    if (!columnName) {
      console.warn('No column name found for parquet heatmap', { heatmapOption, experimentResult });
      return [];
    }
    const parentDisplayName = getHeatmapDisplayName(formatBracketsOptions, heatmapOption.key);
    const nestedOptions = heatmapOption?.layers;
    const nestedItems = map(nestedOptions, (nestedHeatmap) => {
      return {
        ...parseBaseFeatureMetadataFromExperimentResult(experimentResult, 'parseParquetHeatmaps-'),
        id: `[${experimentResult.experimentResultId}]-${heatmapOption.key}-${heatmapOption.column_name}-${nestedHeatmap.key}`,
        key: `${heatmapOption.key}-${nestedHeatmap.key}`,
        color: nestedHeatmap.color,
        show: false,
        selected: false,
        displayName: getHeatmapDisplayName(formatBracketsOptions, nestedHeatmap.key, heatmapOption.key),
        heatmapType: HeatmapType.Parquet,
        options: nestedOptions,
        columnName: heatmapOption.column_name,
        columnType: heatmapOption?.type,
      };
    });

    const newHMResult = {
      ...parseBaseFeatureMetadataFromExperimentResult(experimentResult, 'parseParquetHeatmaps-'),
      id: `[${experimentResult.experimentResultId}]-${heatmapOption.key}-${heatmapOption.column_name}`,
      key: heatmapOption.key,
      color: heatmapOption.color,
      heatmapUrl: presentationInfo.storage_url,
      heatmapType: HeatmapType.Parquet,
      nestedItems,
      displayName: parentDisplayName,
      options: nestedOptions,
      columnName: heatmapOption.column_name,
      columnType: heatmapOption?.type,
    };

    const hasUrls = Boolean(newHMResult?.heatmapUrl) || some(newHMResult.nestedItems, 'heatmapUrl');

    return hasUrls ? newHMResult : [];
  });
};

export const parseHeatmaps = (
  slideResults: ExperimentResult[],
  formatBracketsOptions: FormatBracketsOptions
): {
  publishedHeatmaps: FeatureMetadata[];
  publishableHeatmaps: { [key: string]: FeatureMetadata[] };
  internalHeatmaps: { [key: string]: FeatureMetadata[] };
  deletedHeatmaps: FeatureMetadata[];
} => {
  const allHeatmapsResults: FeatureMetadataBeforeSecondaryAnalysisGrouping[] = flatMap(
    slideResults,
    (experimentResult) => {
      // In old PMT results (first round of implementation) the heatmap is stored in the composite_layer_info key
      const legacyCompositePmtHeatmap = getPresentationInfoForOptionKey(experimentResult, 'composite_layer_info');

      const legacyCompositePmt =
        legacyCompositePmtHeatmap && !isString(legacyCompositePmtHeatmap)
          ? parsePmtHeatmap(experimentResult, legacyCompositePmtHeatmap)
          : null;

      const legacyRasterHeatmaps = parseLegacyRasterHeatmaps(experimentResult);

      const compositeRasterHeatmaps = parseCompositeRasterHeatmapUrls(experimentResult, formatBracketsOptions);

      const parquetHeatmaps = parseParquetHeatmaps(experimentResult, formatBracketsOptions);

      const layeredVectorHeatmaps = parseLayeredVectorHeatmaps(experimentResult, formatBracketsOptions);

      return compact([
        legacyCompositePmt,
        ...legacyRasterHeatmaps,
        ...compositeRasterHeatmaps,
        ...parquetHeatmaps,
        ...layeredVectorHeatmaps,
      ]);
    }
  );

  const allHeatmapsResultsWithNested = groupPrimaryResultsWithSecondaryResults(allHeatmapsResults);

  const [deletedHeatmaps, heatmapsResults] = partition(
    allHeatmapsResultsWithNested,
    // Hidden secondary results are handled separately
    (result) => isParsedSecondaryAnalysisRun(result) && Boolean(result.deletedAt)
  );

  const [publishedHeatmaps, allUnpublishedHeatmaps] = partition(heatmapsResults, 'approved');
  const visibleUnpublishedHeatmaps = filter(allUnpublishedHeatmaps, (heatmap) => Boolean(heatmap.flowClassName));

  const [publishableHeatmapsList, unpublishableHeatmapsList] = partition(
    visibleUnpublishedHeatmaps,
    (heatmap) =>
      includes(publishableFlowClasses, heatmap.flowClassName) || includes(publishableResultTypes, heatmap.resultType)
  );
  const publishableHeatmaps = groupBy(publishableHeatmapsList, experimentResultToGroup);
  const internalHeatmaps = groupBy(unpublishableHeatmapsList, experimentResultToGroup);

  return { publishedHeatmaps, publishableHeatmaps, internalHeatmaps, deletedHeatmaps };
};
