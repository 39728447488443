import { Dictionary, filter, first, forEach, isEmpty, join, map, some, values } from 'lodash';

import { LayerVisualizationSettings } from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import { FeatureMetadata } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import { defaultLayerColors } from 'components/theme/theme';
import { getColorHex, hexToRgb, isHexString } from 'utils/helpers';
import { ParquetFile, ParquetRow } from 'utils/useParquetFile';
import { SizeInMicronsByZoomRange } from '../ZoomAwareLayers/types';
import { ZoomAwareIconLayer } from '../ZoomAwareLayers/ZoomAwareIconLayer';
import { getMarkerPositivityParentHeatmapId, markerPositivityColumnPrefix } from './helpers';

const sizeInMicronsByZoomRange: SizeInMicronsByZoomRange[] = [
  { minZoom: 1, sizeInMicrons: 3 },
  { minZoom: 0, maxZoom: 1, sizeInMicrons: 5 },
  { minZoom: -1, maxZoom: 0, sizeInMicrons: 7 },
  { minZoom: -2, maxZoom: -1, sizeInMicrons: 9 },
  { maxZoom: -2, sizeInMicrons: 12 },
];

function generateColoredCircle(
  colors: string[],
  radius: number = 32,
  centerX: number = radius,
  centerY: number = radius
): string {
  const svgSize = radius * 2;
  const sliceAngle = 360 / colors.length;

  // Generate SVG
  let svg = `<svg xmlns="http://www.w3.org/2000/svg" width="${svgSize}" height="${svgSize}" viewBox="0 0 ${svgSize} ${svgSize}">`;

  // If only one color, draw a simple circle
  if (colors.length === 1) {
    svg += `<circle cx="${centerX}" cy="${centerY}" r="${radius}" fill="${colors[0]}"/>`;
  } else if (colors.length > 1) {
    // Generate path for each slice
    forEach(colors, (color, index) => {
      const startAngle = index * sliceAngle;
      const endAngle = (index + 1) * sliceAngle;

      const startAngleRad = (startAngle - 90) * (Math.PI / 180);
      const endAngleRad = (endAngle - 90) * (Math.PI / 180);

      const startX = centerX + radius * Math.cos(startAngleRad);
      const startY = centerY + radius * Math.sin(startAngleRad);
      const endX = centerX + radius * Math.cos(endAngleRad);
      const endY = centerY + radius * Math.sin(endAngleRad);

      const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

      const path = join(
        [
          `M ${centerX} ${centerY}`,
          `L ${startX} ${startY}`,
          `A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endX} ${endY}`,
          'Z',
        ],
        ' '
      );

      svg += `<path d="${path}" fill="${color}"/>`;
    });
  }

  svg += '</svg>';
  return svg;
}

// TODO: Move to utils
function svgToDataURL(svg: string) {
  return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svg)}`;
}

export const deckGLParquetMarkerPositivityLayer = ({
  idPrefix = '',
  markerHeatmaps,
  visualSettings,
  parquetFile,
  rescaleFactor,
  slideMaxResolution,
}: {
  idPrefix?: string;
  markerHeatmaps: FeatureMetadata[];
  visualSettings: Dictionary<LayerVisualizationSettings>;
  rescaleFactor?: number;
  parquetFile: ParquetFile;
  slideMaxResolution: number;
}) => {
  if (isEmpty(markerHeatmaps)) {
    console.warn('No marker heatmaps provided');
    return null;
  }
  const hasActiveParquetSource = some(values(visualSettings), 'selected');

  const parentHeatmapId = getMarkerPositivityParentHeatmapId(first(markerHeatmaps));

  const markerColumnMetadata = map(
    filter(parquetFile?.metadata?.schema, (field: { name: string }) =>
      field?.name?.startsWith(markerPositivityColumnPrefix)
    ),
    (field) => {
      const layerSettings = visualSettings[`${parentHeatmapId}-${field.name}`];
      const isVisible = layerSettings?.selected && layerSettings?.show && layerSettings?.opacity > 0;
      const opacity = isVisible ? (layerSettings?.opacity ?? 100) / 100 : 0;
      const hexColor = isHexString(getColorHex(layerSettings?.color))
        ? getColorHex(layerSettings?.color)
        : defaultLayerColors[(defaultLayerColors.length + 1) % defaultLayerColors.length];
      return { ...field, isVisible, color: `rgba(${join(hexToRgb(hexColor), ', ')}, ${opacity})` };
    }
  );
  if (!parquetFile?.rows || !hasActiveParquetSource || !some(markerColumnMetadata, 'isVisible')) {
    return null;
  }

  const iconsCache: Dictionary<{ url: string; width: number; height: number }> = {};

  return new ZoomAwareIconLayer<ParquetRow>({
    id: `${idPrefix}${parentHeatmapId}`,
    data: parquetFile.rows,
    getPosition: (d: ParquetRow) => [d[0], d[1]],
    pickable: true,
    sizeInMicronsByZoomRange,
    slideMaxResolution,

    // The point radius is scaled based on the zoom level - the higher the zoom level, the higher the point radius, up to the pointRadiusAtMaxZoom.
    // We don't use the pointRadiusMaxPixels because we can zoom beyond the maximum zoom level of the base layer.
    sizeScale: rescaleFactor ? 1 / rescaleFactor : 1,
    sizeUnits: 'meters', // Which really means original slide pixels in this case
    getIcon: (d: ParquetRow) => {
      const activeMarkerColumns = filter(markerColumnMetadata, (field, columnOffset) => {
        if (!field.isVisible) {
          return false;
        }
        const markerColumnIndex = columnOffset + 2; // X and Y are the first two columns
        const isPositive = Boolean(d[markerColumnIndex]);
        return isPositive;
      });
      const columnNameKey = join(map(activeMarkerColumns, 'name'), ',');
      if (!iconsCache[columnNameKey]) {
        iconsCache[columnNameKey] = {
          url: svgToDataURL(generateColoredCircle(map(activeMarkerColumns, 'color'), 32)),
          width: 32,
          height: 32,
        };
      }
      return iconsCache[columnNameKey];
    },
    updateTriggers: {
      getRadius: [rescaleFactor],
      getIcon: [JSON.stringify(markerColumnMetadata)],
    },
  });
};
