import { useSignals } from '@preact/signals-react/runtime';
import {
  compact,
  concat,
  filter,
  first,
  flatMap,
  flatten,
  fromPairs,
  groupBy,
  indexOf,
  isEmpty,
  join,
  keys,
  map,
  some,
  uniq,
  values,
} from 'lodash';
import { useMemo } from 'react';

import { Layer } from '@deck.gl/core/typed';
import { slidesLayerVisualizationSettings } from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import { HeatmapType } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { ParquetFile } from 'utils/useParquetFile';
import { deckGLParquetCellClassLayer } from './deckGLParquetCellClassLayer';
import { deckGLParquetMarkerPositivityLayer } from './deckGLParquetMarkerPositivityLayer';
import {
  getMarkerPositivityParentHeatmapId,
  groupParquetHeatmapsByType,
  ParquetLayerType,
  useParquetHeatmapFiles,
} from './helpers';

export const useParquetLayers = ({
  slide,
  idPrefix,
  rescaleFactor,
}: {
  slide: SlideWithChannelAndResults;
  idPrefix: string;
  rescaleFactor?: number;
}) => {
  useSignals();
  const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[slide.viewerIndex];

  const slideLayerVisualizationSettings = viewerSlideLayerVisualizationSettings.value?.[slide.id];

  const parquetHeatmapByType = useMemo(
    () =>
      groupParquetHeatmapsByType(
        filter(
          compact(
            concat(
              slide?.heatmapResults?.publishedResults,
              flatten(values(slide?.heatmapResults?.internalResults)),
              flatten(values(slide?.internalHeatmaps))
            )
          ),
          { heatmapType: HeatmapType.Parquet }
        )
      ),
    [slide]
  );

  const { parquetFiles } = useParquetHeatmapFiles({ parquetHeatmapByType });

  const cellClassificationHeatmapIds = compact(map(parquetHeatmapByType[ParquetLayerType.CellClassification], 'id'));
  const markerPositivityHeatmapIds = compact(
    uniq(map(parquetHeatmapByType[ParquetLayerType.MarkerPositivity], getMarkerPositivityParentHeatmapId))
  );

  const cellTableVisualSettingsByHeatmapId = fromPairs(
    concat(
      map(cellClassificationHeatmapIds, (parquetHeatmapId) => [
        parquetHeatmapId,
        fromPairs(
          map(
            filter(keys(slideLayerVisualizationSettings), (key) => key.startsWith(parquetHeatmapId)),
            (key) => [key, slideLayerVisualizationSettings[key].value]
          )
        ),
      ]),
      map(markerPositivityHeatmapIds, (parquetHeatmapId) => [
        parquetHeatmapId,
        fromPairs(
          map(
            filter(keys(slideLayerVisualizationSettings), (key) => key.startsWith(parquetHeatmapId)),
            (key) => [key, slideLayerVisualizationSettings[key].value]
          )
        ),
      ])
    )
  );

  const filteredMarkerPositivityParquets = useMemo(() => {
    const groupMarkerPositivityHeatmapsById = groupBy(
      parquetHeatmapByType[ParquetLayerType.MarkerPositivity],
      getMarkerPositivityParentHeatmapId
    );

    return fromPairs(
      compact(
        map(groupMarkerPositivityHeatmapsById, (markerHeatmaps, parentHeatmapId) => {
          const markerColumns = map(markerHeatmaps, 'columnName');
          const parquetFile =
            parquetFiles?.[first(markerHeatmaps)?.heatmapUrl]?.[join(['X', 'Y', ...markerColumns], ',')];
          const visibleMarkerColumns = filter(markerColumns, (markerValue) => {
            const layerSettings =
              cellTableVisualSettingsByHeatmapId?.[parentHeatmapId]?.[`${parentHeatmapId}-${markerValue}`];
            return Boolean(layerSettings?.selected && layerSettings?.show && layerSettings?.opacity > 0);
          });
          if (!parquetFile || isEmpty(visibleMarkerColumns)) {
            return undefined;
          }
          const metadata = parquetFile?.metadata;
          const activeMarkerColumnIndices = map(
            visibleMarkerColumns,
            (marker) => indexOf(markerColumns, marker) + 2 // X and Y columns are at indices 0 and 1
          );
          return [
            parentHeatmapId,
            {
              rows: filter(parquetFile.rows, (row) =>
                some(activeMarkerColumnIndices, (markerIndex) => Boolean(row[markerIndex]))
              ),
              metadata,
            } as ParquetFile,
          ];
        })
      )
    );
  }, [
    parquetFiles,
    parquetHeatmapByType,
    JSON.stringify(
      map(cellTableVisualSettingsByHeatmapId, ({ selected, show, opacity }) => selected && show && opacity > 0)
    ),
  ]);

  const parquetLayers = useMemo(() => {
    const groupMarkerPositivityHeatmapsById = groupBy(
      parquetHeatmapByType[ParquetLayerType.MarkerPositivity],
      getMarkerPositivityParentHeatmapId
    );

    const cellTableLayers = flatMap(parquetHeatmapByType[ParquetLayerType.CellClassification], (parquetHeatmap) => {
      const filteredColumns = ['X', 'Y', parquetHeatmap.columnName];
      const parquetFile = parquetFiles?.[parquetHeatmap.heatmapUrl]?.[join(filteredColumns, ',')];

      if (!parquetFile?.rows) {
        if (!parquetFile?.isLoading) {
          console.warn('No parquet file found for heatmap', parquetHeatmap);
        }
        return [];
      }

      const parquetHeatmapId = parquetHeatmap.id;
      const visualSettings = cellTableVisualSettingsByHeatmapId[parquetHeatmapId];
      return deckGLParquetCellClassLayer({
        idPrefix: `${idPrefix}-${slide.id}`,
        visualSettings,
        heatmapMetadata: parquetHeatmap,
        parquetHeatmapId,
        parquetFile,
        rescaleFactor,
        filteredColumns,
        slideMaxResolution: slide.maxResolution,
      });
    });

    const markerPositivityLayers = flatMap(groupMarkerPositivityHeatmapsById, (markerHeatmaps, parentHeatmapId) => {
      const parquetFile = filteredMarkerPositivityParquets[parentHeatmapId];
      if (!parquetFile) {
        return null;
      }
      return deckGLParquetMarkerPositivityLayer({
        idPrefix: `${idPrefix}-${slide.id}`,
        visualSettings: cellTableVisualSettingsByHeatmapId[parentHeatmapId],
        parquetFile,
        rescaleFactor,
        markerHeatmaps,
        slideMaxResolution: slide.maxResolution,
      });
    });

    return compact(concat<Layer>(cellTableLayers, markerPositivityLayers));
  }, [
    slide.id,
    slide.maxResolution,
    idPrefix,
    parquetHeatmapByType,
    parquetFiles,
    filteredMarkerPositivityParquets,
    JSON.stringify(cellTableVisualSettingsByHeatmapId),
    rescaleFactor,
  ]);

  return parquetLayers;
};
