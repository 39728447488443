import { Grid, Paper, Skeleton, Typography } from '@mui/material';
import { useSlideLabels } from 'components/StudiesDashboard/StudySettings/StudyLabel/useSlideLabels';
import { QualityControlLabel } from 'interfaces/qualityControlLabels';
import React from 'react';

interface SlideLabelProps {
  label: QualityControlLabel;
  title?: string;
}

const SlideLabel: React.FC<SlideLabelProps> = ({ label, title }) => {
  const {
    filterLabel,
    rejectLabel,
    attachFilterLabel,
    attachRejectLabel,
    filterLabelExternal,
    rejectLabelExternal,
    excludeOption,
  } = label;

  const { isLoading: isLoadingExternalLabelOptions, getExternalLabelDisplayName } = useSlideLabels();

  return (
    <Paper sx={{ padding: 2 }}>
      <Grid item container direction="column" spacing={1}>
        {title && (
          <Grid item>
            <Typography variant="body1" fontWeight="bold">
              {title}
            </Typography>
          </Grid>
        )}
        <Grid item>
          {isLoadingExternalLabelOptions ? (
            <Skeleton variant="text" width="100%" />
          ) : (
            <Typography variant="body1">Filter label: {getExternalLabelDisplayName(filterLabel)}</Typography>
          )}
        </Grid>
        <Grid item>
          <Typography variant="body1">Reject label: {rejectLabel}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">Attach filter label: {attachFilterLabel ? 'True' : 'False'}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">Attach reject label: {attachRejectLabel ? 'True' : 'False'}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">Filter label external: {filterLabelExternal ? 'True' : 'False'}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">Reject label external: {rejectLabelExternal ? 'True' : 'False'}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">Exclude option: {excludeOption ? excludeOption : 'not excluding'}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SlideLabel;
