import { COORDINATE_SYSTEM, Color } from '@deck.gl/core/typed';
import { DeckGLProps } from '@deck.gl/react/typed';
import { useSignals } from '@preact/signals-react/runtime';
import { castArray, concat, first, flatten, includes, isEmpty, last, map, slice, some, times } from 'lodash';
import { useMemo } from 'react';

import {
  baseSlidesVisualSettings,
  deconvolutionComponentsVisualSettings,
  defaultBaseSlideVisualSettings,
} from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import { slidesChannelNormalizationSettings } from 'components/Procedure/SlideControls/Multiplex/channelNormalizations';
import {
  slidesChannelColorSettings,
  slidesChannelToggles,
} from 'components/Procedure/SlideControls/Multiplex/colorSettings';
import { getDeconvolutionComponentSettingsKey } from 'components/Procedure/SlideControls/SlideDeconvolutionControls';
import { MAX_CHANNELS } from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/StainsLayers/constants';
import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { ImagePyramid } from 'components/Procedure/useSlideImages';
import { defaultLayerColors } from 'components/theme/theme';
import { MAX_UINT16, MAX_UINT8 } from 'utils/constants';
import { hexToRgb } from 'utils/helpers';
import useScannerSlideVisualizationPreset from 'utils/queryHooks/useScannerSlideVisualizationPresets';
import useStudy from 'utils/useStudy';
import { CubicDeconvExtensionProps } from './StainsLayers/extensions/bitmapLayer/CubicDeconvExtension';
import { LinearDeconvExtensionProps } from './StainsLayers/extensions/bitmapLayer/LinearDeconvExtension';
import MultiScaleImageLayer, { OVERVIEW_LAYER_ID } from './StainsLayers/layers/multiScaleImageLayer';
import { isInterleaved } from './StainsLayers/layers/utils';

const defaultTransparentColor: Color = [0, 0, 0];

// Deconvolution constants
/*
 * We want to store 3 color channels, 20 coefficients per color in the GPU which deals with
 * vectors and matrices of up to 4 elements. We have 20 coefficients per color, so we need to
 * convert the 20x3 matrix into seven 3x3 matrices (3x3 * 7 = 21x3) to store the coefficients
 * We could use 5 4x4 matrices, or 5 3x4 matrices, but we had issues implementing this
 */
const coefficientsPerMatrix = 3;
const rowsPerMatrix = 3;
const numMatrices = 7; // Math.ceil(coefficientsPerColor / 20);  - How many 3x3 matrices are needed to store 20 coefficients

export const useBaseSlideLayers = ({
  slide,
  baseImagePyramids,
  overview,
}: {
  slide: SlideWithChannelAndResults;
  baseImagePyramids: ImagePyramid;
  overview?: boolean;
}): DeckGLProps['layers'] => {
  useSignals();
  const { study } = useStudy(slide?.studyId, { enabled: Boolean(slide?.studyId) });
  const studyDeconvolutionSettings = study?.settings?.deconvolutionSettings;

  const stainDeconvolutionSettings = studyDeconvolutionSettings?.stainToComponentOperation?.[slide.stainingType];

  const stainDeconvolutionComponentsSettings = useMemo(
    // null will be used to show the original image
    () => (!isEmpty(stainDeconvolutionSettings) ? concat([null], map(stainDeconvolutionSettings)) : []),
    [stainDeconvolutionSettings]
  );
  const slideHasDeconvolutionComponents = !isEmpty(stainDeconvolutionComponentsSettings);

  const slideDeconvComponentProps: Array<LinearDeconvExtensionProps | CubicDeconvExtensionProps | undefined> =
    useMemo(() => {
      if (!slideHasDeconvolutionComponents) {
        return null;
      }

      return map(stainDeconvolutionComponentsSettings, (componentSettings) => {
        const deconvolutionType = componentSettings?.deconvolutionType;
        if (!componentSettings?.coefficients || !includes(['linear', 'cubic'], deconvolutionType)) {
          return undefined;
        } else if (deconvolutionType === 'linear') {
          return {
            deconvolutionType: 'linear',
            coefficients: Float32Array.from(flatten(componentSettings.coefficients)),
            intercept: Float32Array.from(componentSettings.intercept || [0, 0, 0]),
          } as LinearDeconvExtensionProps;
        } else {
          // Matrix data will be stored in column-major order
          const matrixData = times(numMatrices, () =>
            times(rowsPerMatrix, () => times(coefficientsPerMatrix, () => 0))
          );

          // // For each 3x3 matrix
          for (let matrixIndex = 0; matrixIndex < numMatrices; matrixIndex++) {
            // Each matrix has a 3 coefficients
            const inputCoefficientsReadOffset = matrixIndex * coefficientsPerMatrix;

            // Copy RGB coefficients
            for (let rgbIndex = 0; rgbIndex < 3; rgbIndex++) {
              for (
                let coefficientIndexInMatrix = 0;
                coefficientIndexInMatrix < coefficientsPerMatrix;
                coefficientIndexInMatrix++
              ) {
                /*
                 * Each matrix will have 3 coefficients for each RGB channel:
                 * matrixData[0-9]          | matrixData[9-18]          | matrixData[18-27]           | matrixData[27-36]           | matrixData[36-45]           | matrixData[45-54]           | matrixData[54-63]
                 * [[ r_c_1, r_c_2, r_c_3], | [[ r_c_4, r_c_5, r_c_6],  | [[ r_c_7, r_c_8, r_c_9],    | [[ r_c_10, r_c_11, r_c_12], | [[ r_c_13, r_c_14, r_c_15], | [[ r_c_16, r_c_17, r_c_18], | [[ r_c_19, r_c_20, 0.0],
                 *  [ g_c_1, g_c_2, g_c_3], |  [ g_c_4, g_c_5, g_c_6],  |  [ g_c_7, g_c_8, g_c_9],    |  [ g_c_10, g_c_11, g_c_12], |  [ g_c_13, g_c_14, g_c_15], |  [ g_c_16, g_c_17, g_c_18], |  [ g_c_19, g_c_20, 0.0],
                 *  [ b_c_1, b_c_2, b_c_3]] |  [ b_c_4, b_c_5, b_c_6]]  |  [ b_c_7, b_c_8, b_c_9]]    |  [ b_c_10, b_c_11, b_c_12]] |  [ b_c_13, b_c_14, b_c_15]] |  [ b_c_16, b_c_17, b_c_18]] |  [ b_c_19, b_c_20, 0.0]]
                 *
                 * Note that they will be transposed when read in the shader (as matrices are stored in column-major order)
                 */
                matrixData[matrixIndex][rgbIndex][coefficientIndexInMatrix] =
                  componentSettings.coefficients[rgbIndex][inputCoefficientsReadOffset + coefficientIndexInMatrix] || 0;
              }
            }
          }
          const outputMatrixData = [
            Float32Array.from(flatten(matrixData[0])),
            Float32Array.from(flatten(matrixData[1])),
            Float32Array.from(flatten(matrixData[2])),
            Float32Array.from(flatten(matrixData[3])),
            Float32Array.from(flatten(matrixData[4])),
            Float32Array.from(flatten(matrixData[5])),
            Float32Array.from(flatten(matrixData[6])),
          ] as [Float32Array, Float32Array, Float32Array, Float32Array, Float32Array, Float32Array, Float32Array];
          return {
            deconvolutionType: 'cubic',
            coefficients: outputMatrixData,
            intercept: Float32Array.from(componentSettings.intercept || [0, 0, 0]),
          } as CubicDeconvExtensionProps;
        }
      });
    }, [slideHasDeconvolutionComponents, stainDeconvolutionSettings, stainDeconvolutionComponentsSettings]);

  const viewerDeconvolutionComponentsVisualSettings = deconvolutionComponentsVisualSettings[slide.viewerIndex]?.value;
  const slideDeconvolutionComponentsVisualSettings = viewerDeconvolutionComponentsVisualSettings?.[slide.id];
  const { data: baseSlideVisualSettingsScannerPresets } = useScannerSlideVisualizationPreset(
    slide?.scannerManufacturer,
    { enabled: Boolean(slide?.scannerManufacturer) }
  );
  const baseVisualSettings =
    baseSlidesVisualSettings[slide.viewerIndex]?.value?.[slide.id] ||
    baseSlideVisualSettingsScannerPresets?.visualizationPreset ||
    defaultBaseSlideVisualSettings;

  const stainComponentVisualSettings = map(stainDeconvolutionComponentsSettings, (componentSettings) => {
    if (componentSettings) {
      const { componentStainTypeId, displayName } = componentSettings;
      const componentVisualSettingsKey = getDeconvolutionComponentSettingsKey(componentStainTypeId, displayName);
      return slideDeconvolutionComponentsVisualSettings?.[componentVisualSettingsKey]?.value || baseVisualSettings;
    } else {
      return baseVisualSettings;
    }
  });

  const defaultContrastLimits: [min: number, max: number] = [0, slide.encoding === 'uint16' ? MAX_UINT16 : MAX_UINT8];

  const channelSource = baseImagePyramids?.layerSource;

  const viewerBaseSlideSettings = baseSlidesVisualSettings[slide.viewerIndex];
  const viewerChannelColorSettings = slidesChannelColorSettings[slide.viewerIndex]?.value;
  const viewerChannelNormalizationSettings = slidesChannelNormalizationSettings[slide.viewerIndex]?.value;
  const viewerChannelToggles = slidesChannelToggles[slide.viewerIndex]?.value;
  const slideBaseSettings = {
    ...defaultBaseSlideVisualSettings,
    ...baseSlideVisualSettingsScannerPresets?.visualizationPreset,
    ...(viewerBaseSlideSettings?.value?.[slide.id] || {}),
  };

  const slideChannelToggles = viewerChannelToggles?.[slide?.id];

  const slideChannelsOrder = slice(baseImagePyramids?.layersOrder || [], 0, MAX_CHANNELS);
  const slideHasChannels = Boolean(slideChannelsOrder.length > 1);

  const orderedSlideChannelColorSettings = slideHasChannels
    ? map(slideChannelsOrder, (channelId) => viewerChannelColorSettings?.[slide?.id]?.[channelId]?.value)
    : undefined;

  const orderedSlideChannelNormalizationSettings = slideHasChannels
    ? map(slideChannelsOrder, (channelId) => viewerChannelNormalizationSettings?.[slide?.id]?.[channelId]?.value)
    : undefined;

  const slideChannelColors: Color[] = slideHasChannels
    ? map(orderedSlideChannelColorSettings, (channelSettings): Color => {
        const channelColor = channelSettings?.color;
        if (!channelColor) {
          return defaultTransparentColor;
        }
        return channelColor?.rgb
          ? [channelColor?.rgb.r, channelColor?.rgb.g, channelColor?.rgb.b]
          : hexToRgb(channelColor);
      })
    : slideHasDeconvolutionComponents
    ? times(stainDeconvolutionComponentsSettings.length, (index) => hexToRgb(defaultLayerColors[index]))
    : ([hexToRgb(first(defaultLayerColors))] as Color[]);

  const slideChannelsGamma: number[] = slideHasChannels
    ? map(
        orderedSlideChannelColorSettings,
        (channelSettings) => channelSettings?.gamma ?? defaultBaseSlideVisualSettings.gamma
      )
    : slideHasDeconvolutionComponents
    ? map(
        stainComponentVisualSettings,
        (componentSettings) => componentSettings?.gamma ?? defaultBaseSlideVisualSettings.gamma
      )
    : [slideBaseSettings?.gamma];
  const slideChannelContrasts = slideHasChannels
    ? []
    : slideHasDeconvolutionComponents
    ? map(stainComponentVisualSettings, (componentSettings) => componentSettings?.contrast)
    : [slideBaseSettings?.contrast];
  const slideChannelBrightnesses = slideHasChannels
    ? []
    : slideHasDeconvolutionComponents
    ? map(stainComponentVisualSettings, (componentSettings) => componentSettings?.brightness)
    : [slideBaseSettings?.brightness];

  const slideContrastLimits = slideHasChannels
    ? map(orderedSlideChannelNormalizationSettings, (channelSettings): [min: number, max: number] => {
        const range = channelSettings ?? defaultContrastLimits;
        return channelSource?.meta?.usePngDecoder
          ? range
          : // Normalize range to 0-255
            (map(range, (value) => (value / last(defaultContrastLimits)) * 255) as [number, number]);
      })
    : slideHasDeconvolutionComponents
    ? times(stainDeconvolutionComponentsSettings.length, () => defaultContrastLimits)
    : [defaultContrastLimits];

  const slideChannelsVisible = slideHasChannels
    ? map(slideChannelsOrder, (channelId) => slideChannelToggles?.[channelId])
    : slideHasDeconvolutionComponents
    ? map(stainComponentVisualSettings, (componentSettings) => componentSettings?.show)
    : [slideBaseSettings?.show];

  const channelOpacities = slideHasChannels
    ? map(orderedSlideChannelColorSettings, (channelSettings) => channelSettings?.opacity ?? 0)
    : slideHasDeconvolutionComponents
    ? map(stainComponentVisualSettings, (componentSettings) => componentSettings?.opacity)
    : [slideBaseSettings?.opacity ?? 0];

  // Memoize selections and visibility to avoid unnecessary layer data updates
  const selections = useMemo(
    // If no channels, or deconvolution components use a single selection
    () =>
      times(slideChannelsOrder?.length || stainDeconvolutionComponentsSettings?.length || 1, (layerIndex) => ({
        layerIndex,
      })),
    [slideChannelsOrder?.length, stainDeconvolutionComponentsSettings?.length]
  );

  const stableSlideChannelsVisible = useMemo(() => slideChannelsVisible, [JSON.stringify(slideChannelsVisible)]);

  const stableSlideContrastLimits = useMemo(() => slideContrastLimits, [JSON.stringify(slideContrastLimits)]);

  const stableSlideChannelOpacities = useMemo(() => channelOpacities, [JSON.stringify(channelOpacities)]);

  const stableSlideChannelColors = useMemo(() => slideChannelColors, [JSON.stringify(slideChannelColors)]);

  const stableSlideChannelsGamma = useMemo(() => slideChannelsGamma, [JSON.stringify(slideChannelsGamma)]);
  const stableSlideContrastValues = useMemo(() => slideChannelContrasts, [JSON.stringify(slideChannelContrasts)]);
  const stableSlideBrightnessValues = useMemo(
    () => slideChannelBrightnesses,
    [JSON.stringify(slideChannelBrightnesses)]
  );

  return useMemo(() => {
    if (!channelSource || isEmpty(selections)) {
      return null;
    }

    const baseMultiScaleImageLayerParams = {
      layerSource: channelSource,
      overviewLayer: overview,
      viewerIndex: slide.viewerIndex,
      tileSize: channelSource.getTileSize(),
      zoomOffset: 0,
      coordinateSystem: COORDINATE_SYSTEM.DEFAULT,
      pickable: true,
    };
    return slideHasDeconvolutionComponents
      ? map(
          stainDeconvolutionComponentsSettings,
          (componentSettings, index) =>
            new MultiScaleImageLayer({
              ...baseMultiScaleImageLayerParams,
              id: `${overview ? OVERVIEW_LAYER_ID : 'MultiScaleImageLayer'}-${
                componentSettings
                  ? getDeconvolutionComponentSettingsKey(
                      componentSettings.componentStainTypeId,
                      componentSettings.displayName
                    )
                  : slide.stainingType
              }-${channelSource.getUniqueId()}-${index}-${stainDeconvolutionComponentsSettings.length}-components`,
              selections: castArray(selections[index]),
              transparentColor: undefined,
              layerOpacities: castArray(stableSlideChannelOpacities[index]),
              gammaValues: castArray(stableSlideChannelsGamma[index]),
              contrastValues: castArray(stableSlideContrastValues[index]),
              brightnessValues: castArray(stableSlideBrightnessValues[index]),
              layersVisible: castArray(stableSlideChannelsVisible[index]),
              excludeBackground: true,
              ...((slideDeconvComponentProps[index] || {}) as LinearDeconvExtensionProps & CubicDeconvExtensionProps),
            })
        )
      : [
          new MultiScaleImageLayer({
            ...baseMultiScaleImageLayerParams,
            id: `${
              overview ? OVERVIEW_LAYER_ID : 'MultiScaleImageLayer'
            }-${channelSource.getUniqueId()}-${MAX_CHANNELS}`,
            selections,
            transparentColor: slideHasChannels ? defaultTransparentColor : undefined,
            layerOpacities: stableSlideChannelOpacities,
            contrastLimits: stableSlideContrastLimits,
            colors: stableSlideChannelColors,
            gammaValues: stableSlideChannelsGamma,
            contrastValues: stableSlideContrastValues,
            brightnessValues: stableSlideBrightnessValues,
            layersVisible: stableSlideChannelsVisible,
            excludeBackground:
              !overview &&
              // Only for rgb images
              isInterleaved(channelSource.shape) &&
              some(stableSlideChannelOpacities, (opacity) => opacity > 0 && opacity < 100),
          }),
        ];
  }, [
    channelSource,
    selections,
    slideHasChannels,
    stableSlideChannelsGamma,
    stableSlideBrightnessValues,
    stableSlideContrastValues,
    stableSlideChannelOpacities,
    stableSlideContrastLimits,
    stableSlideChannelColors,
    stableSlideChannelsVisible,
    overview,
    slideHasDeconvolutionComponents,
    stainDeconvolutionComponentsSettings,
    slideDeconvComponentProps,
  ]);
};
