import { GridRowId, GridRowModesModel, GridRowParams } from '@mui/x-data-grid';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import { useQueryClient } from '@tanstack/react-query';
import { map, reject } from 'lodash';
import React from 'react';

import { createSlideTagOption, deleteSlideTagOption, updateSlideTagOption } from 'api/slideTags';
import { doesUpdatingRowHaveRequiredFields, isUpdatingRowUnique } from 'components/atoms/EditableDataGrid/helpers';
import { useCrudControlsColumns } from 'components/atoms/EditableDataGrid/rowEditingControlsColumns';
import { SlideTag } from 'interfaces/slideTag';
import { slideTagFields } from 'interfaces/slideTag/slideTagFields';
import { slideTagOptionsQueryKey } from 'utils/queryHooks/useSlideTagOptions';
import { useMutationWithErrorSnackbar } from 'utils/useMutationWithErrorSnackbar';
import { SlideTagRowChangesSummary } from './SlideTagsRowChangesSummary';

const getSlideTagError = ({
  id,
  apiRef,
}: GridRowParams<SlideTag> & {
  apiRef: React.MutableRefObject<GridApiCommunity>;
}) => {
  const hasDuplicateSlideTag = !isUpdatingRowUnique({
    apiRef,
    rowId: id,
    uniqueFields: ['tagValue', 'perStudy', 'perExperimentResult'],
  });

  const missingRequiredFields = !doesUpdatingRowHaveRequiredFields({
    apiRef,
    rowId: id,
    requiredFields: ['tagValue'],
  });

  const currentPerStudy = apiRef?.current?.getRowWithUpdatedValues?.(id, 'perStudy')['perStudy'];
  const currentPerExperimentResult = apiRef?.current?.getRowWithUpdatedValues?.(id, 'perExperimentResult')[
    'perExperimentResult'
  ];
  const experimentResultsWithoutStudy = !currentPerStudy && Boolean(currentPerExperimentResult);

  return hasDuplicateSlideTag
    ? 'Duplicate slide tag'
    : missingRequiredFields
    ? 'Missing required fields'
    : experimentResultsWithoutStudy
    ? 'Cannot assign a slide tag to an experiment result without assigning it to a study'
    : '';
};

export const useSlideTagsColumns = ({
  apiRef,
  noRows,
  slideTags,
  draftSlideTags,
  rowModesModel,
  setDraftSlideTags,
  setRowModesModel,
}: {
  noRows?: boolean;
  apiRef: React.MutableRefObject<GridApiCommunity>;
  slideTags: SlideTag[];
  draftSlideTags: SlideTag[];
  rowModesModel: GridRowModesModel;
  setDraftSlideTags: React.Dispatch<React.SetStateAction<SlideTag[]>>;
  setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>;
}) => {
  const [mutatingRowId, setMutatingRowId] = React.useState<GridRowId | undefined>();

  const queryClient = useQueryClient();

  const commonMutationOptions = {
    onError: () => {
      setMutatingRowId(undefined);
    },
  };

  const createSlideTagMutation = useMutationWithErrorSnackbar({
    ...commonMutationOptions,
    onSuccess: (newSlideTag) => {
      queryClient.invalidateQueries(slideTagOptionsQueryKey);
      queryClient.setQueryData(slideTagOptionsQueryKey, (oldData: SlideTag[]) => [...oldData, newSlideTag]);
      queryClient.invalidateQueries(['slideTags']);
    },
    mutationFn: createSlideTagOption,
    mutationDescription: 'create slide tag',
  });
  const updateSlideTagMutation = useMutationWithErrorSnackbar({
    ...commonMutationOptions,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(slideTagOptionsQueryKey);
      queryClient.setQueryData(slideTagOptionsQueryKey, (oldData: SlideTag[]) => {
        return [...reject(oldData, { id: mutatingRowId }), variables];
      });
      queryClient.invalidateQueries(['slideTags']);
    },
    mutationFn: updateSlideTagOption,
    mutationDescription: 'update slide tag',
  });
  const deleteSlideTagMutation = useMutationWithErrorSnackbar({
    ...commonMutationOptions,
    onSuccess(data, variables) {
      const id = variables;
      queryClient.setQueryData(slideTagOptionsQueryKey, (oldData: SlideTag[]) =>
        map(oldData, (slideTag) => {
          if (slideTag.id === id) {
            return { ...slideTag, deletedAt: new Date().toISOString() };
          } else {
            return slideTag;
          }
        })
      );
      queryClient.invalidateQueries(slideTagOptionsQueryKey);
      queryClient.invalidateQueries(['slideTags']);
    },
    mutationFn: deleteSlideTagOption,
    mutationDescription: 'delete slide tag',
  });

  const columns = useCrudControlsColumns<SlideTag>({
    createMutation: createSlideTagMutation,
    deleteMutation: deleteSlideTagMutation,
    updateMutation: updateSlideTagMutation,
    apiRef,
    rowModesModel,
    setRowModesModel,
    setDraftRows: setDraftSlideTags,
    draftRows: draftSlideTags,
    currentRows: slideTags,
    rowTypeFields: slideTagFields,
    getRowError: getSlideTagError,
    noRows,
    getCancelEditConfirmationModalOptions: ({ newRowValue, isDraft, changes, id }) => ({
      title: `Cancel slideTag ${isDraft ? 'creation' : 'update'}`,
      text: <SlideTagRowChangesSummary slideTagValue={`${newRowValue?.tagValue || id}`} changes={changes} />,
    }),
    getSaveConfirmationModalOptions: ({ newRowValue, isDraft, changes, id }) => ({
      title: `${isDraft ? 'Create' : 'Update'} Slide Tag`,
      text: <SlideTagRowChangesSummary slideTagValue={`${newRowValue?.tagValue || id}`} changes={changes} />,
    }),
    getDeleteConfirmationModalOptions: () => ({
      title: 'Archive Slide Tag',
      text: 'Are you sure you want to archive this slide tag?',
    }),
    mutatingRowId,
    setMutatingRowId,
  });

  return columns;
};
