import BarChartIcon from '@mui/icons-material/BarChart';
import CandlestickChartOutlinedIcon from '@mui/icons-material/CandlestickChartOutlined';
import ExpandIcon from '@mui/icons-material/Expand';
import PieChartIcon from '@mui/icons-material/PieChart';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { QueryFunctionContext, useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import { getFeatureKeysByStudyAndOrchestration } from 'api/features';
import { getProcedures } from 'api/procedures';
import { ChartKeyType } from 'components/FeaturesDashboard/chart.util';
import { SurvivalAnalysisType } from 'components/FeaturesDashboard/charts/kaplanMeier.util';
import { ControlledChartOptions } from 'components/FeaturesDashboard/ControlledChart';
import ExploratoryAnalysis from 'components/FeaturesDashboard/ExploratoryAnalysis';
import Loader from 'components/Loader';
import { DEFAULT_PAGE_SIZE } from 'components/StudyDashboard/ProceduresPage/ProcedurePagination';
import { ChartType } from 'interfaces/chart';
import { CohortWithSelectedFeatures } from 'interfaces/cohort_old';
import { getFeaturesInPresetCharts } from 'interfaces/overviewPreset';
import { ProcedureResponse } from 'interfaces/procedure';
import { Study } from 'interfaces/study';
import { flatMap, forEach, get, includes, isEmpty, keys, map, max, some, times } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { BooleanParam, DelimitedArrayParam, useQueryParam, withDefault } from 'use-query-params';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { ExperimentResultsSelection, useEncodedFilters } from 'utils/useEncodedFilters';
import ChartsSection, { ChartConfigurationParam, ChartSection, ChartsSectionsParams } from './ChartsSection';
import { useOverviewPresets } from './useOverviewPresets';

interface Props {
  currentStudy: Study;
  totalProcedures: number;
  reportActions: ReportAction[][];
}

interface ReportAction {
  function_type: string;
  features?: string[] | string[][];
  plot_type?: string;
  splitting_key?: string;
  title: string;
  description: string;
  kaplan_meier_parameter?: SurvivalAnalysisType;
}

const SelectedCohortIdsParam = withDefault(DelimitedArrayParam, []);

const AnalysisReport: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  currentStudy,
  totalProcedures,
  reportActions,
}) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { labId } = useCurrentLabId();
  const { name: userName } = useAppSelector((state) => state.auth.profile);
  const { queryParams, generateEncodedParams } = useEncodedFilters({
    experimentResultsSelection: ExperimentResultsSelection.FeatureValues,
  });

  const enable = Boolean(currentStudy);

  const [selectedCohortIds, setSelectedCohortIds] = useQueryParam('selectedCohortIds', SelectedCohortIdsParam);

  const { overviewPresets, getUserDefaultPreset, userLocalCasesOverviewPresets } = useOverviewPresets(currentStudy, {
    enabled: enable,
  });

  const [existingChartsMap, setExistingChartsMap] = useQueryParam<Record<number, ControlledChartOptions>>(
    'reportCharts',
    ChartConfigurationParam
  );

  const [chartsSections, setChartsSections] = useQueryParam<Record<number, ChartSection>>(
    'chartsSections',
    ChartsSectionsParams
  );

  const userDefaultPreset = useMemo(() => {
    return getUserDefaultPreset();
  }, [currentStudy, overviewPresets, userLocalCasesOverviewPresets]);

  const [lastInvocationIndex, setLastInvocationIndex] = useState(0);

  useEffect(() => {
    if (reportActions.length > 0 && reportActions.length > lastInvocationIndex) {
      // check the lastInvocation index, if it is bigger than the current one, update the existingChartsMap according to the action
      // This will be generatlized to support multiple actions per interaction
      const currentAction = get(reportActions[reportActions.length - 1], '0');

      if (
        (currentAction?.function_type === 'compare_features' &&
          includes(['histogram', 'scatter'], currentAction?.plot_type)) ||
        currentAction?.function_type === 'plot_kaplan_meier'
      ) {
        const newChartsMap: Record<number, ControlledChartOptions> = {};

        // the new chart ids will generated by increamenting from the last chart id and so on

        const features = currentAction.features || [];
        const lastChartId = max(map(keys(existingChartsMap), Number)) || 0;

        if (currentAction.plot_type === 'scatter') {
          forEach(features, (featurePair, featureIndex) => {
            const chartId: number = Number(featureIndex) + 1 + lastChartId;
            newChartsMap[chartId] = {
              type: ChartType.Scatter,
              horizontalKey: {
                name: (featurePair as string[])[0],
                type: ChartKeyType.Numerical,
              },
              verticalKey: {
                name: (featurePair as string[])[1],
                type: ChartKeyType.Numerical,
              },
              ...(currentAction.splitting_key && {
                splittingKey: {
                  name: currentAction.splitting_key,
                  type: ChartKeyType.Categorical,
                },
              }),
            };
          });
        } else if (currentAction.plot_type === 'histogram') {
          forEach(features, (feature, featureIndex) => {
            const chartId = Number(featureIndex) + 1 + lastChartId;
            newChartsMap[chartId] = {
              type: ChartType.Histogram,
              horizontalKey: {
                name: feature as string,
                type: ChartKeyType.Numerical,
              },
              ...(currentAction.splitting_key && {
                splittingKey: {
                  name: currentAction.splitting_key,
                  type: ChartKeyType.Categorical,
                },
              }),
            };
          });
        } else if (currentAction.function_type === 'plot_kaplan_meier') {
          forEach(features, (feature, featureIndex) => {
            const chartId = Number(featureIndex) + 1 + lastChartId;
            newChartsMap[chartId] = {
              type: ChartType.KaplanMeier,
              kaplanMeierParameter: currentAction.kaplan_meier_parameter || 'overallSurvival',
              splittingKey: {
                name: feature as string,
                type: ChartKeyType.Categorical,
              },
            };
          });
        } else {
          console.error('Unknown plot type:', currentAction.plot_type);
        }

        const chartIds = map(keys(newChartsMap), Number);

        setExistingChartsMap((prevExistingChartsMap) => ({
          ...prevExistingChartsMap,
          ...newChartsMap,
        }));

        const lastSectionId = max(map(keys(chartsSections), Number));
        setChartsSections((prevChartsSections) => ({
          ...prevChartsSections,
          [lastSectionId + 1]: {
            id: lastSectionId + 1,
            title: currentAction.title,
            description: currentAction.description,
            chartIds: chartIds,
          },
        }));
      } else if (includes(['pfs_analysis', 'os_analysis'], currentAction?.function_type)) {
        const lastSectionId = max(map(keys(chartsSections), Number));
        // add the exploratory analysis section
        setChartsSections((prevChartsSections) => ({
          ...prevChartsSections,
          [lastSectionId + 1]: {
            id: lastSectionId + 1,
            title: currentAction.title,
            description: currentAction.description,
            chartIds: null,
            isPFS: true,
          },
        }));
      } else {
        console.error('Unknown function type:', currentAction?.function_type);
      }
      setLastInvocationIndex(reportActions.length - 1);
    }
  }, [reportActions.length]);
  const pageSize = DEFAULT_PAGE_SIZE;

  const getEncodedParams = ({
    page,
    featureSelection,
    isSingleFeature = false,
  }: {
    page?: number;
    featureSelection?: string[];
    isSingleFeature?: boolean;
  }) => {
    return generateEncodedParams(
      {
        page: page,
        pageSize: pageSize,
      },
      { isAnalysis: true, isSingleFeature: isSingleFeature, featuresSelection: (featureSelection || []).sort() },
      { isAnalysis: BooleanParam, isSingleFeature: BooleanParam }
    );
  };

  const [cohortWithSelectedFeatures, setCohort] = useState<CohortWithSelectedFeatures>({
    id: `cohort${currentStudy?.id}`,
    labId: currentStudy?.labId,
    name: currentStudy?.name,
    highlightedFeatures: currentStudy?.highlightedFeatures,
    inferredFeaturesConfig: currentStudy?.inferredFeaturesConfig,
    procedures: [],
    survivalAnalysis: undefined,
    dendrogram: undefined,
  });

  useEffect(() => {
    setCohort((prevCohort) => ({
      ...prevCohort,
      id: `cohort${currentStudy?.id}`,
      labId: currentStudy?.labId,
      name: currentStudy?.name,
      highlightedFeatures: currentStudy?.highlightedFeatures,
      inferredFeaturesConfig: currentStudy?.inferredFeaturesConfig,
    }));
  }, [currentStudy]);

  const totalPages = totalProcedures ? Math.ceil(totalProcedures / pageSize) : 0;

  const [pagesToFetch, setPagesToFetch] = useState(5);

  useEffect(() => {
    setPagesToFetch(5);
  }, [queryParams]);

  const proceduresQueries = useQueries({
    queries: times(pagesToFetch, (index) => {
      const encodedFilters = getEncodedParams({
        page: index + 1,
        featureSelection: [
          ...(getFeaturesInPresetCharts(userDefaultPreset?.preset) ?? []),
          ...map(cohortWithSelectedFeatures.inferredFeaturesConfig, 'featureName'),
        ],
      });
      const basicEncodedFilters = getEncodedParams({
        page: index + 1,
      });

      return {
        queryKey: ['procedures', encodedFilters],
        queryFn: ({ signal }: QueryFunctionContext) => getProcedures(encodedFilters, signal),
        enabled:
          enable &&
          Boolean(userDefaultPreset) &&
          queryClient.getQueryData(['procedures', basicEncodedFilters]) === undefined,
        initialData: queryClient.getQueryData(['procedures', basicEncodedFilters]),
        onSuccess(data: ProcedureResponse) {
          queryClient.setQueryData(['procedures', basicEncodedFilters], data);
          if (totalPages > pagesToFetch) {
            setPagesToFetch((prevPages) => prevPages + 1);
          }
        },
      };
    }),
  });

  useEffect(() => {
    return () => {
      // Cancel all procedures queries if the user navigates away
      queryClient.cancelQueries(['procedures']);
    };
  }, []);

  const procedures = flatMap(proceduresQueries, (query) => query.data?.procedures);

  const finishLoadingBasicData = !isEmpty(proceduresQueries) && !some(proceduresQueries, 'isLoading');
  const orchestrationId = queryParams.filters?.orchestrationId;

  const { data: featureKeys, isLoading: isLoadingFeatures } = useQuery({
    queryKey: [
      'featureKeys',
      {
        studyId: currentStudy?.id,
        orchestrationId,
        includeInferredFeatures: false,
        resultsMode: queryParams.resultsMode,
      },
    ],
    queryFn: () =>
      getFeatureKeysByStudyAndOrchestration(currentStudy?.id, orchestrationId, false, false, queryParams.resultsMode),
    enabled: enable,
  });

  const allFeatureKeys: string[] = featureKeys?.featuresKeys || [];

  useEffect(() => {
    if (finishLoadingBasicData) {
      setCohort((prevCohort) => ({
        ...prevCohort,
        procedures: procedures,
      }));
    }
  }, [finishLoadingBasicData]);

  const borderColor = theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800];

  return (
    <Grid container direction={'column'} item xs={12} width={'100%'}>
      <Grid item container alignItems="center" sx={{ borderBottom: '1px solid ' + borderColor }}>
        <Grid item container direction="row" p={1} alignItems="center" justifyContent="space-between">
          <Grid item container direction="row" xs={8} alignItems={'center'}>
            <BarChartIcon />
            <Typography variant="h3">Report Findings</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item px={1} sx={{ width: '100%' }}>
        {userDefaultPreset ? (
          map(chartsSections, (section, index) =>
            section.isPFS ? (
              <Grid container key={index} direction="column" item>
                <Grid item>
                  <Typography variant="h3">{section.title}</Typography>
                  <Typography variant="body1">{section.description}</Typography>
                </Grid>
                <Grid container item>
                  <ExploratoryAnalysis selectedCohortIds={[`cohort${currentStudy.id}`]} />
                </Grid>
              </Grid>
            ) : (
              <ChartsSection
                key={index}
                id={section.id}
                title={section.title}
                description={section.description}
                cohort={cohortWithSelectedFeatures}
                cohortAllFeatures={allFeatureKeys}
                isLoading={isLoadingFeatures || !finishLoadingBasicData}
                addChartActions={addChartActions}
                chartIds={section.chartIds}
                setCohort={setCohort}
                layout="large"
              />
            )
          )
        ) : (
          <Box height={'100%'} width={'100%'}>
            <Loader />
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default AnalysisReport;

const addChartActions: { key: ChartType; icon: any; name: string }[] = [
  { key: ChartType.Histogram, icon: <BarChartIcon />, name: 'Histogram Chart' },
  { key: ChartType.Box, icon: <CandlestickChartOutlinedIcon />, name: 'Box Plot' },
  { key: ChartType.Scatter, icon: <ScatterPlotIcon />, name: 'Scatter Plot' },
  { key: ChartType.KaplanMeier, icon: <SsidChartIcon />, name: 'Kaplan Meier' },
  { key: ChartType.DistanceBased, icon: <ExpandIcon />, name: 'Distance Based' },
  { key: ChartType.Pie, icon: <PieChartIcon />, name: 'Pie Chart' },
];
