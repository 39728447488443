import { typeToName } from 'components/ReviewPanel/form.util';
import { DisplayedField } from 'interfaces/genericFields';
import { FormType, formTypes } from 'interfaces/reviewForm';
import { flatten, fromPairs, map, mapValues, values } from 'lodash';
import { Procedure } from '..';
import baseFields from './baseFields';
import clinicalFields from './clinicalFields';
import { ProceduresFieldsContext } from './helpers';
import metadataFields from './metadataFields';
import outcomeFields from './outcomeFields';
import patientFields from './patientFields';

export const optionalPluralSuffix = '(s)';

export type ProceduresFieldsCategory = 'metadata' | 'patient' | 'clinical' | 'outcome' | 'labels' | FormType;

export const slideFieldsToExcludeFromEditPanelInCaseMode = [
  'scannerModel',
  'format',
  'scannerManufacturer',
  'tsmAreaField',
  'negativeControl',
  'positiveControl',
  'objectiveMagnification',
  'qcFailed',
  'date_of_metastasis',
];

export const procedureFieldCategoryToTitle: { [g in ProceduresFieldsCategory]: string } = {
  metadata: 'Metadata',
  patient: 'Patient Information',
  clinical: 'Clinical Data',
  outcome: 'Outcome',
  labels: 'QC Labels',
  ...(fromPairs(map(formTypes, (type) => [type, typeToName(type)])) as Record<FormType, string>),
};

export const getFieldsGroupMap = (variableFields: {
  labelsFields?: Array<DisplayedField<Procedure, any, ProceduresFieldsContext>>;
  formsFieldGroups?: Partial<Record<FormType, Array<DisplayedField<Procedure, any, ProceduresFieldsContext>>>>;
}): { [g in ProceduresFieldsCategory]?: string[] } => {
  const fieldGroupFields = variableFields.formsFieldGroups
    ? mapValues(variableFields.formsFieldGroups, (fields) => map(fields, 'dataKey'))
    : {};

  const fieldsGroupMap = {
    metadata: map(metadataFields, 'dataKey'),
    clinical: map(clinicalFields, 'dataKey'),
    patient: map(patientFields, 'dataKey'),
    outcome: map(outcomeFields, 'dataKey'),
    labels: map(variableFields.labelsFields, 'dataKey'), // this is a group of changed columns (depending on the study settings), so the check here is different
    ...fieldGroupFields,
  };

  return fieldsGroupMap;
};

export const getOrderedFields = (
  labelsFields?: Array<DisplayedField<Procedure, boolean[], ProceduresFieldsContext>>,
  formsFieldGroups?: Partial<Record<FormType, Array<DisplayedField<Procedure, any, ProceduresFieldsContext>>>>
): Array<DisplayedField<Procedure, any, ProceduresFieldsContext>> => {
  const fields: Array<DisplayedField<Procedure, any, ProceduresFieldsContext>> = map(
    [
      ...baseFields,
      ...metadataFields,
      ...patientFields,
      ...clinicalFields,
      ...outcomeFields,
      ...(labelsFields || []),
      ...(flatten(values(formsFieldGroups)) || []),
    ],
    (field) => ({
      ...field,
      // Only support sorting for fields that are explicitly marked as sortable (MUI considers all fields sortable by default)
      sortable: Boolean(field.sortable),
    })
  );

  return fields;
};
