import SendIcon from '@mui/icons-material/Send';
import { Fab, Grid, Paper, TextField } from '@mui/material';
import React from 'react';

interface ChatInputProps {
  onSubmit: (message: string) => void;
  message: string;
  onChange?: (message: string) => void;
}

const ChatInput: React.FC<ChatInputProps> = ({ onSubmit, message, onChange }) => {
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    onSubmit(message);
  };

  return (
    <Paper sx={{ p: 1 }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            <TextField
              fullWidth
              placeholder="Type your message..."
              multiline
              value={message}
              onChange={(e) => onChange(e.target.value)}
            />
          </Grid>
          <Grid item>
            <Fab type="submit" color="primary">
              <SendIcon />
            </Fab>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default ChatInput;
