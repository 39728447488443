import { useEffect } from 'react';

const useHandleUnload = ({ showAlert }: { showAlert: boolean }) => {
  useEffect(() => {
    if (showAlert) {
      const handleUnload = (event: BeforeUnloadEvent) => {
        event.preventDefault();
        event.returnValue = '';
      };

      window.addEventListener('beforeunload', handleUnload);

      return () => {
        window.removeEventListener('beforeunload', handleUnload);
      };
    }
  }, [showAlert]);
};

export default useHandleUnload;
