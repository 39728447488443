import CommentIcon from '@mui/icons-material/Comment';
import StraightenIcon from '@mui/icons-material/Straighten';
import { Grid, ToggleButton } from '@mui/material';
import React, { useCallback } from 'react';
import { BooleanParam, StringParam, useQueryParam } from 'use-query-params';

import { useSecondaryAnalysisOrchestrationIdQueryParams } from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/SecondaryAnalysisLayer/useSecondaryAnalysisQueryParams';
import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { useAnnotationQueryParams } from 'services/annotations/useAnnotationQueryParams';
import { SecondaryAnalysisInteractions } from './secondaryAnalysis/SecondaryAnalysisInteractions';
import { SlideAnnotationInteractions } from './slideAnnotations/SlideAnnotationInteractions';

export interface InteractionMenusProps {
  caseId: number;
  selectedSlides: SlideWithChannelAndResults[];
  hasActiveNonAnnotationInteractions: boolean;
  clearNonAnnotationInteractions: () => void;
}

export const SlideInteractionMenu: React.FunctionComponent<{
  caseId: number;
  selectedSlides: SlideWithChannelAndResults[];
}> = ({ caseId, selectedSlides }) => {
  const [useOSDViewer] = useQueryParam('useOSDViewer', BooleanParam);
  const [measureToolActive, setMeasureToolActive] = useQueryParam('measureToolActive', BooleanParam);
  const [commentMode, setCommentMode] = useQueryParam('commentMode', StringParam);

  const clearNonAnnotationInteractions = useCallback(() => {
    setMeasureToolActive(false);
    setCommentMode(null);
  }, [setMeasureToolActive, setCommentMode]);

  const { annotationsActive } = useAnnotationQueryParams();
  const { secondaryAnalysisActive } = useSecondaryAnalysisOrchestrationIdQueryParams();

  const InteractionComponent = secondaryAnalysisActive
    ? SecondaryAnalysisInteractions
    : annotationsActive
    ? SlideAnnotationInteractions
    : false;
  return (
    !useOSDViewer && (
      <>
        {InteractionComponent && (
          <InteractionComponent
            caseId={caseId}
            selectedSlides={selectedSlides}
            hasActiveNonAnnotationInteractions={Boolean(measureToolActive || commentMode)}
            clearNonAnnotationInteractions={clearNonAnnotationInteractions}
          />
        )}
        <Grid item>
          <ToggleButton
            value="measureTool"
            selected={measureToolActive}
            onClick={() => {
              clearNonAnnotationInteractions();
              setMeasureToolActive(!measureToolActive);
            }}
            title="Measure tool"
          >
            <StraightenIcon color={measureToolActive ? 'primary' : 'inherit'} />
          </ToggleButton>
        </Grid>
        <Grid item>
          <ToggleButton
            value="commentMode"
            selected={Boolean(commentMode)}
            onClick={() => {
              clearNonAnnotationInteractions();
              setCommentMode(!commentMode ? 'addPoint' : null);
            }}
            title="Add comments to slide"
          >
            <CommentIcon color={commentMode ? 'primary' : 'inherit'} />
          </ToggleButton>
        </Grid>
      </>
    )
  );
};
