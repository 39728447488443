import { IconLayer } from '@deck.gl/layers/typed';
import { find, isNumber } from 'lodash';

import { UpdateParameters } from '@deck.gl/core/typed';
import { ZoomBoundsProps } from './types';

export class ZoomAwareIconLayer<DataT = any, ExtraPropsT extends ZoomBoundsProps = ZoomBoundsProps> extends IconLayer<
  DataT,
  ExtraPropsT
> {
  static defaultProps = IconLayer.defaultProps;

  static layerName: string = 'ZoomAwareIconLayer';

  state: IconLayer<DataT, ExtraPropsT>['state'] & { sizeForZoom: number };

  constructor(props: any) {
    super(props);
    // replace props with a proxy object that will use sizeForZoom when getSize is called
    const proxyProps = new window.Proxy(this.props, {
      get: (target, prop) => {
        if (prop === 'getSize') {
          return this.state.sizeForZoom;
        }
        return Reflect.get(target, prop);
      },
    });
    this.props = proxyProps;
  }

  updateState(args: UpdateParameters<this>): void {
    super.updateState(args);
    this.state.sizeForZoom = this.getSizeForViewport();
  }

  shouldUpdateState({ props, oldProps, context, changeFlags }: UpdateParameters<this>): boolean {
    const oldSizeForZoom = this.state.sizeForZoom;
    const newSizeForZoom = changeFlags.viewportChanged ? this.getSizeForViewport() : oldSizeForZoom;
    return super.shouldUpdateState({ props, oldProps, context, changeFlags }) || oldSizeForZoom !== newSizeForZoom;
  }

  getSizeForViewport(): number {
    const { zoom } = this.context.viewport;
    const sizeInMicronsByZoomRange = this.props.sizeInMicronsByZoomRange || [];
    const relevantSizeRange = find(sizeInMicronsByZoomRange, ({ minZoom, maxZoom }) => {
      const minZoomMatch = !isNumber(minZoom) || isNaN(minZoom) || zoom >= minZoom;
      const maxZoomMatch = !isNumber(maxZoom) || isNaN(maxZoom) || zoom <= maxZoom;
      return minZoomMatch && maxZoomMatch;
    });
    const slideMaxResolution = this.props.slideMaxResolution || 1;
    const sizeScale = this.props.sizeScale || 1;
    if (!relevantSizeRange) {
      return undefined;
    }
    const { sizeInMicrons } = relevantSizeRange;
    const pointRadiusMicronsAtMaxZoom = Math.max(1, sizeInMicrons / sizeScale);
    // We want to render the markers on top of cells, so we want their minimal size to be 5.00 μm
    return pointRadiusMicronsAtMaxZoom / slideMaxResolution; // (μm) / (μm/px) = px
  }
}
