import { load } from '@loaders.gl/core';
import { ImageLoader } from '@loaders.gl/images';
import GL from '@luma.gl/constants';
import { signal } from '@preact/signals-react';
import { DecodedPng, decode as decodePng, PngDataArray } from 'fast-png';
import { compact, Dictionary, isEmpty, isNumber, join, map, times } from 'lodash';

import { MAX_VIEWERS } from 'components/Procedure/SlidesViewer/constants';
import { MultiScaleImageData } from './utils';

export const multiScaleLayerLoadingStates = times(MAX_VIEWERS, () => signal<Dictionary<boolean>>({}));

const getCropParams = ({
  tileSize,
  overlapPixels,
  width,
  height,
  tileCoordinates,
}: {
  tileSize: number;
  overlapPixels: number;
  width: number;
  height: number;
  tileCoordinates: { x: number; y: number; z: number };
}): { startX: number; startY: number; targetWidth: number; targetHeight: number } => {
  /*
    Example of a grid of tiles with overlapping pixels:

    +-----+-+-----+-+-----+-+-----+
    |     :o:     :o:     :o:     |
    |  T  :o:  T  :o:  T  :o:  T  |
    |     :o:     :o:     :o:     |
    +-----+=+-----+=+-----+=+-----+
    |ooooo:o:ooooo:o:ooooo:o:ooooo|
    +-----+=+-----+=+-----+=+-----+
    |     :o:     :o:     :o:     |
    |  T  :o:  T  :o:  T  :o:  T  |
    |     :o:     :o:     :o:     |
    +-----+=+-----+=+-----+=+-----+
    |ooooo:o:ooooo:o:ooooo:o:ooooo|
    +-----+=+-----+=+-----+=+-----+
    |     :o:     :o:     :o:     |
    |  T  :o:  T  :o:  T  :o:  T  |
    |     :o:     :o:     :o:     |
    +-----+=+-----+=+-----+=+-----+
    |ooooo:o:ooooo:o:ooooo:o:ooooo|
    +-----+=+-----+=+-----+=+-----+
    |     :o:     :o:     :o:     |
    |  T  :o:  T  :o:  T  :o:  T  |
    |     :o:     :o:     :o:     |
    +-----+-+-----+-+-----+-+-----+

    T = Tile
    o = Overlap pixels

    - / | = Tile boundary
    : = Tile overlap boundary

    You can see that the overlap pixels are only present in the interior of the grid, not on the edges.

    More info: https://www.gasi.ch/blog/inside-deep-zoom-2
    */
  const isFirstColumn = tileCoordinates.x === 0;
  const isFirstRow = tileCoordinates.y === 0;

  // Validate size of first row / column images includes 1 overlap pixel at max.
  const maxExpectedFirstRowColumnSize = tileSize + overlapPixels;
  if (isFirstColumn && width > maxExpectedFirstRowColumnSize) {
    console.warn(`First column image width is greater than expected: ${width} > ${maxExpectedFirstRowColumnSize}`);
  }
  if (isFirstRow && height > maxExpectedFirstRowColumnSize) {
    console.warn(`First row image height is greater than expected: ${height} > ${maxExpectedFirstRowColumnSize}`);
  }

  // Skip reading overlap pixels if it's not the first row or column (since in that case there are no overlap pixels on the top / left).
  const startX = isFirstColumn ? 0 : overlapPixels;
  const startY = isFirstRow ? 0 : overlapPixels;

  // Calculate end coordinates - don't read / write more than the tile size
  const targetWidth = Math.min(tileSize, width);
  const targetHeight = Math.min(tileSize, height);

  return { startX, startY, targetWidth, targetHeight };
};

export const cropDecodedPng = (
  image: DecodedPng,
  startX: number,
  startY: number,
  targetWidth: number,
  targetHeight: number
): DecodedPng => {
  // Validate input coordinates
  if (startX < 0 || startY < 0 || targetWidth > image.width || targetHeight > image.height) {
    throw new Error('Crop coordinates out of bounds');
  }
  if (startX >= targetWidth || startY >= targetHeight) {
    throw new Error('Invalid crop dimensions');
  }

  // Calculate bytes per pixel - depth is in bits, so divide by 8 to get bytes
  const bytesPerPixel = (image.depth / 8) * image.channels;

  // Create new data array of the same type as the input
  const Constructor = image.data.constructor as new (length: number) => PngDataArray;
  const newData = new Constructor(targetWidth * targetHeight * bytesPerPixel);

  for (let y = 0; y < targetHeight; y++) {
    for (let x = 0; x < targetWidth; x++) {
      const oldPos = ((startY + y) * image.width + (startX + x)) * bytesPerPixel;
      const newPos = (y * targetWidth + x) * bytesPerPixel;

      for (let b = 0; b < bytesPerPixel; b++) {
        newData[newPos + b] = image.data[oldPos + b];
      }
    }
  }

  // Return new DecodedPng object with cropped dimensions and data
  return {
    ...image,
    width: targetWidth,
    height: targetHeight,
    data: newData,
  };
};

const cropRaster = async (
  image: ImageData,
  startX: number,
  startY: number,
  targetWidth: number,
  targetHeight: number
): Promise<ImageData> => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  canvas.width = targetWidth;
  canvas.height = targetHeight;

  context.drawImage(
    await createImageBitmap(image),
    startX,
    startY,
    targetWidth,
    targetHeight,
    0,
    0,
    targetWidth,
    targetHeight
  );

  return context.getImageData(0, 0, canvas.width, canvas.height);
};

export async function getImageDataFromUrl({
  url,
  usePngDecoder,
  tileCoordinates,
  overlapPixels,
  tileSize,
  cropDecodedPngTiles,
}: {
  url: string | null;
  usePngDecoder: boolean;
  tileCoordinates: { x: number; y: number; z: number };
  overlapPixels: number;
  tileSize: number;
  cropDecodedPngTiles?: boolean;
}): Promise<(ImageData | DecodedPng) | null> {
  try {
    if (!url) {
      return null;
    }
    if (usePngDecoder) {
      if (!url.endsWith('.png')) {
        console.warn('PNG decoding requested but not provided a url to a png file', { url });
      }
      const fetchResponse = await fetch(url);
      if (!fetchResponse.ok) {
        return null;
      }
      const decodedPng = decodePng(await fetchResponse.arrayBuffer());
      if (!cropDecodedPngTiles) {
        return decodedPng;
      }
      if (!decodedPng) {
        return null;
      }
      if (!isNumber(overlapPixels) || isNaN(overlapPixels) || overlapPixels <= 0) {
        return decodedPng;
      } else {
        const { startX, startY, targetWidth, targetHeight } = getCropParams({
          tileSize,
          overlapPixels,
          width: decodedPng.width,
          height: decodedPng.height,
          tileCoordinates,
        });
        return cropDecodedPng(decodedPng, startX, startY, targetWidth, targetHeight);
      }
    }
    const image: ImageData = await load(url, ImageLoader, {
      fetch: { cache: 'force-cache' },
      loadOptions: {
        image: { type: 'image' },
        imagebitmap: { premultiplyAlpha: 'premultiply' },
      },
      nothrow: true,
    });
    if (!isNumber(overlapPixels) || isNaN(overlapPixels) || overlapPixels <= 0) {
      return image;
    } else {
      const { startX, startY, targetWidth, targetHeight } = getCropParams({
        tileSize,
        overlapPixels,
        width: image.width,
        height: image.height,
        tileCoordinates,
      });
      return await cropRaster(image, startX, startY, targetWidth, targetHeight);
    }
  } catch (err) {
    if (
      err instanceof ProgressEvent ||
      err.message?.startsWith?.('Failed to fetch') ||
      err.message?.startsWith('The user aborted a request')
    ) {
      return null;
    } else {
      console.error("Couldn't load tile", url, err);
      return null;
    }
  }
}

const generateEmptyResponse = (selectionUrls: Array<string | null>, usePngDecoder: boolean): MultiScaleImageData => ({
  data: times(selectionUrls?.length ?? 0, () => null),
  format: undefined,
  dataFormat: undefined,
  isDecodedPng: usePngDecoder,
});

export const getTileDataFromUrls = async ({
  selectionUrls,
  isRgb,
  usePngDecoder,
  viewerIndex,
  tileCoordinates,
  overlapPixels,
  tileSize,
  cropDecodedPngTiles,
}: {
  selectionUrls: Array<string | null>;
  isRgb: boolean;
  usePngDecoder: boolean;
  viewerIndex: number;
  tileCoordinates: { x: number; y: number; z: number };
  overlapPixels: number;
  tileSize: number;
  cropDecodedPngTiles?: boolean;
}): Promise<MultiScaleImageData> => {
  // Early return if no selections
  if (isEmpty(selectionUrls)) {
    console.warn('No selections provided to getTileDataFromUrls');
    return null;
  }

  try {
    if (!multiScaleLayerLoadingStates[viewerIndex]) {
      console.warn('No loading states for viewer', viewerIndex);
      return null;
    } else {
      multiScaleLayerLoadingStates[viewerIndex].value = {
        ...multiScaleLayerLoadingStates[viewerIndex].value,
        [join(selectionUrls, ',')]: true,
      };
    }
    const tiles = await Promise.all(
      map(selectionUrls, (url) =>
        getImageDataFromUrl({ url, usePngDecoder, tileCoordinates, overlapPixels, tileSize, cropDecodedPngTiles })
      )
    );

    if (!multiScaleLayerLoadingStates[viewerIndex]) {
      console.warn('No loading states for viewer', viewerIndex);
      return null;
    } else {
      multiScaleLayerLoadingStates[viewerIndex].value = {
        ...multiScaleLayerLoadingStates[viewerIndex].value,
        [join(selectionUrls, ',')]: false,
      };
    }

    if (isEmpty(compact(tiles))) {
      return null;
    }

    const tile = {
      data: tiles,
      format: isRgb ? GL.RGB : undefined,
      dataFormat: isRgb ? GL.RGB : undefined,
      isDecodedPng: usePngDecoder,
    };

    return tile;
  } catch (err) {
    console.error('Error fetching tile', err);

    return generateEmptyResponse(selectionUrls, usePngDecoder);
  }
};

export default getTileDataFromUrls;
