import { useRowSelectionContext } from 'components/atoms/RowSelectionContext';
import { ResultsMode } from 'interfaces/experimentResults';
import { getInnerRowIdFromUnwoundRowMetadata } from 'interfaces/genericFields/unwindRowsWithInnerArrays';
import { difference, isEmpty, map } from 'lodash';
import { useMemo } from 'react';
import { ArrayParam, StringParam } from 'use-query-params';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { filtersQueryParamsSchema, useEncodedFilters } from 'utils/useEncodedFilters';

export interface CasesParams {
  studyId?: string;
  labId?: string;
  filters?: any;
  features?: any;
  clinicalData?: any;
  slidesMode?: boolean;
  resultsMode?: ResultsMode;
  caseIdsToInclude?: number[];
  caseIdsToExclude?: number[];
  slideIdsToInclude?: string[];
  slideIdsToExclude?: string[];
}

export const manualSelectionFilters: Array<keyof CasesParams> = [
  'caseIdsToInclude',
  'caseIdsToExclude',
  'slideIdsToInclude',
  'slideIdsToExclude',
];

export const casesSchema = {
  ...filtersQueryParamsSchema,
  studyId: StringParam,
  caseIdsToExclude: ArrayParam,
  slideIdsToExclude: ArrayParam,
};

export const casesOptions = {
  arrayFormat: 'repeat',
};

export const useCasesParams = (differentQueryParams?: {
  filters?: any;
  features?: any;
  clinicalData?: any;
  slidesMode?: boolean;
  resultsMode?: ResultsMode;
}) => {
  const { labId } = useCurrentLabId();
  const { selectionMode, selectedRows, omittedRows } = useRowSelectionContext();
  const {
    queryParams: {
      filters,
      features,
      clinicalData,
      slidesMode,
      resultsMode,
      caseIdsToInclude: caseIdsToIncludeFilter,
      slideIdsToInclude: slideIdsToIncludeFilter,
    },
  } = useEncodedFilters();

  return useMemo(() => {
    const baseData = {
      studyId: filters?.studyId,
      filters: {
        ...filters,
        ...differentQueryParams?.filters,
      },
      features: {
        ...features,
        ...differentQueryParams?.features,
      },
      clinicalData: {
        ...clinicalData,
        ...differentQueryParams?.clinicalData,
      },
      labId,
      slidesMode: differentQueryParams?.slidesMode || slidesMode,
      resultsMode: differentQueryParams?.resultsMode || resultsMode || ResultsMode.Manual,
    };

    const casesParams: CasesParams = baseData;

    if (!slidesMode) {
      const caseIdsToIncludeSelect = selectionMode === 'select' ? (selectedRows as number[]) : undefined;
      const caseIdsToExcludeSelect = selectionMode === 'omit' ? (omittedRows as number[]) : undefined;

      if (caseIdsToIncludeFilter) {
        let caseIdsToIncludeWithFilteredOptions;
        if (isEmpty(caseIdsToIncludeSelect) && isEmpty(caseIdsToExcludeSelect)) {
          caseIdsToIncludeWithFilteredOptions = map(caseIdsToIncludeFilter, Number);
        } else if (!isEmpty(caseIdsToExcludeSelect)) {
          caseIdsToIncludeWithFilteredOptions = difference(map(caseIdsToIncludeFilter, Number), caseIdsToExcludeSelect);
        } else {
          caseIdsToIncludeWithFilteredOptions = caseIdsToIncludeSelect;
        }

        casesParams.caseIdsToInclude = map(caseIdsToIncludeWithFilteredOptions, (caseId) => Number(caseId));
        if (slideIdsToIncludeFilter) {
          casesParams.slideIdsToInclude = slideIdsToIncludeFilter;
        }
      } else {
        casesParams.caseIdsToInclude = caseIdsToIncludeSelect;
        casesParams.caseIdsToExclude = caseIdsToExcludeSelect;
        if (slideIdsToIncludeFilter) {
          casesParams.slideIdsToInclude = slideIdsToIncludeFilter;
        }
      }
    } else {
      const slideIdsToInclude =
        selectionMode === 'select' ? getInnerRowIdFromUnwoundRowMetadata(selectedRows as string[]) : undefined;
      const slideIdsToExclude =
        selectionMode === 'omit' ? getInnerRowIdFromUnwoundRowMetadata(omittedRows as string[]) : undefined;

      if (slideIdsToIncludeFilter) {
        let slideIdsToIncludeWithFilteredOptions;
        if (isEmpty(slideIdsToInclude) && isEmpty(slideIdsToExclude)) {
          slideIdsToIncludeWithFilteredOptions = slideIdsToIncludeFilter;
        } else if (!isEmpty(slideIdsToExclude)) {
          slideIdsToIncludeWithFilteredOptions = difference(slideIdsToIncludeFilter, slideIdsToExclude);
        } else {
          slideIdsToIncludeWithFilteredOptions = slideIdsToInclude;
        }

        casesParams.slideIdsToInclude = slideIdsToIncludeWithFilteredOptions;
        if (caseIdsToIncludeFilter) {
          casesParams.caseIdsToInclude = map(caseIdsToIncludeFilter, Number);
        }
      } else {
        casesParams.slideIdsToInclude = slideIdsToInclude;
        casesParams.slideIdsToExclude = slideIdsToExclude;
        if (caseIdsToIncludeFilter) {
          casesParams.caseIdsToInclude = map(caseIdsToIncludeFilter, Number);
        }
      }
    }

    return { casesParams, schema: casesSchema, options: casesOptions };
  }, [
    labId,
    filters,
    features,
    clinicalData,
    slidesMode,
    resultsMode,
    selectionMode,
    selectedRows,
    omittedRows,
    caseIdsToIncludeFilter,
    slideIdsToIncludeFilter,
    differentQueryParams,
  ]);
};
