import { getSlideTagOptions } from 'api/slideTags';
import { SlideTag } from 'interfaces/slideTag';
import { filter } from 'lodash';
import { QueryOptions, useQueryWithErrorAndRetrySnackbar } from '../useQueryWithErrorAndRetrySnackbar';

export const slideTagOptionsQueryKey: ['slideTagOptions'] = ['slideTagOptions'];

const useSlideTagOptions = (options: QueryOptions<SlideTag[], SlideTag[], string[]> = {}) => {
  const query = useQueryWithErrorAndRetrySnackbar(slideTagOptionsQueryKey, getSlideTagOptions, {
    queriedEntityName: 'slide tag options',
    ...options,
  });

  const unArchivedSlideTagOptions = filter(query.data, (slideTagOption) => !slideTagOption.deletedAt);

  return {
    ...query,
    slideTagOptionsWithArchived: query.data,
    slideTagOptions: unArchivedSlideTagOptions,
  };
};

export default useSlideTagOptions;
