import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { SlideInferenceResults } from 'interfaces/calculateFeatures';
import {
  compact,
  Dictionary,
  filter,
  flatMap,
  forEach,
  groupBy,
  includes,
  isEmpty,
  map,
  reduce,
  size,
  sortBy,
  values,
} from 'lodash';
import React, { Fragment, useMemo, useState } from 'react';
import { useStainTypeIdToDisplayName } from 'utils/useStainTypeIdToDisplayName';
import {
  AssignmentByStain,
  OrchestrationBySlideByFlowClassName,
  OrchestrationBySlideByType,
  SlideRegistrationDetailsByOrchestration,
} from '..';
import { modelTypesByApiModelValue } from '../../Jobs/inferenceFieldsOptions';
import { getSidesWithoutSomeModels, getSlidesModelsData, ModelSelection } from './utils';

export interface SummaryProps {
  modelsType: string[];
  slides: Dictionary<SlideInferenceResults>;
  selectedOrchestrations: OrchestrationBySlideByType;
  selectedPostprocessedOrchestrations: OrchestrationBySlideByFlowClassName;
  selectedAssignments: AssignmentByStain;
  removeSelectedModels: (slideIds: string[]) => void;
  displayAssignmentAnnotations?: boolean;
  selectedSlideRegistrationsByOrchestration?: SlideRegistrationDetailsByOrchestration;
}
const defaultRowsPerPage = 10;
const pageSizeOptions = [10];

const legendSlidesSummary = [
  {
    icon: <CheckIcon />,
    description: 'Inference Result',
  },
  {
    icon: 'A',
    description: 'Assignment Result',
  },
];

const Summary: React.FC<React.PropsWithChildren<SummaryProps>> = ({
  modelsType,
  slides,
  selectedOrchestrations,
  selectedPostprocessedOrchestrations,
  selectedAssignments,
  removeSelectedModels,
  displayAssignmentAnnotations = false,
  selectedSlideRegistrationsByOrchestration,
}) => {
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: defaultRowsPerPage,
  });
  const [paginationModelStain, setPaginationModelStain] = useState<GridPaginationModel>({
    page: 0,
    pageSize: defaultRowsPerPage,
  });
  const { stainTypeIdToDisplayName, isLoadingStainTypeOptions } = useStainTypeIdToDisplayName();

  const slidesModels = useMemo(
    () =>
      getSlidesModelsData(
        slides,
        selectedOrchestrations,
        selectedPostprocessedOrchestrations,
        selectedAssignments,
        modelsType,
        selectedSlideRegistrationsByOrchestration
      ),
    [
      slides,
      selectedOrchestrations,
      selectedPostprocessedOrchestrations,
      selectedAssignments,
      modelsType,
      selectedSlideRegistrationsByOrchestration,
    ]
  );

  const slidesByCaseLabel = groupBy(sortBy(slidesModels, ['caseLabel', 'stainingType']), 'caseLabel');

  // Sort slides by case label and count of models in case
  const sortedSlidesModelsCountByCase = sortBy(values(slidesByCaseLabel), (slidesModel) => {
    return reduce(
      modelsType,
      (count, modelType) => {
        forEach(slidesModel, (slideModel) => {
          if (slideModel[modelType]) count++;
        });
        return count;
      },
      0
    );
  });
  const sortedSlidesModelsCount = flatMap(sortedSlidesModelsCountByCase);

  const slidesByCase = groupBy(slidesModels, 'caseLabel');

  const sortedSlidesModelsCountWithRegistrations = useMemo(
    () =>
      map(sortedSlidesModelsCount, (slideModel) => {
        const slideRegistrationsPerStain: { [stainId: string]: boolean } = {};
        forEach(slideModel.registrationsInCasePerStain, (registrationExists, stain) => {
          slideRegistrationsPerStain[`registration-${stain}`] = registrationExists;
        });
        const registrationsCount = size(compact(values(slideRegistrationsPerStain)));
        const caseOtherSlides = filter(
          slidesByCase[slideModel.caseLabel],
          (slidesData) => slidesData.slideId !== slideModel.slideId
        );

        return {
          ...slideModel,
          ...slideRegistrationsPerStain,
          registrationCounts: `${registrationsCount}/${size(caseOtherSlides)}`,
        };
      }),
    [sortedSlidesModelsCount, slidesByCase]
  );
  const slidesByStain = groupBy(slidesModels, 'stainingType');

  const countModelsByStain: Record<string, Record<string, number>> = {};
  forEach(slidesByStain, (slidesOfStain, stain) => {
    countModelsByStain[stain] = { slidesCount: slidesOfStain.length };
    forEach(modelsType, (modelType) => {
      countModelsByStain[stain][modelType] = 0;
      forEach(slidesOfStain, (slide) => {
        if (slide[modelType]) {
          countModelsByStain[stain][modelType]++;
        }
      });
    });

    // add registration count
    countModelsByStain[stain].registrations = 0;
    forEach(slidesOfStain, (slide) => {
      const slideRegistrationCount = size(compact(values(slide.registrationsInCasePerStain)));
      countModelsByStain[stain].registrations += slideRegistrationCount;
    });
  });

  const countModelsByStainData = map(countModelsByStain, (countModels, stain) => {
    return {
      stain,
      ...countModels,
    };
  });

  const slidesWithoutSomeModels = getSidesWithoutSomeModels(modelsType, slidesModels);

  const columnsStain: GridColDef[] = [
    {
      field: 'stain',
      headerName: 'Stain',
      width: 80,
      valueFormatter: (value) => (isLoadingStainTypeOptions ? 'Loading...' : stainTypeIdToDisplayName(value)),
    },
    {
      field: 'slidesCount',
      headerName: 'Slides Count',
      headerAlign: 'center',
      width: 100,
      type: 'number',
      align: 'center',
    },
  ];

  const modelsColumnsStain: GridColDef[] = map(modelsType, (modelType) => {
    return {
      field: modelType,
      headerAlign: 'center',
      headerName: modelTypesByApiModelValue[modelType]?.text ?? modelType,
      width: 80,
      type: 'number',
      align: 'center',
    };
  });

  const registrationColumnStain: GridColDef = {
    field: 'registrations',
    headerName: 'Registrations',
    width: 150,
    type: 'number',
    align: 'center',
  };

  const columns: GridColDef[] = [
    { field: 'caseLabel', headerName: 'Case Label', width: 100 },
    {
      field: 'stainingType',
      headerName: 'Stain',
      width: 80,
      valueFormatter: (value) => (isLoadingStainTypeOptions ? 'Loading...' : stainTypeIdToDisplayName(value)),
    },
    { field: 'slideId', headerName: 'Slide ID', width: 200 },
  ];

  const modelsColumns: GridColDef[] = map(modelsType, (modelType) => {
    return {
      field: modelType,
      headerAlign: 'center',
      headerName: modelTypesByApiModelValue[modelType]?.text ?? modelType,
      width: 70,
      type: 'boolean',
      renderCell: (params) => {
        const modelSelected = params.row[modelType] as ModelSelection;

        return (
          <Typography margin="auto" variant="body2">
            {modelSelected?.inference && modelSelected?.assignment ? (
              <Badge badgeContent={'A'}>
                <CheckIcon />
              </Badge>
            ) : modelSelected?.assignment ? (
              'A'
            ) : modelSelected?.inference ? (
              <CheckIcon />
            ) : params.row.countModelsInSlide > 0 && countModelsByStain?.[params.row.stainingType]?.[modelType] > 0 ? (
              <ClearIcon />
            ) : (
              '--'
            )}
          </Typography>
        );
      },
    };
  });

  const registrationColumns: GridColDef[] = [
    {
      field: 'registrationCounts',
      headerAlign: 'center',
      headerName: 'Registration Count',
      width: 150,
      align: 'center',
    },
    ...map(countModelsByStainData, (stainData): GridColDef => {
      const stainDisplayName = stainTypeIdToDisplayName(stainData.stain);

      return {
        field: `registration-${stainData.stain}`,
        headerAlign: 'center',
        headerName: `Registration ${stainDisplayName}`,
        width: 150,
        type: 'boolean',
        renderCell: (params) => {
          const caseLabel = params.row['caseLabel'];
          const caseOtherSlides = filter(
            slidesByCase[caseLabel],
            (slidesData) => slidesData.slideId !== params.row.slideId
          );
          const caseOtherStains = map(caseOtherSlides, 'stainingType');
          const hasRegistrationWithStain = params.row[params.field];

          return (
            <Typography margin="auto" variant="body2">
              {!includes(caseOtherStains, stainData.stain) ? (
                '--'
              ) : hasRegistrationWithStain ? (
                <CheckIcon />
              ) : (
                <ClearIcon />
              )}
            </Typography>
          );
        },
      };
    }),
  ];

  const actionColumns: GridColDef[] = [
    {
      field: 'actions',
      type: 'actions',
      sortable: false,
      editable: false,
      width: 60,
      cellClassName: 'actions',
      getActions: (params) => {
        const slideId = params.row.slideId;

        const action = [
          <Tooltip
            placement="top-start"
            title="Remove selected artifacts results"
            key={`remove-selected-models-${params.id}`}
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                removeSelectedModels([slideId]);
              }}
            >
              <CancelOutlinedIcon />
            </IconButton>
          </Tooltip>,
        ];

        return action;
      },
    },
  ];

  return (
    <Grid container spacing={1} direction="column">
      <Grid container spacing={1} direction="column">
        <Grid item xs={12} mt={1} width={'100%'}>
          <Grid item mb={1}>
            <Typography>Slides Summary:</Typography>
          </Grid>
          <Grid item xs={12}>
            <DataGrid
              autoHeight
              getRowId={(row) => row?.slideId}
              rows={sortedSlidesModelsCountWithRegistrations ?? []}
              rowCount={sortedSlidesModelsCount.length ?? 0}
              columns={[...columns, ...modelsColumns, ...registrationColumns, ...actionColumns]}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              pageSizeOptions={pageSizeOptions}
              pagination
              paginationMode="client"
            />
          </Grid>
        </Grid>
        {displayAssignmentAnnotations && (
          <Grid item xs={12} mt={1} width={'100%'}>
            {map(legendSlidesSummary, (legend) => (
              <Grid container spacing={1} alignItems="center" key={legend.description}>
                <Grid item xs={0.5}>
                  {legend.icon}
                </Grid>
                <Grid item>{' - '}</Grid>
                <Grid item>{legend.description}</Grid>
              </Grid>
            ))}
          </Grid>
        )}
        <Grid item xs={12} mt={1} width={'100%'}>
          <Grid item mb={1}>
            <Typography>Stain Summary:</Typography>
          </Grid>
          <Grid item mb={1}>
            <DataGrid
              autoHeight
              getRowId={(row) => row?.stain}
              rows={countModelsByStainData ?? []}
              rowCount={countModelsByStainData.length ?? 0}
              columns={[...columnsStain, ...modelsColumnsStain, registrationColumnStain]}
              paginationModel={paginationModelStain}
              onPaginationModelChange={setPaginationModelStain}
              pageSizeOptions={pageSizeOptions}
              pagination
              paginationMode="client"
            />
          </Grid>
        </Grid>
        {!isEmpty(slidesWithoutSomeModels) && (
          <Grid item xs={12} mt={1} width={'100%'}>
            <Grid item mb={1}>
              <Typography>Errors:</Typography>
            </Grid>
            <Grid item mb={1}>
              <Typography mb={1}>Some slides have models that are not selected:</Typography>
              {map(
                groupBy(slidesWithoutSomeModels, 'stainingType'),
                (slidesWithoutSomeModelsByStainType, stainType) => (
                  <Fragment key={stainType}>
                    <Accordion key={stainType}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        {isLoadingStainTypeOptions ? (
                          <CircularProgress />
                        ) : (
                          <Typography>{stainTypeIdToDisplayName(stainType)}</Typography>
                        )}
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={1} direction="column">
                          {map(slidesWithoutSomeModelsByStainType, (slide) => (
                            <Grid item key={slide.slideId}>
                              <Typography>{slide.slideId}</Typography>
                            </Grid>
                          ))}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                    <Grid container mb={1} alignItems="center">
                      <Grid item>
                        <Typography>Do you want to remove those slides from the manifest?</Typography>
                      </Grid>
                      <Grid item>
                        <Button
                          onClick={() => removeSelectedModels(map(slidesWithoutSomeModelsByStainType, 'slideId'))}
                        >
                          yes
                        </Button>
                      </Grid>
                    </Grid>
                  </Fragment>
                )
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default Summary;
