import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from '@mui/material';
import { PrepareCustomerResultsParams } from 'interfaces/job';
import { filter, keys, map, orderBy, some, toNumber, uniq } from 'lodash';
import React, { useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import { useCurrentLabId } from 'utils/useCurrentLab';
import { useNavigationToViewerPage } from 'utils/useNavigationToViewerPage';

export const PrepareCustomerResultsJobSnapshotTable: React.FC<{
  snapshot: PrepareCustomerResultsParams['snapshot'];
  studyId: string;
}> = ({ snapshot, studyId }) => {
  const { getUrlToSlidePage, getUrlToCasePage } = useNavigationToViewerPage();
  const { labId } = useCurrentLabId();

  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderByColumn, setOrderByColumn] = useState<string>('caseId');
  const [textFilterState, setTextFilter] = useState<string>('');

  const [textFilter] = useDebounce(textFilterState, 200, {
    leading: true,
    trailing: true,
  });

  const handleSort = (column: string) => {
    const isAsc = orderByColumn === column && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderByColumn(column);
  };

  const filteredCases = useMemo(
    () =>
      textFilter
        ? filter(
            keys(snapshot),
            (caseId) =>
              caseId.includes(textFilter) || some(snapshot[caseId].slides, (slideId) => slideId.includes(textFilter))
          )
        : keys(snapshot),
    [snapshot, textFilter]
  );

  const sortedFilteredCases = useMemo(
    () => orderBy(filteredCases, (caseId) => toNumber(caseId), order),
    [filteredCases, order]
  );

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="body1">Query Snapshot: </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ 'overflow-x': 'auto' }}>
        <TextField
          label="Filter by Case ID"
          variant="outlined"
          size="small"
          value={textFilterState}
          onChange={(e) => setTextFilter(e.target.value)}
          style={{ marginBottom: '16px' }}
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sortDirection={orderByColumn === 'caseId' ? order : false}>
                <TableSortLabel
                  active={orderByColumn === 'caseId'}
                  direction={orderByColumn === 'caseId' ? order : 'asc'}
                  onClick={() => handleSort('caseId')}
                >
                  Case ID
                </TableSortLabel>
              </TableCell>
              <TableCell>Slide IDs</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {map(sortedFilteredCases, (caseId) => (
              <TableRow key={caseId}>
                <TableCell>
                  <Link
                    to={getUrlToCasePage({ caseId: toNumber(caseId), labId, caseStudyId: studyId })}
                    target="_blank"
                    rel="noopener noreferrer"
                    component={RouterLink}
                  >
                    <Typography variant="caption">{caseId}</Typography>
                  </Link>
                </TableCell>
                <TableCell>
                  {map(uniq(snapshot[caseId].slides), (slideId) => (
                    <Grid item key={slideId}>
                      <Link
                        to={getUrlToSlidePage({ slideId, labId, caseStudyId: studyId })}
                        target="_blank"
                        rel="noopener noreferrer"
                        component={RouterLink}
                      >
                        <Typography
                          variant="caption"
                          key={slideId}
                          color={!textFilter || slideId.includes(textFilter) ? undefined : 'text.disabled'}
                        >
                          {slideId}
                        </Typography>
                      </Link>
                    </Grid>
                  ))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  );
};
