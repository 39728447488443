import DeckGL, { DeckGLProps, DeckGLRef } from '@deck.gl/react/typed';
import { useSignals } from '@preact/signals-react/runtime';
import { debounce, isEqual, some } from 'lodash';
import { EditableGeoJsonLayer } from 'nebula.gl';
import React, { useCallback, useEffect } from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';

import { useAnnotationsAutoSaveParam } from 'components/Procedure/Infobar/SlideInfobar/SlideAnnotation/AnnotationChangesControls';
import {
  baseSlidesVisualSettings,
  BaseSlideVisualSettings,
  defaultBaseSlideVisualSettings,
} from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { HeatmapsImagePyramids, ImagePyramid } from 'components/Procedure/useSlideImages';
import { useAnnotationQueryParams } from 'services/annotations/useAnnotationQueryParams';
import AnnotatingQuickMenu from './layers/EditAnnotationLayers/AnnotatingQuickMenu';
import { AnnotationsContextMenu } from './layers/EditAnnotationLayers/AnnotationsContextMenu';
import { viewerAnnotationData } from './layers/EditAnnotationLayers/useActiveAnnotationDraft';
import useAnnotationsForViewer from './layers/EditAnnotationLayers/useAnnotations';
import { EDIT_COMMENT_LAYER_ID } from './layers/usePinCommentsLayer';
import { useSlideLayers } from './layers/useSlideLayers';
import OrthographicMapView from './OrthographicMapview';
import PinComments from './PinComments';
import { PMTFeaturePopover } from './PMTFeaturePopover';
import { deckGLViewerStates } from './slidesViewerState';
import { SlideViewerOverlays } from './SlideViewerOverlays';
import {
  onClickHandlers,
  onHoverHandlers,
  updateLastRenderTimeHandlers,
  viewerClickData,
  viewerHoverData,
} from './viewerDataSignals';

const BASE_MAP_VIEW_ID = 'baseMap';
const AUTOSAVE_DELAY = 5000;

const getCursorForComments = (viewerIndex: number) => {
  const layerId = viewerHoverData[viewerIndex]?.value?.[0].layer?.id;
  return layerId === EDIT_COMMENT_LAYER_ID ? 'pointer' : 'inherit';
};

export const SlideDeckGLViewer: React.FC<
  React.PropsWithChildren<{
    slide: SlideWithChannelAndResults;
    baseImagePyramids: ImagePyramid;
    heatmapsImagePyramids: HeatmapsImagePyramids;
    displaySlideId?: boolean;
    viewSize: { width: number; height: number };
    onViewStateChange: DeckGLProps['onViewStateChange'];
    hideComments?: boolean;
    procedureId: number;
    visualizationSettingsPreset?: Partial<BaseSlideVisualSettings>;
  }>
> = ({
  slide,
  baseImagePyramids,
  heatmapsImagePyramids,
  hideComments,
  procedureId,
  displaySlideId,
  viewSize,
  onViewStateChange,
  visualizationSettingsPreset,
}) => {
  useSignals();
  const viewerSlidesStates = deckGLViewerStates[slide?.viewerIndex]?.value;
  const viewState = viewerSlidesStates?.[slide?.id];

  const { layers, interactiveLayer, numLoadingBaseLayers, numBaseLayers, numLoadingHeatmapLayers, numHeatmapLayers } =
    useSlideLayers({
      slide,
      baseImagePyramids,
      heatmapsImagePyramids,
      viewSize,
      viewState,
      hideComments,
      procedureId,
    });

  const [autoSave] = useAnnotationsAutoSaveParam();
  const { draftDataSaved, saveDraftMutation, saveDraft, activeAnnotationAssignmentId } = useAnnotationsForViewer({
    slideId: slide?.id,
    viewerIndex: slide?.viewerIndex,
  });

  const debouncedSave = useCallback(debounce(saveDraft, AUTOSAVE_DELAY), [AUTOSAVE_DELAY]);

  useEffect(() => {
    if (autoSave && activeAnnotationAssignmentId && !draftDataSaved && !saveDraftMutation.isLoading) {
      const currentFeatureCollection = viewerAnnotationData[slide?.viewerIndex]?.value;
      const hasUntaggedFeatures = some(
        currentFeatureCollection?.features,
        (feature) => !feature?.properties?.diagnosis
      );
      if (!hasUntaggedFeatures) {
        debouncedSave({
          assignmentId: activeAnnotationAssignmentId,
          featureCollection: currentFeatureCollection,
        });
      }
    }
    return () => {
      debouncedSave.cancel(); // Cleanup
    };
  }, [draftDataSaved, saveDraftMutation.isLoading, debouncedSave, autoSave]);

  const hasInteractiveLayer = Boolean(interactiveLayer);

  const deckGlRef = React.useRef<DeckGLRef>(null);

  const [hideDeckGLOverlays] = useQueryParam('hideDeckGLOverlays', BooleanParam);
  const [showPmtFeaturePopover] = useQueryParam('showPmtFeaturePopover', BooleanParam);

  const interactiveLayerGetCursor = (interactiveLayer as any)?.getCursor?.bind(interactiveLayer);

  const getCursor: DeckGLProps['getCursor'] = React.useCallback(
    (...props) => {
      if (interactiveLayerGetCursor) {
        const cursor: string = interactiveLayerGetCursor(...props);

        return cursor;
      } else if (interactiveLayer?.id == EDIT_COMMENT_LAYER_ID) {
        return getCursorForComments(slide.viewerIndex);
      }
    },
    [interactiveLayerGetCursor, slide?.viewerIndex]
  );

  const defaultVisualizationSettings = {
    ...defaultBaseSlideVisualSettings,
    ...visualizationSettingsPreset,
  };
  const viewerBaseSlideSettings = baseSlidesVisualSettings[slide?.viewerIndex].value?.[slide?.id];
  const currentVisualizationSettings = {
    ...defaultVisualizationSettings,
    ...viewerBaseSlideSettings,
  };
  const showResearchPurposesWarning = !isEqual(currentVisualizationSettings, defaultVisualizationSettings);

  const baseMapView = React.useMemo(
    () =>
      new OrthographicMapView({
        id: BASE_MAP_VIEW_ID,
        controller: {
          inertia: 200,
          doubleClickZoom: !hasInteractiveLayer,
        },
        ignorePitch: true,
        height: viewSize.height,
        width: viewSize.width,
      }),
    [viewSize, hasInteractiveLayer]
  );

  const [clickedLayer, clickEvent] = viewerClickData[slide.viewerIndex]?.value || [null, null];
  const isRightClickEvent = clickEvent?.rightButton;
  const isEditableGeoJsonLayer = clickedLayer?.layer instanceof EditableGeoJsonLayer;

  const { annotationsActive } = useAnnotationQueryParams();

  return (
    Boolean(viewState) && (
      <DeckGL
        _pickable={true}
        ref={deckGlRef}
        views={baseMapView}
        layers={layers}
        initialViewState={viewerSlidesStates?.[slide?.id]}
        onViewStateChange={onViewStateChange}
        pickingRadius={2}
        getCursor={getCursor}
        onHover={onHoverHandlers[slide?.viewerIndex]}
        onDragStart={(info, event) => {
          // Handle the drag event starting from annotation option selection
          if (!event.leftButton) {
            console.warn('Drag start with non-left button');
            event.preventDefault();
          }
        }}
        onClick={(info, event) => {
          if (event.srcEvent.defaultPrevented) {
            event.preventDefault();
            return;
          }
          onClickHandlers?.[slide?.viewerIndex]?.(info, event);
        }}
        glOptions={{ antialias: false }}
        onAfterRender={updateLastRenderTimeHandlers[slide?.viewerIndex]}
      >
        {!hideDeckGLOverlays
          ? ({ viewport }) => (
              <>
                <SlideViewerOverlays
                  displaySlideId={displaySlideId}
                  slide={slide}
                  viewSize={viewSize}
                  viewState={viewerSlidesStates?.[slide?.id]}
                  numLoadingBaseLayers={numLoadingBaseLayers}
                  numBaseLayers={numBaseLayers}
                  numLoadingHeatmapLayers={numLoadingHeatmapLayers}
                  numHeatmapLayers={numHeatmapLayers}
                  showResearchPurposesWarning={showResearchPurposesWarning}
                />
                {!hideComments && (
                  <PinComments
                    slideId={slide.id}
                    procedureId={procedureId}
                    viewerIndex={slide.viewerIndex}
                    viewport={viewport}
                  />
                )}
                {showPmtFeaturePopover && <PMTFeaturePopover slide={slide} viewport={viewport} />}
                {annotationsActive && (
                  <AnnotationsContextMenu
                    slide={slide}
                    baseImagePyramids={baseImagePyramids}
                    viewport={viewport}
                    rightClickInfo={isRightClickEvent && isEditableGeoJsonLayer ? clickedLayer : undefined}
                  />
                )}
                <AnnotatingQuickMenu slide={slide} />
              </>
            )
          : null}
      </DeckGL>
    )
  );
};
