import ExpandIcon from '@mui/icons-material/ExpandMore';

import { yupResolver } from '@hookform/resolvers/yup';
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Grid, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getArtifactsInference, runMultiplexCellTyping } from 'api/platform';
import { getSlidesDataForArtifactsResults } from 'api/study';
import { CellRulesPanelSelect } from 'components/atoms/CellRulesPanelSelect';
import { OrchestrationBySlideByType } from 'components/Pages/CalculateFeatures';
import InferenceModelsForSlides from 'components/Pages/CalculateFeatures/InferenceModelsForSlides';
import { getModelsTypeByModelInferences } from 'components/Pages/CalculateFeatures/InferenceModelsForSlides/utils';
import { BinaryClassifierConfig } from 'interfaces/inferenceArtifacts';
import { MultiplexCellTypingConfig } from 'interfaces/jobs/multiplex/cellTypingParams';
import { first, flatMap, isEmpty, map, uniq, values } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import useCellRules from 'utils/queryHooks/cellRule/useCellRules';
import { useCasesParams } from 'utils/useCasesParams';
import { encodeQueryParamsUsingSchema } from 'utils/useEncodedFilters';
import * as yup from 'yup';
import { PlatformStepper } from '../PlatformStepper';
import { JobNameAndDescriptionStep } from '../utils';
import { convertToCellRules } from './helpers';

const SNACK_BAR_KEY_RUN_MULTIPLEX_CELL_TYPING = 'RUN_MULTIPLEX_CELL_TYPING';

export interface CellTypingFormValues {
  jobName: string;
  jobDescription: string;
  configParams: MultiplexCellTypingConfig;
}

const validationSchema = [
  yup.object({
    configParams: yup.object({
      cellRules: yup.object().required(),
      inferenceResultsArtifactUrlPattern: yup.string().required(),
    }),
  }),
  yup.object({}),
];

export interface RunMultiplexCellTypingProps {
  onClose: () => void;
}

export const RunMultiplexCellTyping = (props: RunMultiplexCellTypingProps): ReactElement => {
  const { onClose } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchema[activeStep];

  const [isStepFailed, setIsStepFailed] = useState<Record<number, boolean>>({});

  const { casesParams, schema, options } = useCasesParams();
  const getEncodedParams = () => encodeQueryParamsUsingSchema(casesParams, schema, options);

  const studyId = casesParams.filters?.studyId;

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<CellTypingFormValues>({
    mode: 'onChange',
    resolver: yupResolver(currentValidationSchema),
  });

  const enableInferenceArtifactsQuery = Boolean(studyId);

  const {
    data: inferenceArtifacts,
    isLoading: isInferenceArtifactsLoading,
    isFetching: isInferenceArtifactsFetching,
  } = useQuery({
    queryKey: [
      'artifactsInference',
      { studyId: studyId, casesParams: getEncodedParams(), configParams: BinaryClassifierConfig },
    ],
    queryFn: ({ signal }) =>
      getArtifactsInference({ studyId, caseParams: getEncodedParams(), configParams: BinaryClassifierConfig }, signal),
    enabled: enableInferenceArtifactsQuery,
  });

  const { data: slides, isFetching: isSlidesFetching } = useQuery({
    queryKey: ['slidesDataForArtifactsResults', { studyId, caseParams: getEncodedParams() }],
    queryFn: ({ signal }) => getSlidesDataForArtifactsResults(studyId, getEncodedParams(), signal),
    enabled: Boolean(studyId),
  });

  const [selectedOrchestrations, setSelectedOrchestrations] = useState<OrchestrationBySlideByType>({});
  const selectedOrchestration = useMemo(
    () => first(map(flatMap(flatMap(values(selectedOrchestrations), values)), 'orchestration')),
    [selectedOrchestrations]
  );
  const validateNotMoreThenOneInferenceUrl = useCallback(() => {
    const uniqOrchestrationIds = uniq(
      map(flatMap(flatMap(values(selectedOrchestrations), values)), 'orchestration.orchestrationId')
    );
    return uniqOrchestrationIds.length <= 1;
  }, [selectedOrchestrations]);

  const checkValidationAndSetIsStepFailed = (stepIndex: number, objectToValidate: Record<string, any>) => {
    validationSchema[stepIndex]
      .validate(objectToValidate)
      .then(() => {
        setIsStepFailed((prev) => ({
          ...prev,
          [stepIndex]: false,
        }));
      })
      .catch(() => {
        setIsStepFailed((prev) => ({
          ...prev,
          [stepIndex]: true,
        }));
      });
  };

  const runMultiplexCellTypingMutation = useMutation(runMultiplexCellTyping, {
    onError: () => {
      enqueueSnackbar('Error occurred, Multiplex Cell Typing failed', {
        variant: 'error',
      });
    },
    onSuccess: () => {
      enqueueSnackbar('Multiplex Cell Typing Started', { variant: 'success' });
    },
    onSettled() {
      closeSnackbar(SNACK_BAR_KEY_RUN_MULTIPLEX_CELL_TYPING);
    },
  });

  const onSubmit: SubmitHandler<CellTypingFormValues> = async (data) => {
    runMultiplexCellTypingMutation.mutate({
      ...casesParams,
      jobName: data.jobName,
      jobDescription: data.jobDescription,
      configParams: {
        ...data.configParams,
        inferenceResultsArtifactUrlPattern: selectedOrchestration?.orchestrationResultArtifactUrlPattern,
        cellRules,
      },
    });

    enqueueSnackbar({
      variant: 'success',
      message: (
        <Grid container>
          <Grid item>
            <Typography>Waiting for Multiplex Cell Typing to start</Typography>
          </Grid>
          <Grid item>
            <CircularProgress sx={{ marginLeft: 10 }} color="inherit" size={20} />
          </Grid>
        </Grid>
      ),
      key: SNACK_BAR_KEY_RUN_MULTIPLEX_CELL_TYPING,
      autoHideDuration: null,
    });

    onClose();
  };

  const validateCellTypingParams = async () => {
    checkValidationAndSetIsStepFailed(0, {
      configParams: {
        ...watch('configParams'),
        inferenceResultsArtifactUrlPattern: selectedOrchestration?.orchestrationResultArtifactUrlPattern,
        cellRules,
      },
    });
  };

  const [selectedPanelId, setSelectedPanelId] = useState<string | null>(null);
  const { data: panel } = useCellRules(selectedPanelId, studyId);

  const cellRules = useMemo(() => {
    return convertToCellRules(panel);
  }, [panel]);

  useEffect(() => {
    if (selectedOrchestration?.orchestrationResultArtifactUrlPattern && cellRules && !isEmpty(cellRules)) {
      setIsStepFailed((prev) => ({
        ...prev,
        [0]: false,
      }));
    } else {
      setIsStepFailed((prev) => ({
        ...prev,
        [0]: true,
      }));
    }
  }, [selectedOrchestration, cellRules]);

  const steps = [
    {
      label: 'Cell Typing Configuration',
      content: (
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <InferenceModelsForSlides
              studyId={studyId}
              slides={slides}
              modelsType={getModelsTypeByModelInferences(inferenceArtifacts)}
              inferenceModels={inferenceArtifacts}
              isLoading={isInferenceArtifactsLoading || isInferenceArtifactsFetching || isSlidesFetching}
              selectedOrchestrations={selectedOrchestrations}
              setSelectedOrchestrations={setSelectedOrchestrations}
            />
            {!validateNotMoreThenOneInferenceUrl() && (
              <Typography color="error">Only one Inference Results Artifact URL is allowed</Typography>
            )}
          </Grid>
          <Grid item container>
            <CellRulesPanelSelect
              selectedPanelId={selectedPanelId}
              onSelectPanel={setSelectedPanelId}
              studyId={studyId}
            />
          </Grid>
        </Grid>
      ),
      onNextOrBackClick: validateCellTypingParams,
    },
    {
      label: 'Job Name and Description',
      subLabel: activeStep > 0 && watch('jobName'),
      optional: true,
      content: <JobNameAndDescriptionStep control={control} errors={errors} register={register} />,
    },
  ];

  return (
    <>
      <PlatformStepper
        handleSubmit={handleSubmit(onSubmit)}
        steps={steps}
        setActiveStepForValidation={setActiveStep}
        isStepFailed={isStepFailed}
      />
      <Accordion>
        <AccordionSummary expandIcon={<ExpandIcon />}>Cell Typing Params Summary (JSON)</AccordionSummary>
        <AccordionDetails>
          <Typography component="pre">
            {JSON.stringify(
              {
                jobName: watch('jobName'),
                jobDescription: watch('jobDescription'),
                configParams: {
                  ...watch('configParams'),
                  inferenceResultsArtifactUrlPattern: selectedOrchestration?.orchestrationResultArtifactUrlPattern,
                  cellRules,
                },
              },
              null,
              2
            )}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
