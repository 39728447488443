import { COORDINATE_SYSTEM } from '@deck.gl/core/typed';
import { GeoJsonLayer } from '@deck.gl/layers/typed';
import { useSignals } from '@preact/signals-react/runtime';
import { Feature } from '@turf/helpers';
import { cloneDeep, entries, filter, find, flatMap, fromPairs, map, size } from 'lodash';
import { useMemo } from 'react';

import { getAnnotationSettingsKey } from 'components/Procedure/Infobar/SlideInfobar/SlideAnnotation/helpers';
import {
  defaultLayerVisualizationSettings,
  slidesLayerVisualizationSettings,
} from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { defaultLayerColors } from 'components/theme/theme';
import { Annotation } from 'interfaces/annotation';
import { Permission } from 'interfaces/permissionOption';
import markerAnnotationService from 'services/annotations/markerAnnotationService';
import { getColorHex, hexToRgb } from 'utils/helpers';
import { usePermissions } from 'utils/usePermissions';
import { useSlideAnnotationLayersData } from './useSlideAnnotationLayersData';

export const useViewAnnotationLayers = ({ slide }: { slide: SlideWithChannelAndResults }) => {
  useSignals();
  const { hasPermission } = usePermissions();
  const canViewAnnotations = hasPermission(Permission.ViewAnnotations);

  const { slideAnnotations, isLoading } = useSlideAnnotationLayersData({ slideId: slide?.id });

  const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[slide.viewerIndex];
  const slideLayerVisualizationSettings = fromPairs(
    map(entries(viewerSlideLayerVisualizationSettings?.value?.[slide.id]), ([key, settingsSignal]) => {
      const settingValue = settingsSignal?.value;
      return [key, cloneDeep(settingValue || defaultLayerVisualizationSettings)];
    })
  );

  const selectedAnnotationFeatures = useMemo(() => {
    if (isLoading || !canViewAnnotations || size(slideAnnotations) === 0) {
      return [];
    }
    return flatMap(slideAnnotations, (annotation: Annotation) => {
      // show the published version
      const featureCollection = markerAnnotationService.convertAnnotationToGeoJson({
        annotation: cloneDeep(annotation),
      });

      const filteredFeatures = filter(featureCollection?.features, (feature) =>
        Boolean(
          slideLayerVisualizationSettings?.[getAnnotationSettingsKey(annotation, feature?.properties?.diagnosis)]
            ?.selected
        )
      );

      return map(filteredFeatures, (feature) => ({
        ...feature,
        properties: {
          ...feature.properties,
          annotationId: annotation.annotationId,
        },
      })) as Feature[];
    });
  }, [canViewAnnotations, slideAnnotations, JSON.stringify(slideLayerVisualizationSettings)]);

  const viewAnnotationLayers = map(selectedAnnotationFeatures, (feature, dataIdx) => {
    const annotationId = feature?.properties?.annotationId;
    const diagnosis = feature?.properties?.diagnosis;
    const annotation = find(slideAnnotations, { annotationId });

    const featureKey = getAnnotationSettingsKey(annotation, diagnosis);
    const featureSettings = slideLayerVisualizationSettings?.[featureKey];

    return new GeoJsonLayer({
      id: `annotation-view-${slide.id}-${featureKey}-${dataIdx}`,
      coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
      data: feature,
      opacity: featureSettings?.show ? featureSettings?.opacity / 100 : 0,
      getFillColor: hexToRgb(
        getColorHex(featureSettings?.color) || defaultLayerColors[dataIdx % defaultLayerColors.length]
      ),
      getLineColor: hexToRgb(
        getColorHex(featureSettings?.color) || defaultLayerColors[dataIdx % defaultLayerColors.length]
      ),
      filled: false,
      pointRadiusMinPixels: 5,
      pointRadiusScale: 2,
      lineWidthScale: 2,
      lineWidthMinPixels: 1,
    });
  });

  return viewAnnotationLayers;
};
