import { DataGridProps, GridRowModes, GridRowModesModel, useGridApiRef } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { find, isEmpty } from 'lodash';
import React from 'react';

import { useTheme } from '@mui/material';
import { getExternalLabelOptions } from 'api/externalLabels';
import { SettingsDataGrid } from 'components/atoms/BaseDataGrid/SettingsDataGrid';
import {
  generateGetCellClassNames,
  handleRowModesModelChangeWithoutDraftIds,
} from 'components/atoms/EditableDataGrid/helpers';
import Loader from 'components/Loader';
import { ExternalLabel } from 'interfaces/externalLabel';
import { externalLabelFields } from 'interfaces/externalLabel/externalLabelFields';
import { BooleanParam, StringParam, useQueryParam, useQueryParams } from 'use-query-params';
import { uuidv4 } from 'utils/helpers';
import SettingsDetailsDrawer, { CurrentSelectedSettingsDetailsType } from '../SettingsDetailsDrawer';
import { ExternalLabelDetails } from './ExternalLabelDetails';
import { useExternalLabelsColumns } from './useExternalLabelsColumns';

const columnVisibilityModel = { id: false };

const generateDraftId = () => `draft-${uuidv4()}`;

const getRowId = (row: any) => row.id ?? generateDraftId();

export const ExternalLabelsDataGrid = () => {
  const theme = useTheme();
  const { data: dbExternalLabels, isLoading: isLoadingExternalLabels } = useQuery(['externalLabels'], () =>
    getExternalLabelOptions()
  );
  const [draftExternalLabels, setDraftExternalLabels] = React.useState<ExternalLabel[]>([]);
  const externalLabels = React.useMemo(
    () => [...draftExternalLabels, ...(dbExternalLabels || [])],
    [dbExternalLabels, draftExternalLabels]
  );

  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

  const apiRef = useGridApiRef();

  const handleRowModesModelChange: DataGridProps<ExternalLabel>['onRowModesModelChange'] = React.useCallback(
    (newRowModesModel: GridRowModesModel) => {
      handleRowModesModelChangeWithoutDraftIds(newRowModesModel, setRowModesModel, draftExternalLabels);
    },
    [draftExternalLabels]
  );

  const columns = useExternalLabelsColumns({
    noRows: isEmpty(externalLabels),
    apiRef,
    externalLabels,
    draftExternalLabels,
    rowModesModel,
    setDraftExternalLabels,
    setRowModesModel,
  });

  const getExternalLabelCellClassName: DataGridProps['getCellClassName'] = React.useMemo(
    () =>
      generateGetCellClassNames({
        apiRef,
        requiredFields: ['text'],
        uniqueFieldGroups: [['text']],
        draftRows: draftExternalLabels,
        fieldsToCheckForErrors: externalLabelFields,
      }),
    [apiRef, draftExternalLabels]
  );
  const handleAddExternalLabel = () => {
    const draftId = generateDraftId();
    const newExternalLabel: ExternalLabel = { text: '', id: draftId };
    setDraftExternalLabels((oldDraftExternalLabels) => [newExternalLabel, ...oldDraftExternalLabels]);
    setRowModesModel((oldRowModesModel) => ({
      ...oldRowModesModel,
      [newExternalLabel.id]: { mode: GridRowModes.Edit },
    }));
  };

  const [queryParams, setQueryParams] = useQueryParams({
    settingsDetailsDrawerOpen: BooleanParam,
    currentLabelId: StringParam,
  });

  const [, setSelectedSettingsDetailsType] =
    useQueryParam<CurrentSelectedSettingsDetailsType>('selectedSettingsDetailsType');

  const handleRowClick: DataGridProps['onRowClick'] = (params) => {
    setQueryParams({ settingsDetailsDrawerOpen: true, currentLabelId: params.row.id });
    setSelectedSettingsDetailsType(CurrentSelectedSettingsDetailsType.ExternalLabels);
  };

  return !isLoadingExternalLabels ? (
    <>
      <SettingsDataGrid
        apiRef={apiRef}
        addText="Add External Label"
        handleAdd={handleAddExternalLabel}
        columnVisibilityModel={columnVisibilityModel}
        getCellClassName={getExternalLabelCellClassName}
        rows={externalLabels}
        columns={columns}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        getRowId={getRowId}
        onRowClick={handleRowClick}
      />
      <SettingsDetailsDrawer
        title={find(externalLabels, { id: queryParams.currentLabelId })?.text || 'No Label Selected'}
        type={CurrentSelectedSettingsDetailsType.ExternalLabels}
      >
        <ExternalLabelDetails externalLabel={find(externalLabels, { id: queryParams.currentLabelId })} />
      </SettingsDetailsDrawer>
    </>
  ) : (
    <Loader />
  );
};
