import { useQuery } from '@tanstack/react-query';
import { getAreaTypeOptions } from 'api/areaTypes';
import { getFeatureParams } from 'api/featureParams';
import { getPostProcessingActions } from 'api/postProcessingAction';
import { modelTypeBinaryClassifier, modelTypeNucleiSegmentation } from 'components/Pages/Jobs/inferenceFieldsOptions';
import { NumberOption, OrchestrationDataType } from 'interfaces/calculateFeatures';
import PostProcessingAction, {
  ActionInput,
  InputSource,
  MappingFilterMetadata,
  PostProcessingActionCreated,
} from 'interfaces/postProcessingAction';
import {
  concat,
  Dictionary,
  filter,
  find,
  findIndex,
  flatMap,
  forEach,
  get,
  groupBy,
  includes,
  isArray,
  isEmpty,
  isEqual,
  isNumber,
  join,
  keyBy,
  map,
  omitBy,
  pickBy,
  set,
  size,
  sortBy,
  startCase,
  uniq,
  uniqWith,
} from 'lodash';
import { stringify } from 'qs';
import { useMemo } from 'react';
import { getFormattedCell } from 'utils/formatBrackets';
import { cellTaxonomy } from 'utils/queryHooks/taxonomy/useTaxonomy';
import useTaxonomyTags from 'utils/queryHooks/taxonomy/useTaxonomyTags';
import { useCellTypeOptions } from 'utils/queryHooks/useCellTypeOptions';
import { useAreaTypeIdToDisplayName } from 'utils/useAreaTypeIdToDisplayName';
import { useStainTypeIdToDisplayName } from 'utils/useStainTypeIdToDisplayName';
import { ClassConfigs, NewCell, OrchestrationMetadata } from '..';
import { combineArrays } from './utils';

export const useActionsQuery = () => {
  const { data: postProcessingActionsAreas } = useQuery({
    queryKey: ['postProcessingActions', stringify({ actionInput: ActionInput.AREA })],
    queryFn: () => getPostProcessingActions(stringify({ actionInput: ActionInput.AREA })),
  });
  const { data: postProcessingActionsCells } = useQuery({
    queryKey: ['postProcessingActions', stringify({ actionInput: ActionInput.CELL })],
    queryFn: () => getPostProcessingActions(stringify({ actionInput: ActionInput.CELL })),
  });
  const { data: postProcessingActionsFeatures } = useQuery({
    queryKey: ['postProcessingActions', stringify({ actionInput: ActionInput.FEATURE_FAMILY })],
    queryFn: () => getPostProcessingActions(stringify({ actionInput: ActionInput.FEATURE_FAMILY })),
  });
  const postProcessingActionsById = useMemo(
    () =>
      keyBy(
        [
          ...(postProcessingActionsAreas ?? []),
          ...(postProcessingActionsCells ?? []),
          ...(postProcessingActionsFeatures ?? []),
        ],
        'id'
      ),
    [postProcessingActionsAreas, postProcessingActionsCells, postProcessingActionsFeatures]
  );
  return {
    postProcessingActionsById,
    postProcessingActionsAreas,
    postProcessingActionsCells,
    postProcessingActionsFeatures,
  };
};

const getOutputNames = (
  actions: PostProcessingActionCreated[],
  stain: string,
  classConfig: ClassConfigs,
  index: number,
  withFreeSoloValues: boolean = true
): Record<string, string[]> => {
  const outputNamesUntilIndex: Record<string, string[]> = {};
  for (let actionIndex = 0; actionIndex < index; actionIndex++) {
    if (actions[actionIndex]?.output_name && actions[actionIndex]?.stain === stain) {
      const actionInput = actions[actionIndex]?.newOptionValueInput || actions[actionIndex]?.actionInput;
      let outputName = actions[actionIndex]?.output_name;

      // add tags to the output names if they exist
      if (!isEmpty(actions[actionIndex]?.action_params?.tags)) {
        outputName += `-${join(actions[actionIndex]?.action_params?.tags, '-')}`;
      }

      // add the output name if it is not new option value or withFreeSoloValues it's true
      // and it is not in the class config
      if (
        (withFreeSoloValues || outputName !== actions[actionIndex]?.newOptionValue) &&
        !includes(classConfig?.[stain]?.[actionInput], outputName)
      ) {
        outputNamesUntilIndex[actionInput] = uniq(concat(outputNamesUntilIndex[actionInput] ?? [], outputName));
      }
    }
  }

  return outputNamesUntilIndex;
};

const usePostProcessingActions = (
  stains: string[],
  classConfig: ClassConfigs,
  orchestrationMetadata: OrchestrationMetadata,
  newCellsCreated: Record<string, NewCell>
) => {
  const { stainTypeIdToDisplayName, isLoadingStainTypeOptions } = useStainTypeIdToDisplayName();
  const { areaTypeIdToDisplayName, isLoadingAreaTypes } = useAreaTypeIdToDisplayName();

  const { data: allFeatureParams } = useQuery({
    queryKey: ['allFeatureParams'],
    queryFn: () => getFeatureParams(),
    enabled: !isLoadingStainTypeOptions && !isLoadingAreaTypes,
  });

  const featureParamsByType = useMemo(() => groupBy(allFeatureParams, 'type'), [allFeatureParams]);

  const { data: areaTypes } = useQuery(['areaTypes'], () => getAreaTypeOptions(), {
    enabled: !isLoadingStainTypeOptions && !isLoadingAreaTypes,
  });

  const { data: cellTypes } = useCellTypeOptions();
  const { data: taxonomyTags } = useTaxonomyTags(cellTaxonomy);

  const getOutputNamesForAllStains = (
    index: number,
    actions: PostProcessingActionCreated[]
  ): Record<string, Record<string, string[]>> => {
    const outputNamesForAllStains: Record<string, Record<string, string[]>> = {};

    forEach(stains, (stain) => {
      outputNamesForAllStains[stain] = getOutputNames(actions, stain, classConfig, index, false);
    });

    return outputNamesForAllStains;
  };

  // return only the options that are common to all selected stains
  const getOnlyTheRepeatedOptions = (
    selectedStains: string[],
    options: {
      id: string;
      label: string;
    }[]
  ) => {
    if (selectedStains?.length === 1) {
      return options;
    }

    const grouped = groupBy(options, 'id');

    return flatMap(grouped, (group) => {
      if (group.length === selectedStains?.length) {
        return group;
      }
      return [];
    });
  };

  const defaultAreas = ['tissue', 'tissue_raw'];

  const getAreaOptions = (selectedStains: string[], options: ClassConfigs) => {
    const allAreasConfig = flatMap(
      map(selectedStains, (stain) => {
        return map(
          uniq([...(options?.[stain]?.area || []), ...(options?.[stain]?.tls || []), ...defaultAreas]),
          (area) => ({
            id: area as string,
            label: areaTypeIdToDisplayName(area as string),
          })
        );
      })
    );

    return getOnlyTheRepeatedOptions(selectedStains, allAreasConfig);
  };

  const getCellOptions = (selectedStains: string[], options: ClassConfigs) => {
    const allCellConfig = flatMap(
      map(selectedStains, (stain) => {
        return map(options?.[stain]?.cell, (cellId: string) => ({
          id: cellId,
          label: getFormattedCell(cellId),
        }));
      })
    );

    return getOnlyTheRepeatedOptions(selectedStains, allCellConfig);
  };

  const getCreatedCellsOptions = (selectedStains: string[]) => {
    if (selectedStains?.length > 1) {
      // if more than one stain is selected there is no way that the new cells exist in all selected stains
      return [];
    }

    return flatMap(
      map(selectedStains, (stain) => {
        return map(
          pickBy(newCellsCreated, (newCell) => newCell.stain === stain && !isEmpty(newCell.value)),
          (cell, cellId) => ({
            id: cellId,
            label: cell.name,
          })
        );
      })
    );
  };

  const getFinalCellOptions = (cellsOptions: any[]): any[] => {
    if (size(cellsOptions) === 0) {
      return [];
    }
    // add generic all_cells option
    return sortBy(uniqWith([allCellsOption, ...cellsOptions], isEqual), 'label');
  };

  const options: Record<
    string,
    (
      currentNewOptions?: Record<string, Record<string, string[]>>,
      selectedStains?: string[],
      inputSourceDependentOn?: string
    ) => { id: string; label: string; input?: string }[]
  > = {
    [InputSource.STAIN_TYPES_OF_SELECTED_SLIDES]: () => {
      const stainOptions = map(stains, (stain) => ({
        id: stain,
        label: stainTypeIdToDisplayName(stain),
      }));

      return sortBy(stainOptions, 'label');
    },
    [InputSource.AREAS]: (currentNewOptions, selectedStains) => {
      const areaOptions = map(areaTypes, (areaType) => ({
        id: areaType.id,
        label: areaTypeIdToDisplayName(areaType.id),
      }));
      const newAreas = getAreaOptions(selectedStains, currentNewOptions);

      return sortBy(uniqWith([...areaOptions, ...newAreas], isEqual), 'label');
    },
    [InputSource.INTERMEDIATE_AREAS_PER_TARGET_STAIN]: (currentNewOptions, selectedStains) => {
      const areaConfigOptions = getAreaOptions(selectedStains, classConfig);
      const newAreas = getAreaOptions(selectedStains, currentNewOptions);

      return sortBy(uniqWith([...areaConfigOptions, ...newAreas], isEqual), 'label');
    },
    [InputSource.CLASSES_CONFIG_AREAS_PER_TARGET_STAIN]: (_, selectedStains) => {
      const areaConfigOptions = getAreaOptions(selectedStains, classConfig);

      return sortBy(uniqWith(areaConfigOptions, isEqual), 'label');
    },
    [InputSource.CELLS]: (currentNewOptions, selectedStains) => {
      const cellOptions = map(filter(cellTypes, { selectable: true }), (cellType) => {
        return {
          id: cellType.shortId,
          label: getFormattedCell(cellType.shortId),
        };
      });
      const newCells = getCellOptions(selectedStains, currentNewOptions);

      return sortBy(uniqWith([...cellOptions, ...newCells], isEqual), 'label');
    },
    [InputSource.INTERMEDIATE_CELLS_PER_TARGET_STAIN]: (currentNewOptions, selectedStains) => {
      const cellConfigOptions = getCellOptions(selectedStains, classConfig);
      const newCells = getCellOptions(selectedStains, currentNewOptions);
      const cellsOption = [...cellConfigOptions, ...newCells];

      return getFinalCellOptions(cellsOption);
    },
    [InputSource.CLASSES_CONFIG_CELLS_PER_TARGET_STAIN]: (_, selectedStains) => {
      const cellConfigOptions = getCellOptions(selectedStains, classConfig);

      return getFinalCellOptions(cellConfigOptions);
    },
    [InputSource.INTERMEDIATE_CELLS_FEATURES_PER_TARGET_STAIN]: (currentNewOptions, selectedStains) => {
      const cellConfigOptions = getCellOptions(selectedStains, classConfig);
      const newCells = getCellOptions(selectedStains, currentNewOptions);

      const newCellsCreatedInFeatures = getCreatedCellsOptions(selectedStains);
      const cellsOption = [...cellConfigOptions, ...newCells, ...newCellsCreatedInFeatures];

      return getFinalCellOptions(cellsOption);
    },
    [InputSource.TAXONOMY_TAGS]: () => {
      const taxonomyTagsOptions = map(taxonomyTags, (taxonomyTag) => ({
        id: taxonomyTag.id,
        label: taxonomyTag.displayName,
      }));

      return sortBy(taxonomyTagsOptions, 'label');
    },
    [InputSource.ATTRIBUTES_OF_SELECTED_SLIDES]: (_, selectedStains) => {
      const attributesOptions = flatMap(
        map(selectedStains, (stain) =>
          map(classConfig?.[stain], (classes, key) => {
            return {
              id: `${key}.label`,
              input: key,
              label: key,
            };
          })
        )
      );

      return sortBy(uniqWith(attributesOptions, isEqual), 'label');
    },
    [InputSource.OPTIONS_OF_SELECTED_ATTRIBUTE]: (currentNewOptions, selectedStains, attribute) => {
      const optionsByAttribute: Record<string, { id: string; label: string }[]> = {
        ['cell.label']: options.cells(currentNewOptions, selectedStains),
        ['area.label']: options.areas(currentNewOptions, selectedStains),
      };

      return optionsByAttribute[attribute] || [];
    },
    [InputSource.GEOMETRY_METADATA]: (_, selectedStains) => {
      const geometryMetadata: { id: string; label: string }[] = [];

      forEach(selectedStains, (stain) => {
        forEach(orchestrationMetadata?.[stain], (orchestrationData, flowClassName) => {
          forEach(orchestrationData, (data) => {
            if (data.type === OrchestrationDataType.Multipolygon) {
              geometryMetadata.push({
                id: `${flowClassName}.${data.label}`,
                label: `${flowClassName}.${data.label}`,
              });
            }
          });
        });

        // build the mapping filters metadata from the classes config only for nucleai segmentation
        if (classConfig?.[stain]?.[modelTypeNucleiSegmentation.value]) {
          geometryMetadata.push({
            id: `${modelTypeNucleiSegmentation.value}.geometry`,
            label: `${modelTypeNucleiSegmentation.value}.geometry`,
          });
        }

        // build the mapping filters metadata from the classes config only for binary classifier
        if (classConfig?.[stain]?.[modelTypeBinaryClassifier.value]) {
          geometryMetadata.push({
            id: `${modelTypeBinaryClassifier.value}.nuclear_geometry`,
            label: `${modelTypeBinaryClassifier.value}.nuclear_geometry`,
          });
        }
      });

      return getOnlyTheRepeatedOptions(selectedStains, geometryMetadata);
    },
  };

  // add feature params by type to options
  forEach(featureParamsByType, (featureParams, featureParamType) => {
    options[featureParamType] = () => {
      return map(featureParams, (featureParam) => ({
        id: featureParam.id,
        label: featureParam.displayName,
      }));
    };
  });

  const getOptions = (
    optionSource: string | string[],
    selectedStains: string[],
    index: number,
    actions: PostProcessingActionCreated[],
    actionInput: string,
    inputSourceDependentOn?: string
  ) => {
    if (isArray(optionSource)) {
      return map(optionSource, (option) => ({
        id: option,
        label: startCase(option),
      }));
    } else {
      const getOptionsFunction = options[optionSource];
      if (!getOptionsFunction) {
        return [];
      }

      const outputNamesPerStain: Record<string, Record<string, string[]>> = {};

      forEach(selectedStains, (stain) => {
        set(
          outputNamesPerStain,
          stain,
          getOutputNames(actions, stain, classConfig, index, actionInput !== ActionInput.FEATURE_FAMILY)
        );
      });

      return getOptionsFunction(outputNamesPerStain, selectedStains, inputSourceDependentOn);
    }
  };

  const getOptionsByValue = (
    optionSource: string | string[],
    selectedStains: string[],
    index: number,
    actions: PostProcessingActionCreated[],
    actionInput: string,
    inputSourceDependentOn?: string
  ) => {
    return keyBy(getOptions(optionSource, selectedStains, index, actions, actionInput, inputSourceDependentOn), 'id');
  };

  const getCellNewMappingData = (
    actions: PostProcessingActionCreated[],
    stain: string,
    index: number,
    postProcessingActionsById: Dictionary<PostProcessingAction>,
    actionInput: string
  ): {
    label: string;
    type: OrchestrationDataType;
  }[] => {
    const newCellNewMappingData: {
      label: string;
      type: OrchestrationDataType;
    }[] = [];

    for (let actionIndex = 0; actionIndex < index; actionIndex++) {
      const postProcessingAction = postProcessingActionsById[actions[actionIndex]?.actionId];
      if (!isEmpty(postProcessingAction?.newCellColumns) && actions[actionIndex]?.stain === stain) {
        const labels: string[][] = [];
        forEach(postProcessingAction.newCellColumns, (newCellColumn) => {
          const newCellColumnInput = find(postProcessingAction.inputs, { inputKey: newCellColumn });

          // the newCellColumn is inputKey
          if (!isEmpty(newCellColumnInput)) {
            const newCellColumnInputValue = get(actions[actionIndex], newCellColumn);
            if (!isEmpty(newCellColumnInputValue) || isNumber(newCellColumnInputValue)) {
              labels.push(flatMap([newCellColumnInputValue]));
            }
          } else {
            const getOptionsFunction = options[newCellColumn];
            // the newCellColumn is inputSource
            if (getOptionsFunction) {
              const outputNamesPerStain: Record<string, Record<string, string[]>> = {};
              set(
                outputNamesPerStain,
                stain,
                getOutputNames(actions, stain, classConfig, actionIndex, actionInput !== ActionInput.FEATURE_FAMILY)
              );

              const classesOptions = getOptionsFunction(outputNamesPerStain, [stain]);
              if (!isEmpty(classesOptions)) {
                labels.push(map(classesOptions, 'id'));
              }
              // simple string
            } else {
              labels.push([newCellColumn]);
            }
          }
        });

        if (!isEmpty(labels)) {
          forEach(combineArrays(labels), (label) => {
            newCellNewMappingData.push({
              label,
              type: OrchestrationDataType.Float,
            });
          });
        }
      }
    }

    return sortBy(uniqWith(newCellNewMappingData, isEqual), 'label');
  };

  const getMappingFiltersMetadataForLogicalQuery = (
    stain: string,
    index: number,
    actions: PostProcessingActionCreated[],
    actionInput: string,
    postProcessingActionsById: Dictionary<PostProcessingAction>
  ) => {
    const optionsKeyByKey: Record<string, string> = {
      cell: InputSource.INTERMEDIATE_CELLS_PER_TARGET_STAIN,
      area: InputSource.INTERMEDIATE_AREAS_PER_TARGET_STAIN,
    };

    const mappingFiltersMetadata: MappingFilterMetadata[] = [];

    // build the mapping filters metadata from the classes config
    forEach(classConfig?.[stain], (classes, key) => {
      // if the key is nuclei segmentation we don't want to show the classes
      if (!isEmpty(classes) && key !== modelTypeNucleiSegmentation.value) {
        let classesOptions = map(classes, (classOption: string | number) => ({
          id: classOption,
          label: classOption,
        }));

        const getOptionsFunction = options[optionsKeyByKey[key]];
        if (getOptionsFunction) {
          const outputNamesPerStain: Record<string, Record<string, string[]>> = {};
          set(
            outputNamesPerStain,
            stain,
            getOutputNames(actions, stain, classConfig, index, actionInput !== ActionInput.FEATURE_FAMILY)
          );

          classesOptions = getOptionsFunction(outputNamesPerStain, [stain]);
        }

        mappingFiltersMetadata.push({
          sourceName: key,
          data: [
            {
              label: 'label',
              options: classesOptions,
              type: OrchestrationDataType.Categorical,
            },
          ],
        });
      }
    });

    // build the mapping filters metadata from created in get_nuclear_morphology_features action
    const cellMappingData = getCellNewMappingData(actions, stain, index, postProcessingActionsById, actionInput);

    if (!isEmpty(cellMappingData)) {
      const cellMappingFiltersMetadataIndex = findIndex(mappingFiltersMetadata, { sourceName: 'cell' });
      if (cellMappingFiltersMetadataIndex !== -1) {
        mappingFiltersMetadata[cellMappingFiltersMetadataIndex].data = [
          ...mappingFiltersMetadata[cellMappingFiltersMetadataIndex].data,
          ...cellMappingData,
        ];
      } else {
        mappingFiltersMetadata.push({
          sourceName: 'cell',
          data: cellMappingData,
        });
      }
    }

    // build the mapping filters metadata from the orchestration metadata
    forEach(orchestrationMetadata?.[stain], (orchestrationData, key) => {
      mappingFiltersMetadata.push({
        sourceName: key,
        data: map(
          omitBy(orchestrationData, (data) =>
            includes([OrchestrationDataType.Point, OrchestrationDataType.Multipolygon], data.type)
          ),
          (data) => {
            const dataOptions =
              data.type === OrchestrationDataType.Categorical
                ? map(data.options, (option: string) => ({
                    id: option,
                    label: option,
                  }))
                : (data.options as NumberOption);

            return {
              label: data.label,
              options: dataOptions,
              type: data.type,
            };
          }
        ),
      });
    });

    return mappingFiltersMetadata;
  };

  return {
    getOptions,
    getOptionsByValue,
    getOutputNamesForAllStains,
    getMappingFiltersMetadataForLogicalQuery,
  };
};

export default usePostProcessingActions;

const allCellsId = '__all_cells__';

export const allCellsOption = {
  id: allCellsId,
  label: getFormattedCell(allCellsId),
};
