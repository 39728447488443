import { COORDINATE_SYSTEM, Layer, PickingInfo } from '@deck.gl/core/typed';
import { DeckGLProps } from '@deck.gl/react/typed';
import GL from '@luma.gl/constants';
import { useSignals } from '@preact/signals-react/runtime';
import { Feature } from '@turf/helpers';
import { cloneDeep, find, forEach, get, includes, isArray, map, times, without } from 'lodash';
import { EditableGeoJsonLayer, ViewMode } from 'nebula.gl';
import { useCallback, useMemo, useState } from 'react';
import { BooleanParam, StringParam, useQueryParam } from 'use-query-params';

import {
  editTypeToShapeSubTypeMap,
  SlideAnnotationEditType,
} from 'components/Procedure/Header/SlideInteractionMenu/slideAnnotations/options';
import {
  useActiveAnnotationDraft,
  viewerAnnotationData,
} from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/EditAnnotationLayers/useActiveAnnotationDraft';
import { Permission } from 'interfaces/permissionOption';
import { usePermissions } from 'utils/usePermissions';
import { OrthographicMapViewState } from '../../OrthographicMapview';
import {
  DrawAnnotationPointMode,
  DrawAnnotationPolygonMode,
  DrawAnnotationRectangleMode,
} from '../NebulaGLExtensions/editModes';
import { FeatureCollection } from '../NebulaGLExtensions/geojson-types';
import { getEditHandleColor, getEditHandleTypeFromEitherLayer, TOOLTIP_COLOR } from '../NebulaGLExtensions/helpers';
import { ModifyOrthographicMode } from '../NebulaGLExtensions/ModifyOrthographicMode';
import useActiveClassToAnnotate from './useActiveClassToAnnotate';
import useAnnotationsForViewer, { UNKNOWN_DIAGNOSIS } from './useAnnotations';

const doesFeatureMatchEditAnnotationsMode = (feature: Feature, editAnnotationsMode: string) => {
  if (editAnnotationsMode === 'point') {
    return feature?.geometry?.type === 'Point';
  } else if (editAnnotationsMode === 'polygon') {
    return feature?.properties?.shapeSubType === 'polygon';
  } else if (editAnnotationsMode === 'roi') {
    return feature?.properties?.shapeSubType === 'rect';
  }
  return true;
};

export const useEditAnnotationLayers = ({
  slideId,
  viewerIndex,
  viewState,
  viewSize,
  viewOnly,
}: {
  slideId: string;
  viewerIndex: number;
  viewState: OrthographicMapViewState;
  viewSize: { width: number; height: number };
  viewOnly?: boolean;
}) => {
  useSignals();
  const { hasPermission } = usePermissions();
  const canAnnotateSlides = hasPermission(Permission.AnnotateSlides);

  const {
    getFillColorForFeature,
    getColorForFeature,
    activeAnnotationAssignmentId,
    slideAnnotations,
    isSlideAnnotationsLoading,
  } = useAnnotationsForViewer({
    slideId,
    viewerIndex,
  });

  const savedAnnotationData = canAnnotateSlides
    ? find(slideAnnotations, { annotationAssignment: { annotationAssignmentId: activeAnnotationAssignmentId } })
    : undefined;

  const [hideFillColor] = useQueryParam('hideFillColor', BooleanParam);
  const [editAnnotationsModeFromUrl] = useQueryParam('editAnnotationsMode', StringParam);
  const editAnnotationsMode = viewOnly ? undefined : editAnnotationsModeFromUrl;
  const [isHoveringOverFeature, setIsHoveringOverFeature] = useState(false);

  const { activeAnnotationData: editAnnotationFeatureCollection } = useActiveAnnotationDraft({
    slideId,
    viewerIndex,
  });
  const activeAnnotationClass = useActiveClassToAnnotate({
    slideId: slideId,
    viewerIndex: viewerIndex,
  });

  const deleteFeature: DeckGLProps['onClick'] = useCallback(
    (pickingInfo, event) => {
      const featureIndex = pickingInfo?.index;
      if (
        !editAnnotationFeatureCollection ||
        featureIndex === undefined ||
        !isArray(editAnnotationFeatureCollection.features) ||
        !editAnnotationFeatureCollection.features[featureIndex]
      ) {
        console.warn('No feature to delete', { editAnnotationFeatureCollection, featureIndex });
        return;
      }
      const updatedData = cloneDeep(editAnnotationFeatureCollection);
      updatedData.features.splice(featureIndex, 1);
      if (viewerAnnotationData[viewerIndex]) {
        viewerAnnotationData[viewerIndex].value = updatedData;
      }
    },
    [viewerIndex, editAnnotationFeatureCollection]
  );

  const onClick = useCallback(
    (info: PickingInfo, event: Parameters<DeckGLProps['onClick']>[1]) => {
      // if the user is in the middle of drawing an annotation, right click should not do anything
      if (event.rightButton && info?.object?.properties?.guideType === 'tentative') {
        event.preventDefault();
        event.stopPropagation();
        return true;
      }
      if (editAnnotationsMode === SlideAnnotationEditType.Delete || (event.leftButton && event.srcEvent.altKey)) {
        // @ts-ignore
        deleteFeature(info, event);
        event.srcEvent.preventDefault();
        event.srcEvent.stopPropagation();
      }
    },
    [editAnnotationsMode, deleteFeature]
  );

  const editAnnotationFeaturesNumber = editAnnotationFeatureCollection?.features?.length ?? 0;

  const onEdit = ({
    updatedData,
    editType,
    editContext,
  }: {
    updatedData: FeatureCollection;
    editType: string;
    editContext: any;
  }) => {
    times(updatedData?.features?.length ?? 0, (featureIndex) => {
      const shapeSubType =
        updatedData.features[featureIndex].properties?.shapeSubType ||
        get(editTypeToShapeSubTypeMap, editAnnotationsMode, '');
      updatedData.features[featureIndex].properties.shapeSubType = shapeSubType;
      updatedData.features[featureIndex].properties.annotationId = savedAnnotationData?.annotationId;
      updatedData.features[featureIndex].properties.markerType = 'tagger_annotation';
    });

    if (editType === 'addFeature' && editContext?.featureIndexes) {
      if (activeAnnotationClass?.name) {
        forEach(editContext.featureIndexes, (featureIndex) => {
          if (activeAnnotationClass.isMarkerAnnotation) {
            updatedData.features[featureIndex].properties.diagnosis = UNKNOWN_DIAGNOSIS;
            updatedData.features[featureIndex].properties.markerPositivity = {
              [activeAnnotationClass.name]: activeAnnotationClass.positive,
            };
          } else {
            updatedData.features[featureIndex].properties.diagnosis = activeAnnotationClass.name;
            updatedData.features[featureIndex].properties.markerPositivity = activeAnnotationClass?.markerPositivity;
          }
        });
      }
    }
    if (viewerAnnotationData[viewerIndex]) {
      viewerAnnotationData[viewerIndex].value = updatedData;
    }
  };

  const getCursor = ({ isDragging }: { isDragging: boolean }) => {
    if (editAnnotationsMode === SlideAnnotationEditType.Delete) {
      // Allow panning when not hovering over a feature
      if (isHoveringOverFeature) {
        return 'crosshair';
      } else if (isDragging) {
        return 'grabbing';
      }
      return 'grab';
    } else if (
      includes(
        [SlideAnnotationEditType.Point, SlideAnnotationEditType.Roi, SlideAnnotationEditType.Polygon],
        editAnnotationsMode
      )
    ) {
      return 'crosshair';
    } else {
      if (isDragging) {
        return 'grabbing';
      }
      return 'grab';
    }
  };

  const selectedFeatureIndexes = useMemo(
    () => times(editAnnotationFeaturesNumber, (i) => i),
    [editAnnotationFeaturesNumber]
  );

  const EditableGeoJsonLayerMode =
    editAnnotationsMode === SlideAnnotationEditType.Polygon
      ? DrawAnnotationPolygonMode
      : editAnnotationsMode === SlideAnnotationEditType.Roi
      ? DrawAnnotationRectangleMode
      : editAnnotationsMode === SlideAnnotationEditType.Point
      ? DrawAnnotationPointMode
      : editAnnotationsMode === SlideAnnotationEditType.Modify
      ? ModifyOrthographicMode
      : ViewMode;

  const shouldShowLayer = activeAnnotationAssignmentId && !isSlideAnnotationsLoading;

  const editAnnotationLayer = !shouldShowLayer
    ? null
    : // @ts-ignore
      (new EditableGeoJsonLayer({
        id: `annotation-editable-${slideId}-${savedAnnotationData?.annotationId || 'new'}`,
        coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
        getRadius: 2,
        data: editAnnotationFeatureCollection,
        selectedFeatureIndexes,
        // @ts-ignore
        onClick,
        onEdit,
        mode: EditableGeoJsonLayerMode,
        modeConfig: {
          viewport: { ...viewState, ...viewSize },
          selectedIndexes: without(
            map(editAnnotationFeatureCollection?.features, (feature, index) =>
              doesFeatureMatchEditAnnotationsMode(feature as Feature, editAnnotationsMode) ? index : null
            ),
            null
          ),
          ...(editAnnotationsMode ? { lockRectangles: true } : {}),
        },
        pickable: true,
        autoHighlight: editAnnotationsMode === SlideAnnotationEditType.Delete,
        highlightColor: [255, 255, 255, 25],
        stroked: true,
        filled: !hideFillColor,
        editHandleType: 'point',
        getFillColor: getFillColorForFeature,
        getLineColor: getColorForFeature,
        _subLayerProps: {
          tooltips: { getColor: TOOLTIP_COLOR },
          geojson: {
            updateTriggers: {
              getFillColor: [getFillColorForFeature],
              getLineColor: [getColorForFeature],
            },
          },
        },
        getEditHandleIcon: getEditHandleTypeFromEitherLayer,
        getEditHandleIconSize: 40,
        getEditHandleIconColor: getEditHandleColor,

        pointRadiusMinPixels: 5,
        pointRadiusScale: 2,

        parameters: {
          depthTest: true,
          depthMask: false,

          blend: true,
          blendEquation: GL.FUNC_ADD,
          blendFunc: [GL.SRC_ALPHA, GL.ONE_MINUS_SRC_ALPHA],
        },
        updateTriggers: {
          renderSublayers: [editAnnotationsMode],
        },
      }) as Layer<any>);

  if (shouldShowLayer && editAnnotationsMode === SlideAnnotationEditType.Delete) {
    // Use onHover to detect hover state
    // @ts-ignore
    editAnnotationLayer.onHover = (info: PickingInfo) => {
      setIsHoveringOverFeature(Boolean(info.object)); // Set to true if hovering over a feature
    };
  }
  if (shouldShowLayer && editAnnotationsMode !== SlideAnnotationEditType.Modify) {
    // @ts-ignore
    editAnnotationLayer.getCursor = getCursor;
  }

  return editAnnotationLayer;
};
