import { useSignals } from '@preact/signals-react/runtime';
import { compact, Dictionary, filter, isEmpty, map, pick } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BooleanParam, StringParam, useQueryParam } from 'use-query-params';

import Loader from 'components/Loader';
import { Accession } from 'interfaces/accession';
import { Permission } from 'interfaces/permissionOption';
import { Procedure } from 'interfaces/procedure';
import setPreferences from 'redux/actions/preferences';
import { useAppSelector } from 'redux/hooks';
import { Point } from 'utils/slideTransformation';
import { usePermissions } from 'utils/usePermissions';
import Header from './Header';
import NotFoundMessage from './NotFoundMessage';
import SlidesViewer from './SlidesViewer/SlidesViewer';
import { SlideWithChannelAndResults } from './useSlideChannelsAndResults/utils';
import { HeatmapsImagePyramids, ImagePyramid } from './useSlideImages';
import { useSlideMagnification } from './useSlideMagnification';

interface ViewerPageBodyProps {
  procedure: Procedure | Accession;
  isLoadingCaseData: boolean;
  isAccessionViewer: boolean;
  isPlaceholderData?: boolean;
  selectedSlides: SlideWithChannelAndResults[];

  slidesBaseImagePyramids: Dictionary<ImagePyramid>;
  slidesHeatmapsImagePyramids: Dictionary<HeatmapsImagePyramids>;
  baseSlideLoadingStates: boolean[];
  slideImagesError: string;
  canLoadSlides: boolean;
}

export const CaseViewBody: React.FunctionComponent<React.PropsWithChildren<ViewerPageBodyProps>> = ({
  procedure,
  isLoadingCaseData,
  isPlaceholderData,
  isAccessionViewer,
  selectedSlides,

  slidesBaseImagePyramids,
  slidesHeatmapsImagePyramids,
  baseSlideLoadingStates,
  slideImagesError,
  canLoadSlides,
}) => {
  useSignals();
  const dispatch = useDispatch();
  const [fieldOfView, setFieldOfView] = useState(null);

  const { magnificationValue, handleMagnificationChangedInHeader, updateMagnificationFromZoom } = useSlideMagnification(
    selectedSlides || []
  );

  const { showNavigator } = useAppSelector((state) => state.preferences);
  const [showNavigation, setShowNavigation] = useState(
    showNavigator && !procedure.presentationInfo?.navigatorOffByDefault
  );

  const { hasPermission } = usePermissions();
  const publishResults = hasPermission(Permission.PublishResults);

  const onUnmountViewportBounds = React.useCallback(
    (center: Point, zoom: number) => !isAccessionViewer && setFieldOfView({ center, zoom }),
    [isAccessionViewer, setFieldOfView]
  );

  const [slideIdForReviewing] = useQueryParam('slideIdForReviewing', StringParam);
  const [slideTagsDrawerOpen] = useQueryParam('slideTagsDrawerOpen', BooleanParam);
  const [fullscreenViewer] = useQueryParam('fullscreenViewer', BooleanParam);

  /* Slide registration cannot occur with placeholder data, so we display a loader */
  const isLoadingRegistration = isLoadingCaseData && isPlaceholderData && selectedSlides?.length > 1;

  const slidesDataForDisplay = compact(
    filter(
      map(selectedSlides, (slide) => pick(slide, ['id', 'format', 'labId'])),
      (slideData) => !isEmpty(slideData)
    )
  );
  const hasSlidesDataForDisplay = !isEmpty(slidesDataForDisplay);

  const onShowNavigatorChange = useCallback(
    (newShowNavigator: boolean) => dispatch(setPreferences(newShowNavigator)),
    [dispatch]
  );

  const showErrorMessage =
    !isLoadingCaseData &&
    !isLoadingRegistration &&
    (slideImagesError || (!isPlaceholderData && Boolean(selectedSlides) && !canLoadSlides) || !procedure);

  return (
    <>
      {!fullscreenViewer && (
        <Header
          procedure={procedure}
          selectedSlides={selectedSlides}
          showNavigation={showNavigation}
          onChangeNavigationVisibility={setShowNavigation}
          isAccession={isAccessionViewer}
          isLoadingCaseData={isLoadingCaseData}
          isPlaceholderData={isPlaceholderData}
          magnificationValue={magnificationValue}
          onChangeMagnification={handleMagnificationChangedInHeader}
          onShowNavigatorChange={onShowNavigatorChange}
        />
      )}
      {showErrorMessage ? (
        <NotFoundMessage hideHeader />
      ) : /* Slide registration cannot occur with placeholder data, so we display a loader */
      // selectedSlides starts as null, which we consider as loading. An empty array may be an error state.
      !selectedSlides || (isLoadingCaseData && !hasSlidesDataForDisplay) || isLoadingRegistration ? (
        <Loader />
      ) : (
        <SlidesViewer
          procedureId={procedure.id}
          slides={selectedSlides}
          slidesBaseImagePyramids={slidesBaseImagePyramids}
          slidesHeatmapsImagePyramids={slidesHeatmapsImagePyramids}
          baseSlideLoadingStates={baseSlideLoadingStates}
          showNavigation={showNavigation}
          magnificationValue={magnificationValue}
          displaySlideId={publishResults}
          hideComments={isAccessionViewer}
          updateMagnificationFromZoom={updateMagnificationFromZoom}
          unmountViewportBounds={onUnmountViewportBounds}
          fieldOfView={fieldOfView}
          focusedSlideId={slideTagsDrawerOpen ? slideIdForReviewing : undefined}
        />
      )}
    </>
  );
};
