import { Button, Grid, Typography, useTheme } from '@mui/material';
import { first } from 'lodash';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { CaseSearchItem } from 'services/searchIndex';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { useNavigationToViewerPage } from 'utils/useNavigationToViewerPage';
import { unknownStudyName } from './FullSearch';
import SearchResultMatch from './SearchResultMatch';

interface SearchResultProps {
  option: CaseSearchItem;
  notInCurrentStudy?: boolean;
  searchQuery?: string;
}

const SearchResult: FunctionComponent<React.PropsWithChildren<SearchResultProps>> = ({
  option,
  notInCurrentStudy,
  searchQuery,
}) => {
  const theme = useTheme();
  const { getUrlToCasePage, getUrlToSlidePage } = useNavigationToViewerPage();
  const [slidesMode] = useQueryParam('slidesMode', BooleanParam);

  let urlToPage = '';

  const { labId } = useCurrentLabId();

  if (slidesMode || option.isSlideOnly) {
    const slide = first(option.slides);
    const slideId = slide?.id;
    if (!slideId) {
      console.error(`trying to move to slides page without slide id, option: `, option);
    } else {
      urlToPage = getUrlToSlidePage({
        slideId: slideId,
        labId: slide.labId,
        caseId: undefined,
        caseStudyId: undefined,
      });
    }
  } else {
    const caseId = option.caseId as number;
    if (!caseId) {
      console.error(`trying to move to case page without case id, option: `, option);
    } else {
      urlToPage = getUrlToCasePage({
        caseId: caseId,
        caseStudyId: option.studyId,
      });
    }
  }

  return (
    <Grid container justifyContent="space-between" alignItems="center" flexWrap={'nowrap'}>
      <Grid item container xs flexGrow={1} overflow="hidden" whiteSpace="nowrap">
        <Grid container item xs={12} direction="row">
          <Grid item xs={12} container justifyContent="flex-start" alignItems="center" spacing={1}>
            <Grid item>
              <Typography variant="h4" color="text.primary">
                {slidesMode ? first(option.slides)?.id : option.caseLabel}
              </Typography>
            </Grid>
            <Grid item>
              {notInCurrentStudy && option.studyName != unknownStudyName && (
                <Typography variant="body1" color="text.secondary">
                  ({option.studyName})
                </Typography>
              )}
              {option.isSlideOnly && (
                <Typography variant="body1" color="text.secondary">
                  ({first(option.slides)?.labId === labId ? 'Pending slide' : `Lab: ${first(option.slides)?.labName}`})
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            whiteSpace: 'normal',
            wordBreak: 'break-word',
          }}
        >
          <SearchResultMatch option={option} searchQuery={searchQuery} />
        </Grid>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          size="small"
          disableElevation
          component={Link}
          sx={{
            textTransform: 'none',
            backgroundColor:
              theme.palette.mode == 'light' ? theme.palette.secondary.light : theme.palette.secondary.dark,
            '&:hover': {
              backgroundColor: theme.palette.secondary.main,
            },
            color: theme.palette.mode == 'light' ? theme.palette.secondary.dark : theme.palette.secondary.light,
          }}
          to={urlToPage}
          disabled={urlToPage === ''}
          onClick={(event) => event.stopPropagation()}
        >
          Go to {option.isSlideOnly || slidesMode ? 'slide' : 'case'}
        </Button>
      </Grid>
    </Grid>
  );
};

export default SearchResult;
