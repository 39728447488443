import CheckIcon from '@mui/icons-material/Check';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  Grid,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material';
import { DataGrid, GridEventListener } from '@mui/x-data-grid';
import { JobFilters } from 'api/jobs';
import { getSlideByIdKeys } from 'api/slides';
import { getStudyProcedureQueryKey } from 'api/study';
import { useJobColumns } from 'components/Pages/Jobs/columns';
import { JobDrawer } from 'components/Pages/Jobs/JobDrawer';
import { defaultRowsPerPage, useJobs } from 'components/Pages/Jobs/useJobs';
import { JobStatus, JobType } from 'interfaces/job';
import { Permission } from 'interfaces/permissionOption';
import { Procedure } from 'interfaces/procedure';
import { compact, filter, first, flatMap, forEach, includes, isEmpty, size, some, toString } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';
import queryClient from 'utils/queryClient';
import { useSelectedSlideIds } from 'utils/useCurrentSlideIds';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import { usePermissions } from 'utils/usePermissions';
import usePrevious from 'utils/usePrevious';
import { headerHeight } from '../constants';

const displayedColumns = ['startedAt', 'name', 'orchestrationId', 'status'];

export const JobsStatus: React.FC<{
  enabled?: boolean;
  procedure: Procedure;
}> = ({ enabled = true, procedure }) => {
  const caseId = procedure?.id;
  const studyId = procedure?.studyId;
  const [selectedSlideIds] = useSelectedSlideIds(procedure);
  const didHaveRunningJobs = useRef(false);
  const wasWaitingForRecalculatedResults = useRef(false);
  const { hasPermission, isLoading: isLoadingPermissions } = usePermissions();
  const canViewJobs = hasPermission(Permission.ViewJobs);
  const canRunSecondaryAnalysis = hasPermission(Permission.RunSecondaryAnalysis);
  const canOnlyRunSecondaryAnalysis = !canViewJobs && canRunSecondaryAnalysis;

  const [currentJobId, setCurrentJobId] = useState<string | null>(null);

  const [page, setPage] = React.useState(1);

  const handleChangePage = (newPage: number) => setPage(newPage);

  const canQueryJobs =
    (canViewJobs || canRunSecondaryAnalysis) && Boolean(studyId) && !isEmpty(compact(selectedSlideIds)) && enabled;

  const commonJobsFilters: JobFilters = {
    studyId,
    slideIds: compact(selectedSlideIds),
    // Users who can view secondary analysis jobs can view calculate features jobs only
    ...(canOnlyRunSecondaryAnalysis ? { type: JobType.CalculateFeatures } : {}),
  };
  const {
    data: activeJobsQueryResponse,
    isLoading: activeJobsIsLoading,
    isFetching: activeJobsIsFetching,
    dataUpdatedAt: activeJobsDataUpdatedAt,
    refetch: refetchActiveJobs,
  } = useJobs({
    page,
    enabled: canQueryJobs,
    additionalFilters: { ...commonJobsFilters, statuses: [JobStatus.Pending, JobStatus.Running] },
    fullData: true,
  });

  // We want to show the children of the active jobs if they exist, as they are the ones who will write the results
  const activeJobsReplacedByChildrenIfExist = useMemo(
    () =>
      flatMap(activeJobsQueryResponse?.jobs, (job) => {
        const slideChildren = filter(job?.children, (childJob) =>
          some(selectedSlideIds, (slideId) => toString(childJob?.manifest).includes(`"${slideId}"`))
        );
        return !isEmpty(slideChildren) ? slideChildren : job;
      }),
    [activeJobsQueryResponse]
  );

  const numRunning = size(filter(activeJobsReplacedByChildrenIfExist, { status: JobStatus.Running }));
  // In case status wasn't updated, we assume it's pending, so we calculate by removing running and completed jobs
  const numPending =
    size(activeJobsReplacedByChildrenIfExist) -
    numRunning -
    size(filter(activeJobsReplacedByChildrenIfExist, { status: JobStatus.Completed }));

  const [showAllJobsInCasePageState, setShowAllJobsInCasePage] = useQueryParam('showAllJobsInCasePage', BooleanParam);
  // We only allow showing all jobs if the user can view them
  const showAllJobsInCasePage = showAllJobsInCasePageState && !canOnlyRunSecondaryAnalysis;

  const showAllJobsInCasePageAfterCompletion = numPending === 0 && numRunning === 0 && didHaveRunningJobs.current;

  // Only show the all jobs if there are no pending or running jobs, and there were running jobs before - or if the user asked to show all jobs
  const shouldQueryAllJobs =
    canQueryJobs &&
    !canOnlyRunSecondaryAnalysis &&
    !activeJobsIsLoading &&
    !activeJobsIsFetching &&
    (showAllJobsInCasePageAfterCompletion || showAllJobsInCasePage);

  const {
    data: allJobsQueryResponse,
    isLoading: allJobsIsLoading,
    isFetching: allJobsIsFetching,
    dataUpdatedAt: allJobsDataUpdatedAt,
    refetch: refetchAllJobs,
  } = useJobs({
    page,
    enabled: shouldQueryAllJobs,
    additionalFilters: commonJobsFilters,
  });

  const isLoading = activeJobsIsLoading || (shouldQueryAllJobs && allJobsIsLoading);
  const isFetching = activeJobsIsFetching || (shouldQueryAllJobs && allJobsIsFetching);

  const hasActiveJobs = !isEmpty(activeJobsReplacedByChildrenIfExist);
  const dataUpdatedAt = hasActiveJobs ? activeJobsDataUpdatedAt : allJobsDataUpdatedAt;
  const refetchJobs = hasActiveJobs ? refetchActiveJobs : refetchAllJobs;

  const rows = hasActiveJobs ? activeJobsReplacedByChildrenIfExist || [] : allJobsQueryResponse?.jobs || [];

  const rowCount = hasActiveJobs
    ? activeJobsQueryResponse?.totalJobs ?? size(activeJobsQueryResponse?.jobs)
    : allJobsQueryResponse?.totalJobs ?? size(allJobsQueryResponse?.jobs);

  const toggleRef = useRef<HTMLDivElement | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback(() => {
    setAnchorEl(toggleRef.current);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setCurrentJobId(null);
  }, []);

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    setCurrentJobId(params.row.id);
  };

  const { queryParams, generateEncodedParams } = useEncodedFilters();
  const fullSlideQueryEncodedFilters = generateEncodedParams({
    filters: { ...(queryParams?.filters || {}), studyId },
  });

  const previousRunningJobs = usePrevious(numRunning);
  useEffect(() => {
    // If there are running jobs, we want to keep the status available
    if (numRunning > 0 || numPending > 0) {
      didHaveRunningJobs.current = true;
    } else if ((previousRunningJobs ?? 0) > numRunning) {
      // If the number of running jobs decreased, we need to refetch experiment results for the slides and case
      queryClient.invalidateQueries(getStudyProcedureQueryKey(studyId, caseId, queryParams));
      forEach(selectedSlideIds, (slideId) => {
        queryClient.invalidateQueries(getSlideByIdKeys({ slideId, encodedFilters: fullSlideQueryEncodedFilters }));
      });
    }
  }, [numRunning, numPending, previousRunningJobs]);

  const hasActiveSecondaryAnalysisJobs = some(
    rows,
    (row) => row?.type === JobType.CalculateFeatures && Boolean(row.params?.primaryRunOrchestrationId)
  );
  useEffect(() => {
    if (!wasWaitingForRecalculatedResults.current && hasActiveSecondaryAnalysisJobs) {
      wasWaitingForRecalculatedResults.current = true;
    }
  }, [hasActiveSecondaryAnalysisJobs]);

  const { jobColumns, isLoading: isLoadingColumnsQueries } = useJobColumns({ viewOnly: true });

  const isQueryRunning = isLoading || isFetching;

  const jobDrawerOpen = currentJobId !== null;

  const allJobsDone = numPending + numRunning <= 0;
  return (
    !isLoadingPermissions &&
    canQueryJobs &&
    (hasActiveJobs || didHaveRunningJobs.current || showAllJobsInCasePage) && (
      <>
        <Tooltip
          title={
            canOnlyRunSecondaryAnalysis && (
              <Grid item container justifyContent="center" alignItems="center" direction="column">
                {!canOnlyRunSecondaryAnalysis && (
                  <Grid item>
                    <Typography variant="caption">
                      {`View ${
                        hasActiveJobs && rowCount === 1
                          ? first(activeJobsReplacedByChildrenIfExist)?.name || 'job'
                          : 'job'
                      } details (updated at ${moment(dataUpdatedAt).format('HH:mm:ss')})`}
                    </Typography>
                  </Grid>
                )}
                <Grid item>
                  <Button sx={{ color: 'white' }} onClick={() => refetchJobs()} disabled={isQueryRunning}>
                    {isQueryRunning ? 'Refreshing...' : 'Refresh'}
                  </Button>
                </Grid>
              </Grid>
            )
          }
        >
          <Grid
            item
            container
            columnGap={1}
            ref={toggleRef}
            onClick={!canOnlyRunSecondaryAnalysis ? handleClick : undefined}
            sx={{ cursor: 'pointer' }}
          >
            <Chip
              label={
                numPending > 0 && numRunning
                  ? `Jobs: ${numPending} pending, ${numRunning} running`
                  : numPending > 0
                  ? `Jobs: ${numPending} pending`
                  : numRunning > 0
                  ? `Jobs: ${numRunning} running`
                  : wasWaitingForRecalculatedResults.current
                  ? 'Recalculated Results are Ready'
                  : 'All jobs completed'
              }
              icon={isQueryRunning || !allJobsDone ? <CircularProgress size={20} /> : <CheckIcon />}
              color={allJobsDone ? 'success' : 'default'}
              deleteIcon={
                !canOnlyRunSecondaryAnalysis &&
                (open ? <ExpandLessIcon color="primary" /> : <ExpandMoreIcon color="primary" />)
              }
              onDelete={!canOnlyRunSecondaryAnalysis ? handleClick : undefined}
            />
          </Grid>
        </Tooltip>
        <Popover
          open={open && !canOnlyRunSecondaryAnalysis}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ horizontal: 'center', vertical: -8 }}
        >
          <Grid item container spacing={1} p={1} height="35vh" width={800}>
            {!showAllJobsInCasePageAfterCompletion && (
              <Grid item>
                <FormControlLabel
                  label="Show all jobs"
                  control={
                    <Checkbox
                      size="small"
                      checked={Boolean(showAllJobsInCasePage)}
                      onChange={(e) => {
                        setShowAllJobsInCasePage(e.target.checked);

                        if (e.target.checked) {
                          refetchAllJobs();
                        } else {
                          refetchActiveJobs();
                        }
                      }}
                    />
                  }
                />
              </Grid>
            )}
            <Grid item>
              <Button onClick={() => refetchJobs()} disabled={isQueryRunning}>
                {isQueryRunning ? 'Refreshing...' : 'Refresh'}
              </Button>
            </Grid>
            <Grid item>
              <DataGrid
                autoHeight
                paginationModel={{ page: page - 1, pageSize: defaultRowsPerPage }}
                onPaginationModelChange={({ page: newPage }) => handleChangePage(newPage + 1)}
                loading={isLoadingColumnsQueries || (isLoading && canQueryJobs)}
                pagination
                rows={rows}
                rowCount={rowCount}
                columns={filter(jobColumns, (column) => includes(displayedColumns, column.field))}
                onRowClick={handleRowClick}
                paginationMode="server"
              />
            </Grid>
          </Grid>
          {jobDrawerOpen && (
            <JobDrawer
              headerHeight={headerHeight}
              currentJobId={currentJobId}
              jobDrawerOpen={jobDrawerOpen}
              setJobDrawerOpen={(newOpen) => {
                if (!newOpen) {
                  setCurrentJobId(null);
                }
              }}
            />
          )}
        </Popover>
      </>
    )
  );
};
