import { useSignals } from '@preact/signals-react/runtime';
import { compact, filter, fromPairs, keys, map, omit } from 'lodash';
import { useMemo } from 'react';

import { slidesLayerVisualizationSettings } from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import { HeatmapType } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import {
  SlideWithChannelAndResults,
  getAllFlatMapDeepHeatmapsFromSlide,
} from 'components/Procedure/useSlideChannelsAndResults/utils';
import { ResultType } from 'interfaces/experimentResults';
import { useProtomapTilesList } from 'utils/useProtomapTiles';
import { deckGLPMTLayer } from './deckGLPMTLayer';

export const usePmtLayers = ({
  slide,
  maxLevel,
  idPrefix,
  rescaleFactor,
}: {
  slide: SlideWithChannelAndResults;
  maxLevel: number;
  idPrefix: string;
  rescaleFactor?: number;
}) => {
  useSignals();
  const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[slide.viewerIndex];

  const slideLayerVisualizationSettings = viewerSlideLayerVisualizationSettings.value?.[slide.id];

  // Get all PMT heatmaps from the slide
  const pmtHeatmaps = filter(
    map(getAllFlatMapDeepHeatmapsFromSlide(slide), (heatmap) => ({
      slideInfo: omit(slide, 'viewerIndex'),
      ...heatmap,
    })),
    ({ heatmapUrl, heatmapType }) => Boolean(heatmapUrl) && heatmapType === HeatmapType.Pmt
  );

  const { pmtLayersData } = useProtomapTilesList(pmtHeatmaps);

  // Get the visual settings for each heatmap and it's layers, grouped by heatmap id
  const pmtVisualSettingsByHeatmapId = fromPairs(
    map(pmtLayersData, ({ pmtHeatmap }) => [
      pmtHeatmap?.id,
      fromPairs(
        map(
          filter(keys(slideLayerVisualizationSettings), (key) => pmtHeatmap?.id && key.startsWith(pmtHeatmap?.id)),
          (key) => [key, slideLayerVisualizationSettings[key].value]
        )
      ),
    ])
  );

  // Create the deck.gl layers for each heatmap
  const pmtLayers = useMemo(
    () =>
      compact(
        map(
          pmtLayersData,
          ({ pmtHeatmap, pmtMetadata, pmtTileSource }) =>
            pmtTileSource &&
            pmtMetadata &&
            deckGLPMTLayer({
              idPrefix: `${idPrefix}-${slide.id}`,
              visualSettings: pmtVisualSettingsByHeatmapId[pmtHeatmap.id],
              pmtHeatmap,
              pmtMetadata,
              maxLevel,
              rescaleFactor,
              // Nuclei heatmaps are stroked, others are filled
              filled: pmtHeatmap?.resultType !== ResultType.SlideInferenceNuclei,
              stroked: pmtHeatmap?.resultType === ResultType.SlideInferenceNuclei,
            })
        )
      ),
    [idPrefix, slide.id, pmtLayersData, JSON.stringify(pmtVisualSettingsByHeatmapId), maxLevel, rescaleFactor]
  );

  return pmtLayers;
};
