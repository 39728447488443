import * as d3 from 'd3';
import { interpolatePlasma } from 'd3';
import { entries, first, flatMap, identity, map, size } from 'lodash';

export type CorrelationMatrix = Record<string, Record<string, number>>;

interface Cell {
  row: number;
  col: number;
  value: number;
}

export const generateDendogram = ({
  parent,
  data,
  getNameOverrideOrDisplayNameWithContext,
}: {
  parent: string;
  data: CorrelationMatrix;
  getNameOverrideOrDisplayNameWithContext: (featureKey: string) => string;
}) => {
  if (!data) return;

  try {
    const dataAsMatrix = map(entries(data), ([, rowValues]) => {
      return map(entries(rowValues), ([, value]) => {
        return value;
      });
    });

    const clusterSpace = 200;
    const cellSize = 7;
    const colNumber = size(dataAsMatrix);
    const rowNumber = size(first(dataAsMatrix));
    const margin = { top: 10, right: 0, bottom: 10, left: 0 };
    const width = colNumber * cellSize;
    const height = rowNumber * cellSize;

    const svg = d3.select(parent).append('svg');

    const matrix = flatMap(dataAsMatrix, (row: number[], i: number) =>
      map(row, (col: number, j: number) => ({
        row: i + 1,
        col: j + 1,
        value: col,
      }))
    );

    const minimumValue = d3.min(dataAsMatrix, (row: number[]) => d3.min(row));
    const maximumValue = d3.max(dataAsMatrix, (row: number[]) => d3.max(row));

    const colorScale = d3.scaleSequential<string>(interpolatePlasma).domain([minimumValue, maximumValue]);

    svg.selectAll('*').remove();
    svg
      .attr('width', width + margin.left + margin.right + clusterSpace)
      .attr('height', height + margin.top + margin.bottom + clusterSpace);

    const rowLabel = map(entries(data), ([key]) => getNameOverrideOrDisplayNameWithContext(key));
    const colLabel = map(entries(data), ([key]) => getNameOverrideOrDisplayNameWithContext(key));

    const fontSize = '8px';

    svg // row labels
      .append('g')
      .selectAll('.rowLabelg')
      .data(rowLabel)
      .enter()
      .append('text')
      .text(identity)
      .attr('x', 0)
      .attr('y', function (d, i) {
        return (i + 1) * cellSize;
      })
      .style('text-anchor', 'start')
      .style('font-size', fontSize)
      .attr('transform', 'translate(' + (width + cellSize) + ',' + cellSize / 1.5 + ')')
      .attr('class', 'rowLabel mono');

    svg // coll labels
      .append('g')
      .selectAll('.colLabelg')
      .data(colLabel)
      .enter()
      .append('text')
      .text(identity)
      .attr('x', 0)
      .attr('y', function (d, i) {
        return (i + 1) * cellSize;
      })
      .style('text-anchor', 'end')
      .style('font-size', fontSize)
      .attr(
        'transform',
        'translate(' + cellSize / 2 + ',-6) rotate (-90)  translate( -' + (height + cellSize * 2) + ')'
      )
      .attr('class', 'colLabel mono');

    svg // draw heatmap
      .append('g')
      .attr('class', 'g3')
      .selectAll('.cellg')
      .data(matrix, function (d: Cell) {
        return d.row + ':' + d.col;
      })
      .enter()
      .append('rect')
      .attr('x', function (d) {
        return d.col * cellSize;
      })
      .attr('y', function (d) {
        return d.row * cellSize;
      })
      .attr('class', 'cell cell-border')
      .attr('width', cellSize)
      .attr('height', cellSize)
      .style('fill', function (d) {
        return colorScale(d.value);
      })
      .on('mouseover', function (event, d) {
        d3.select(this).classed('cell-hover', true);
        //Update the tooltip position and value
        d3.select('#d3tooltip')
          .style('left', event.pageX + 10 + 'px')
          .style('top', event.pageY - 10 + 'px')
          .select('#value')
          .html(colLabel[d.col - 1] + '<br>' + rowLabel[d.row - 1] + '<br> correlation: ' + d.value);
        //Show the tooltip
        d3.select('#d3tooltip').transition().duration(200).style('opacity', 0.9);
      })
      .on('mouseout', function () {
        d3.select(this).classed('cell-hover', false);
        d3.selectAll('.rowLabel').classed('text-highlight', false);
        d3.selectAll('.colLabel').classed('text-highlight', false);
        d3.select('#d3tooltip').transition().duration(200).style('opacity', 0);
      });

    return () => {
      svg.remove();
    };
  } catch (error) {
    console.error('Error generating dendogram', error);
  }
};
