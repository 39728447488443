import MuiPagination from '@mui/material/Pagination';
import { TablePaginationProps } from '@mui/material/TablePagination';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import {
  DataGridProps,
  gridPageCountSelector,
  GridPagination,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import { omit } from 'lodash';
import * as React from 'react';

function Pagination({
  page,
  pageCount: propsPageCount,
  onPageChange,
  className,
}: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'> & { pageCount?: number }) {
  const apiRef = useGridApiContext();
  const pageCount = propsPageCount ?? useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event as React.MouseEvent<HTMLButtonElement, MouseEvent>, newPage - 1);
      }}
    />
  );
}

export const DataGridPagination: DataGridProps['slots']['pagination'] = (props) => {
  const ActionsComponent: React.FC<React.PropsWithChildren<TablePaginationActionsProps>> = React.useCallback(
    (actionProps) => <Pagination {...actionProps} pageCount={props?.count} />,
    [props?.count]
  );

  return <GridPagination ActionsComponent={ActionsComponent} {...omit(props, 'pageCount')} />;
};
