import { ChartKeyType } from 'components/FeaturesDashboard/chart.util';
import { ControlledChartOptions } from 'components/FeaturesDashboard/ControlledChart';
import { ChartType } from 'interfaces/chart';

export const defaultCharts: Record<number, ControlledChartOptions> = {
  1: { type: ChartType.Pie, categoricalKey: { name: 'cancerTypeId', type: ChartKeyType.Categorical } },
  2: { type: ChartType.Pie, categoricalKey: { name: 'biopsySiteId', type: ChartKeyType.Categorical } },
  3: { type: ChartType.Pie, categoricalKey: { name: 'stainingType', type: ChartKeyType.Categorical } },
  4: { type: ChartType.Pie, categoricalKey: { name: 'cancerSubtypes', type: ChartKeyType.Categorical } },
  5: { type: ChartType.Pie, categoricalKey: { name: 'biopsyType', type: ChartKeyType.Categorical } },
  8: { type: ChartType.Pie, categoricalKey: { name: 'negativeControl', type: ChartKeyType.Categorical } },
  9: { type: ChartType.Pie, categoricalKey: { name: 'positiveControl', type: ChartKeyType.Categorical } },
};

export const agentDefaultCharts: Record<number, ControlledChartOptions> = {
  1: { type: ChartType.Pie, categoricalKey: { name: 'cancerTypeId', type: ChartKeyType.Categorical } },
  2: { type: ChartType.Pie, categoricalKey: { name: 'cancerSubtypes', type: ChartKeyType.Categorical } },
};
