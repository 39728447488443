import { GridRowId } from '@mui/x-data-grid';
import deepEqual from 'deep-equal';
import { UnwoundRow } from 'interfaces/genericFields/unwindRowsWithInnerArrays';
import { cloneDeep, fromPairs, get, isNull, map, omit, set, toPairs } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { applyObjectChanges } from 'utils/applyObjectChanges';
import useHandleUnload from 'utils/useHandleUnload';
import {
  BasicTableRow,
  RowChange,
  RowsChangesState,
  TableEditingContextProps,
  TableEditingContextProviderProps,
} from './types';

const TableEditingContext = React.createContext<TableEditingContextProps | undefined>(undefined);

export const TableEditingContextProvider = <
  R extends BasicTableRow,
  Context extends {} = {},
  UnwoundType extends any | undefined = undefined
>({
  children,
  fieldsContext = {} as Context,
  idGetter,
}: TableEditingContextProviderProps<Context>) => {
  const [bulkEditMode, setBulkEditMode] = React.useState(false);
  const [rowsChanges, setRowsChanges] = React.useState<RowsChangesState>({});
  const [bulkChanges, setBulkChanges] = React.useState<Record<string, any>>({});
  const hasChanges = !isEmpty(rowsChanges) || !isEmpty(bulkChanges);

  useHandleUnload({ showAlert: hasChanges });

  const clearChanges = React.useCallback(() => {
    setRowsChanges({});
    setBulkChanges({});
  }, []);

  const clearRowChanges = React.useCallback(
    (rowId: number | string) => setRowsChanges((prevChanges) => omit(cloneDeep(prevChanges), rowId)),
    [setRowsChanges]
  );

  const applyRowUpdates = React.useCallback(
    (rowId: number | string, changes: RowChange[]) => {
      let changedRow: { [field: string]: any } | undefined = undefined;
      for (const { path, value } of changes) {
        const currentChangedValue = get(rowsChanges?.[rowId] || {}, path);
        if (!deepEqual(currentChangedValue, value ?? null) || (!isNull(currentChangedValue) && isNull(value ?? null))) {
          changedRow = changedRow || cloneDeep(rowsChanges?.[rowId] || {});
          set(changedRow, path, value ?? null);
        }
      }
      if (changedRow) {
        setRowsChanges((prevChanges) => ({ ...prevChanges, [rowId]: changedRow }));
      }
    },
    [rowsChanges]
  );

  const applyBulkUpdates = React.useCallback(
    (change: RowChange, shouldApplyBulkChangesToRow?: (rowId: GridRowId) => boolean) => {
      if (!deepEqual(get(bulkChanges, change.path), change.value)) {
        setBulkChanges((prevValues) => {
          const newValues = { ...prevValues };
          set(newValues, change.path, change.value);
          return newValues;
        });
      }

      // The rest of this function code is to remove the changed field from all relevant rowChanges
      const rowChangePairs = toPairs(rowsChanges);

      const rowChangePairsWithPathRemoved = map(rowChangePairs, ([rowId, specificRowChanges]) => {
        if (shouldApplyBulkChangesToRow(rowId)) {
          const changedRow = cloneDeep(specificRowChanges);
          const changes = {};
          // Assign a null value to the path to remove it
          set(changes, change.path, null);
          return [rowId, applyObjectChanges(changedRow, changes)];
        } else {
          return [rowId, specificRowChanges];
        }
      });

      const rowChangeWithPathRemoved = fromPairs(rowChangePairsWithPathRemoved);
      if (!deepEqual(rowChangeWithPathRemoved, rowsChanges)) {
        setRowsChanges(rowChangeWithPathRemoved);
      }
    },
    [bulkChanges, rowsChanges]
  );

  const getRowWithChanges = React.useCallback(
    (row: UnwoundType extends undefined ? R : UnwoundRow<R, UnwoundType>, shouldApplyBulkChanges: boolean) => {
      const rowId = idGetter?.(row) || row.id;
      let specificChanges: Record<string, any> = {};
      // Calculate the changes to make by applying the bulk changes first, then the row-specific changes
      if (shouldApplyBulkChanges) {
        specificChanges = applyObjectChanges(bulkChanges, rowsChanges?.[rowId] || {});
      } else {
        specificChanges = rowsChanges?.[rowId] || {};
      }
      const rowWithChanges: UnwoundType extends undefined ? R : UnwoundRow<R, UnwoundType> = applyObjectChanges(
        row,
        specificChanges
      ) as UnwoundType extends undefined ? R : UnwoundRow<R, UnwoundType>;

      return rowWithChanges;
    },
    [bulkChanges, rowsChanges]
  );

  const getRowsWithChanges = React.useCallback(
    (
      rowData: UnwoundType extends undefined ? R[] : UnwoundRow<R, UnwoundType>[],
      shouldApplyBulkChangesToRow?: (rowId: GridRowId) => boolean
    ) => {
      return map(rowData, (row: UnwoundType extends undefined ? R : UnwoundRow<R, UnwoundType>) =>
        getRowWithChanges(row, !shouldApplyBulkChangesToRow || shouldApplyBulkChangesToRow(idGetter?.(row) || row.id))
      );
    },
    [getRowWithChanges]
  );

  const contextValue: TableEditingContextProps<R, Context> = {
    clearChanges,
    clearRowChanges,
    rowsChanges,
    bulkChanges,
    bulkEditMode,
    setBulkEditMode,
    hasChanges,
    applyRowUpdates,
    applyBulkUpdates,
    getRowWithChanges,
    getRowsWithChanges,
    fieldsContext,
  };

  return <TableEditingContext.Provider value={contextValue}>{children}</TableEditingContext.Provider>;
};

export const useTableEditingContext = <
  R extends BasicTableRow = any,
  Context extends {} = {},
  UnwoundType extends any | undefined = undefined
>() => {
  const context = React.useContext(
    TableEditingContext as React.Context<TableEditingContextProps<R, Context, UnwoundType>>
  );
  if (context === undefined) {
    throw new Error('useTableEditingContext must be used within a TableEditingContextProvider');
  }
  return context;
};
