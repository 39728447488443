import { useQuery } from '@tanstack/react-query';
import { getScannerSlideVisualizationPreset } from 'api/scannerSlideVisualizationPreset';

const useScannerSlideVisualizationPreset = (
  scannerManufacturer: string,
  {
    enabled = true,
  }: {
    enabled?: boolean;
  } = {}
) => {
  return useQuery(
    ['scannerSlideVisualizationPreset', scannerManufacturer],
    () => getScannerSlideVisualizationPreset(scannerManufacturer),
    { enabled }
  );
};

export default useScannerSlideVisualizationPreset;
