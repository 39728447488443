import { Tooltip, Typography, TypographyProps } from '@mui/material';
import React from 'react';
import { useClipboard } from 'use-clipboard-copy';

interface CopyableTextProps extends TypographyProps {
  text: string;
}

const CopyableText = ({ text, ...typographyProps }: CopyableTextProps) => {
  const clipboard = useClipboard({ copiedTimeout: 1000 });

  return (
    <Tooltip title={clipboard.copied ? 'Copied!' : 'Click to copy'} placement="top">
      <Typography
        variant="body1"
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        sx={{
          cursor: 'pointer',
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          clipboard.copy(text);
        }}
        {...typographyProps}
      >
        {text}
      </Typography>
    </Tooltip>
  );
};

export default CopyableText;
