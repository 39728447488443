import { yupResolver } from '@hookform/resolvers/yup';
import ExpandIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Grid, Typography } from '@mui/material';
import { runSlideRegistrations } from 'api/platform';
import { DropdownOption } from 'components/atoms/Dropdown/Dropdown';
import { useProceduresFieldsContext } from 'interfaces/procedure/fields/helpers';
import { filter, map } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { ReactElement, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { CasesParams } from 'utils/useCasesParams';
import { useMutationWithErrorSnackbar } from 'utils/useMutationWithErrorSnackbar';
import { useStainTypeIdToDisplayName } from 'utils/useStainTypeIdToDisplayName';
import * as yup from 'yup';
import { PlatformStepper } from './PlatformStepper';
import { JobNameAndDescriptionStep, StainInput } from './utils';

const SNACK_BAR_KEY_RUN_SLIDE_REGISTRATIONS = 'RUN_SLIDE_REGISTRATIONS';

export interface SlideRegistrationsFormValues {
  jobName: string;
  jobDescription: string;
  sourceStain: string;
  targetStain: string;
}

const validationSchema = [
  yup.object().shape({
    sourceStain: yup
      .string()
      .required('Stain is required')
      .notOneOf([yup.ref('targetStain')], 'Target stain must be different from source stain'),
    targetStain: yup
      .string()
      .required('Stain is required')
      .notOneOf([yup.ref('sourceStain')], 'Target stain must be different from source stain'),
  }),
  yup.object({}),
];
export interface RunSlideRegistrationsProps {
  onClose: () => void;
  casesParams: CasesParams;
}

export const RunSlideRegistrations = (props: RunSlideRegistrationsProps): ReactElement => {
  const { onClose, casesParams } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchema[activeStep];
  const {
    register,
    handleSubmit,
    control,
    watch,
    trigger,
    formState: { errors },
  } = useForm<SlideRegistrationsFormValues>({
    mode: 'onChange',
    resolver: yupResolver(currentValidationSchema),
  });
  const { stainTypesInStudy } = useProceduresFieldsContext();
  const { stainTypeIdToDisplayName } = useStainTypeIdToDisplayName();
  const stainTypeDropDownOptions: DropdownOption[] = map(stainTypesInStudy, (currStain) => ({
    value: currStain,
    text: stainTypeIdToDisplayName(currStain),
  }));
  const sourceStainTypesOptions = filter(stainTypeDropDownOptions, (option) => option.value !== watch('targetStain'));
  const targetStainTypesOptions = filter(stainTypeDropDownOptions, (option) => option.value !== watch('sourceStain'));

  const [isStepFailed, setIsStepFailed] = useState<Record<number, boolean>>({});

  const checkValidationAndSetIsStepFailed = async (stepIndex: number) => {
    const isValid = await trigger();

    setIsStepFailed((prev) => ({
      ...prev,
      [stepIndex]: !isValid,
    }));
  };
  const runSlideRegistrationsMutation = useMutationWithErrorSnackbar({
    mutationFn: runSlideRegistrations,
    mutationDescription: 'run slide registrations',
    onSuccess: () => {
      enqueueSnackbar('Slide Registrations Started', { variant: 'success' });
      onClose();
    },
    onSettled() {
      closeSnackbar(SNACK_BAR_KEY_RUN_SLIDE_REGISTRATIONS);
    },
  });

  const onSubmit: SubmitHandler<any> = (data) => {
    runSlideRegistrationsMutation.mutate({
      ...casesParams,
      ...data,
    });

    enqueueSnackbar({
      variant: 'success',
      message: (
        <Grid container>
          <Grid item>
            <Typography>Waiting for Slide Registration to start</Typography>
          </Grid>
          <Grid item>
            <CircularProgress sx={{ marginLeft: 10 }} color="inherit" size={20} />
          </Grid>
        </Grid>
      ),
      key: SNACK_BAR_KEY_RUN_SLIDE_REGISTRATIONS,
      autoHideDuration: null,
    });
  };

  const steps = [
    {
      label: 'Select Stains',
      content: (
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <StainInput
              label="Source Stain"
              name="sourceStain"
              options={sourceStainTypesOptions}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item>
            <StainInput
              label="Target Stain"
              name="targetStain"
              options={targetStainTypesOptions}
              control={control}
              errors={errors}
            />
          </Grid>
        </Grid>
      ),
      onNextOrBackClick: () => checkValidationAndSetIsStepFailed(0),
    },
    {
      label: 'Job Name and Description',
      subLabel: activeStep > 0 && watch('jobName'),
      optional: true,
      content: <JobNameAndDescriptionStep control={control} errors={errors} register={register} />,
    },
  ];

  return (
    <>
      <PlatformStepper
        handleSubmit={handleSubmit(onSubmit)}
        steps={steps}
        setActiveStepForValidation={setActiveStep}
        isStepFailed={isStepFailed}
      />
      <Accordion>
        <AccordionSummary expandIcon={<ExpandIcon />}>Cell Typing Params Summary (JSON)</AccordionSummary>
        <AccordionDetails>
          <Typography component="pre">
            {JSON.stringify(
              {
                jobName: watch('jobName'),
                jobDescription: watch('jobDescription'),
                sourceStain: watch('sourceStain'),
                targetStain: watch('targetStain'),
              },
              null,
              2
            )}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
